import React from 'react';
import '../App.css';

function Container({ children, className }) {
  return (
    <div className={`w-full flex-1 bg-signUpBG flex justify-center ${className}`}>
      <div className="max-w-4xl flex flex-col w-4xl sm_ss:w-full sm_s:w-full h-full p-7 sm_ss:p-0 sm_s:p-0 mt-5 justify-center">
        {children}
      </div>
    </div>
  );
}

export default Container;
