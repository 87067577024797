/* eslint-disable arrow-body-style */
export default function Capitalize(letter) {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const uppercaseWord = letter?.charAt(0)?.toUpperCase() + letter?.slice(1);
  return uppercaseWord;
}

export const Encrypt = (num) => {
  const random = Math.random() * 1000;
  const encryptNum = `${random}-${window.btoa(num)}`;
  return encryptNum;
};

export const Decrypt = (num) => {
  const sliceNum = num?.split('-')[1] ? num?.split('-')[1] : '';
  const decryptNum = window.atob(sliceNum);
  return decryptNum;
};

export const useForceUpdate = (setValue) => {
  return () => setValue((val) => val + 1);
};

export const numberWithCommas = (x) => {
  const value = x?.toString();
  if (value?.length > 0) {
    return x.toString().replace(/\B(?=<!\.\d*)(?=(\d{3})+(?=!\d))/g, ',');
  }
  return '';
};
