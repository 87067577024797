/* eslint-disable no-unused-vars */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'js-cookie';

import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { Canton, addNewMember } from '../../apiCalls';
import Container from '../../components/Container';
import FieldsContainer from '../../components/FieldsContainer';
import ItemContainer from '../../components/ItemContainer';
import TextInput from '../../components/TextInput';
import {
  AddNewMemberSchema,
  // countryOptions,
  // countryOptionsfr,
  language,
  languagefr,
  MemberRoles,
  MemberRolesFr,
  titleOptions,
  titleOptionsfr,
  userRoles,
} from '../../constants';
import Separater from '../../components/Separater';
import SubWrapper from '../../components/SubWrapper';
import SelectField from '../../components/SelectField';
import ErrorContainer from '../../components/ErrorContainer';
import { appRoutes } from '../../constants/routeConstants/Routes';
import { resetaddNewMember } from '../../redux/slices/AddNewMember';
import CountryCodeInput from '../CountryCodeInput';

function AddNewMember() {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [canton, setCanton] = useState([]);
  const [agree, setAgree] = useState(false);
  const [scrollState, setScrollState] = useState();
  const scrollTo = useRef();
  const noScroll = useRef();
  const dispatch = useDispatch();
  const success = useSelector((state) => state.addNewMemberReducer.success);
  const pending = useSelector((state) => state.addNewMemberReducer.pending);
  const error = useSelector((state) => state.addNewMemberReducer.error);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userRole, setUserRole] = useState();
  const filledUser = useSelector((state) => state.fetchUser.filledUser);
  const language_selected = Cookies.get('i18next');
  const methods = useForm({
    resolver: yupResolver(AddNewMemberSchema),
    defaultValues: { country: 212 },
  });

  const { errors } = methods.formState;
  console.log('errors', errors);
  useEffect(() => {
    Canton(212, setCanton);
  }, []);

  const onError = (err) => {
    setScrollState(Object.keys(err)[0]);
  };
  useEffect(() => {
    if (error?.email) {
      setScrollState('email');
    } else if (error?.mobile_number) {
      setScrollState('mobile_number');
    }
  }, [error]);

  useEffect(() => {
    if (scrollState && scrollTo?.current?.scrollIntoView) {
      scrollTo.current.scrollIntoView({
        behavior: 'smooth',
      });
      setScrollState('');
    }
  }, [scrollState, scrollTo?.current?.scrollIntoView]);

  useEffect(() => {
    if (success === true) {
      MySwal.fire({
        title: (
          <span className="flex flex-col text-base">
            <p>{t('Member_Added')}</p>
          </span>
        ),
        icon: 'success',
        confirmButtonText: 'Ok',
      }).then((res) => {
        if (res.isConfirmed) {
          dispatch(resetaddNewMember());
          navigate(appRoutes.DASHBOARD_PROVIDER);
        }
      });
    }
  }, [success]);

  const onSubmit = (data) => {
    const newMember = {
      country_code: `+${data?.countryCode?.dialCode}`,
      ...data,
      mobile_number: data?.phoneNumber,
    };
    addNewMember(newMember, dispatch);
  };

  // useEffect(() => {
  //   if (filledUser?.email) {
  //     methods.setValue('email', filledUser.email);
  //   }
  //   if (filledUser?.number) {
  //     methods.setValue('mobile_number', filledUser.number);
  //   }
  // }, [filledUser]);

  return (
    <Container className="pb-20">
      <FormProvider {...methods}>
        <form className="w-full flex flex-col" onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <div className="flex items-center md:mb-6 px-5 lg:px-0">
            <span className="font-extrabold flex text-4xl text-themeSecgray sm_ss:text-lg sm_s:text-lg sm_ss:text-center sm_s:text-center">
              {t('Add_member')}
            </span>
            <Link className="text-sm ml-2 text-blue" to={appRoutes.ORGANISATIONS}>
              {'<'} {t('Back_Button')}
            </Link>
          </div>
          <ItemContainer>
            <FieldsContainer
              refer={scrollState === 'title' ? scrollTo : noScroll}
              className="flex items-center"
            >
              <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                <div className="w-8/12 sm_ss:w-full sm_s:w-full">
                  <SelectField
                    title={t('Title')}
                    options={language_selected === 'en' ? titleOptions : titleOptionsfr}
                    id="title"
                  />
                </div>
                {errors.title && <ErrorContainer errMsg={t(`${errors.title.message}`)} />}
              </SubWrapper>
              <Separater className="w-12" />
              <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                {/* <TextInput
                  text={t('PositionProvider')}
                  textType="text"
                  placeholder="Enter your position"
                  name="position"
                  id="position"
                /> */}
              </SubWrapper>
            </FieldsContainer>
            <FieldsContainer
              refer={
                scrollState === 'first_name' || scrollState === 'last_name' ? scrollTo : noScroll
              }
              className="flex items-center "
            >
              <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('Firstname')}
                  textType="text"
                  placeholder={t('placeholderFname')}
                  name="first_name"
                  id="first_name"
                />
                {errors.first_name && <ErrorContainer errMsg={t(`${errors.first_name.message}`)} />}
              </SubWrapper>
              <Separater className="w-12" />
              <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('Lastname')}
                  textType="text"
                  placeholder={t('placeholderLname')}
                  name="last_name"
                  id="last_name"
                />
                {errors.last_name && <ErrorContainer errMsg={t(`${errors.last_name.message}`)} />}
              </SubWrapper>
            </FieldsContainer>
            <FieldsContainer
              refer={
                scrollState === 'email' || scrollState === 'mobile_number' ? scrollTo : noScroll
              }
              className="flex items-center "
            >
              <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('ProviderEmail')}
                  textType="email"
                  placeholder={t('PlaceholderEmail')}
                  name="email"
                  id="email"
                />
                {errors.email
                  ? errors.email && <ErrorContainer errMsg={t(`${errors.email.message}`)} />
                  : error?.email && <ErrorContainer errMsg={t(`${error?.email[0]}`)} />}
              </SubWrapper>
              <Separater className="w-12" />
              <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                <span
                  className={`text-sectext mb-1 md:mb-2 sm_ss:text-xs sm_s:text-xs sm_ss:mt-3 sm_s:mt-3 `}
                >
                  {t('MobilePHN')}
                </span>
                <CountryCodeInput
                  setCountryCode={(value, countryCode) => {
                    methods.setValue('countryCode', countryCode);
                    methods.setValue('country_short_code', countryCode?.countryCode);
                    methods.setValue(
                      'phoneNumber',
                      value.slice(countryCode?.dialCode?.length || 0)
                    );
                  }}
                  country="ch"
                  placeholder={t('placeholderMobileNumber')}
                  name="mobile_number"
                  id="mobile_number"
                  textType="text"
                />
                {errors.mobile_number
                  ? errors.mobile_number && (
                      <ErrorContainer errMsg={t(`${errors.mobile_number.message}`)} />
                    )
                  : error?.mobile_number && (
                      <ErrorContainer errMsg={t(`${error?.mobile_number[0]}`)} />
                    )}
              </SubWrapper>
            </FieldsContainer>
            <FieldsContainer
              refer={scrollState === 'preferred_language' ? scrollTo : noScroll}
              className="flex items-center "
            >
              <SubWrapper className="h-28 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                <div className="w-full sm_ss:w-full sm_s:w-full">
                  <SelectField
                    title={t('Language')}
                    options={language_selected === 'en' ? language : languagefr}
                    id="preferred_language"
                  />
                </div>
                {errors.preferred_language && (
                  <ErrorContainer errMsg={t(`${errors.preferred_language.message}`)} />
                )}
              </SubWrapper>
              <Separater className="w-12" />
              <SubWrapper className="h-24  mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                <div
                  ref={scrollState === 'member_role' ? scrollTo : noScroll}
                  className="w-full sm_ss:w-full sm_s:w-full"
                >
                  <div className="text-sectext pb-1 md:pb-2  sm_ss:text-xs sm_s:text-xs sm_ss:mt-3 sm_s:mt-3 ">
                    {t('Member Role *')}
                  </div>
                  <Controller
                    name="member_role"
                    control={methods.control}
                    id="member_role"
                    // defaultValue={userRoles[0].value}
                    render={({ field: props }) => (
                      <Autocomplete
                        {...props}
                        fullWidth
                        className="bg-white rounded-md"
                        size="small"
                        options={language_selected === 'en' ? MemberRoles : MemberRolesFr}
                        value={userRole}
                        isOptionEqualToValue={(option, selectValue) =>
                          option.label === selectValue.label
                        }
                        getOptionLabel={(option) => option.label}
                        // defaultValue={userRoles[0]}
                        renderInput={(params) => (
                          <TextField
                            placeholder={t('PlaceholderSelect')}
                            variant="outlined"
                            {...params}
                          />
                        )}
                        onChange={(event, data) => {
                          setUserRole(data);
                          props.onChange(data?.value);
                        }}
                      />
                    )}
                  />
                </div>

                {errors.member_role && (
                  <ErrorContainer errMsg={t(`${errors.member_role.message}`)} />
                )}
              </SubWrapper>
            </FieldsContainer>
            {/* <FieldsContainer
              refer=
              className="flex items-center "
            >
              <Separater className="w-12" />
            </FieldsContainer> */}

            {userRole === userRoles[0] ? (
              <>
                <FieldsContainer
                  refer={scrollState === 'organization_name' ? scrollTo : noScroll}
                  className="flex items-center"
                >
                  <SubWrapper className="h-24  mb-4 sm_ss:w-full flex flex-1 flex-col sm_s:w-full">
                    <TextInput
                      text={t('Organisation_name')}
                      textType="text"
                      placeholder={t('Enter your Organisation name')}
                      name="organization_name"
                      id="organization_name"
                    />
                    {errors.organization_name && (
                      <ErrorContainer errMsg={t(`${errors.organization_name.message}`)} />
                    )}
                  </SubWrapper>
                </FieldsContainer>
                <FieldsContainer
                  refer={
                    scrollState === 'organization_email' || scrollState === 'organization_phone'
                      ? scrollTo
                      : noScroll
                  }
                  className="flex items-center "
                >
                  <SubWrapper className="h-24  mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                    <TextInput
                      text={t('Organisation e-mail')}
                      textType="text"
                      placeholder={t('placeholderOrganisationE-mail')}
                      name="organization_email"
                      id="organization_email"
                    />
                    {errors.organization_email && (
                      <ErrorContainer errMsg={t(`${errors.organization_email.message}`)} />
                    )}
                  </SubWrapper>
                  <Separater className="w-12" />
                  <SubWrapper className="h-24  mb-4 sm_ss:w-full flex flex-col sm_s:w-full">
                    <TextInput
                      text={t('OrganisationPhone')}
                      textType="text"
                      placeholder={t('placeholderOrganisationPhone')}
                      name="organization_phone"
                      id="organization_phone"
                    />
                    {errors.organization_phone && (
                      <ErrorContainer errMsg={t(`${errors.organization_phone.message}`)} />
                    )}
                  </SubWrapper>
                </FieldsContainer>
              </>
            ) : (
              ''
            )}

            {/* <FieldsContainer
              refer={
                scrollState === 'street' || scrollState === 'street_number' ? scrollTo : noScroll
              }
              className="flex items-center "
            >
              <SubWrapper className="h-24  mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('StreetProvider')}
                  textType="text"
                  placeholder={t('placeholderStreetProvider')}
                  name="street"
                  id="street"
                />
                {errors.street && <ErrorContainer errMsg={t(`${errors.street.message}`)} />}
              </SubWrapper>
              <Separater className="w-12" />
              <SubWrapper className="h-24  mb-4 sm_ss:w-full flex flex-col sm_s:w-full">
                <TextInput
                  text={t('Nb')}
                  textType="text"
                  placeholder="Enter your Street number"
                  name="street_number"
                  id="street_number"
                />
                {errors.street_number && (
                  <ErrorContainer errMsg={t(`${errors.street_number.message}`)} />
                )}
              </SubWrapper>
            </FieldsContainer> */}

            {/* <FieldsContainer
              refer={scrollState === 'zip_code' || scrollState === 'city' ? scrollTo : noScroll}
              className="flex items-center "
            >
              <SubWrapper className="h-24  mb-4 sm_ss:w-full flex flex-col sm_s:w-full">
                <TextInput
                  text={t('Zip_code')}
                  textType="number"
                  placeholder="Enter your zip code"
                  name="zip_code"
                  id="zip_code"
                />
                {errors.zip_code && <ErrorContainer errMsg={t(`${errors.zip_code.message}`)} />}
              </SubWrapper>
              <Separater className="w-12" />
              <SubWrapper className="h-28 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                <div className="sm_ss:w-full sm_s:w-full">
                  {cities.length && <SelectField title={t('City')} options={cities} id="city" />}
                </div>
                {errors.city && <ErrorContainer errMsg={t(`${errors.city.message}`)} />}
              </SubWrapper>
            </FieldsContainer> */}

            {/* <FieldsContainer
              refer={scrollState === 'zip_code' ? scrollTo : noScroll}
              className="flex md:w-1/2 items-center "
            >
              <SubWrapper className="h-28 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                <div className="text-sectext pb-1 md:pb-2 ">{t('Country')}</div>
                <TextField
                  size="small"
                  className="bg-white rounded-lg"
                  text="Country *"
                  textType="text"
                  value="Switzerland"
                  placeholder="Switzerland"
                  name="country"
                  id="country"
                  disabled
                /> */}

            {/* <SelectField
                  id="country"
                  options={language_selected === 'en' ? countryOptions : countryOptionsfr}
                  title={t('formCountry')}
                  language_selected={language_selected}
                />
                {errors?.country && <ErrorContainer errMsg={t(`${errors.country.message}`)} />} */}
            {/* </SubWrapper>
            </FieldsContainer> */}
          </ItemContainer>
          <ItemContainer className="flex flex-col">
            <FieldsContainer
              refer={
                scrollState === 'password' || scrollState === 'confirm_password'
                  ? scrollTo
                  : noScroll
              }
              className="flex items-center"
            >
              <SubWrapper className="h-24  mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('password')}
                  textType={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  placeholder="*******"
                  showEyeButton
                  passwordShown={showPassword}
                  setPasswordShown={setShowPassword}
                  // togglePassword={() => {
                  //   setShowPassword(!showPassword);
                  // }}
                />
                {errors.password && <ErrorContainer errMsg={t(`${errors.password.message}`)} />}
              </SubWrapper>
              <Separater className="w-10" />
              <SubWrapper className="h-24  mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('RepeatPassword')}
                  textType={showConfirmPassword ? 'text' : 'password'}
                  id="confirm_password"
                  name="confirm_password"
                  placeholder="*******"
                  showEyeButton
                  passwordShown={showConfirmPassword}
                  setPasswordShown={setShowConfirmPassword}
                  // togglePassword={() => {
                  //   setShowConfirmPassword(!showConfirmPassword);
                  // }}
                />
                {errors.confirm_password && (
                  <ErrorContainer errMsg={t(`${errors.confirm_password.message}`)} />
                )}
              </SubWrapper>
            </FieldsContainer>
          </ItemContainer>
          <Separater className="h-10" />
          <div className="bg-white sm_ss:rounded-none sm_s:rounded-none flex items-center justify-center px-6 py-10 rounded-xl">
            <div className="flex items-start">
              <div>
                <input
                  type="checkbox"
                  className="h-6 w-6 my-auto"
                  onChange={() => setAgree(!agree)}
                />
              </div>
              <span className="ml-2 w-full text-[#000000d0]">
                {t('ProviderTerms1')}{' '}
                <span className="text-blue underline">{t('ProviderTerms2')}</span>{' '}
                {t('ProviderTerms3')}
              </span>
            </div>
          </div>
          <div className="flex items-center pt-10 sm_ss:pl-4 sm_s:pl-4">
            <button
              type="submit"
              disabled={agree === false}
              className="bg-blue h-10 disabled:bg-themeGray disabled:cursor-not-allowed w-28 text-white rounded-lg flex items-center justify-center uppercase"
            >
              {pending ? <CircularProgress className="p-2" color="inherit" /> : t('REGISTER')}
            </button>
            <div className="w-1" />
          </div>
        </form>
      </FormProvider>
    </Container>
  );
}

export default AddNewMember;
