/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */

// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-phone-input-2/lib/material.css';
import React, { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  TextField,
} from '@mui/material';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { MdCancel } from 'react-icons/md';
import { VerifyOtp, getOtp } from '../apiCalls';
import Container from '../components/Container';
import FieldsContainer from '../components/FieldsContainer';
import ItemContainer from '../components/ItemContainer';
import TextInput from '../components/TextInput';
import {
  appUserRoles,
  // countryOptions,
  // countryOptionsfr,
  language,
  languagefr,
  providerFormOrganizationSchema,
  providerFormSchema,
  titleOptions,
  titleOptionsfr,
  userRoles,
  userRolesfr,
} from '../constants';
import Separater from '../components/Separater';
import SubWrapper from '../components/SubWrapper';
import SelectField from '../components/SelectField';
import ErrorContainer from '../components/ErrorContainer';
import SuccessFullSignup from './SuccessFullSignup';
import { appRoutes } from '../constants/routeConstants/Routes';
import CountryCodeInput from '../components/CountryCodeInput';
import OtpInput from '../components/OtpInput';
import { providerSignupSuccess } from '../redux/slices/ProviderSignup';

function ProviderRegistration() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [canton, setCanton] = useState([]);
  const [agree, setAgree] = useState(false);
  const [scrollState, setScrollState] = useState();
  const scrollTo = useRef();
  const noScroll = useRef();
  const dispatch = useDispatch();
  const success = useSelector((state) => state.providerSignup.success);
  // const pending = useSelector((state) => state.providerSignup.pending);
  const error = useSelector((state) => state.providerSignup.error);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userRole, setUserRole] = useState();
  const [loadings, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  // const [cerr, setErr] = useState();
  const language_selected = Cookies.get('i18next');
  const filledUser = useSelector((state) => state.fetchUser.filledUser);
  const MySwal = withReactContent(Swal);
  const [otp, setOtp] = useState('');
  // const userRoleByIndex = language_selected === 'en' ? userRoles[0] : userRolesfr[0];

  const methods = useForm({
    resolver: yupResolver(
      userRole?.value === 'real_estate_agency' ? providerFormOrganizationSchema : providerFormSchema
    ),
  });

  const { errors } = methods.formState;

  // const { confirm_password } = methods.watch();
  const userData = JSON.parse(localStorage.getItem('user'));
  const authState = userData?.data?.isAuthenticated;
  const formValues = methods.watch();

  let token;
  if (!userData) {
    token = null;
  } else {
    token = userData?.data?.result?.access_token;
  }

  const UserRole = userData?.data?.result?.role;
  useEffect(() => {
    if (authState && token) {
      if (UserRole === appUserRoles.provider) {
        navigate(appRoutes.DASHBOARD_PROVIDER);
      } else if (userRole === appUserRoles.seeker) {
        navigate(appRoutes.DASHBOARD_SEEKER);
      }
    }
  }, [userRole, authState, token]);

  const onError = (err) => {
    setScrollState(Object.keys(err)[0]);
  };
  useEffect(() => {
    if (error?.email) {
      setScrollState('email');
    } else if (error?.mobile_number) {
      setScrollState('mobile_number');
    }
  }, [error]);

  useEffect(() => {
    if (scrollState && scrollTo?.current?.scrollIntoView) {
      scrollTo.current.scrollIntoView({
        behavior: 'smooth',
      });
      setScrollState('');
    }
  }, [scrollState, scrollTo?.current?.scrollIntoView]);
  // ************************ handle OTP State **********************//
  // eslint-disable-next-line no-shadow

  const onSubmit = (data) => {
    setLoading(true);
    setotpError('');
    setOtp();
    getOtp({
      role: 'provider',
      countryCode: `+${data?.countryCode?.dialCode || filledUser.dialCode?.dialCode}`,
      number: data?.phoneNumber || filledUser.number,
      userEmail: formValues?.email,
      prefferd_language: language_selected,
    })
      .then(() => {
        handleClickOpen();
        setLoading(false);
      })
      // eslint-disable-next-line no-shadow
      .catch((error) => {
        if (error.response.status === 400) {
          MySwal.fire({
            title: error?.response?.data?.message,
            icon: 'error',
            confirmButtonText: t('Ok'),
          });
        } else {
          MySwal.fire({
            title: Object.values(error?.response?.data?.message)?.[0],
            icon: 'error',
            confirmButtonText: t('Ok'),
          });
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (filledUser?.email) {
      methods.setValue('email', filledUser.email);
    }
    if (filledUser?.number) {
      methods.setValue('mobile_number', `${filledUser.dialCode?.dialCode}${filledUser.number}`);
    }
    if (filledUser?.dialCode) {
      methods.setValue('countryCode', filledUser.dialCode?.dialCode);
    }
    if (filledUser?.dialCode) {
      methods.setValue('countryStateCode', filledUser.dialCode?.countryCode);
    }
  }, [filledUser]);
  // console.log(filledUser, '[[[[[[[[');
  useEffect(() => {
    userRolesfr.forEach((el) => {
      if (el.value === userRoles) {
        setUserRole(el);
      }
    });
  }, [UserRole]);
  const [open, setOpen] = React.useState(false);
  const [otpError, setotpError] = useState();

  const handlesubmit = async (data) => {
    setOtp(data);
  };
  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;

    const pastedData = clipboardData.getData('text/plain');
    const otpRegex = /^\d{6}$/; // assuming the OTP is 6 digits long

    if (otpRegex.test(pastedData)) {
      const otpArray = pastedData.split('');

      const newValues = [...otpArray];
      otpArray.forEach((digit, idx) => {
        if (newValues[idx] === undefined) {
          newValues[idx] = digit;
        }
      });
      setOtp(newValues);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const otpSubmit = () => {
    setRequestLoading(true);
    let otpValue;

    // eslint-disable-next-line valid-typeof
    if (typeof otp === 'object') {
      otpValue = otp?.join('');
    } else {
      otpValue = otp;
    }

    const data = {
      role: 'provider',
      countryCode: `+${formValues?.countryCode?.dialCode || filledUser.dialCode?.dialCode}`,
      number: formValues?.phoneNumber || filledUser.number,
      Verify_otp: otpValue,
      form: formValues,
    };
    VerifyOtp(data)
      .then(() => {
        dispatch(providerSignupSuccess());
        setRequestLoading(false);
        setOpen(false);
      })
      // eslint-disable-next-line no-shadow
      .catch((err) => {
        setRequestLoading(false);

        // MySwal.fire({
        //   title: error?.response?.data?.message?.email[0],
        //   icon: 'error',
        //   confirmButtonText: t('Ok'),
        // });
        setotpError(
          err?.response?.data?.message?.code
            ? err?.response?.data?.message?.code?.[0]
            : err?.response?.data?.message
        );
      });
  };

  const ResendOtp = () => {
    setRequestLoading(true);
    getOtp({
      role: 'provider',
      countryCode: `+${formValues?.countryCode?.dialCode || filledUser.dialCode?.dialCode}`,
      number: formValues?.phoneNumber,
      userEmail: formValues?.email,
    })
      .then(() => {
        setRequestLoading(false);
        handleClickOpen();
      })
      // eslint-disable-next-line no-shadow
      .catch(() => {
        setRequestLoading(false);
      });
  };
  const onClose = () => {
    setOpen(false);
    setotpError('ddasdfas');
  };

  return (
    <Container className="pb-20">
      {success ? (
        <SuccessFullSignup />
      ) : (
        <FormProvider {...methods}>
          <form className="w-full flex flex-col" onSubmit={methods.handleSubmit(onSubmit, onError)}>
            <span className="font-extrabold text-4xl md:mb-6 text-themeSecgray sm_ss:text-lg sm_s:text-lg sm_ss:text-center sm_s:text-center">
              {t('ProviderHeading')}
            </span>
            <ItemContainer>
              <FieldsContainer
                refer={scrollState === 'title' ? scrollTo : noScroll}
                className="flex items-center"
              >
                <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                  {/* <div className="w-8/12 sm_ss:w-full sm_s:w-full"> */}
                  <SelectField
                    title={t('Title')}
                    options={language_selected === 'en' ? titleOptions : titleOptionsfr}
                    id="title"
                  />
                  {/* </div> */}
                  {errors.title && <ErrorContainer errMsg={t(`${errors.title.message}`)} />}
                </SubWrapper>

                <Separater className="w-12" />
                <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                  <div className="text-sectext pb-1 md:pb-2 ">{t('SeekerRoleRegister')}</div>
                  <Controller
                    name="chosen_role"
                    control={methods.control}
                    // defaultValue={userRoles[0].value}
                    render={({ field: props }) => (
                      <Autocomplete
                        {...props}
                        fullWidth
                        className="bg-white rounded-md"
                        size="small"
                        options={language_selected === 'en' ? userRoles : userRolesfr}
                        value={userRole}
                        isOptionEqualToValue={(option, selectValue) =>
                          option.label === selectValue.label
                        }
                        getOptionLabel={(option) => option.label}
                        // defaultValue={userRoles[0]}
                        renderInput={(params) => (
                          <TextField
                            placeholder={t('PlaceholderSelect')}
                            variant="outlined"
                            {...params}
                          />
                        )}
                        onChange={(event, data) => {
                          setUserRole(data);
                          props.onChange(data?.value);
                        }}
                      />
                    )}
                  />
                  {errors.chosen_role && (
                    <ErrorContainer errMsg={t(`${errors.chosen_role.message}`)} />
                  )}
                  {/* <TextInput
                    text={t('PositionProvider')}
                    textType="text"
                    placeholder={t('PlaceholderPosition')}
                    name="position"
                    id="position"
                  /> */}
                </SubWrapper>
              </FieldsContainer>
              <FieldsContainer
                refer={
                  scrollState === 'first_name' || scrollState === 'last_name' ? scrollTo : noScroll
                }
                className="flex items-center"
              >
                <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                  <TextInput
                    text={t('Firstname')}
                    textType="text"
                    placeholder={t('placeholderFname')}
                    name="first_name"
                    id="first_name"
                  />
                  {errors.first_name && (
                    <ErrorContainer errMsg={t(`${errors.first_name.message}`)} />
                  )}
                </SubWrapper>
                <Separater className="w-12" />
                <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                  <TextInput
                    text={t('Lastname')}
                    textType="text"
                    placeholder={t('placeholderLname')}
                    name="last_name"
                    id="last_name"
                  />
                  {errors.last_name && <ErrorContainer errMsg={t(`${errors.last_name.message}`)} />}
                </SubWrapper>
              </FieldsContainer>
              <FieldsContainer
                refer={
                  scrollState === 'email' || scrollState === 'mobile_number' ? scrollTo : noScroll
                }
                className="flex items-center"
              >
                <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                  <TextInput
                    text={t('ProviderEmail')}
                    textType="email"
                    placeholder={t('PlaceholderEmail')}
                    name="email"
                    id="email"
                  />
                  {errors.email && <ErrorContainer errMsg={t(`${errors.email.message}`)} />}
                  {error?.email && <ErrorContainer errMsg={error?.email[0]} />}
                </SubWrapper>
                <Separater className="w-12" />
                <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                  <span
                    className={`text-sectext mb-1 md:mb-2 sm_ss:text-xs sm_s:text-xs sm_ss:mt-3 sm_s:mt-3 `}
                  >
                    {t('MobilePHN')}
                  </span>
                  <CountryCodeInput
                    setCountryCode={(value, countryCode) => {
                      methods.setValue('countryCode', countryCode);
                      methods.setValue('countryStateCode', countryCode?.countryCode);
                      methods.setValue(
                        'phoneNumber',
                        value.slice(countryCode?.dialCode?.length || 0)
                      );
                    }}
                    country="ch"
                    placeholder={t('placeholderMobileNumber')}
                    name="mobile_number"
                    id="mobile_number"
                    textType="text"
                  />
                  {errors.mobile_number && (
                    <ErrorContainer errMsg={t(`${errors.mobile_number.message}`)} />
                  )}
                  {error?.mobile_number && <ErrorContainer errMsg={error?.mobile_number[0]} />}
                </SubWrapper>
              </FieldsContainer>
              <FieldsContainer
                refer={scrollState === 'preferred_language' ? scrollTo : noScroll}
                className="flex items-center "
              >
                <SubWrapper className="h-28 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                  <div className="w-1/3 sm_ss:w-full sm_s:w-full">
                    <SelectField
                      title={t('Language')}
                      options={language_selected === 'en' ? language : languagefr}
                      id="preferred_language"
                    />
                  </div>
                  {errors.preferred_language && (
                    <ErrorContainer errMsg={t(`${errors.preferred_language.message}`)} />
                  )}
                </SubWrapper>
                <Separater className="w-12" />
              </FieldsContainer>
              <Separater className="h-20" />

              {/* <FieldsContainer
                refer={scrollState === 'chosen_role' ? scrollTo : noScroll}
                className="flex items-center "
              >
                <SubWrapper className="h-24  mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                  <div className="w-1/3 sm_ss:w-full sm_s:w-full"> */}
              {/* <SelectField
                      title={`${ProviderForm.RegistringAsA}`}
                      options={userRoles}
                      id="chosen_role"
                    /> */}
              {/* <div className="text-sectext pb-1 md:pb-2 ">{t('SeekerRoleRegister')}</div>
                    <Controller
                      name="chosen_role"
                      control={methods.control}
                      // defaultValue={userRoles[0].value}
                      render={({ field: props }) => (
                        <Autocomplete
                          {...props}
                          fullWidth
                          className="bg-white rounded-md"
                          size="small"
                          options={language_selected === 'en' ? userRoles : userRolesfr}
                          value={userRole}
                          isOptionEqualToValue={(option, selectValue) =>
                            option.label === selectValue.label
                          }
                          getOptionLabel={(option) => option.label}
                          // defaultValue={userRoles[0]}
                          renderInput={(params) => (
                            <TextField
                              placeholder={t('PlaceholderSelect')}
                              variant="outlined"
                              {...params}
                            />
                          )}
                          onChange={(event, data) => {
                            setUserRole(data);
                            props.onChange(data?.value);
                          }}
                        />
                      )}
                    />
                  </div>

                  {errors.chosen_role && (
                    <ErrorContainer errMsg={t(`${errors.chosen_role.message}`)} />
                  )}
                </SubWrapper>
                <Separater className="w-12" />
              </FieldsContainer> */}
              {userRole === userRoles[0] || userRole === userRolesfr[0] ? (
                <>
                  <FieldsContainer
                    refer={scrollState === 'organisation_email' ? scrollTo : noScroll}
                    className="flex items-center"
                  >
                    <SubWrapper className="h-24  mb-4 sm_ss:w-full flex flex-1 flex-col sm_s:w-full">
                      <TextInput
                        text={t('Organisation e-mail')}
                        textType="email"
                        placeholder={t('placeholderOrganisationE-mail')}
                        name="organisation_email"
                        id="organisation_email"
                      />
                      {errors.organisation_email && (
                        <ErrorContainer errMsg={t(`${errors.organisation_email.message}`)} />
                      )}
                    </SubWrapper>
                  </FieldsContainer>
                  <FieldsContainer
                    refer={
                      scrollState === 'organisation_name' || scrollState === 'organisation_phone'
                        ? scrollTo
                        : noScroll
                    }
                    className="flex items-center"
                  >
                    <SubWrapper className="h-24  mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('Organisation_name')}
                        textType="text"
                        placeholder={t('Enter your Organisation name')}
                        name="organisation_name"
                        id="organisation_name"
                      />
                      {errors.organisation_name && (
                        <ErrorContainer errMsg={t(`${errors.organisation_name.message}`)} />
                      )}
                    </SubWrapper>
                    <Separater className="w-12" />
                    <SubWrapper className="h-24  mb-4 sm_ss:w-full flex flex-col sm_s:w-full">
                      <TextInput
                        text={t('OrganisationPhone')}
                        textType="text"
                        placeholder={t('placeholderOrganisationPhone')}
                        name="organisation_phone"
                        id="organisation_phone"
                      />
                      {errors.organisation_phone && (
                        <ErrorContainer errMsg={t(`${errors.organisation_phone.message}`)} />
                      )}
                    </SubWrapper>
                  </FieldsContainer>
                </>
              ) : (
                ''
              )}

              {/* <FieldsContainer
                refer={
                  scrollState === 'street' || scrollState === 'street_number' ? scrollTo : noScroll
                }
                className="flex items-center "
              >
                <SubWrapper className="h-24  mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                  <TextInput
                    text={t('StreetProvider')}
                    textType="text"
                    placeholder={t('placeholderStreetProvider')}
                    name="street"
                    id="street"
                  />
                  {errors.street && <ErrorContainer errMsg={t(`${errors.street.message}`)} />}
                </SubWrapper>
                <Separater className="w-12" />
                <SubWrapper className="h-24  mb-4 sm_ss:w-full flex flex-col sm_s:w-full">
                  <TextInput
                    text={t('Nb')}
                    textType="text"
                    placeholder={t('PlaceholderNb')}
                    name="street_number"
                    id="street_number"
                  />
                  {errors.street_number && (
                    <ErrorContainer errMsg={t(`${errors.street_number.message}`)} />
                  )}
                </SubWrapper>
              </FieldsContainer> */}
              {/* <FieldsContainer
                refer={scrollState === 'zip_code' || scrollState === 'city' ? scrollTo : noScroll}
                className="flex items-center "
              >
                <SubWrapper className="h-24  mb-4 sm_ss:w-full flex flex-col sm_s:w-full">
                  <TextInput
                    text={t('zipcode')}
                    textType="number"
                    placeholder={t('PlaceholderZipCode')}
                    name="zip_code"
                    id="zip_code"
                  />
                  {errors.zip_code && <ErrorContainer errMsg={t(`${errors.zip_code.message}`)} />}
                </SubWrapper>
                <Separater className="w-12" />
                <SubWrapper className="h-28 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                  <div className="sm_ss:w-full sm_s:w-full">
                    {cities.length && <SelectField title={t('city')} options={cities} id="city" />}
                  </div>
                  {errors.city && <ErrorContainer errMsg={t(`${errors.city.message}`)} />}
                </SubWrapper>
              </FieldsContainer> */}
              {/* <FieldsContainer className="flex md:w-1/2 items-center ">
                <SubWrapper className="h-28 flex-1 flex flex-col sm_ss:w-full sm_s:w-full"> */}
              {/* <div className="text-sectext pb-1 md:pb-2 ">{t('Country')}</div>
                  <TextField
                    size="small"
                    className="bg-white rounded-lg"
                    // text="Country *"
                    textType="text"
                    value="Switzerland"
                    placeholder="Switzerland"
                    name="country"
                    id="country"
                    disabled
                  /> */}
              {/* <SelectField
                    id="country"
                    options={language_selected === 'en' ? countryOptions : countryOptionsfr}
                    title={t('formCountry')}
                    language_selected={language_selected}
                  />
                </SubWrapper>
              </FieldsContainer> */}
            </ItemContainer>
            <ItemContainer className="flex flex-col">
              <FieldsContainer
                refer={
                  scrollState === 'password' || scrollState === 'confirm_password'
                    ? scrollTo
                    : noScroll
                }
                className="flex items-center"
              >
                <SubWrapper className="h-24  mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                  <TextInput
                    text={t('password')}
                    textType={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    placeholder="*******"
                    showEyeButton
                    passwordShown={showPassword}
                    setPasswordShown={setShowPassword}
                    // togglePassword={() => {
                    //   setShowPassword(!showPassword);
                    // }}
                  />
                  {errors.password && <ErrorContainer errMsg={t(`${errors.password.message}`)} />}
                </SubWrapper>

                <Separater className="w-10" />
                <SubWrapper className="h-24  mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                  <TextInput
                    text={t('RepeatPassword')}
                    textType={showConfirmPassword ? 'text' : 'password'}
                    id="confirm_password"
                    name="confirm_password"
                    placeholder="*******"
                    showEyeButton
                    passwordShown={showConfirmPassword}
                    setPasswordShown={setShowConfirmPassword}
                  />
                  {errors.confirm_password && (
                    <ErrorContainer errMsg={t(`${errors.confirm_password.message}`)} />
                  )}
                  {/* {cerr?.err && <ErrorContainer errMsg={cerr?.message} />} */}
                </SubWrapper>
              </FieldsContainer>
            </ItemContainer>
            <Separater className="h-10" />
            <div className="bg-white sm_ss:rounded-none sm_s:rounded-none flex items-center justify-center px-6 py-10 rounded-xl">
              <div className="flex items-start">
                <div>
                  <input
                    type="checkbox"
                    className="h-6 w-6 my-auto"
                    onChange={() => setAgree(!agree)}
                  />
                </div>
                <span
                  className="ml-2 w-full text-[#000000d0]"
                  // onClick={() => navigate(`${appRoutes.Terms}`)}
                >
                  {t('ProviderTerms1')}{' '}
                  <span className="text-blue underline" style={{ cursor: 'pointer' }}>
                    <a href={appRoutes.Terms} target="_blank" rel="noreferrer">
                      {t('ProviderTerms2')}
                      {'  '}
                    </a>
                  </span>{' '}
                  {t('ProviderTerms3')}
                </span>
              </div>
            </div>
            <div className="flex items-center pt-10 sm_ss:pl-4 sm_s:pl-4">
              <button
                type="submit"
                disabled={agree === false}
                className="bg-blue h-10 disabled:bg-themeGray disabled:cursor-not-allowed w-28 text-white rounded-lg flex items-center justify-center uppercase"
              >
                {/* {pending ? <CircularProgress className="p-2" color="inherit" /> : t('REGISTER')} */}
                {loadings ? <CircularProgress className="p-2" color="inherit" /> : t('REGISTER')}
              </button>
              <div className="w-1" />
            </div>
          </form>
        </FormProvider>
      )}

      <div>
        <Dialog
          sx={{
            zIndex: '999999',
          }}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <MdCancel />
              </IconButton>
              <div className="p-11">
                <div className="flex justify-center">
                  <p className="text-lg font-semibold">{t('Enter Code')}</p>
                </div>
                <div className="flex justify-center">
                  <p>
                    {t('Security Code')} +{formValues?.mobile_number}
                  </p>
                </div>
                <div className="mt-4 justify-center  ">
                  <OtpInput
                    value={otp}
                    valueLength={6}
                    onChange={handlesubmit}
                    handlePaste={handlePaste}
                  />
                  <p className="mt-2 text-center text-sm text-red-600">{t(otpError)}</p>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  className="bg-blue h-10 disabled:bg-themeGray disabled:cursor-not-allowed w-80 text-white rounded-lg flex items-center justify-center uppercase"
                  onClick={otpSubmit}
                >
                  {requestLoading ? (
                    <CircularProgress className="p-2" color="inherit" />
                  ) : (
                    t('Submit Code')
                  )}
                </button>
              </div>
              <div className="flex justify-center">
                <div className="mt-2 text-sm font-light">
                  {' '}
                  {t('DidNotRecive')}
                  <span onClick={() => ResendOtp()} className="underline cursor-pointer">
                    {t('Request_Again')}
                  </span>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </Container>
  );
}

export default ProviderRegistration;
