/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-self-compare */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'js-cookie';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  furnished,
  furnishedfr,
  housingTypeBuyOption,
  housingTypeBuyOptionfr,
  housingTypeOption,
  housingTypeOptionfr,
  LeftButtonData,
  Numbers,
  renewablePeriod,
  renewablePeriodfr,
  // propertyLocation,
  RightButtonData,
  seekerFormSchemaFirstPage,
} from '../../constants';
import PropertyStatusBar from './Dashboard/PropertyStatusBar';
import ErrorContainer from '../ErrorContainer';
import SubWrapper from '../SubWrapper';
import RadioButton from '../RadioButton';
import MultipleCheck from '../common/MultipleCheck';
import Separater from '../Separater';
import FieldsContainer from '../FieldsContainer';
import SelectField from '../SelectField';
import ItemContainer from '../ItemContainer';
import { borderSide, genevaSide } from '../../assets/images/map/mapImage';
import mapGenevaLake from '../../assets/icons/map-geneva-lake.jpg';
import mapGeneva from '../../assets/images/map-geneva/map-geneva@2x.jpg';
// import Footer from '../layoutComponents/Footer';
import { SeekerRequestNew } from '../../apiCalls';
import { appRoutes } from '../../constants/routeConstants/Routes';
import AxiosInstance from '../../Axios';
import API from '../../constants/API';
import mapGenevaIcons from '../../assets/images/map-geneva/map-geneva-icons@2x.png';
import mapGenevaLakeIcons from '../../assets/images/map/map-lake-icons@2x.png';
import TextInput from '../TextInput';

function EditSeekerHousingAlerts() {
  const MySwal = withReactContent(Swal);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [scrollState, setScrollState] = useState();
  const [preferredLocations, setPreferredLocations] = useState([]);
  const [userProfile, setUserProfile] = useState();
  const [requestType, setRequestType] = useState();
  const [ShowPreferredLocationsError, setShowPreferredLocationsError] = useState(false);
  const success = useSelector((state) => state.seekerNewReqReducer.success);
  const error = useSelector((state) => state.seekerNewReqReducer.error);
  const pending = useSelector((state) => state.seekerNewReqReducer.pending);
  const scrollTo = useRef();
  const noScroll = useRef();
  const firstFormMethods = useForm({
    resolver: yupResolver(seekerFormSchemaFirstPage),
    defaultValues: {
      request_type: 'rent',
    },
  });
  const language_selected = Cookies.get('i18next');

  const { errors: errMsg } = firstFormMethods.formState;
  const onError = (err) => {
    setScrollState(Object.keys(err)[0]);
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useLayoutEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.data?.result?.access_token;
    if (token) {
      const userProfileData = async () => {
        const res = await AxiosInstance.post(API.GETPROFILE);
        setUserProfile(res.data.result);
      };
      userProfileData();
    }
  }, []);
  const [disable, setDisable] = useState(false);
  const handleChange = (item) => {
    setShowPreferredLocationsError(false);
    if (preferredLocations.includes(item)) {
      const array = [...preferredLocations];
      array.splice(array.indexOf(item), 1);
      setPreferredLocations([...array]);
    } else {
      setPreferredLocations([...preferredLocations, item]);
    }
  };

  const nextPage = (data) => {
    if (preferredLocations.length === 0) {
      setShowPreferredLocationsError(true);
      setScrollState('ShowPreferredLocationsError');
    } else {
      const dataArr = [];
      if (data.request_type === 'buy') {
        const val = data?.housing_type?.filter((el) => el !== 'room');
        dataArr.push(...val);
      } else {
        dataArr.push(...data?.housing_type);
      }

      const { maximum_budget_range } = data;
      const maximum_budget_start = maximum_budget_range?.split(' - ')[0];
      const maximum_budget_end = maximum_budget_range?.split(' - ')[1];

      const newReqData = {
        ...data,
        preferred_locations: preferredLocations,
        maximum_budget_start:
          maximum_budget_start === 'More than CHF 3000000' ? 3000000 : maximum_budget_start,
        maximum_budget_end: maximum_budget_end === undefined ? 0 : maximum_budget_end,
        housing_type: dataArr,
      };
      setScrollState('top');
      SeekerRequestNew(dispatch, newReqData);

      setScrollState('top');
    }
  };

  useEffect(() => {
    if (success) {
      localStorage.setItem('suspend', JSON.parse(false));
      navigate(appRoutes.pendingActivation);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      MySwal.fire({
        title: 'Something went wrong',
        icon: 'error',
      });
    }
  }, [error, MySwal, dispatch]);

  useEffect(() => {
    if (userProfile) {
      setPreferredLocations(
        userProfile?.latest_request?.preferred_locations?.map((item) => item.name)
      );
    }
  }, [userProfile?.latest_request?.preferred_locations]);

  useEffect(() => {
    if (userProfile) {
      firstFormMethods.reset(userProfile?.latest_request);
      const housingTypes = userProfile?.latest_request?.housing_types?.map(
        (type) => type?.housing_type
      );
      firstFormMethods.setValue('housing_type', housingTypes);
      firstFormMethods.setValue('request_type', userProfile?.latest_request?.request_type);
    }
  }, [userProfile]);
  const value = firstFormMethods.watch();

  useEffect(() => {
    if (value?.request_type === 'buy' && value?.housing_type?.includes('room')) {
      firstFormMethods.setValue('housing_type', [], { shouldTouch: true });
    }
  }, [value?.request_type]);

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div data-aos="fade" className="w-full h-full flex flex-col items-center">
      <div className=" max-w-4xl py-6 md:mx-7 lg:mx-0 xl:mx-0 2xl:mx-0 mx-3">
        <PropertyStatusBar />
        <FormProvider {...firstFormMethods}>
          <form
            className="w-full flex flex-col"
            onSubmit={firstFormMethods.handleSubmit(nextPage, onError)}
          >
            <div className="flex items-center gap-3">
              <span className="font-extrabold text-4xl text-themeSecgray sm_ss:text-lg sm_s:text-lg sm_ss:text-center sm_s:text-center">
                {t('DesiredHousing')}
              </span>
              <div onClick={goBack} className="text-primary">
                {'< '}
                {t('Back')}
              </div>
            </div>
            <ItemContainer>
              <div
                ref={
                  scrollState === 'request_type' || scrollState === 'housing_type'
                    ? scrollTo
                    : noScroll
                }
                className="grid sm:grid-cols-2 gap-10 px-4 sm:px-0"
              >
                <div className="flex flex-col flex-1  relative">
                  <span className="text-sectext pb-2 sm_ss:text-xs sm_s:text-xs">
                    {t('SeekerReqType')}
                  </span>
                  <SubWrapper className="flex items-center">
                    <RadioButton
                      value="rent"
                      id="rent"
                      name="request_type"
                      checked={userProfile?.latest_request?.request_type === 'rent'}
                      setRequestType={setRequestType}
                      methods={firstFormMethods}
                    />
                    <span className="text-sectext text-lg ml-1 sm_ss:text-xs sm_s:text-xs py-1">
                      {t('RequestTypeRent')}
                    </span>
                  </SubWrapper>
                  <SubWrapper className="flex items-center">
                    <RadioButton
                      value="buy"
                      id="buy"
                      name="request_type"
                      checked={userProfile?.latest_request?.request_type === 'buy'}
                      setRequestType={setRequestType}
                      methods={firstFormMethods}
                    />
                    <span className="text-sectext text-lg ml-1 sm_ss:text-xs sm_s:text-xs">
                      {t('RequestTypeBuy')}
                    </span>
                  </SubWrapper>
                  {errMsg && (
                    <div className="relative">
                      <ErrorContainer className="top-1" errMsg={errMsg?.request_type?.message} />
                    </div>
                  )}
                </div>
                <div className="flex-flex-col flex-1 relative">
                  {value?.request_type === 'rent' ? (
                    <MultipleCheck
                      name="housing_type"
                      label={t('Housing_type')}
                      Options={language_selected === 'en' ? housingTypeOption : housingTypeOptionfr}
                      disabled={value?.housing_type?.includes('room')}
                      defaultValue={value?.housing_type ? value?.housing_type : []}
                    />
                  ) : (
                    <MultipleCheck
                      name="housing_type"
                      label={t('Housing_type')}
                      Options={
                        language_selected === 'en' ? housingTypeBuyOption : housingTypeBuyOptionfr
                      }
                      defaultValue={value?.housing_type}
                      // alerts={userProfile?.latest_request?.housing_types}
                    />
                  )}
                  {errMsg && (
                    <div className="relative">
                      <ErrorContainer className="top-1" errMsg={errMsg?.housing_type?.message} />
                    </div>
                  )}
                </div>
              </div>
              <Separater className="h-10" />
              <FieldsContainer
                refer={
                  scrollState === 'furnished' || scrollState === 'minimum_bedrooms'
                    ? scrollTo
                    : noScroll
                }
                className="flex items-start"
              >
                <SubWrapper className="flex-1 sm_s:px-4 h-28 w-full sm_ss:w-full sm_s:w-full">
                  <SelectField
                    title={t('Furniture')}
                    options={language_selected === 'en' ? furnished : furnishedfr}
                    id="furnished"
                    defaultValue={userProfile?.latest_request?.furnished}
                    disabled={value?.housing_type?.includes('room')}
                  />
                  {errMsg && (
                    <ErrorContainer className="-bottom-1" errMsg={errMsg?.furnished?.message} />
                  )}
                </SubWrapper>
                <Separater className="w-11" />
                <SubWrapper className="flex-1 sm_s:px-4 sm_ss:w-full sm_s:w-full">
                  <SelectField
                    title={t('Bedrooms')}
                    options={Numbers}
                    id="minimum_bedrooms"
                    defaultValue={userProfile?.latest_request?.minimum_bedrooms.toString()}
                    disabled={value?.housing_type?.includes('room')}
                  />
                  {errMsg && (
                    <ErrorContainer
                      className="-bottom-1"
                      errMsg={errMsg?.minimum_bedrooms?.message}
                    />
                  )}
                </SubWrapper>
              </FieldsContainer>
              <FieldsContainer
                refer={scrollState === 'maximum_budget_range' ? scrollTo : noScroll}
                className={`flex ${
                  value?.request_type !== 'rent' ? 'w-1/2' : 'w-full'
                } sm_ss:w-full sm_s:w-full items-start`}
              >
                <SubWrapper className="flex-1 sm_s:px-4 sm_ss:w-full sm_s:w-full">
                  <TextInput
                    text={t('Budget')}
                    textType="number"
                    id="maximum_budget_range"
                    name="maximum_budget_range"
                    defaultValue={userProfile?.latest_request?.maximum_budget_range}
                    min="1"
                  />
                  {value?.request_type !== 'buy' && (
                    <p className="mt-2">
                      {t('maximum budget recommended (30% of your gross income)')}
                    </p>
                  )}
                  {errMsg && (
                    <ErrorContainer
                      className="-bottom-1"
                      errMsg={errMsg?.maximum_budget_range?.message}
                    />
                  )}
                </SubWrapper>
                <Separater className={value?.request_type !== 'rent' ? 'w-6' : 'w-12'} />
                {value?.request_type === 'rent' && (
                  <SubWrapper className="flex-1 sm_s:px-4 w-full sm_ss:w-full sm_s:w-full">
                    <div>
                      <SelectField
                        title={t('Duration')}
                        options={language_selected === 'en' ? renewablePeriod : renewablePeriodfr}
                        id="duration"
                        defaultValue={userProfile?.latest_request?.duration}
                      />
                      {errMsg?.duration?.message && (
                        <ErrorContainer errMsg={t(`${errMsg?.duration?.message}`)} />
                      )}
                    </div>
                  </SubWrapper>
                )}
              </FieldsContainer>
            </ItemContainer>

            <Separater className="h-12" />

            <span className="font-extrabold text-4xl text-themeSecgray sm_ss:text-lg sm_s:text-lg sm_ss:text-center sm_s:text-center">
              {t('Preferred locations')}
            </span>

            <ItemContainer className="flex md:border-none lg:border-none 2lg:border-none 2xl:border-none md:bg-white lg:bg-white 2lg:bg-white 2xl:bg-white sm:bg-white sm_ss:bg-white sm_s:bg-white">
              <FieldsContainer
                refer={scrollState === 'ShowPreferredLocationsError' ? scrollTo : noScroll}
                className="grid mx-auto sm:mx-0 sm:grid-cols-2 sm:gap-4 pb-4"
              />
              {ShowPreferredLocationsError && (
                <div className="relative h-10">
                  <ErrorContainer errMsg="Select preferred location" />
                </div>
              )}
              <FieldsContainer className="flex flex-col md:flex-row gap-4 ">
                <div className="w-full flex flex-col justify-end">
                  <div className="flex h-[180px] justify-center md:justify-start">
                    <div className="flex flex-col justify-end">
                      {RightButtonData.map((item) => (
                        <Button
                          key={item.id}
                          title={t(`${item.value}`)}
                          name={t(`${item.name}`)}
                          preferredLocations={preferredLocations}
                          setIsActive={setPreferredLocations}
                          handleChange={handleChange}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="relative ">
                    <div className="w-full h-full p-6 md:p-0 ">
                      {genevaSide.map((item, index) => (
                        <img
                          src={item}
                          alt="bg"
                          className={`${
                            preferredLocations?.includes(RightButtonData[index].value)
                              ? ''
                              : 'hidden'
                          } absolute z-50 w-full p-6 md:p-0 top-0 left-0`}
                        />
                      ))}
                      <img src={mapGeneva} alt="bg" className=" w-full " />
                      <img src={mapGenevaIcons} alt="" className="absolute top-0 left-0 z-50" />
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-col ">
                  <div className="flex h-[180px] justify-center md:justify-start">
                    <div className="flex flex-col justify-end">
                      {LeftButtonData.map((item) => (
                        <Button
                          key={item.id}
                          title={item.value}
                          name={t(`${item.name}`)}
                          preferredLocations={preferredLocations}
                          setIsActive={setPreferredLocations}
                          handleChange={handleChange}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="relative">
                    <div className="w-full h-full p-6 md:p-0 ">
                      {borderSide.map((item, index) => (
                        <img
                          src={item}
                          alt="bg"
                          className={`${
                            preferredLocations?.includes(LeftButtonData[index].value)
                              ? ''
                              : 'hidden'
                          } absolute z-50 w-full p-6 md:p-0 top-0 left-0`}
                        />
                      ))}
                      <img src={mapGenevaLake} alt="bg" className=" w-full h-full" />
                      <img src={mapGenevaLakeIcons} alt="" className="absolute top-0 left-0 z-50" />
                    </div>
                  </div>
                </div>
              </FieldsContainer>
              <FieldsContainer className="flex items-center mt-3 mx-4 md:mx-0">
                <div className="w-full py-1">
                  <div className="flex flex-col">
                    <div className="capitalize text-textGray flex items-center my-1 text-sm">
                      <i className="fa-solid fa-plane bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center" />
                      {t('Airport')}
                    </div>
                    <div className="capitalize flex items-center text-textGray my-1 text-sm">
                      <i className="fa-solid fa-train bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center" />
                      {t('Train')}
                    </div>
                    <div className="capitalize text-textGray flex items-center my-1 text-sm">
                      <i className="fa-solid fa-plane bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center" />
                      {t('OldTown')}
                    </div>
                    <div className="capitalize text-textGray flex items-center my-1 text-sm">
                      <i className="fa-solid fa-plane bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center" />
                      {t('UnitedNationsn')}
                    </div>
                  </div>
                </div>
                <div className="w-full py-1">
                  <div className="flex flex-col">
                    <div className="capitalize text-textGray flex items-center my-1 text-sm">
                      {t('InternationalSchools')}
                    </div>
                    <div className="capitalize flex items-center text-textGray my-1 text-sm">
                      <div className="bg-sectext mr-1 font-bold text-white text-center pt-[1px] h-[23px] w-[25px] rounded-full">
                        1
                      </div>
                      <div className="w-full"> {t('InternationalInstituteLancy')}</div>
                    </div>
                    <div className="capitalize text-textGray flex items-center my-1 text-sm">
                      <div className="bg-sectext mr-1 font-bold text-white text-center pt-[1px] h-[23px] w-[25px] rounded-full">
                        2
                      </div>
                      <div className="w-full"> {t('Ecolint_Nations')}</div>
                    </div>
                    <div className="capitalize text-textGray flex items-center my-1 text-sm">
                      <div className="bg-sectext mr-1 font-bold text-white text-center pt-[1px] h-[23px] w-[25px] rounded-full">
                        3
                      </div>
                      <div className="w-full"> {t('Ecolint-LaGrandeBoissière')}</div>
                    </div>
                  </div>
                </div>
                <div className="w-full py-1">
                  <div className="flex flex-col">
                    <span className="capitalize text-textGray flex items-center my-1 text-sm">
                      <span className="bg-sectext mr-1 font-bold text-white h-6 w-6 rounded-full flex items-center justify-center">
                        4
                      </span>
                      {t('Ecolint-LaChâtaigneraie')}
                    </span>
                    <span className="capitalize flex items-center text-textGray my-1 text-sm">
                      <span className="bg-sectext mr-1 font-bold text-white h-6 w-6 rounded-full flex items-center justify-center">
                        5
                      </span>
                      {t('GenevaEnglishSchool')}
                    </span>
                    <span className="capitalize text-textGray flex items-center my-1 text-sm">
                      <span className="bg-sectext mr-1 font-bold text-white h-6 w-6 rounded-full flex items-center justify-center">
                        6
                      </span>
                      {t('FlorimontInstitute')}
                    </span>
                    <span className="capitalize text-textGray flex items-center my-1 text-sm">
                      <span className="bg-sectext mr-1 font-bold text-white h-6 w-6 rounded-full flex items-center justify-center">
                        7
                      </span>
                      {t('Collège Du Léman')}
                    </span>
                  </div>
                </div>
              </FieldsContainer>
            </ItemContainer>
            <button
              type="submit"
              className="bg-blue h-10 w-40 disabled:bg-themeGray disabled:cursor-not-allowed text-white rounded-lg mt-6"
            >
              {pending ? <CircularProgress className="p-2" color="inherit" /> : t('UPDATE')}
            </button>
          </form>
        </FormProvider>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

function Button({ title, name, preferredLocations, handleChange }) {
  return (
    <div
      onClick={() => handleChange(title)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleChange(title);
        }
      }}
      id={title}
      className={`bg-blue ${
        preferredLocations?.includes(title) ? 'opacity-100' : 'opacity-40'
      } w-72 h-9 mb-2 cursor-pointer px-2 flex items-center justify-between rounded-lg`}
    >
      <div className="text-white font-semibold">{name}</div>
      <i
        className={`fa-solid fa-check text-white font-semibold ${
          preferredLocations.includes(title) ? 'opacity-100' : 'opacity-0'
        }`}
      />
    </div>
  );
}

export default EditSeekerHousingAlerts;
