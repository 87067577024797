import React from 'react';
import { Link } from 'react-router-dom';

function Button({ title, route }) {
  return (
    <Link to={route} className="w-max">
      <button
        type="submit"
        className="bg-blue text-white font-bold text-sm py-2 px-5 mt-5 rounded-md"
      >
        {title}
      </button>
    </Link>
  );
}

export default Button;
