/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-var */
/* eslint-disable func-names */
/* eslint-disable react/jsx-props-no-spreading */
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import ShowImageDetails from '../common/ShowImageDetails';
import ImageDropZone from '../common/ImageDropZone';
import API from '../../constants/API';
import DraftButton from '../common/DraftButton';

function PicturesForm({
  expandAll,
  collapseAll,
  setPropertyImages,
  handelDraftSubmit,
  Data,
  error,
  scrollState,
  scrollTo,
  noScroll,
  serverImages,
  setRemoved,
  setServerImages,
  removed,
  setDuplicateImages,
  language_selected,
  draftLoading,
}) {
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const [expended, setExpended] = useState(false);
  const [deleteImage, setDeleteImage] = useState(false);
  const [extensioError, setExtensionError] = useState();
  const [errMsg, setErrMsg] = useState();
  const [legitimation_card_images, setLegitimation_card_images] = useState([]);
  const [images, setImages] = useState([]);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadImages = (files) => {
    setErrMsg();
    setExtensionError();
    if ([...files, ...images].length > 5) {
      // setErrMsg({ err: true, maxImageError: "Max 5 images allowed" });
      MySwal.fire({
        title: 'Max 5 images are allowed',
        icon: 'error',
        buttonsStyling: { backgroundColor: '#f44336' },
      });
    } else {
      // setError(false);
      const fileType = files;

      let fileExtension;
      for (let i = 0; i < fileType.length; i++) {
        [fileExtension] = fileType[i].name.split('.');
        if (fileExtension.includes('gif')) {
          setExtensionError({
            err: true,
            message: 'Only png, jpeg and jpg formats are allowed',
          });
        } else if (images.length > 5) {
          // setErrMsg({ err: true, maxImageError: "Max 5 images allowed" });
          MySwal.fire({
            title: 'Max 5 images are allowed',
            icon: 'error',
            buttonsStyling: { backgroundColor: '#f44336' },
          });
          setImages([]);
        } else {
          setImages([...images, ...Object.entries(files).map((item) => item[1])]);
        }
      }
    }
  };

  const handleDeleteImage = (file) => {
    setLegitimation_card_images([]);
    setImages();
    const newState = images.filter((item) => item.name !== file);
    setImages(newState);
    if (newState.length > 0) {
      setDeleteImage(true);
    }
  };

  const handleDeleteServerImage = (file) => {
    const newState = serverImages.filter((item) => {
      setRemoved([...removed, item?.id]);
      return item.id !== file.id;
    });
    setServerImages(newState);
    setDuplicateImages(newState?.map((picture) => picture?.id));
  };

  function encodeImageFileAsURL(element, cb) {
    var reader = new FileReader();
    reader.onloadend = function () {
      cb(reader.result);
    };
    return reader.readAsDataURL(element);
  }

  const convertImageToBase64 = () => {
    const data = [];
    for (let i = 0; i < images.length; i++) {
      // eslint-disable-next-line prefer-arrow-callback
      encodeImageFileAsURL(images[i], function (dataUrl) {
        data.push(dataUrl);
        setLegitimation_card_images(data);
      });
    }
  };

  useEffect(() => {
    if (deleteImage) {
      convertImageToBase64();
      setDeleteImage(false);
    }
  }, [deleteImage]);

  useEffect(() => {
    if (!deleteImage) {
      if (images.length > 0) {
        convertImageToBase64();
      }
    }
  }, [images.length]);

  useEffect(() => {
    if (expandAll) {
      setExpended(true);
    }
  }, [expandAll]);

  useEffect(() => {
    if (collapseAll) {
      setExpended(false);
    }
  }, [collapseAll]);

  useEffect(() => {
    if (images.length < 1) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [images.length]);

  useEffect(() => {
    if (legitimation_card_images?.length > 0) {
      setPropertyImages(legitimation_card_images);
    } else {
      setPropertyImages([]);
    }
  }, [legitimation_card_images]);

  useEffect(() => {
    if (Data?.length) {
      setPropertyImages(Data.pictures);
    }
  }, [Data]);

  return (
    <div className="border-2  rounded-lg  overflow-hidden">
      <Accordion
        className="border-themeGray"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: '0',
          border: 'solid 2px #A6A6A6',
          borderRadius: '10px !important',
        }}
        expanded={expended}
        onChange={() => {
          setExpended(!expended);
        }}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          {expended ? (
            <FaMinus className="text-primary text-2xl my-auto" />
          ) : (
            <FaPlus className="text-primary text-2xl my-auto" />
          )}
          <div className="text-primary text-2xl font-extrabold px-3">{t('Pictures')}</div>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '10px 40px' }}>
          <div
            className="grid  gap-x-4 md:gap-x-12"
            ref={scrollState === 'images' ? scrollTo : noScroll}
          >
            <ImageDropZone handleUploadImages={handleUploadImages} Data={Data} />
            {error?.err === true && (
              <span className="text-center text-base text-red-600 pt-2">{error?.message}</span>
            )}
            {extensioError?.err && (
              <span className="text-center text-red-600 pt-2">{extensioError?.message}</span>
            )}
            {errMsg?.err ? (
              <span className="text-center text-red-600 pt-2">{errMsg.maxImageError}</span>
            ) : (
              ''
            )}
            {serverImages?.length > 0 &&
              serverImages?.map((picture, index) => {
                return (
                  <div
                    key={index}
                    className="border-2 flex flex-row justify-between border-textGray rounded-sm mt-6"
                  >
                    <div className="flex w-9/12 item-center p-2 justify-start h-28">
                      <img
                        src={API.BASE_URL + picture.image_path}
                        alt="card"
                        width="176px"
                        height="96px"
                        className="rounded-md object-cover"
                      />{' '}
                      <div className="h-full overflow-hidden px-1 flex justify-start items-center w-80 sm_ss:hidden sm_s:hidden pl-2">
                        <span className="text-blue">{picture.image_path?.split('/')[2]}</span>
                      </div>
                    </div>
                    <div className="flex items-center justify-end p-3 h-28">
                      {/* <i className="fa-solid fa-bars text-textGray pr-3 cursor-pointer"></i> */}
                      <span
                        onKeyDown={() => {}}
                        onClick={() => handleDeleteServerImage(picture)}
                        className="bg-red-600 p-1 h-6 flex items-center justify-center cursor-pointer"
                      >
                        <i id={picture.id} className="fa-solid fa-trash text-white" />
                      </span>
                    </div>
                  </div>
                );
              })}
            {images &&
              images.map((val, index) => {
                return (
                  <div key={`${index + 1}`}>
                    <ShowImageDetails
                      index={index}
                      val={val}
                      data={Data}
                      handleDeleteImage={handleDeleteImage}
                    />
                  </div>
                );
              })}
          </div>
          <DraftButton
            handelDraftSubmit={() => {
              handelDraftSubmit('draft', (e) => {
                setIsLoading(e);
                if (!e) {
                  setImages([]);
                }
              });
            }}
            disable={disable}
            language_selected={language_selected}
            draftLoading={isLoading}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default PicturesForm;
