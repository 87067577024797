import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';
import { appRoutes } from '../constants/routeConstants/Routes';

function SetPasswordSuccess() {
  const { t } = useTranslation();
  return (
    <div className="w-full bg-signUpBG flex justify-center items-center h-fullX">
      <div className="w-1/2 bg-white py-5 rounded-lg flex flex-col justify-center items-center">
        <p className="text-sectext py-2">{t('ResetPWDSuccess')}</p>
        <p className="text-sectext py-2">{t('ResetPWDSuccess2')}</p>

        <Button title={t('LOGIN')} route={appRoutes.LOGIN_STEP_2} />
      </div>
    </div>
  );
}

export default SetPasswordSuccess;
