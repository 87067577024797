/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import { yupResolver } from '@hookform/resolvers/yup';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CircularProgress } from '@mui/material';
import ItemContainer from '../../ItemContainer';
import FieldsContainer from '../../FieldsContainer';
import SubWrapper from '../../SubWrapper';
import { SeekerSatisfationSurveyApi } from '../../../apiCalls';
import AxiosInstance from '../../../Axios';
import API from '../../../constants/API';
import {
  satisfactionSurveySchema,
  accomodationOptions,
  accomodationOptionsFr,
  totalRental,
  totalRentalFr,
  ourServices,
  ourServicesFr,
  premise,
  premisefr,
} from '../../../constants';
import 'aos/dist/aos.css';
import TextInput from '../../TextInput';
import ErrorContainer from '../../ErrorContainer';
import SelectField from '../../SelectField';
import { resetseekerSatisfactionSurvey } from '../../../redux/slices/SeekerSatisfactionSurvey';

function SatisfactionSurvey() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const success = useSelector((state) => state.seekerSatisfactionSurvey.success);
  const pending = useSelector((state) => state.seekerSatisfactionSurvey.pending);
  // const error = useSelector((state) => state.seekerSatisfactionSurvey.error);
  const language_selected = Cookies.get('i18next');
  const [scrollState, setScrollState] = useState();
  const [userProfile, setUserProfile] = useState();
  const scrollTo = useRef();
  const noScroll = useRef();
  const methods = useForm({
    resolver: yupResolver(satisfactionSurveySchema),
  });

  useEffect(() => {
    dispatch(resetseekerSatisfactionSurvey());
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.data?.result?.access_token;
    if (token) {
      const userProfileData = async () => {
        const res = await AxiosInstance.post(API.GETPROFILE);
        setUserProfile(res.data.result);
      };
      userProfileData();
    }
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 2500,
    });
  }, []);

  const { accommodation_through } = methods.watch();
  const { errors } = methods.formState;
  // const { errors: errMsg } = methods.formState;

  useEffect(() => {
    setScrollState(Object.keys(errors)[0]);
  }, [errors]);

  useEffect(() => {
    if (scrollState && scrollTo?.current?.scrollIntoView) {
      scrollTo.current.scrollIntoView({
        behavior: 'smooth',
      });
      setScrollState('');
    }
  }, [scrollState, scrollTo?.current?.scrollIntoView]);

  const onSubmit = (data) => {
    const surveyData = {
      request_id: userProfile && userProfile?.latest_request?.id,
      accommodation_through: data?.accommodation_through,
      other_accomodation_through: data?.other_accomodation_through,
      rental_applications_count: data?.rental_applications_count,
      allocated_landlord: data?.allocated_landlord,
      agency: data?.agency,
      services_use: data?.services_use,
      comment: data?.comment,
    };
    SeekerSatisfationSurveyApi(surveyData, dispatch).then(() => {
      MySwal.fire({
        title: t('Seeker Suspend'),
        icon: 'success',
      });
    });
  };

  const onError = (err) => {
    setScrollState(Object.keys(err)[0]);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (success === true) {
        // MySwal.fire({
        //   title: t('Survey form submitted successfully'),
        //   icon: 'success',
        // });

        const userProfileData = async () => {
          const res = await AxiosInstance.post(API.GETPROFILE);

          setUserProfile(res.data.result);
        };
        userProfileData();
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [success]);

  return (
    <div data-aos="fade" className="w-full flex items-center justify-center">
      {userProfile?.latest_request?.is_satisfaction_survey_added === 0 && (
        <div className="">
          <FormProvider {...methods}>
            <form
              ref={scrollState === 'top' ? scrollTo : noScroll}
              className="flex flex-col w-[600px] m_ss:w-[400px] sm_s:w-[400px]"
              onSubmit={methods.handleSubmit(onSubmit, onError)}
            >
              <span className="font-extrabold text-4xl text-themeSecgray sm_ss:text-lg sm_s:text-lg sm_ss:text-center sm_s:text-center">
                {t('Satisfation Survey')}
              </span>
              <ItemContainer className="flex flex-col sm_ss:px-10 sm_s:px-4 ">
                <FieldsContainer
                  className="flex items-center"
                  refer={scrollState === 'accommodation_through' ? scrollTo : noScroll}
                >
                  <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                    <SelectField
                      title={t('Found Recommendation')}
                      id="accommodation_through"
                      options={
                        language_selected === 'en' ? accomodationOptions : accomodationOptionsFr
                      }
                    />

                    <ErrorContainer
                      errMsg={
                        errors.accommodation_through?.message &&
                        t(`${errors.accommodation_through?.message}`)
                      }
                    />
                  </SubWrapper>
                </FieldsContainer>

                {accommodation_through === 'other' && (
                  <FieldsContainer
                    className="flex items-center"
                    refer={scrollState === 'other_accomodation_through' ? scrollTo : noScroll}
                  >
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('Other')}
                        id="other_accomodation_through"
                        name="other_accomodation_through"
                        textType="text"
                        placeholder={t('Please specify')}
                        max={30}
                        // maxRows={1}
                      />
                      <ErrorContainer
                        errMsg={
                          errors.other_accomodation_through?.message &&
                          t(`${errors.other_accomodation_through?.message}`)
                        }
                      />
                    </SubWrapper>
                  </FieldsContainer>
                )}

                <FieldsContainer
                  className="flex items-center"
                  refer={scrollState === 'rental_applications_count' ? scrollTo : noScroll}
                >
                  <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                    <SelectField
                      title={t('How many rental applications have you submitted')}
                      options={language_selected === 'en' ? totalRental : totalRentalFr}
                      placeholder="1 to 5"
                      id="rental_applications_count"
                      textType="text"
                    />
                    <ErrorContainer
                      errMsg={
                        errors.rental_applications_count?.message &&
                        t(`${errors.rental_applications_count?.message}`)
                      }
                    />
                  </SubWrapper>
                </FieldsContainer>

                <FieldsContainer
                  className="flex items-center"
                  refer={scrollState === 'allocated_landlord' ? scrollTo : noScroll}
                >
                  <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                    <SelectField
                      title={t('allocated accommodation')}
                      id="allocated_landlord"
                      options={language_selected === 'en' ? premise : premisefr}
                    />
                    <ErrorContainer
                      errMsg={
                        errors.allocated_landlord?.message &&
                        t(`${errors.allocated_landlord?.message}`)
                      }
                    />
                  </SubWrapper>
                </FieldsContainer>

                {/* {allocated_landlord === "a_real_estate_agency" && */}
                <FieldsContainer
                  className="flex items-center"
                  refer={scrollState === 'agency' ? scrollTo : noScroll}
                >
                  <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                    <TextInput
                      text={t('Which Agency')}
                      id="agency"
                      name="agency"
                      textType="text"
                      placeholder={t('Please specify')}
                      max={30}
                    />
                    <ErrorContainer
                      errMsg={errors.agency?.message && t(`${errors.agency?.message}`)}
                    />
                  </SubWrapper>
                </FieldsContainer>
                {/* } */}

                <FieldsContainer
                  className="flex items-center"
                  refer={scrollState === 'services_use' ? scrollTo : noScroll}
                >
                  <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                    <SelectField
                      title={t('How useful were our services to you')}
                      options={language_selected === 'en' ? ourServices : ourServicesFr}
                      placeholder="1 to 5"
                      id="services_use"
                      textType="text"
                    />
                    <ErrorContainer
                      errMsg={errors.services_use?.message && t(`${errors.services_use?.message}`)}
                    />
                  </SubWrapper>
                </FieldsContainer>

                <FieldsContainer
                  className="flex items-center"
                  refer={scrollState === 'comment' ? scrollTo : noScroll}
                >
                  <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                    <TextInput
                      text={t('comment box')}
                      id="comment"
                      name="comment"
                      textType="text"
                      placeholder={t('Comments')}
                      multiline
                      rows={2}
                      maxRows={3}
                    />

                    <ErrorContainer
                      errMsg={errors.comment?.message && t(`${errors.comment?.message}`)}
                    />
                  </SubWrapper>
                </FieldsContainer>
                <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                  <button
                    type="submit"
                    className="bg-blue text-white font-bold rounded-md mt-8 h-10 cursor-pointer w-auto sm_ss:w-full sm_s:w-full px-4"
                  >
                    {pending ? <CircularProgress className="p-2" color="inherit" /> : t('Submit')}
                  </button>
                </SubWrapper>
              </ItemContainer>
            </form>
          </FormProvider>
        </div>
      )}
    </div>
  );
}

export default SatisfactionSurvey;
