/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { yupResolver } from '@hookform/resolvers/yup';
import { IoArrowBackOutline } from 'react-icons/io5';
import { CircularProgress } from '@mui/material';
import { SeekerRegistartionApi } from '../apiCalls';
import Container from '../components/Container';
import FieldsContainer from '../components/FieldsContainer';
import ItemContainer from '../components/ItemContainer';
import SelectField from '../components/SelectField';
import Separater from '../components/Separater';
import SubWrapper from '../components/SubWrapper';
import CountryCodeInput from '../components/CountryCodeInput';
import mapGenevaLake from '../assets/icons/map-geneva-lake.jpg';
import mapGeneva from '../assets/images/map-geneva/map-geneva@2x.jpg';
import mapGenevaIcons from '../assets/images/map-geneva/map-geneva-icons@2x.png';
import mapGenevaLakeIcons from '../assets/images/map/map-lake-icons@2x.png';
import locationtrainstation from '../assets/icons/location-train-station.svg';
import locationairport from '../assets/icons/location-airport.svg';
import OLDTOWN from '../assets/icons/location-old-town.svg';
import UnitedNations from '../assets/icons/location-un.svg';
import location1 from '../assets/icons/location-1.svg';
import location2 from '../assets/icons/location-2.svg';
import location3 from '../assets/icons/location-3.svg';
import location4 from '../assets/icons/location-4.svg';
import location5 from '../assets/icons/location-5.svg';
import location6 from '../assets/icons/location-6.svg';
import location7 from '../assets/icons/location-7.svg';
import {
  LeftButtonData,
  ProviderForm,
  RightButtonData,
  furnished,
  FurnishedBoolean,
  budgetRanges,
  language,
  EmployedBy,
  LegitimationCard,
  titleOptions,
  seekerFormSchema,
  seekerFormSchemaFirstPage,
  housingTypeOption,
  numbers,
  furnishedfr,
  titleOptionsfr,
  FurnishedBooleanfr,
  languagefr,
  budgetRangesfr,
  housingTypeOptionfr,
  EmployedByfr,
  LegitimationCardFR,
  Numbers,
  ChildrenYesArray,
  renewablePeriod,
  renewablePeriodfr,
  housingTypeBuyOption,
  housingTypeBuyOptionfr,
} from '../constants';
import TextInput from '../components/TextInput';
import RadioButton from '../components/RadioButton';
// import CheckBox from '../components/CheckBox';
import SuccessFullSignup from './SuccessFullSignup';
import ErrorContainer from '../components/ErrorContainer';
import MultipleCheck from '../components/common/MultipleCheck';
import { borderSide, genevaSide } from '../assets/images/map/mapImage';

// import validations from '../util';

function SeekerRegistration() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const success = useSelector((state) => state.SeekerSignup.success);
  const pending = useSelector((state) => state.SeekerSignup.pending);
  const error = useSelector((state) => state.SeekerSignup.error);
  const [preferredLocations, setPreferredLocations] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [ShowPreferredLocationsError, setShowPreferredLocationsError] = useState(false);
  const [showNextPage, setShowNextPage] = useState(false);
  const [firstPageData, setFirstPageData] = useState({});
  const [scrollState, setScrollState] = useState();
  const [submit, setSubmit] = useState(false);
  const [requestType, setRequestType] = useState();
  const [cerr, setErr] = useState();
  const scrollTo = useRef();
  const noScroll = useRef();

  const firstFormMethods = useForm({
    resolver: yupResolver(seekerFormSchemaFirstPage),
    defaultValues: {
      request_type: 'rent',
    },
  });
  const filledUser = useSelector((state) => state.fetchUser.filledUser);
  const methods = useForm({
    resolver: yupResolver(seekerFormSchema),
  });
  const formValues = methods.watch();

  const { confirm_password, children, agree } = methods.watch();
  const language_selected = Cookies.get('i18next');
  const preferd_language = Cookies.get('i18next');
  const { errors } = methods.formState;
  const { errors: errMsg } = firstFormMethods.formState;
  const onError = (err) => {
    setScrollState(Object.keys(err)[0]);
  };

  useEffect(() => {
    if (scrollState && scrollTo?.current?.scrollIntoView) {
      scrollTo.current.scrollIntoView({
        behavior: 'smooth',
      });
      setScrollState('');
    }
  }, [scrollState, scrollTo?.current?.scrollIntoView]);

  const user_type = 'seeker';
  const nextPage = (data) => {
    if (preferredLocations.length === 0) {
      setShowPreferredLocationsError(true);
      setScrollState('ShowPreferredLocationsError');
    } else {
      setFirstPageData({ ...data, preferred_locations: preferredLocations });
      setShowNextPage(true);
      setScrollState('top');
    }
  };

  useEffect(() => {
    if (error?.email) {
      setScrollState('email');
    } else if (error?.mobile_number) {
      setScrollState('mobile_number');
    }
  }, [error]);

  const handleChange = (item) => {
    setShowPreferredLocationsError(false);
    if (preferredLocations.includes(item)) {
      const array = [...preferredLocations];
      array.splice(array.indexOf(item), 1);
      setPreferredLocations([...array]);
    } else {
      setPreferredLocations([...preferredLocations, item]);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length > 1) {
      setSubmit(true);
    }
  }, [errors]);
  useEffect(() => {
    if (filledUser?.email) {
      methods.setValue('email', filledUser.email);
    }
    if (filledUser?.number) {
      methods.setValue('mobile_number', `${filledUser.dialCode?.dialCode}${filledUser.number}`);
    }
    if (filledUser?.dialCode) {
      methods.setValue('countryCode', filledUser.dialCode?.dialCode);
    }
    if (filledUser?.dialCode) {
      methods.setValue('country_short_code', filledUser.dialCode?.countryCode);
    }
  }, [filledUser]);

  const onSubmit = (data) => {
    let dataArr = [];

    let firstPage = firstPageData?.housing_type;

    if (firstPageData.request_type === 'buy') {
      const data = firstPageData?.housing_type?.filter((el) => el !== 'room');
      dataArr.push(...data);
    } else {
      dataArr.push(...firstPage);
    }

    const { maximum_budget_range } = firstPageData;
    const maximum_budget_start = maximum_budget_range?.split(' - ')[0];
    const maximum_budget_end = maximum_budget_range?.split(' - ')[1];
    // delete firstPageData?.housing_type;
    const seekerData = {
      ...firstPageData,
      ...data,
      maximum_budget_start:
        maximum_budget_start === 'More than CHF 3000000' ? 3000000 : maximum_budget_start,
      maximum_budget_end: maximum_budget_end === undefined ? 0 : maximum_budget_end,
      user_type,
      housing_type: dataArr,
      country_code: data?.countryCode
        ? `+${filledUser.dialCode?.dialCode || data?.countryCode?.dialCode}`
        : '41',
      mobile_number: data?.phoneNumber || filledUser.number,
      language_selected: preferd_language,
    };

    SeekerRegistartionApi(seekerData, dispatch);
  };

  useEffect(() => {
    if (submit) {
      if (!confirm_password) {
        setErr({
          err: true,
          message: t('RepeatPWDErr'),
        });
      } else {
        setErr();
      }
    }
  }, [confirm_password, submit, language_selected]);

  const value = firstFormMethods.watch();

  useEffect(() => {
    if (value.request_type === 'buy' && value?.housing_type?.includes('room')) {
      firstFormMethods.setValue('housing_type', [], { shouldTouch: true });
    }
  }, [value.request_type]);

  return (
    <Container className="pb-20">
      {success ? (
        <SuccessFullSignup />
      ) : (
        <>
          {showNextPage === false && (
            <FormProvider {...firstFormMethods}>
              <form
                className="w-full flex flex-col"
                onSubmit={firstFormMethods.handleSubmit(nextPage, onError)}
              >
                <span className="font-extrabold text-4xl text-themeSecgray sm_ss:text-lg sm_s:text-lg sm_ss:text-center sm_s:text-center">
                  {t('DesiredHousing')}
                </span>
                <ItemContainer>
                  <div
                    ref={
                      scrollState === 'request_type' || scrollState === 'housing_type'
                        ? scrollTo
                        : noScroll
                    }
                    className="grid sm:grid-cols-2 gap-10 px-4 sm:px-0"
                  >
                    <div className="flex flex-col flex-1  relative">
                      <span className="text-sectext pb-2">{t('SeekerReqType')}</span>
                      <SubWrapper className="flex items-center">
                        <RadioButton
                          selected
                          value="rent"
                          id="rent"
                          name="request_type"
                          setRequestType={setRequestType}
                          methods={firstFormMethods}
                        />
                        <span className="text-sectext text-lg ml-1">{t('RequestTypeRent')}</span>
                      </SubWrapper>
                      <SubWrapper className="flex items-center">
                        <RadioButton
                          value="buy"
                          id="buy"
                          name="request_type"
                          setRequestType={setRequestType}
                          methods={firstFormMethods}
                        />
                        <span className="text-sectext text-lg ml-1">{t('RequestTypeBuy')}</span>
                      </SubWrapper>
                    </div>
                    <div className="flex-flex-col flex-1 relative">
                      {value?.request_type === 'rent' ? (
                        <MultipleCheck
                          name="housing_type"
                          label={t('Housing_type')}
                          Options={
                            language_selected === 'en' ? housingTypeOption : housingTypeOptionfr
                          }
                          disabled={value?.housing_type?.includes('room')}
                          defaultValue={value?.housing_type ? value?.housing_type : []}
                        />
                      ) : (
                        <MultipleCheck
                          name="housing_type"
                          label={t('Housing_type')}
                          Options={
                            language_selected === 'en'
                              ? housingTypeBuyOption
                              : housingTypeBuyOptionfr
                          }
                          defaultValue={value?.housing_type}
                        />
                      )}
                      <div className="relative">
                        <ErrorContainer
                          className="top-1"
                          errMsg={t(
                            `${errMsg?.housing_type?.message ? errMsg?.housing_type?.message : ''}`
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <Separater className="h-10" />
                  <FieldsContainer
                    refer={
                      scrollState === 'furnished' || scrollState === 'minimum_bedrooms'
                        ? scrollTo
                        : noScroll
                    }
                    className="flex items-start"
                  >
                    <SubWrapper className="flex-1 h-28 w-full sm_ss:w-full sm_s:w-full">
                      <SelectField
                        title={t('Furniture')}
                        options={language_selected === 'en' ? furnished : furnishedfr}
                        id="furnished"
                        defaultValue={value?.furnished}
                        disabled={
                          value?.request_type === 'rent'
                            ? value?.housing_type?.includes('room')
                            : ''
                        }
                      />
                      {errMsg && (
                        <ErrorContainer
                          className="-bottom-1"
                          errMsg={
                            errMsg?.furnished?.message ? t(`${errMsg?.furnished?.message}`) : ''
                          }
                        />
                      )}
                    </SubWrapper>
                    <Separater className="w-11" />
                    <SubWrapper className="flex-1 sm_ss:w-full sm_s:w-full">
                      <SelectField
                        title={t('Bedrooms')}
                        options={Numbers}
                        id="minimum_bedrooms"
                        defaultValue={value?.minimum_bedrooms}
                        disabled={
                          value?.request_type === 'rent'
                            ? value?.housing_type?.includes('room')
                            : ''
                        }
                      />
                      {errMsg && (
                        <ErrorContainer
                          className="-bottom-1"
                          errMsg={
                            errMsg?.minimum_bedrooms?.message
                              ? t(`${errMsg?.minimum_bedrooms?.message}`)
                              : ''
                          }
                        />
                      )}
                    </SubWrapper>
                  </FieldsContainer>
                  <FieldsContainer
                    refer={scrollState === 'maximum_budget_range' ? scrollTo : noScroll}
                    className={`flex ${
                      value?.request_type !== 'rent' ? 'w-1/2' : 'w-full'
                    } sm_ss:w-full sm_s:w-full items-start`}
                  >
                    <SubWrapper className="flex-1 h-28 w-full sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('Budget')}
                        textType="number"
                        id="maximum_budget_range"
                        name="maximum_budget_range"
                        defaultValue={value?.maximum_budget_range}
                        placeholder={t('Please enter maximum budget')}
                        min="1"
                      />
                      {value?.request_type !== 'buy' && (
                        <p className="mt-2">
                          {t('maximum budget recommended (30% of your gross income)')}
                        </p>
                      )}
                      {errMsg && (
                        <ErrorContainer
                          className="-bottom-1"
                          errMsg={
                            errMsg?.maximum_budget_range?.message
                              ? t(`${errMsg?.maximum_budget_range?.message}`)
                              : ''
                          }
                        />
                      )}
                    </SubWrapper>
                    <Separater className={value?.request_type !== 'rent' ? 'w-6' : 'w-12'} />
                    {value?.request_type === 'rent' && (
                      <SubWrapper className="flex-1 h-28 w-full sm_ss:w-full sm_s:w-full">
                        <div ref={scrollState === 'duration' ? scrollTo : noScroll}>
                          <SelectField
                            title={t('Duration')}
                            options={
                              language_selected === 'en' ? renewablePeriod : renewablePeriodfr
                            }
                            id="duration"
                            defaultValue={value?.duration}
                          />
                          {errMsg?.duration?.message && (
                            <ErrorContainer errMsg={t(`${errMsg?.duration?.message}`)} />
                          )}
                        </div>
                      </SubWrapper>
                    )}
                  </FieldsContainer>
                </ItemContainer>

                <Separater className="h-12" />

                <span className="font-extrabold text-4xl text-themeSecgray sm_ss:text-lg sm_s:text-lg sm_ss:text-center sm_s:text-center">
                  {t('Preferred locations')}
                </span>

                <ItemContainer className="flex md:border-none lg:border-none 2lg:border-none 2xl:border-none md:bg-white lg:bg-white 2lg:bg-white 2xl:bg-white sm:bg-white sm_ss:bg-white sm_s:bg-white">
                  <FieldsContainer
                    refer={scrollState === 'ShowPreferredLocationsError' ? scrollTo : noScroll}
                    className="grid mx-auto sm:mx-0 sm:grid-cols-2 sm:gap-4 pb-4"
                  />
                  {ShowPreferredLocationsError && (
                    <div className="relative h-10">
                      <ErrorContainer errMsg={t('PrefferdLocationErr')} />
                    </div>
                  )}
                  <FieldsContainer className="flex flex-col md:flex-row gap-4">
                    <div className="w-full flex flex-col justify-end">
                      <div className="flex h-[180px] justify-center md:justify-start">
                        <div className="flex flex-col justify-end">
                          {RightButtonData.map((item) => (
                            <Button
                              Key={item.id}
                              title={item.value}
                              preferredLocations={preferredLocations}
                              setIsActive={setPreferredLocations}
                              handleChange={handleChange}
                              name={t(`${item.name}`)}
                            />
                          ))}
                        </div>
                      </div>

                      <div className="relative">
                        <div className="w-full h-full p-6 md:p-0">
                          {genevaSide.map((item, index) => (
                            <img
                              src={item}
                              alt="bg"
                              className={`${
                                preferredLocations.includes(RightButtonData[index].value)
                                  ? ''
                                  : 'hidden'
                              } absolute z-50 w-full p-6 md:p-0 top-[1px] left-0`}
                            />
                          ))}

                          <img src={mapGeneva} alt="bg" className=" w-full " />
                          <img src={mapGenevaIcons} alt="" className="absolute top-0 left-0 z-50" />
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex flex-col ">
                      <div className="flex h-[180px] justify-center md:justify-start">
                        <div className="flex flex-col justify-end">
                          {LeftButtonData.map((item) => (
                            <Button
                              Key={item.id}
                              title={item.value}
                              preferredLocations={preferredLocations}
                              setIsActive={setPreferredLocations}
                              handleChange={handleChange}
                              name={t(`${item.name}`)}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="relative">
                        <div className="w-full h-full p-6 md:p-0 ">
                          {borderSide.map((item, index) => (
                            <img
                              src={item}
                              alt="bg"
                              className={`${
                                preferredLocations.includes(LeftButtonData[index].value)
                                  ? ''
                                  : 'hidden'
                              } absolute z-50 w-full p-6 md:p-0 top-[1.9px] right-[1.5px]`}
                            />
                          ))}
                          <img src={mapGenevaLake} alt="bg" className=" w-full h-full" />
                          <img
                            src={mapGenevaLakeIcons}
                            alt=""
                            className="absolute top-0 left-0 z-50"
                          />
                        </div>
                      </div>
                    </div>
                  </FieldsContainer>
                  <FieldsContainer className="flex items-center mt-3 mx-4 md:mx-0">
                    <div className="w-full py-1">
                      <div className="flex flex-col">
                        <div className="capitalize text-textGray flex items-center my-1 text-sm">
                          <img
                            src={locationairport}
                            alt="locationairport"
                            className=" bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center"
                          />
                          {t('Airport')}
                        </div>
                        <div className="capitalize flex items-center text-textGray my-1 text-sm">
                          <img
                            src={locationtrainstation}
                            alt="locationtrainstation"
                            className=" bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center"
                          />
                          {t('Train')}
                        </div>
                        <div className="capitalize text-textGray flex items-center my-1 text-sm">
                          <img
                            src={OLDTOWN}
                            alt="OLDTOWN"
                            className=" bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center"
                          />
                          {t('OldTown')}
                        </div>
                        <div className="capitalize text-textGray flex items-center my-1 text-sm">
                          <img
                            src={UnitedNations}
                            alt="OLDTOWN"
                            className=" bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center"
                          />
                          {t('UnitedNationsn')}
                        </div>
                      </div>
                    </div>
                    <div className="w-full py-1">
                      <div className="flex flex-col">
                        <div className="capitalize text-textGray flex items-center my-1 text-sm">
                          {t('InternationalSchools')}
                        </div>
                        <div className="capitalize flex items-center text-textGray my-1 text-sm">
                          <img
                            src={location1}
                            alt=""
                            className="bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center"
                          />
                          <div className="w-full"> {t('InternationalInstituteLancy')}</div>
                        </div>
                        <div className="capitalize text-textGray flex items-center my-1 text-sm">
                          <img
                            src={location2}
                            alt=""
                            className="bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center"
                          />
                          <div className="w-full"> {t('Ecolint_Nations')}</div>
                        </div>
                        <div className="capitalize text-textGray flex items-center my-1 text-sm">
                          <img
                            src={location3}
                            alt=""
                            className="bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center"
                          />
                          <div className="w-full"> {t('Ecolint-LaGrandeBoissière')}</div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full py-1">
                      <div className="flex flex-col">
                        <span className="capitalize text-textGray flex items-center my-1 text-sm">
                          <img
                            src={location4}
                            alt=""
                            className="bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center"
                          />
                          {t('Ecolint-LaChâtaigneraie')}
                        </span>
                        <span className="capitalize flex items-center text-textGray my-1 text-sm">
                          <img
                            src={location5}
                            alt=""
                            className="bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center"
                          />
                          {t('GenevaEnglishSchool')}
                        </span>
                        <span className="capitalize text-textGray flex items-center my-1 text-sm">
                          <img
                            src={location6}
                            alt=""
                            className="bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center"
                          />
                          {t('FlorimontInstitute')}
                        </span>
                        <span className="capitalize text-textGray flex items-center my-1 text-sm">
                          <img
                            src={location7}
                            alt=""
                            className="bg-sectext mr-1 text-white h-6 w-6 rounded-full flex items-center justify-center"
                          />
                          {t('Collège Du Léman')}
                        </span>
                      </div>
                    </div>
                  </FieldsContainer>
                </ItemContainer>
                <button type="submit" className="text-white bg-blue py-2 w-28 rounded-lg">
                  {t('NEXT')}
                </button>
              </form>
            </FormProvider>
          )}
          {showNextPage && (
            <FormProvider {...methods}>
              <form
                ref={scrollState === 'top' ? scrollTo : noScroll}
                className="w-full flex flex-col"
                onSubmit={methods.handleSubmit(onSubmit, onError)}
              >
                <div className="flex items-center">
                  <IoArrowBackOutline
                    size="20px"
                    color="#6F6F6F"
                    className="cursor-pointer"
                    onClick={() => setShowNextPage(false)}
                  />
                  <span className="font-extrabold text-4xl text-themeSecgray sm_ss:text-lg sm_s:text-lg sm_ss:text-center sm_s:text-center">
                    {t('My profile')}
                  </span>
                </div>

                <ItemContainer className="flex flex-col">
                  <FieldsContainer
                    refer={scrollState === 'title' ? scrollTo : noScroll}
                    className="flex items-center"
                  >
                    <SubWrapper className="h-28 md:w-1/4 items-center">
                      <SelectField
                        title={t('Title')}
                        id="title"
                        options={language_selected === 'en' ? titleOptions : titleOptionsfr}
                        defaultValue=""
                      />
                      {errors.title && <ErrorContainer errMsg={t(`${errors.title.message}`)} />}
                    </SubWrapper>
                  </FieldsContainer>
                  <FieldsContainer
                    refer={
                      scrollState === 'first_name' || scrollState === 'last_name'
                        ? scrollTo
                        : noScroll
                    }
                    className="flex items-center"
                  >
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('Firstname')}
                        id="first_name"
                        name="first_name"
                        textType="text"
                        placeholder={t('placeholderFname')}
                      />
                      {errors.first_name && (
                        <ErrorContainer errMsg={t(`${errors.first_name.message}`)} />
                      )}
                    </SubWrapper>
                    <Separater className="w-10" />
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('Lastname')}
                        id="last_name"
                        name="last_name"
                        textType="text"
                        placeholder={t('placeholderLname')}
                      />
                      {errors.last_name && (
                        <ErrorContainer errMsg={t(`${errors.last_name.message}`)} />
                      )}
                    </SubWrapper>
                  </FieldsContainer>
                  <FieldsContainer
                    refer={
                      scrollState === 'email' || scrollState === 'alternative_email'
                        ? scrollTo
                        : noScroll
                    }
                    className="flex items-center"
                  >
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('EmailSeeker')}
                        textType="email"
                        id="email"
                        name="email"
                        placeholder={t('placeholderEmail')}
                      />
                      {errors.email && <ErrorContainer errMsg={t(`${errors.email.message}`)} />}
                      {error?.email && <ErrorContainer errMsg={error?.email[0]} />}
                    </SubWrapper>
                    <Separater className="w-10" />
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('ALternativeEmail')}
                        textType="email"
                        id="alternate_email"
                        name="alternate_email"
                        placeholder={t('placeholderalternateEmail')}
                      />
                      {errors.alternative_email && (
                        <ErrorContainer errMsg={t(`${errors.alternative_email.message}`)} />
                      )}
                    </SubWrapper>
                  </FieldsContainer>
                  <FieldsContainer
                    refer={
                      scrollState === 'mobile_number' || scrollState === 'professional_phone'
                        ? scrollTo
                        : noScroll
                    }
                    className="flex items-center"
                  >
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      {/* <TextInput
                        text={t('SMobilePHN')}
                        textType="text"
                        id="mobile_number"
                        name="mobile_number"
                        placeholder={t('placeholderMobileNumber')}
                      /> */}
                      <span
                        className={`text-sectext mb-1 md:mb-2 sm_ss:text-xs sm_s:text-xs sm_ss:mt-3 sm_s:mt-3 `}
                      >
                        {t('SMobilePHN')}
                      </span>
                      <CountryCodeInput
                        setCountryCode={(value, countryCode) => {
                          methods.setValue('countryCode', countryCode);
                          methods.setValue('country_short_code', countryCode?.countryCode);
                          methods.setValue(
                            'phoneNumber',
                            value.slice(countryCode?.dialCode?.length || 0)
                          );
                        }}
                        country="ch"
                        placeholder={t('placeholderMobileNumber')}
                        name="mobile_number"
                        id="mobile_number"
                        textType="text"
                      />
                      {errors.mobile_number && (
                        <ErrorContainer errMsg={t(`${errors.mobile_number.message}`)} />
                      )}
                      {error?.mobile_number && <ErrorContainer errMsg={error?.mobile_number[0]} />}
                    </SubWrapper>
                    <Separater className="w-10" />
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('ProfessionalPhone')}
                        textType="text"
                        id="professional_phone"
                        name="professional_phone"
                        placeholder={t('placeholderProMobileNumber')}
                      />
                      {errors.professional_phone && (
                        <ErrorContainer errMsg={t(`${errors.professional_phone.message}`)} />
                      )}
                    </SubWrapper>
                  </FieldsContainer>
                  <FieldsContainer
                    refer={
                      scrollState === 'spouse_partner' || scrollState === 'children'
                        ? scrollTo
                        : noScroll
                    }
                    className="flex items-center"
                  >
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <SelectField
                        title={t('Spousepartner')}
                        options={language_selected === 'en' ? FurnishedBoolean : FurnishedBooleanfr}
                        id="spouse_partner"
                        defaultValue=""
                      />
                      {errors.spouse_partner && (
                        <ErrorContainer errMsg={t(`${errors.spouse_partner.message}`)} />
                      )}
                    </SubWrapper>
                    <Separater className="w-10" />
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <SelectField
                        title={t('Children')}
                        options={language_selected === 'en' ? FurnishedBoolean : FurnishedBooleanfr}
                        id="children"
                        defaultValue=""
                      />
                      {errors.children && (
                        <ErrorContainer errMsg={t(`${errors.children.message}`)} />
                      )}
                    </SubWrapper>
                  </FieldsContainer>

                  <FieldsContainer
                    refer={scrollState === 'preferred_language' ? scrollTo : noScroll}
                    className="flex items-center"
                  >
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <SelectField
                        title={t('Language')}
                        options={language_selected === 'en' ? language : languagefr}
                        id="preferred_language"
                        defaultValue=""
                      />
                      {errors.preferred_language && (
                        <ErrorContainer errMsg={t(`${errors.preferred_language.message}`)} />
                      )}
                    </SubWrapper>

                    <Separater className="w-10" />
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      {children === true && (
                        <>
                          <div ref={scrollState === 'children_count' ? scrollTo : noScroll}>
                            <SelectField
                              title={t('ifChildrengetSelected')}
                              options={ChildrenYesArray}
                              id="children_count"
                              defaultValue=""
                            />
                          </div>
                          {errors.children_count && (
                            <ErrorContainer errMsg={t(`${errors.children_count.message}`)} />
                          )}
                        </>
                      )}
                    </SubWrapper>

                    {/* <Separater className="w-10" />

                    <SubWrapper className="h-28 sm:w-1/2 md:w-1/4 items-center">
                      <SelectField
                        title={t('Language')}
                        options={language_selected === 'en' ? language : languagefr}
                        id="preferred_language"
                        defaultValue=""
                      />
                      {errors.preferred_language && (
                        <ErrorContainer errMsg={t(`${errors.preferred_language.message}`)} />
                      )}
                    </SubWrapper> */}
                  </FieldsContainer>

                  <Separater className="h-10" />
                  <FieldsContainer
                    refer={
                      scrollState === 'employed_by' || scrollState === 'organisation_employer'
                        ? scrollTo
                        : noScroll
                    }
                    className="flex items-center"
                  >
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <SelectField
                        title={t('Employedby')}
                        options={language_selected === 'en' ? EmployedBy : EmployedByfr}
                        id="employed_by"
                        defaultValue=""
                      />
                      {errors.employed_by && (
                        <ErrorContainer errMsg={t(`${errors.employed_by.message}`)} />
                      )}
                    </SubWrapper>
                    <Separater className="w-10" />
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('Organisation(employer)')}
                        textType="text"
                        id="organisation_employer"
                        name="organisation_employer"
                        placeholder={t('placeholderOrg')}
                      />
                      {errors.organisation_employer && (
                        <ErrorContainer errMsg={t(`${errors.organisation_employer.message}`)} />
                      )}
                    </SubWrapper>
                  </FieldsContainer>
                  <FieldsContainer
                    refer={
                      scrollState === 'position' || scrollState === 'legitimation_card_type'
                        ? scrollTo
                        : noScroll
                    }
                    className="flex items-center"
                  >
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('Position')}
                        textType="text"
                        id="position"
                        name="position"
                        placeholder={t('PlaceholderPosition')}
                      />
                      {errors.position && (
                        <ErrorContainer errMsg={t(`${errors.position.message}`)} />
                      )}
                    </SubWrapper>
                    <Separater className="w-10" />
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <SelectField
                        title={t('Legitimationcard')}
                        options={language_selected === 'en' ? LegitimationCard : LegitimationCardFR}
                        id="legitimation_card_type"
                        defaultValue=""
                      />
                      {errors.legitimation_card_type && (
                        <ErrorContainer errMsg={t(`${errors.legitimation_card_type.message}`)} />
                      )}
                    </SubWrapper>
                  </FieldsContainer>
                  <FieldsContainer
                    refer={scrollState === 'is_intern' ? scrollTo : noScroll}
                    className="flex items-center"
                  >
                    <SubWrapper className="h-28 flex md:w-1/4 items-center">
                      <input
                        className="h-5 my-auto w-5 cursor-pointer"
                        {...methods.register('is_intern')}
                        type="checkbox"
                        name="is_intern"
                      />
                      {/* <CheckBox value="yes" id="is_intern" name="is_intern" /> */}
                      <span className="text-sectext ml-4">{t('Intern')}</span>
                    </SubWrapper>
                  </FieldsContainer>
                </ItemContainer>

                <span className="font-extrabold text-4xl py-4 text-themeSecgray sm_ss:text-lg sm_s:text-lg sm_ss:text-center sm_s:text-center">
                  {t('Account password')}
                </span>
                <span className="text-black pb-2 mx-4 md:mx-0">{t('ManageEmail')}</span>

                <ItemContainer className="flex flex-col">
                  <FieldsContainer
                    refer={
                      scrollState === 'password' || scrollState === 'confirm_password'
                        ? scrollTo
                        : noScroll
                    }
                    className="flex items-center"
                  >
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('password')}
                        textType={showPassword ? 'text' : 'password'}
                        id="password"
                        name="password"
                        placeholder="*******"
                        showEyeButton
                        passwordShown={showPassword}
                        setPasswordShown={setShowPassword}
                        // togglePassword={() => {
                        //   setShowPassword(!showPassword);
                        // }}
                      />
                      {errors.password && (
                        <ErrorContainer errMsg={t(`${errors.password.message}`)} />
                      )}
                    </SubWrapper>
                    <Separater className="w-10" />
                    <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('RepeatPassword')}
                        textType={showConfirmPassword ? 'text' : 'password'}
                        id="confirm_password"
                        name="confirm_password"
                        placeholder="*******"
                        showEyeButton
                        passwordShown={showConfirmPassword}
                        setPasswordShown={setShowConfirmPassword}
                        // togglePassword={() => {
                        //   setShowConfirmPassword(!showConfirmPassword);
                        // }}
                      />
                      {errors.confirm_password && (
                        <ErrorContainer errMsg={t(`${errors.confirm_password.message}`)} />
                      )}

                      {/* {cerr?.err && <ErrorContainer errMsg={cerr?.message} />} */}
                    </SubWrapper>
                  </FieldsContainer>
                </ItemContainer>

                <ItemContainer className="flex flex-col md:border-none lg:border-none 2lg:border-none 2xl:border-none md:bg-white lg:bg-white 2lg:bg-white 2xl:bg-white sm:bg-white sm_ss:bg-white sm_s:bg-white">
                  <span className="font-semibold mx-4">{t('seekerTerms')}</span>
                  <div className="flex mx-4 pt-5 items-start">
                    <div>
                      <input
                        type="checkbox"
                        id="agree"
                        name="agree"
                        {...methods.register('agree')}
                        className="h-5 w-5 mr-2 cursor-pointer"
                      />
                    </div>
                    <span className="text-sectext w-full">
                      {t('seekerTerms2')}{' '}
                      <a
                        href="https://www.cagi.ch/en/terms-and-conditions/"
                        target="__blank"
                        className="text-blue cursor-pointer underline"
                      >
                        {t('seekerTerms3')}
                      </a>{' '}
                      {t('seekerTerms4')}
                    </span>
                  </div>
                </ItemContainer>
                <button
                  disabled={agree === false}
                  type="submit"
                  className="bg-blue h-10 w-40 disabled:bg-themeGray disabled:cursor-not-allowed text-white rounded-lg mt-6"
                >
                  {pending ? <CircularProgress className="p-2" color="inherit" /> : t('REGISTER')}
                </button>
              </form>
            </FormProvider>
          )}
        </>
      )}
    </Container>
  );
}

function Button({ title, name, preferredLocations, handleChange, Key }) {
  return (
    <div
      key={Key}
      onClick={() => handleChange(title)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleChange(title);
        }
      }}
      id={title}
      className={`bg-blue ${
        preferredLocations.includes(title) ? 'opacity-100' : 'opacity-40'
      } w-[270px] h-9 mb-2 cursor-pointer px-2 flex items-center justify-between rounded-lg`}
    >
      <div className="text-white font-semibold">{name}</div>
      <i
        className={`fa-solid fa-check text-white font-semibold ${
          preferredLocations.includes(title) ? 'opacity-100' : 'opacity-0'
        }`}
      />
    </div>
  );
}

export default SeekerRegistration;
