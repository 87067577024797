/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-curly-brace-presence */
// eslint-disable-next-line react/jsx-props-no-spreading
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { fetchUserByEmail, fetchUserByNumber } from '../apiCalls';
import { resetfetchUser } from '../redux/slices/FetchUser';
import ProfileConfirmation from './ProfileConformation';
import CountryCodeInput from '../components/CountryCodeInput';

function EmployerLogin() {
  const { t } = useTranslation();
  const [showNextPage, setShowNextPage] = useState(false);
  const dispatch = useDispatch();
  const pending = useSelector((state) => state.fetchUser.pending);
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  // const [type, setType] = useState('provider');
  const [dialCode, setDialCode] = useState();
  const [numberState, setNumberState] = useState('');
  useEffect(() => {
    dispatch(resetfetchUser());
  }, []);

  useEffect(() => {
    if (email || number) {
      setDisable(false);
    } else if (email === '' || number === '') {
      setDisable(true);
    }
  }, [email, number]);

  function validateEmail(data) {
    const re = /\S+@\S+\.\S+/;
    return re.test(data);
  }

  const validations = (email) => {
    const errors = {};

    if (!validateEmail(email)) {
      errors.email = 'Please Enter a valid email';
    }

    return errors;
  };

  const numberValidation = (number) => {
    const errors = {};
    if (number?.length < 8 || number?.length > 13) {
      errors.number = t('Mobile number must be between 8 and 13 characters');
    }
    return errors;
  };

  useEffect(() => {
    if (email || number) {
      setDisable(false);
    } else if (email === '' || number === '') {
      setDisable(true);
    }
  }, [email, number]);

  useEffect(() => {
    if (submit) {
      if (email) {
        const err = validations(email);
        if (Object.keys(err).length > 0) {
          setError(true);
          setErrMsg(err);
        } else {
          setError(false);
          setErrMsg();
        }
      }
      if (number) {
        const err = numberValidation(number);
        if (Object.keys(err).length > 0) {
          setError(true);
          setErrMsg(err);
        } else {
          setError(false);
          setErrMsg();
        }
      }
    }
  }, [email, number, submit]);

  const handleData = (e) => {
    e.preventDefault();
    setSubmit(true);
    const user_type = localStorage.getItem('user_type')
      ? localStorage.getItem('user_type')
      : 'provider';
    if (email) {
      const err = validations(email);
      if (Object.keys(err).length > 0) {
        setError(true);
        setErrMsg(err);
      } else {
        fetchUserByEmail(email, user_type, dispatch);
        setShowNextPage(true);
      }
    } else if (number.length > 0) {
      const err = numberValidation(number);
      if (Object.keys(err).length > 0) {
        setError(true);
        setErrMsg(err);
      } else {
        fetchUserByNumber(
          number.slice(dialCode?.dialCode?.length || 0),
          user_type,
          dialCode,
          numberState,
          dispatch
        );
        setShowNextPage(true);
      }
    }
  };

  return (
    <>
      {showNextPage === false && (
        <div className="w-full h-full flex flex-col items-center flex-1">
          <div className="flex my-auto flex-col max-w-4xl smx:w-3xl md:w-3xl 2xl:w-3xl sm_ss:w-full sm_ss:p-0 sm_s:p-0 sm:w-full sm_s:w-full">
            <form
              onSubmit={(e) => handleData(e)}
              className="bg-white overflow-hidden sm_ss:rounded-none sm_s:rounded-none flex flex-col rounded-3xl sm_ss:w-full sm_s:w-full p-3 md:p-10"
            >
              <div className="flex flex-col items-center">
                <div className="w-full sm_ss:w-full sm_s:w-full flex flex-col justify-center relative">
                  <div className="flex flex-col relative h-24">
                    <span className="text-black pb-2">{t('Email')}</span>
                    <input
                      type="text"
                      className="p-2 border border-themeGray rounded-lg outline-none disabled:bg-templategray"
                      value={email}
                      placeholder={t('PlaceholderEmail')}
                      disabled={!!number}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {error && number.length === 0 && (
                      <span className="text-red-600 text-xs absolute bottom-1">
                        {t(errMsg.email)}
                      </span>
                    )}
                  </div>
                </div>
                <span className="self-center text-sectext py-2">{t('OR')}</span>
                <div className="w-full  sm_ss:w-full sm_s:w-full flex flex-col justify-center relative">
                  <div className="flex flex-col relative h-24">
                    <span className="text-black pb-2">{t('Number')}</span>

                    <PhoneInput
                      onChange={(e, data) => {
                        setNumber(e);
                        setDialCode(data);
                        setNumberState(data?.countryCode);
                      }}
                      value={number}
                      placeholder={t('PlaceholderNumber')}
                      country="ch"
                      inputProps={{
                        style: {
                          padding: '7.3px 19px 7.5px 58px',
                          width: '99%',
                          borderRadius: '0.5rem',
                          border: '1px solid rgb(166 166 166)',
                        },
                      }}
                      specialLabel=""
                      type="text"
                    />

                    {/* <input
                      type="text"
                      className="p-2 border border-themeGray rounded-lg outline-none disabled:bg-templategray"
                      value={number}
                      placeholder={t('PlaceholderNumber')}
                      disabled={!!email}
                      onChange={(e) => setNumber(e.target.value)}
                    /> */}
                    {errMsg?.number ? (
                      ''
                    ) : (
                      <span className="text-themeGray">Format: +00 00 000 00 00</span>
                    )}
                    {error && email.length === 0 && (
                      <span className="text-red-600 text-xs absolute bottom-1">
                        {errMsg.number}
                      </span>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={disable}
                  className="disabled:bg-textGray self-center mt-4 disabled:cursor-not-allowed bg-blue px-4 py-2 text-white rounded-lg uppercase font-semibold text-sm 2xl:text-lg"
                >
                  {pending ? <CircularProgress /> : t('Continue')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showNextPage === true && <ProfileConfirmation />}
    </>
  );
}

export default EmployerLogin;
