import { createSlice } from '@reduxjs/toolkit';

const seekerNewReq = createSlice({
  name: 'seekerNewReq',
  initialState: {
    pending: false,
    success: false,
    error: false,
  },
  reducers: {
    seekerNewReqStart: (state) => {
      state.pending = true;
      state.error = false;
    },
    seekerNewReqSuccess: (state) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    seekerNewReqError: (state, action) => {
      state.success = false;
      state.pending = false;
      state.error = action.payload;
    },
    resetseekerNewReq: (state) => {
      state.success = false;
    },
  },
});
export const { seekerNewReqError, seekerNewReqStart, seekerNewReqSuccess, resetseekerNewReq } =
  seekerNewReq.actions;
const seekerNewReqReducer = seekerNewReq.reducer;
export default seekerNewReqReducer;
