/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { MdLockOutline } from 'react-icons/md';
import { FaUser, FaIdBadge } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import withReactContent from 'sweetalert2-react-content';
import { useDispatch, useSelector } from 'react-redux';
import i18next from '../I18';
import { appUserRoles } from '../../constants/index';
import { appRoutes } from '../../constants/routeConstants/Routes';
import MainHeaderLogo from '../../assets/images/icons/logo-cagi-dark.svg';
import SecondaryHeaderLogo from '../../assets/images/icons/logo-cagi-white.svg';
import flag from '../../assets/images/icons/flag-fr.svg';
import EmployeeIcon from '../../assets/images/icons/awesome-id-badge.svg';
import UserProfileIcon from '../../assets/images/icons/awesome-user.svg';
import LogOutIcon from '../../assets/images/icons/ionic-ios-exit.svg';
import englishFlagIcon from '../../assets/icons/englishFlagIcon.png';
import frenchFlagIcon from '../../assets/images/icons/french.png';
import menu_mobile from '../../assets/icons/menu_mobile.svg';

import { logout } from '../../apiCalls';
import AxiosInstance from '../../Axios';
import API from '../../constants/API';
import { userSuccess } from '../../redux/slices/user';

const MySwal = withReactContent(Swal);

function Header({ stateUpdate, toggleURL, setLanguage, language }) {
  const location = useLocation();
  const userData = JSON.parse(localStorage.getItem('user'));
  const authState = userData?.data?.isAuthenticated;
  const [token, setToken] = useState(null);
  const updateUser = useSelector((state) => state.updateUserInfoReducer.user);

  useEffect(() => {
    if (userData) {
      setToken(userData?.data?.result?.access_token);
    }
  }, [location?.pathname]);

  const userRole = userData?.data?.result?.role;
  if (authState && token) {
    if (userRole === appUserRoles.provider) {
      return (
        <AuthHeaderProvider
          updateUser={updateUser}
          stateUpdate={stateUpdate}
          toggleURL={toggleURL}
          setLanguage={setLanguage}
          language={language}
        />
      );
    }
    if (userRole === appUserRoles.seeker) {
      return (
        <AuthHeaderSeeker
          updateUser={updateUser}
          toggleURL={toggleURL}
          setLanguage={setLanguage}
          language={language}
        />
      );
    }
  } else {
    return <DefaultHeader toggleURL={toggleURL} setLanguage={setLanguage} language={language} />;
  }
}

function AuthHeaderProvider({ updateUser, toggleURL, setLanguage, language }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatcher = useDispatch();

  const [menu, setshowMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const language_selected = Cookies.get('i18next');
  const role = JSON.parse(localStorage.getItem('user'))?.data?.result?.role;
  const handleRoute = () => {
    if (role === 'provider') {
      navigate(appRoutes.PROVIDER_PROFILE_UPDATE);
      setshowMenu(false);
    } else {
      navigate(appRoutes.SEEKER_PROFILE_UPDATE);
    }
  };
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user?.data?.result?.access_token;
  useLayoutEffect(() => {
    if (token) {
      const userProfileData = async () => {
        const res = await AxiosInstance.post(API.GETPROFILE);
        setUserProfile(res.data.result);
        dispatcher(userSuccess(res.data.result));
      };
      userProfileData();
    }
  }, [token, updateUser]);

  useEffect(() => {
    if (show === true) {
      MySwal.fire({
        title: (
          <p style={{ fontFamily: 'museo-sans, sans-serif', fontSize: '18px' }}>
            {t('Logout_Message')}
          </p>
        ),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: t('Yes'),
        cancelButtonText: t('CANCEL'),
      }).then((res) => {
        if (res.isConfirmed) {
          logout(navigate);
        }
      });
      setShow(false);
    }
  }, [show, t, navigate]);

  return (
    <div className="flex  bg-white flex-shrink-0 justify-between w-full h-[92px] md:h-32 relative">
      <div
        onClick={() => setLanguage(false)}
        className="flex items-center w-11/12 sm_ss:w-10/12 sm_s:w-10/12"
      >
        <Link to={appRoutes.DASHBOARD_PROVIDER} className="h-full w-32 min-w-[82px] cursor-pointer">
          <div className="h-full w-32 min-w-[82px] cursor-pointer">
            <img
              src={SecondaryHeaderLogo}
              alt="Icon"
              className="h-full w-full p-4 2xl:p-6 cursor-pointer bg-blue sm_s:max-w-[90px] sm_ss:max-w-[90px]"
            />
          </div>
        </Link>
        <div className="p-3">
          <p className="uppercase text-blue sm_s:text-blue sm_ss:text-blue  md:text-2xl sm:text-xl sm_ss:text-xs sm_s:text-sm font-extrabold ">
            {t('titleHeader')}
          </p>
          <p className="uppercase text-themeSecgray sm_ss:text-[12px] sm_s:text-[13px]  md:mt-0  md:text-2xl text-gray mt-1 font-extralight">
            {t('PROVIDER')}
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between w-1/4 ">
        <div className="flex flex-col justify-start smx:hidden w-max sm_ss:hidden sm_s:hidden  md:hidden lg:flex gap-2">
          <div className="flex w-max" onClick={() => setLanguage(false)}>
            <img src={EmployeeIcon} alt="" className="h-5 w-5 mr-2" />
            <Link to={appRoutes.DASHBOARD_PROVIDER}>
              <span className="text-blue cursor-pointer capitalize">{t('My Properties')}</span>
            </Link>
          </div>
          {userProfile?.organization_name && (
            <div className="flex ">
              <img src={UserProfileIcon} alt="" className="h-5 w-5 mr-2" />
              <span className="text-blue cursor-pointer capitalize">
                <Link to={appRoutes.ORGANISATIONS}>
                  {`${userProfile?.organization_name.substring(0, 13)}...`}
                </Link>
              </span>
            </div>
          )}
          <div className="flex h-5">
            <img src={UserProfileIcon} alt="" className="h-5 w-5 mr-2" />
            <span className="text-blue cursor-pointer capitalize" onClick={handleRoute}>
              {updateUser?.name
                ? `${updateUser?.name} ${updateUser?.last_name}`
                : userProfile?.name &&
                  `${userProfile?.name} ${
                    userProfile?.last_name?.length > 5
                      ? `${userProfile?.last_name.substring(0, 5)}..`
                      : userProfile?.last_name
                  }`}
            </span>
          </div>
        </div>
        <div className="flex min-w-[30px] smx:hidden items-center justify-center sm_ss:hidden sm_s:hidden h-full  md:hidden lg:flex">
          <div className="h-5 w-5 rounded-xl cursor-pointer sm_ss:mr-0 sm_s:mr-0 relative">
            <img
              src={language_selected === 'en' ? englishFlagIcon : flag}
              alt=""
              className="w-full h-full rounded-full"
              onClick={() => setLanguage(!language)}
            />
            {language && (
              <div className="z-50 rounded-sm h-12 w-22 2xl:h-18 2xl:w-24 flex flex-col absolute justify-around top-8 -left-13 2xl:-left-14 bg-white">
                <div
                  onClick={() => {
                    i18next.changeLanguage('en');
                    setLanguage(false);
                    toggleURL(false);
                  }}
                  className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                >
                  <img src={englishFlagIcon} alt="" className="h-3 w-5 2xl:h-6 2xl:w-8" />
                  <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                    English
                  </span>
                </div>
                <div
                  onClick={() => {
                    i18next.changeLanguage('fr');
                    setLanguage(false);
                    toggleURL(true);
                  }}
                  className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                >
                  <img src={frenchFlagIcon} alt="" className="h-3 w-5 2xl:h-6 2xl:w-8" />
                  <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                    French
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className=" lg:hidden h-full flex items-center">
          <div className="w-[50px] h-full flex items-center justify-center relative ">
            <img
              src={menu_mobile}
              alt=""
              className="w-[25px] h-[20px]"
              onClick={() => setshowMenu(!menu)}
            />
          </div>
          <div className="flex items-center justify-center p-5 sm_ss:p-0 sm_s:p-0 w-1/5 sm_ss:w-[40px] sm_s:w-[50px] h-full ">
            <div className="h-5 w-5 min-w-[20px] rounded-xl cursor-pointer sm_ss:mr-0 sm_s:mr-0 relative">
              <img
                src={language_selected === 'en' ? englishFlagIcon : flag}
                alt=""
                className="w-full h-full rounded-full"
                onClick={() => setLanguage(!language)}
              />
              {language && (
                <div className="z-50 rounded-sm h-12 w-22 2xl:h-18 2xl:w-24 flex flex-col absolute justify-around top-8 -left-13 2xl:-left-14 bg-white">
                  <div
                    onClick={() => {
                      i18next.changeLanguage('en');
                      setLanguage(false);
                    }}
                    className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                  >
                    <img src={englishFlagIcon} alt="" className="h-3 w-5 2xl:h-6 2xl:w-8" />
                    <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                      English
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      i18next.changeLanguage('fr');
                      setLanguage(false);
                    }}
                    className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                  >
                    <img src={frenchFlagIcon} alt="" className="h-3 w-5 2xl:h-6 2xl:w-8" />
                    <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                      French
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-blue w-10 smx:hidden h-full  justify-center items-center sm_ss:hidden sm_s:hidden hidden md:hidden lg:flex">
          <img src={LogOutIcon} alt="" className="cursor-pointer" onClick={() => setShow(true)} />
        </div>
      </div>
      {menu && (
        <MobileMenu
          menu={menu}
          setshowMenu={setshowMenu}
          handleRoute={handleRoute}
          navigate={navigate}
        />
      )}
    </div>
  );
}

function AuthHeaderSeeker({ updateUser, toggleURL, setLanguage, language }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [menu, setshowMenu] = useState(false);

  const [show, setShow] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const language_selected = Cookies.get('i18next');
  useEffect(() => {
    const userProfileData = async () => {
      const res = await AxiosInstance.post(API.GETPROFILE);
      setUserProfile(res.data.result);
    };
    userProfileData();
  }, []);
  const handleRoute = () => {
    navigate(appRoutes.SEEKER_PROFILE_UPDATE);
    setshowMenu(false);
  };
  useEffect(() => {
    if (show === true) {
      MySwal.fire({
        title: (
          <p style={{ fontFamily: 'museo-sans, sans-serif', fontSize: '18px' }}>
            {t('Logout_Message')}
          </p>
        ),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: t('Yes'),
        cancelButtonText: t('CANCEL'),
      }).then((res) => {
        if (res.isConfirmed) {
          logout(navigate);
        }
      });
      setShow(false);
    }
  }, [show, t, navigate]);
  return (
    <div className="flex bg-white flex-shrink-0 justify-between w-full h-32">
      <div className="flex items-center w-11/12" onClick={() => setLanguage(false)}>
        <Link to={appRoutes.DASHBOARD_SEEKER} className="h-full w-32 min-w-[82px] cursor-pointer">
          <div className="h-full w-32 min-w-[82px] cursor-pointer">
            <img
              src={SecondaryHeaderLogo}
              alt="Icon"
              className="h-full w-full p-4 2xl:p-6 cursor-pointer bg-blue sm_s:max-w-[90px] sm_ss:max-w-[90px]"
            />
          </div>
        </Link>
        {/* <p className="uppercase text-blue sm_s:text-blue sm_ss:text-blue  md:text-2xl sm:text-xl sm_ss:text-xs sm_s:text-sm font-extrabold">
          {t('titleHeader')}
        </p>
        <p className="uppercase text-themeSecgray sm_ss:hidden sm_s:hidden md:mt-0 2xl:text-4xl md:text-2xl text-gray mt-1 2xl:mt-1">
          {t('SEEKER')}
        </p> */}
        <div className="p-3">
          <p className="uppercase text-blue sm_s:text-blue sm_ss:text-blue  md:text-2xl sm:text-xl sm_ss:text-xs sm_s:text-sm font-extrabold ">
            {t('titleHeader')}
          </p>
          <p className="uppercase text-themeSecgray sm_ss:text-[12px] sm_s:text-[13px]   md:mt-0  md:text-2xl text-gray mt-1 font-extralight">
            {t('SEEKER')}
          </p>
        </div>
      </div>
      <div className="flex items-center justify-end  w-1/4 ">
        <div className="flex flex-col smx:hidden justify-start w-full pl-5 sm_ss:hidden sm_s:hidden ipadAir:hidden md:hidden lg:flex gap-2">
          <div className="flex w-max">
            <img src={EmployeeIcon} alt="" className="h-5 w-5 mr-2" />
            <Link to={appRoutes.DASHBOARD_SEEKER}>
              <span className="text-blue cursor-pointer capitalize w-40">{t('Properties')}</span>
            </Link>
          </div>
          <div className="flex w-max">
            <img src={UserProfileIcon} alt="" className="h-5 w-5 mr-2" />
            <span className="text-blue cursor-pointer capitalize" onClick={handleRoute}>
              {updateUser?.name
                ? `${updateUser?.name} ${updateUser?.last_name}`
                : userProfile?.name &&
                  `${userProfile?.name} ${
                    userProfile?.last_name?.length > 5
                      ? `${userProfile?.last_name.substring(0, 5)}..`
                      : userProfile?.last_name
                  }`}
            </span>
          </div>
        </div>
        <div className="flex min-w-[30px] smx:hidden items-center justify-center sm_ss:hidden sm_s:hidden h-full  md:hidden lg:flex">
          <div className="h-5 w-5 min-w-[20px] rounded-xl cursor-pointer mr-4 sm_ss:mr-0 sm_s:mr-0 relative">
            <img
              src={language_selected === 'en' ? englishFlagIcon : flag}
              alt=""
              className="w-full h-full rounded-full"
              onClick={() => setLanguage(!language)}
            />
            {language && (
              <div className="z-50 rounded-sm h-12 w-22 2xl:h-18 2xl:w-24 flex flex-col absolute justify-around top-8 -left-13 2xl:-left-14 bg-white">
                <div
                  onClick={() => {
                    i18next.changeLanguage('en');
                    setLanguage(false);
                    toggleURL(false);
                  }}
                  className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                >
                  <img src={englishFlagIcon} alt="" className="h-3 w-5 2xl:h-6 2xl:w-8" />
                  <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                    English
                  </span>
                </div>
                <div
                  onClick={() => {
                    i18next.changeLanguage('fr');
                    setLanguage(false);
                    toggleURL(true);
                  }}
                  className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                >
                  <img src={frenchFlagIcon} alt="" className="h-3 w-5 2xl:h-6 2xl:w-8" />
                  <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                    French
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="lg:hidden h-full flex items-center">
          <div className="w-[50px] h-full flex items-center justify-center relative">
            <img
              src={menu_mobile}
              alt=""
              className="w-[25px] h-[20px]"
              onClick={() => setshowMenu(!menu)}
            />
          </div>
          <div className="flex items-center justify-center p-5 sm_ss:p-0 sm_s:p-0 w-1/5 sm_ss:w-[40px] sm_s:w-[50px] h-full">
            <div className="h-5 w-5 min-w-[20px] rounded-xl cursor-pointer sm_ss:mr-0 sm_s:mr-0 relative">
              <img
                src={language_selected === 'en' ? englishFlagIcon : flag}
                alt=""
                className="w-full h-full rounded-full"
                onClick={() => setLanguage(!language)}
              />
              {language && (
                <div className="z-50 rounded-sm h-12 w-22 2xl:h-18 2xl:w-24 flex flex-col absolute justify-around top-8 -left-13 2xl:-left-14 bg-white">
                  <div
                    onClick={() => {
                      i18next.changeLanguage('en');
                      setLanguage(false);
                    }}
                    className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                  >
                    <img src={englishFlagIcon} alt="" className="h-3 w-5 2xl:h-6 2xl:w-8" />
                    <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                      English
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      i18next.changeLanguage('fr');
                      setLanguage(false);
                    }}
                    className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                  >
                    <img src={frenchFlagIcon} alt="" className="h-3 w-5 2xl:h-6 2xl:w-8" />
                    <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                      French
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-blue w-10 smx:hidden h-full  justify-center items-center sm_ss:hidden sm_s:hidden hidden md:hidden lg:flex">
          <img src={LogOutIcon} alt="" className="cursor-pointer" onClick={() => setShow(true)} />
        </div>
      </div>
      {menu && (
        <MobileMenu
          menu={menu}
          setshowMenu={setshowMenu}
          handleRoute={handleRoute}
          navigate={navigate}
        />
      )}
    </div>
  );
}

function DefaultHeader({ toggleURL, language, setLanguage }) {
  const { t } = useTranslation();

  const language_selected = Cookies.get('i18next');
  return (
    <div className="flex z-[1301] bg-blue flex-shrink-0 justify-between w-full h-32">
      <div className="flex items-center w-full sm_ss:w-10/12 sm_s:w-10/12">
        <Link to={appRoutes.HOME} className="h-full w-32 min-w-[82px] cursor-pointer">
          <img
            src={MainHeaderLogo}
            alt="Icon"
            className="h-full w-full p-4 sm_s:p-4 sm_ss:p-4 2xl:p-5 cursor-pointer bg-white sm_s:max-w-[90px] sm_ss:max-w-[90px]"
          />
        </Link>
        <p className="uppercase ml-5 sm_ss:ml-0 sm_s:ml-0 sm_s:font-extrabold sm_ss:font-extrabold sm_ss:text-base sm_s:text-base mdx:font-extrabold 2lg:text-4xl text-white 2xl:text-5xl 2xl:ml-10 lg:tracking-wide md:text-[28px] md:font-extrabold sm:text-xl font-bold p-4">
          {t('titleHeader')}
        </p>
      </div>
      <div className="flex items-center justify-end p-5 w-1/5 sm:w-1/5 sm_ss:w-1/7 sm_s:w-2/12">
        <div className="md:w-36 flex items-center text-white mr-3 ">
          <Link replace to={appRoutes.LOGIN_STEP_2}>
            <MdLockOutline color="white" size="24px" className="mb-1" />
          </Link>
          <Link replace to={appRoutes.LOGIN_STEP_2}>
            <p className="cursor-pointer sm_ss:hidden sm_s:hidden">{t('HeaderLogin')}</p>
          </Link>
        </div>
        <div className="h-5 w-5 min-w-[20px] rounded-xl cursor-pointer mr-4 sm_ss:mr-0 sm_s:mr-0 relative">
          <img
            src={language_selected === 'en' ? englishFlagIcon : flag}
            alt=""
            className="w-full h-full rounded-full"
            onClick={() => setLanguage(!language)}
          />
          {language && (
            <div className="z-50 rounded-sm h-12 w-22 2xl:h-18 2xl:w-24 flex flex-col absolute justify-around top-8 -left-13 2xl:-left-14 bg-white">
              <div
                onClick={() => {
                  i18next.changeLanguage('en');
                  setLanguage(false);
                  toggleURL(false);
                }}
                className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
              >
                <img src={englishFlagIcon} alt="" className="h-3 w-5 2xl:h-6 2xl:w-8" />
                <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                  English
                </span>
              </div>
              <div
                onClick={() => {
                  i18next.changeLanguage('fr');
                  setLanguage(false);
                  toggleURL(true);
                }}
                className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
              >
                <img src={frenchFlagIcon} alt="" className="h-3 w-5 2xl:h-6 2xl:w-8" />
                <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                  French
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function MobileMenu({ menu, setshowMenu, handleRoute, navigate }) {
  const { t } = useTranslation();
  const [userProfile, setUserProfile] = useState();
  const [show, setShow] = useState(false);
  useEffect(() => {
    const userProfileData = async () => {
      const res = await AxiosInstance.post(API.GETPROFILE);
      setUserProfile(res.data.result);
    };
    userProfileData();
  }, []);
  const orgRoute = () => {
    navigate(appRoutes.ORGANISATIONS);
    setshowMenu(false);
  };
  const home = () => {
    navigate(appRoutes.DASHBOARD_PROVIDER);
    setshowMenu(false);
  };
  const role = JSON.parse(localStorage.getItem('user'))?.data?.result?.role;

  useEffect(() => {
    if (show === true) {
      MySwal.fire({
        title: (
          <p style={{ fontFamily: 'museo-sans, sans-serif', fontSize: '18px' }}>
            {t('Logout_Message')}
          </p>
        ),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: t('Yes'),
        cancelButtonText: t('CANCEL'),
        showClass: 'z-50',
      }).then((res) => {
        if (res.isConfirmed) {
          logout(navigate);
        }
      });
      setShow(false);
    }
  }, [show, t, navigate]);

  return (
    <div className="z-40">
      {menu && (
        <div className="w-full absolute top-0 left-0 h-screen bg-blue items-start">
          <div className="h-32 flex justify-between">
            <div className="flex items-center w-11/12 sm_ss:w-10/12 sm_s:w-10/12">
              <div className="h-full w-32 min-w-[82px] cursor-pointer">
                <img
                  src={SecondaryHeaderLogo}
                  alt="Icon"
                  className="h-full w-full p-4 2xl:p-6 cursor-pointer bg-blue "
                />
              </div>
              <p className="uppercase text-blue sm_s:text-blue sm_ss:text-blue 2xl:ml-3 md:text-3xl sm:text-xl sm_ss:text-xs sm_s:text-sm font-extrabold p-4">
                {t('titleHeader')}
              </p>
              <p className="uppercase sm_ss:hidden sm_s:hidden md:mt-0 sm_xx:hidden  md:text-2xl text-gray mt-1 ">
                {role === 'provider' ? t('PROVIDER') : t('SEEKER')}
              </p>
            </div>
            <div className=" md:flex  lg:hidden flex items-center justify-center">
              <div className="h-full flex items-center justify-center relative mr-6">
                <i
                  className="fa-solid fa-xmark text-3xl text-white"
                  onClick={() => setshowMenu(false)}
                />
              </div>
            </div>
          </div>
          {role === 'provider' ? (
            <div className="flex flex-col p-4">
              <div className="flex h-[30px] items-center mb-2">
                <FaUser className="text-white text-xl mr-2" />
                <p onClick={home} className="text-white">
                  {t('My properties')}
                </p>
              </div>
              {userProfile?.organization_name && (
                <div className="flex h-[30px] items-center mb-2">
                  <FaUser className="text-white text-xl mr-2" />
                  <p onClick={orgRoute} className="text-white">
                    {userProfile?.organization_name}
                  </p>
                </div>
              )}
              <div className="flex h-[30px] items-center mb-2">
                <FaIdBadge className="text-white text-xl mr-2" />
                <p onClick={handleRoute} className="text-white">
                  {userProfile?.name}
                </p>
              </div>
              <div className="flex h-[30px] items-center mb-2">
                <img src={LogOutIcon} alt="" className="-ml-[6px] mr-2" />
                <p className="text-white" onClick={() => setShow(true)}>
                  LOGOUT
                </p>
              </div>
            </div>
          ) : (
            <div className="flex flex-col p-4">
              <div className="flex h-[30px] items-center mb-2">
                <FaIdBadge className="text-white text-xl mr-2" />
                <p onClick={handleRoute} className="text-white">
                  {userProfile?.name}
                </p>
              </div>
              <div className="flex h-[30px] items-center mb-2">
                <img src={LogOutIcon} alt="" className="-ml-[6px] mr-2" />
                <p onClick={() => setShow(true)} className="text-white">
                  LOGOUT
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default Header;
