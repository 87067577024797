import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import AxiosInstance from '../Axios';
import PageHeading from '../components/common/PageHeading';
import PropertyCard from '../components/seeker/Dashboard/PropertyCard';
import PropertyStatusBar from '../components/seeker/Dashboard/PropertyStatusBar';
import SatisfactionSurvey from '../components/seeker/Dashboard/SatifactionSurvey';
import API from '../constants/API';
import { appRoutes } from '../constants/routeConstants/Routes';
import 'aos/dist/aos.css';
import Pagination from '../components/Dashboard/Pagination';

function DashBoardSeeker() {
  const { t } = useTranslation();
  const postPerPage = 8;
  const [paginationLength, setPaginationLength] = useState(0);
  const [propertyData, setPropertyData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [page, setPage] = useState(1);
  const [isSuspended, setIsSuspended] = useState(false);

  const paginate = (pageNumber) => setPage(pageNumber);

  useEffect(() => {
    AOS.init({
      duration: 2500,
    });
  }, []);
  useEffect(() => {
    AxiosInstance.post(
      API.SEEKER_LISTING,
      {
        page,
      },
      setIsLoading(true)
    )
      .then((res) => {
        setIsLoading(false);
        setPropertyData(res.data.result.properties.data);
        setPaginationLength(res.data?.result?.total);
      })
      .catch((err) => {
        setPaginationLength([]);
      });
  }, [page]);

  useLayoutEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.data?.result?.access_token;
    if (token) {
      const userProfileData = async () => {
        const res = await AxiosInstance.post(API.GETPROFILE);
        setUserProfile(res.data.result);
      };
      userProfileData();
    }
  }, []);

  return (
    <div data-aos="fade" className="w-4/5 max-w-[1500px] mx-auto my-12">
      <PropertyStatusBar setIsSuspended={setIsSuspended} />
      {!isSuspended && userProfile?.latest_request?.status === 'active' && (
        <div className="flex flex-col justify-start md:justify-between md:flex-row mb-8 px-3">
          <div className="flex flex-col md:flex-row md:items-center">
            <PageHeading>{t('Objects corresponding to your criteria')}</PageHeading>
            <Link to={appRoutes.EDITSEEKERALERTS}>
              <div className="text-primary md:px-4 py-4 text-base rounded-md font-bold md:mx-4">
                {'>'} {t('Edit my house seeking alerts')}
              </div>
            </Link>
          </div>

          <Pagination
            postPerPage={postPerPage}
            totalPost={paginationLength}
            paginate={paginate}
            page={page}
          />
        </div>
      )}

      {!isSuspended && userProfile?.latest_request?.status === 'active' && (
        <div>
          {propertyData?.length > 0 ? (
            <div className="grid overflow-hidden md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 py-4 px-2">
              {isLoading ? (
                <div className="lg:w-[64rem] xl:w-[64rem] 2xl:w-[92rem]  min-h-[325px] flex justify-center items-center">
                  <CircularProgress size="40px" color="primary" />
                </div>
              ) : (
                (userProfile?.latest_request?.status === 'active' ||
                  userProfile?.latest_request?.status === 'archived') &&
                propertyData?.map((item, index) => (
                  <PropertyCard key={`${index + index}`} data={item} />
                ))
              )}
            </div>
          ) : (
            <div className="w-full flex items-center justify-center py-[25px]">
              {(userProfile?.latest_request?.status === 'active' ||
                userProfile?.latest_request?.status === 'archived') && (
                <div className=" py-8 px-4 mt-1 font-bold text-xl">
                  {t('No Properties found as per the criteria')}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {(isSuspended || userProfile?.latest_request?.status === 'expired') && <SatisfactionSurvey />}
    </div>
  );
}

export default DashBoardSeeker;
