/* eslint-disable camelcase */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import moment from 'moment';
import React from 'react';
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useSelector } from 'react-redux';
import API from '../../constants/API';
import PropertyMenu from './PropertyMenu';
import '../../App.css';
import AxiosInstance from '../../Axios';
import { appRoutes } from '../../constants/routeConstants/Routes';
import { Encrypt } from '../../util';

function PropertyBar({
  data,
  setRefetcgProperties,
  userProfile,
  setReFetchPropertiesOnArchice,
  index,
}) {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const id = Encrypt(data?.id);

  const propertyEdit = () => {
    // prettier-ignore
    navigate(`${appRoutes.EDIT_PROPERTY}?id=${id}`,{replace: true});
  };

  const propertArchive = () => {
    // prettier-ignore
    navigate(`${appRoutes.DRAFT_PROPERTY}?id=${id}`,{replace: true});
  };

  const propertDuplicate = () => {
    // prettier-ignore
    navigate(`${appRoutes.DUPLICATE_PROPERTY}?id=${id}`,{replace: true});
  };

  const propertyDelete = async () => {
    MySwal.fire({
      title: t('Are you sure to delete this property'),
      icon: 'info',
      confirmButtonText: t('Yes'),
      showCancelButton: true,
      cancelButtonText: t('CANCEL'),
    }).then(async (res) => {
      if (res.isConfirmed) {
        await AxiosInstance.post(API.PROPERTY_DELETE, {
          property_id: data.id,
        }).then((response) => {
          if (response) {
            setRefetcgProperties(true);
          }
        });
      }
    });
  };

  const archiveProperty = async () => {
    MySwal.fire({
      title: t('Are you sure to archive this property'),
      icon: 'info',
      confirmButtonText: t('Yes'),
      showCancelButton: true,
      cancelButtonText: t('CANCEL'),
    }).then(async (res) => {
      if (res.isConfirmed) {
        await AxiosInstance.post(API.ARCHIVE_PROPERTY, {
          property_id: data.id,
        }).then((response) => {
          if (response) {
            setReFetchPropertiesOnArchice(true);
            MySwal.fire({
              title: 'Propert archived successfully',
              icon: 'success',
            });
          }
        });
      }
    });
  };

  const organisation_role_save = useSelector((state) => state.userReducer);
  const organisation_role = organisation_role_save?.user?.organisation_members?.[0]?.role;

  return (
    // <Link to={`/property/${data?.id}`} key={index}>
    <div
      style={{ zIndex: '9 !important' }}
      className="w-full lg:h-30 mb-3 lg:mb-6 flex h-26 bg-white rounded-lg card-shadow sm_ss:justify-between sm_s:justify-between"
    >
      <Link
        to={`/property/${id}`}
        key={index}
        className="flex md:w-2/3 lg:w-1/2 sm_ss:w-full sm_s:w-60  rounded-lg overflow-hidden"
      >
        <div className="flex items-center w-full rounded-lg overflow-hidden">
          <div className="w-60  ">
            <img
              src={
                data?.pictures?.length > 0
                  ? API.BASE_URL + data?.pictures[0]?.image_path
                  : // eslint-disable-next-line prefer-template
                    API.BASE_URL + `images/icons/default-property.png`
              }
              alt=""
              className="w-20 h-22 md:w-32 md:h-26 lg:w-70 lg:h-70"
            />
          </div>
          <div className="w-full">
            <div className="text-primary my-auto px-2 ml-4 sm_ss:text-xs sm_s:text-xs ">
              {data?.street
                ? `${data?.street} ${data?.nb} ${data?.zip_code} ${data?.city}`
                : '#street #nb #zip code #city'}
            </div>
          </div>
        </div>
      </Link>
      <div className="flex items-center justify-between sm_ss:justify-end sm_s:justify-end md:w-1/5 lg:w-1/2">
        <div className="flex flex-row md:w-10/12 lg:w-10/12 items-center justify-between sm_ss:hidden sm_s:w-30">
          <div className="flex flex-col  flex-wrap">
            <div
              className={`my-auto  uppercase flex items-center justify-center h-7 ${
                data?.request_status === 'pending'
                  ? 'w-32 h-auto sm_s:w-14'
                  : 'w-32 h-auto sm_s:w-20'
              } px-2  text-white sm_s:text-[.60rem] sm_ss:text-[.60rem] text-sm md:text-md text-center font-bold rounded-md ${
                data?.request_status === 'pending' && 'bg-[#6F6F6F]'
              }
              ${data?.request_status === 'active' && 'bg-[#73C768]'} 
               ${data?.request_status === 'draft' && 'bg-[#E6BC2E]'} 
               ${data?.request_status === 'rejected' && 'bg-[#D13838]'}
               ${data?.request_status === 'expired' && 'bg-[#C9C9C9]'}
               ${data?.request_status === 'archived' && 'bg-[#C9C9C9]'}
               ${data?.request_status === 'hold' && 'bg-[#6F6F6F]'}
              `}
            >
              {data?.request_status === 'pending'
                ? t(`under review`)
                : t(`${data?.request_status}`)}
            </div>
            {data?.request_status === 'pending' ||
            data?.request_status === 'expired' ||
            data?.request_status === 'draft' ||
            data?.request_status === 'rejected' ||
            data?.request_status === 'archived' ? (
              ''
            ) : (
              <div className="my-auto ml-2 sm:text-xs lg:text-lg  sm_s:hidden mt-1">
                {t('Until')} {moment(data.active_until).format('DD.MM.YYYY')}
              </div>
            )}
            {/* )} */}
            <div className="lg:hidden">
              {data?.request_status === 'draft' ||
              data?.request_status === 'pending' ||
              data?.request_status === 'expired' ? (
                ''
              ) : (
                <div className="my-auto text-[#A6A6A6] w-[100px] md:ml-7  mr-20 sm_s:hidden ">{`${
                  data?.views_count
                } ${t('views')}`}</div>
              )}
            </div>
          </div>
          <div className="md:hidden lg:flex">
            {/* )} */}
            {data?.request_status === 'draft' ||
            data?.request_status === 'pending' ||
            data?.request_status === 'expired' ? (
              ''
            ) : (
              <div className="my-auto text-[#A6A6A6] w-[100px] md:ml-7  mr-20 sm_s:hidden ">{`${
                data?.views_count
              } ${t('views')}`}</div>
            )}
          </div>
        </div>
        <div className="my-auto md:pr-10 z-50 sm_ss:pr-0 sm_s:pr-0 p-8 ">
          {userProfile?.role === 'provider' && organisation_role === 'admin' ? (
            <div className="my-auto">
              <PropertyMenu
                callparentfunction={propertyDelete}
                callPropertyEdit={propertyEdit}
                callerPropertArchive={propertArchive}
                callerPropertDuplicate={propertDuplicate}
                data={data}
                archiveProperty={archiveProperty}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
    // </Link>
  );
}

export default PropertyBar;
