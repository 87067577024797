/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prettier/prettier */
import { t } from 'i18next';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AxiosInstance from '../../../Axios';
import { propertyStatus } from '../../../constants';
import API from '../../../constants/API';
import { Encrypt } from '../../../util';
import defaultImg from '../../../assets/images/default-property.png';

function PropertyCard({ data }) {
  const id = Encrypt(data?.id);
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState(propertyStatus.ACTIVE.value);

  const CountView = async () => {
    await AxiosInstance.post(API.ADD_VIEW, {
      property_id: data?.id,
    });
  };
  const truncate = (str) => (str.length > 10 ? `${str.substring(0, 29)}...` : str);
  return (
    <Link className="" to={`/property/${id}`} onClick={CountView}>
      <div className="bg-white rounded-lg overflow-hidden my-auto card-shadow">
        <div className="h-8/12 overflow-hidden relative">
          {data?.is_new === true && (
            <div className="absolute bg-white text-primary right-2 top-2 px-2 font-bold text-sm rounded">
              New
            </div>
          )}
          <img
            src={
              data?.pictures?.length > 0 ? API.BASE_URL + data?.pictures[0]?.image_path : defaultImg
            }
            className={`w-full h-44 ${
              data?.pictures?.length > 0 ? 'object-cover' : 'object-contain'
            }`}
            alt=""
          />
        </div>
        <div className="flex flex-col justify-between p-3 min-h-[125px]">
          <div className="text-primary">{`${truncate(data?.street)} ${data?.nb} ${data?.zip_code} ${
            data?.city
          }`}</div>
          <div>
            <div className="text-lg">
              {data?.number_of_rooms === 'Studio'
                ? data?.number_of_rooms
                : `${data?.number_of_rooms} ${t('room(s)')}`}{' '}
              {data?.housing_type !== 'room' &&
                data?.kitchen &&
                (data?.number_of_bedrooms !== 'Studio' || data?.number_of_rooms !== 'Studio') &&
                `(${t('Kitchen included')})`}
            </div>
            <div className="font-extrabold">
              {data?.overall_rent
                ? `CHF ${data?.overall_rent?.toLocaleString('en')}`
                : `CHF ${data?.selling_price?.toLocaleString('en')}`}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default PropertyCard;
