import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../Axios';
import API from '../../constants/API';

export const SeekerProfileupdate = createAsyncThunk(
  'ProfileUpdate',
  async ({ data, dispatch, updateUserInfoSuccess }, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.post(API.SEEKER_PROFILE_UPDATE, { ...data });
      dispatch(updateUserInfoSuccess(response.data.result));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const seekerProfileUpdate = createSlice({
  name: 'seekerProfileUpdate',
  initialState: {
    updatedSeeker: {},
    pending: false,
    success: false,
    error: false,
  },
  reducers: {
    seekerProfileUpdateStart: (state) => {
      state.pending = true;
      state.error = false;
    },
    seekerProfileUpdateSuccess: (state) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    seekerProfileUpdateError: (state, action) => {
      state.success = false;
      state.pending = false;
      state.error = action.payload;
    },
    resetseekerProfileUpdate: (state) => {
      state.success = false;
      state.error = false;
      state.pending = false;
    },
  },
  extraReducers: {
    [SeekerProfileupdate.fulfilled]: (state, payload) => {
      state.success = true;
      state.updatedSeeker = payload;
      state.pending = false;
      state.error = false;
    },
    [SeekerProfileupdate.rejected]: (state, payload) => {
      state.success = true;
      state.pending = false;
      state.error = payload;
    },
  },
});
export const {
  seekerProfileUpdateError,
  seekerProfileUpdateStart,
  seekerProfileUpdateSuccess,
  resetseekerProfileUpdate,
} = seekerProfileUpdate.actions;
const seekerProfileUpdateReducer = seekerProfileUpdate.reducer;
export default seekerProfileUpdateReducer;
