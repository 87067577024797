import { createSlice } from '@reduxjs/toolkit';
// import AxiosInstance from '../../Axios';
// import API from '../../constants/API';

// export const ProfileUpdate = createAsyncThunk(
//   'ProfileUpdate',
//   async ({ data, dispatch, updateUserInfoSuccess }) => {
//     // if()
//     const response = await AxiosInstance.post(API.PROFILE_UPDATE, { ...data });
//     dispatch(updateUserInfoSuccess(response.data.result));
//     return response.data.result;
//   }
// );

const profileUpdate = createSlice({
  name: 'profileUpdate',
  initialState: {
    pending: false,
    success: false,
    error: false,
  },
  reducers: {
    profileUpdateStart: (state) => {
      state.pending = true;
      state.error = false;
    },
    profileUpdateSuccess: (state) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    profileUpdateError: (state, action) => {
      state.success = false;
      state.pending = false;
      state.error = action.payload;
    },
    resetprofileUpdate: (state) => {
      state.success = false;
      state.error = false;
      state.pending = false;
    },
  },
  // extraReducers: {
  //   [ProfileUpdate.fulfilled]: (state) => {
  //     state.success = true;
  //     state.pending = false;
  //     state.error = false;
  //   },
  //   [ProfileUpdate.rejected]: (state) => {
  //     state.success = true;
  //     state.pending = false;
  //     state.error = false;
  //   },
  // },
});
export const { profileUpdateError, profileUpdateStart, profileUpdateSuccess, resetprofileUpdate } =
  profileUpdate.actions;
const profileUpdateReducer = profileUpdate.reducer;
export default profileUpdateReducer;
