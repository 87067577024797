/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import Cookies from 'js-cookie';
import TextInput from '../components/TextInput';
import { appRoutes } from '../constants/routeConstants/Routes';
import { ResetPassword } from '../apiCalls';

function ResetPasswordSubmission() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const pending = useSelector((state) => state.resetPassword.pending);
  const success = useSelector((state) => state.resetPassword.success);
  const token = location.pathname.split('/')[2];
  const email = location.search.split('=')[1];
  const UserEmail = email?.split('?')[0];
  //   const role = location.search.split('role=')[1];
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowconfirmPassword] = useState(false);

  const schema = yup.object().shape({
    password: yup.string().required('Password is required').min(8).max(20),
    repeatPassword: yup
      .string()
      .required(t('Repeat Password is required'))
      .oneOf([yup.ref('password'), null], 'Password must match'),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const { errors } = methods.formState;
  const { password, repeatPassword } = methods.watch();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const role = user?.data?.result?.role;
    const token = user?.data?.result?.access_token;
    if (user && token && role === 'employer') {
      navigate(appRoutes.EmployerDashboard);
    }
    if (user && token && role === 'employee') {
      navigate(appRoutes.EmployeeDashboard);
    }
  });

  useEffect(() => {
    if (success === true) {
      navigate(appRoutes.SetPasswordSuccess);
    }
  }, [success, navigate]);

  const toogleshowPassword = (id) => {
    if (id === 'password') {
      setShowPassword(!showPassword);
    }
    if (id === 'confirmPassword') {
      setShowconfirmPassword(!showconfirmPassword);
    }
  };

  const handleData = (data) => {
    if (!password || !repeatPassword) {
      methods.setError(true);
    } else {
      const language_selected = Cookies.get('i18next');
      ResetPassword(data.password, language_selected, token, UserEmail, dispatch);
    }
  };

  return (
    <div className="w-full flex flex-col justify-center items-center bg-secgray h-full sm_ss:overflow-hidden">
      <div className="flex flex-col py-5 w-1/2 max-w-4xl sm_ss:w-full sm_ss:p-3 sm:w-7/12  sm_s:w-10/12 2xl:w-1/2">
        <p className="text-themeSecgray font-bold text-4xl text-center py-3  sm_ss:text-center">
          {t('newPWDText')}
        </p>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleData)}
            className="w-full flex items-center justify-center"
          >
            <div className="bg-white w-[500px] 2xl:w-[700px] self-center py-7 flex flex-col justify-center items-center my-5 p-4 rounded-xl sm_ss:p-4 2xl:py-20">
              <div className="w-full px-6 sm_ss:w-full sm_s:w-full flex flex-col relative h-28">
                <TextInput
                  text={t('Password')}
                  textType={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  required
                  showEyeButton
                  passwordShown={showPassword}
                  togglePassword={() => toogleshowPassword('password')}
                />
                {errors && (
                  <span className="text-red-600 text-sm absolute bottom-3 left-6">
                    {t(errors?.password?.message)}
                  </span>
                )}
              </div>
              <div className="w-full px-6 sm_ss:w-full sm_s:w-full flex flex-col relative h-28">
                <TextInput
                  text={t('Repeat')}
                  textType={showconfirmPassword ? 'text' : 'password'}
                  id="repeatPassword"
                  name="repeatPassword"
                  showEyeButton
                  passwordShown={showconfirmPassword}
                  togglePassword={() => toogleshowPassword('confirmPassword')}
                />
                {errors && (
                  <span className="text-red-600 text-sm absolute bottom-3 left-6">
                    {t(errors?.repeatPassword?.message)}
                  </span>
                )}
              </div>

              <button
                type="submit"
                // disabled={disable}
                className="disabled:bg-textGray mt-2 disabled:cursor-not-allowed bg-blue h-10 w-auto text-white rounded-lg uppercase font-semibold text-sm 2xl:text-lg p-2"
              >
                {pending ? <CircularProgress /> : t('Update')}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default ResetPasswordSubmission;
