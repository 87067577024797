import { createSlice } from '@reduxjs/toolkit';

const providerSignup = createSlice({
  name: 'providerSignup',
  initialState: {
    pending: false,
    success: false,
    error: false,
  },
  reducers: {
    providerSignupStart: (state) => {
      state.pending = true;
      state.error = false;
    },
    providerSignupSuccess: (state) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    providerSignupError: (state, action) => {
      state.success = false;
      state.pending = false;
      state.error = action.payload;
    },
    resetProviderSignup: (state) => {
      state.success = false;
    },
  },
});
export const {
  providerSignupError,
  providerSignupStart,
  providerSignupSuccess,
  resetProviderSignup,
} = providerSignup.actions;
const providerSignUpReducer = providerSignup.reducer;
export default providerSignUpReducer;
