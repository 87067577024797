/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Cookies from 'js-cookie';
import { CircularProgress } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { resetPasswordError } from '../redux/slices/ResetPassword';
import ResetPasswordThanks from './ResetPasswordThanks';
import TextInput from '../components/TextInput';
import { appRoutes } from '../constants/routeConstants/Routes';
import { resetPasswordSendLink } from '../apiCalls';

function ResetPassword() {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pending = useSelector((state) => state.resetPassword.pending);
  const Error = useSelector((state) => state.resetPassword.error);
  const [submitted, setSubmitted] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().required('Email is required').email(),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const { errors } = methods.formState;

  const { email } = methods.watch();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const role = user?.data?.result?.role;
    const token = user?.data?.result?.access_token;
    if (user && token && role === 'employer') {
      navigate(appRoutes.EmployerDashboard);
    }
    if (user && token && role === 'employee') {
      navigate(appRoutes.EmployeeDashboard);
    }
  });

  const sendLink = async (data) => {
    const language_selected = Cookies.get('i18next');
    const user_type = localStorage.getItem('user_type')
      ? localStorage.getItem('user_type')
      : 'provider';
    if (!email) {
      methods.setError(true);
    } else {
      resetPasswordSendLink(data.email, language_selected, user_type, dispatch, setSubmitted);
    }
  };

  useEffect(() => {
    if (Error) {
      MySwal.fire({
        title: <span className="capitalize">Email not found</span>,
        icon: 'error',
        buttonsStyling: { backgroundColor: '#f44336' },
      }).then((res) => {
        if (res.isConfirmed) {
          dispatch(resetPasswordError(false));
        }
      });
    }
  }, [Error, MySwal, dispatch, t]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {submitted ? (
        <ResetPasswordThanks />
      ) : (
        <div className="w-full flex justify-center items-center h-full bg-signUpBG">
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(sendLink)}
              className="w-full flex flex-col justify-center items-center"
            >
              <div className="flex overflow-hidden flex-col justify-center items-center shadow-lg sm_ss:w-full sm_s:w-full bg-white rounded-lg smx:w-8/12 mt-5 sm:w-1/3 md:w-1/2 lg:w-1/2 xl:w-1/3 2xl:w-1/4">
                <div className="py-8 px-8 w-full">
                  <p className="text-sectext font-bold text tracking-wider mb-2">
                    {t('PasswordResetText')}
                  </p>
                  <div className="border opacity-40" />
                  {/* <p className="mt-3 opacity-40">{t("Don't worry, happens to best of us")}</p> */}
                  <div className="flex flex-col smx:w-full w-full relative h-28 mt-7">
                    <TextInput
                      text={t('emailResetPassword')}
                      textType="text"
                      placeholder={t('Email')}
                      id="email"
                      name="email"
                      required
                      bold
                    />
                    {errors && (
                      <span className="text-red-600 text-sm absolute bottom-1">
                        {errors?.email?.message}
                      </span>
                    )}
                  </div>
                  <button
                    disabled={!email}
                    type="submit"
                    className="bg-blue text-white disabled:bg-[#6F6F6F] disabled:cursor-not-allowed w-full rounded-md h-10"
                  >
                    {pending ? (
                      <CircularProgress className="p-2" size="10px" color="primary" />
                    ) : (
                      t('SendLink')
                    )}
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      )}
    </>
  );
}

export default ResetPassword;
