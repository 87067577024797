import { t } from 'i18next';
import React from 'react';
import Dropzone from 'react-dropzone';

function ImageDropZone({ handleUploadImages }) {
  return (
    <div>
      <Dropzone onDrop={(acceptedFiles) => handleUploadImages(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              id=""
              htmlFor="file-upload"
              className=" bg-white rounded-lg border-2 border-dashed border-textGray flex items-center justify-center h-36 cursor-pointer"
            >
              <div className="flex flex-col items-center">
                <i className="fa-solid fa-cloud-arrow-up text-textGray text-3xl" />
                <p className="text-sectext">{t('Browse files')}</p>
                <p className="text-textGray">{t('Drag and drop files here')}</p>
              </div>
              <input {...getInputProps()} />
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
}

export default ImageDropZone;
