import { createSlice } from '@reduxjs/toolkit';

const user = createSlice({
  name: 'user',
  initialState: {
    user: {},
    pending: false,
    success: false,
    error: false,
  },
  reducers: {
    userStart: (state) => {
      state.pending = true;
      state.error = false;
    },
    userSuccess: (state, action) => {
      state.user = action.payload;
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    userError: (state) => {
      state.success = false;
      state.pending = false;
      state.error = true;
    },
    resetuser: (state) => {
      state.success = false;
    },
  },
});
export const { userError, userStart, userSuccess, resetuser } = user.actions;
const userReducer = user.reducer;
export default userReducer;
