/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
import Axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AxiosInstance from './Axios';
import API from './constants/API';
import {
  providerSignupError,
  providerSignupStart,
  providerSignupSuccess,
} from './redux/slices/ProviderSignup';
import {
  seekerSignupError,
  seekerSignupStart,
  seekerSignupSuccess,
} from './redux/slices/SeekerSignup';
import {
  seekerSatisfactionSurveyError,
  seekerSatisfactionSurveyStart,
  seekerSatisfactionSurveySuccess,
} from './redux/slices/SeekerSatisfactionSurvey';
import {
  fetchNumberState,
  fetchUserError,
  fetchUserStart,
  fetchUserSuccess,
} from './redux/slices/FetchUser';
import {
  suspendRequestError,
  suspendRequestStart,
  suspendRequestSuccess,
} from './redux/slices/SuspendRequest';
import {
  seekerNewReqError,
  seekerNewReqStart,
  seekerNewReqSuccess,
} from './redux/slices/SeekerNewReq';
import {
  addNewMemberError,
  addNewMemberStart,
  addNewMemberSuccess,
} from './redux/slices/AddNewMember';
import { appRoutes } from './constants/routeConstants/Routes';
import {
  resetPasswordError,
  resetPasswordStart,
  resetPasswordSuccess,
  setPasswordError,
  setPasswordStart,
  setPasswordSuccess,
} from './redux/slices/ResetPassword';
import {
  profileUpdateError,
  profileUpdateStart,
  profileUpdateSuccess,
} from './redux/slices/ProviderProfileUpdate';
// import { loginError, loginStart, loginSuccess } from './redux/slices/Login';

export const Canton = async (id, setCanton, language_selected) => {
  try {
    const response = await Axios.get(`${API.BASE_URL}api/countries/${id}/states`);
    if (response.data.result) {
      if (language_selected === 'en') {
        setCanton(response.data.result.english_states);
      } else {
        setCanton(response.data.result.french_states);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const ProviderRegistrationApi = async (data, dispatch) => {
  dispatch(providerSignupStart());
  await AxiosInstance.post(API.PROVIDER_SIGNUP, { ...data })
    .then(() => dispatch(providerSignupSuccess()))
    .catch((err) => dispatch(providerSignupError(err.response.data.message)));
};

export const addNewMember = async (data, dispatch) => {
  dispatch(addNewMemberStart());
  await AxiosInstance.post(API.AddNEWMEMBER, { ...data })
    .then(() => dispatch(addNewMemberSuccess()))
    .catch((err) => dispatch(addNewMemberError(err.response.data.message)));
};

export const SeekerRegistartionApi = async (data, dispatch) => {
  dispatch(seekerSignupStart());
  await AxiosInstance.post(API.SEEKER_SIGNUP, { ...data })
    .then(() => dispatch(seekerSignupSuccess()))
    .catch((err) => dispatch(seekerSignupError(err.response.data.message)));
};

export const SeekerSatisfationSurveyApi = async (data, dispatch) => {
  dispatch(seekerSatisfactionSurveyStart());
  await AxiosInstance.post(API.SATISFACTION_SURVEY, { ...data })
    .then(() => {
      // eslint-disable-next-line no-use-before-define
      suspendReq(dispatch);
      dispatch(seekerSatisfactionSurveySuccess());
    })
    .catch((err) => dispatch(seekerSatisfactionSurveyError(err.response.data.message)));
};

export const fetchUserByEmail = async (email, user_type, dispatch) => {
  dispatch(fetchUserStart({ email }));
  await AxiosInstance.post(API.FETCH_USER, { email, user_type })
    .then((res) => {
      dispatch(fetchUserSuccess(res.data.result));
    })
    .catch((err) => {
      fetchUserError(err.response.data.message);
    });
};

export const fetchUserByNumber = async (number, user_type, dialCode, numberState, dispatch) => {
  dispatch(fetchUserStart({ number, dialCode }));
  dispatch(fetchNumberState({ numberState }));
  await AxiosInstance.post(API.FETCH_USER, { number, user_type })
    .then((res) => {
      dispatch(fetchUserSuccess(res.data.result));
    })
    .catch((err) => {
      fetchUserError(err.response.data.message);
    });
};

export const logout = (navigate) => {
  localStorage.clear();
  navigate('/');
};

export const suspendReq = async (dispatch) => {
  dispatch(suspendRequestStart());

  await AxiosInstance.post(API.SUSPEND)
    .then(() => {
      dispatch(suspendRequestSuccess());
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch((err) => {
      if (err) {
        dispatch(suspendRequestError());
      }
    });
};

export const SeekerRequestNew = async (dispatch, data) => {
  const MySwal = withReactContent(Swal);
  dispatch(seekerNewReqStart());
  await AxiosInstance.post(API.SEEKERNEWREQUEST, { ...data })
    .then(() => {
      dispatch(seekerNewReqSuccess());
    })
    .catch((err) => {
      const errors = err?.response?.data?.message;
   
      if (err) {
        MySwal.fire({
          title: errors.length > 0
            ? errors
            : 'Something went wrong',
          icon: 'error',
        });
        dispatch(seekerNewReqError());
      }
    });
};

export const resetPasswordSendLink = async (
  email,
  language_selected,
  user_type,
  dispatch,
  setSubmitted
) => {
  dispatch(resetPasswordStart());
  await AxiosInstance({
    method: 'POST',
    url: API.FORGETPASSWORD,
    data: { email, language_selected, user_type },
  })
    .then((res) => {
      const { status, statusText } = res;
      const data = { status, statusText };
      if (status === 200) {
        setSubmitted(true);
      }
      dispatch(resetPasswordSuccess(data));
    })
    .catch((err) => {
      dispatch(resetPasswordError(err.response.data.message));
    });
};

export const ResetPassword = async (password, language_selected, token, email, dispatch) => {
  dispatch(setPasswordStart());
  await AxiosInstance({
    method: 'POST',
    url: API.RESETPASSWORD,
    data: {
      email,
      token,
      password,
      language_selected,
    },
  })
    .then((res) => {
      const { status, statusText } = res;
      const data = { status, statusText };
      dispatch(setPasswordSuccess(data));
    })
    .catch((err) => {
      dispatch(setPasswordError(err.response.data.message));
    });
};

export const ProfileUpdate = async (data, updateUserInfoSuccess, dispatch, fun) => {
  dispatch(profileUpdateStart());
  await AxiosInstance.post(API.PROFILE_UPDATE, { ...data })
    .then((res) => {
      dispatch(profileUpdateSuccess());
      dispatch(updateUserInfoSuccess(res.data.result));
      fun();
    })
    .catch((err) => {
      dispatch(profileUpdateError(err.response.data.message));
    });
};

export const getOtp = async ({ role, countryCode, number, userEmail, prefferd_language }) => {
  const response = await AxiosInstance.post(API.GET_OTP, {
    user_type: role,
    country_code: countryCode,
    mobile_number: number,
    email: userEmail,
    language_selected: prefferd_language,
  });
  return response;
};
export const VerifyOtp = async (data) => {
  const { role, countryCode, number, Verify_otp, form } = data;

  const response = await AxiosInstance.post(API.VERIFY_OTP, {
    ...form,
    user_type: role,
    country_code: countryCode,
    mobile_number: number,
    code: Verify_otp,
    country_short_code: data?.form?.countryStateCode,
  });
  return response;
};
