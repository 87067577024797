import { createSlice } from '@reduxjs/toolkit';

const seekerSatisfactionSurvey = createSlice({
  name: 'seekerSatisfactionSurvey',
  initialState: {
    pending: false,
    success: false,
    error: false,
  },
  reducers: {
    seekerSatisfactionSurveyStart: (state) => {
      state.pending = true;
      state.error = false;
    },
    seekerSatisfactionSurveySuccess: (state) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    seekerSatisfactionSurveyError: (state, action) => {
      state.success = false;
      state.pending = false;
      state.error = action.payload;
    },
    resetseekerSatisfactionSurvey: (state) => {
      state.success = false;
    },
  },
});
export const {
  seekerSatisfactionSurveyError,
  seekerSatisfactionSurveyStart,
  seekerSatisfactionSurveySuccess,
  resetseekerSatisfactionSurvey,
} = seekerSatisfactionSurvey.actions;
const seekerSatisfactionSurveyReducer = seekerSatisfactionSurvey.reducer;
export default seekerSatisfactionSurveyReducer;
