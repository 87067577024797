import { createSlice } from '@reduxjs/toolkit';

const fetchUser = createSlice({
  name: 'fetchUser',
  initialState: {
    filledUser: {},
    user: {},
    pending: false,
    success: false,
    error: false,
    stateNumber: '',
  },
  reducers: {
    fetchUserStart: (state, action) => {
      state.filledUser = action.payload;
      state.pending = true;
      state.error = false;
    },
    fetchNumberState: (state, action) => {
      state.stateNumber = action.payload;
    },
    fetchUserSuccess: (state, action) => {
      state.filledUser = {};
      state.success = true;
      state.user = action.payload;
      state.pending = false;
      state.error = false;
    },
    fetchUserError: (state, action) => {
      state.success = false;
      state.pending = false;
      state.error = action.payload;
    },
    resetfetchUser: (state) => {
      state.filledUser = {};
      state.success = false;
      state.pending = false;
      state.user = {};
      state.error = {};
    },
  },
});
export const {
  fetchUserError,
  fetchUserStart,
  fetchUserSuccess,
  fetchNumberState,
  resetfetchUser,
} = fetchUser.actions;
const fetchUserReducer = fetchUser.reducer;
export default fetchUserReducer;
