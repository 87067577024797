/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';

function TextInput({
  textType,
  text,
  subHeading,
  placeholder,
  textAfter,
  name,
  disable,
  showEyeButton,
  togglePassword,
  passwordShown,
  error,
  id,
  defaultValue,
  max,
  min,
  bold,
  rows,
  maxRows,
  setPasswordShown,
  multiline,
}) {
  const { control } = useFormContext();

  return (
    <div className="flex flex-col flex-1 relative sm_ss:pr-0 sm_ss:">
      {textAfter === true ? (
        ''
      ) : (
        <span
          className={`text-sectext mb-1 md:mb-2 sm_ss:text-xs sm_s:text-xs sm_ss:mt-3 sm_s:mt-3 ${
            bold && 'font-bold'
          }`}
        >
          {text}
        </span>
      )}
      <Controller
        render={({ field: { onChange, value } }) => (
          <TextField
            onChange={onChange}
            value={value}
            type={`${textType}`}
            error={error}
            variant="outlined"
            disabled={disable}
            size="small"
            placeholder={placeholder}
            name={name}
            itemID={id}
            className={`bg-white rounded-lg ${disable ? '' : ''}`}
            inputProps={{ maxLength: max, minLength: min, min: min?.toString() }}
            multiline={multiline}
            rows={rows}
            maxRows={maxRows}
          />
        )}
        control={control}
        name={name}
        defaultValue={defaultValue}
      />
      {textAfter && <span className="text-sectext mb-1">{text}</span>}
      {subHeading && <p className="text-textGray py-1  mb-5">Format: +00 00 000 00 00</p>}
      {showEyeButton &&
        (!passwordShown ? (
          <i
            // onClick={togglePassword}
            onClick={() => setPasswordShown(!passwordShown)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                togglePassword();
              }
            }}
            className="fa-solid fa-eye-slash absolute right-4 top-11 cursor-pointer text-sectext text-sm"
          />
        ) : (
          <i
            onClick={() => setPasswordShown(!passwordShown)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                togglePassword();
              }
            }}
            className="fa-solid fa-eye absolute right-4 top-11 cursor-pointer text-sectext text-sm"
          />
        ))}
    </div>
  );
}

export default TextInput;
