/* eslint-disable camelcase */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
// import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AxiosInstance from '../../Axios';
import API from '../../constants/API';
import PropertyMenu from './PropertyMenu';
import { appRoutes } from '../../constants/routeConstants/Routes';
import '../../App.css';
import { Encrypt } from '../../util';
import { propertyHoldReasons } from '../../constants/index';

function PropertyCard({
  data,
  setRefetcgProperties,
  userProfile,
  setReFetchPropertiesOnArchice,
  index,
  length,
  setPage,
  page,
}) {
  // eslint-disable-next-line no-unused-vars
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const id = Encrypt(data?.id);
  const [reasons, setReasons] = useState([]);
  const fullAdress = `${data?.street} ${data?.nb} ${data?.zip_code} ${data?.city}`;

  useEffect(() => {
    AOS.init({
      duration: 4000,
    });
  }, []);

  useEffect(() => {
    if (data?.reason) {
      propertyHoldReasons.forEach((element) => {
        if (element.value === data?.reason) {
          element.id = data?.id;
          setReasons([...reasons, element]);
        }
      });
    }
  }, [data]);

  const propertyDelete = async () => {
    MySwal.fire({
      title: t('Are you sure to delete this property'),
      icon: 'info',
      confirmButtonText: t('Yes'),
      showCancelButton: true,
      cancelButtonText: t('CANCEL'),
    }).then(async (res) => {
      if (res.isConfirmed) {
        await AxiosInstance.post(API.PROPERTY_DELETE, {
          property_id: data.id,
        }).then((response) => {
          if (response) {
            if (length === 1) {
              setPage(page === 1 ? 1 : page - 1);
            } else {
              setRefetcgProperties(true);
            }
            MySwal.fire({
              title: t('Property deleted successfully'),
              icon: 'success',
            });
          }
        });
      }
    });
  };

  const ReactiveProperty = async () => {
    MySwal.fire({
      title: t('are you sure to reactivate this property'),
      icon: 'info',
      confirmButtonText: t('Yes'),
      showCancelButton: true,
      cancelButtonText: t('CANCEL'),
    }).then(async (res) => {
      if (res.isConfirmed) {
        await AxiosInstance.post(API.REACTIVE_PROPERTY, {
          user_id: data?.user_id,
          property_id: data?.id,
        }).then((response) => {
          if (response) {
            MySwal.fire({
              title: t('Reactivate Success'),
              icon: 'success',
            }).then((resp) => {
              if (resp.isConfirmed) {
                setRefetcgProperties(true);
              }
            });
          }
        });
      }
    });
  };

  const archiveProperty = async () => {
    MySwal.fire({
      title: t('Are you sure to archive this property'),
      icon: 'info',
      confirmButtonText: t('Yes'),
      showCancelButton: true,
      cancelButtonText: t('CANCEL'),
    }).then(async (res) => {
      if (res.isConfirmed) {
        await AxiosInstance.post(API.ARCHIVE_PROPERTY, {
          property_id: data?.id,
        }).then((response) => {
          if (response) {
            setReFetchPropertiesOnArchice(true);
            MySwal.fire({
              title: t('Property archived successfully'),
              icon: 'success',
            });
          }
        });
      }
    });
  };

  const propertyEdit = () => {
    // prettier-ignore
    navigate(`${appRoutes.EDIT_PROPERTY}?id=${id}`, { replace: true });
  };

  const propertArchive = () => {
    // prettier-ignore
    navigate(`${appRoutes.DRAFT_PROPERTY}?id=${id}`, { replace: true });
  };

  const propertDuplicate = () => {
    // prettier-ignore
    navigate(`${appRoutes.DUPLICATE_PROPERTY}?id=${id}`, { replace: true });
  };

  const truncate = (str) => (str.length > 62 ? `${str.substring(0, 62)}...  ` : str);

  return (
    <div className=" bg-white rounded-lg card-shadow overflow-hidden my-auto" key={index}>
      <Link to={`/property/${id}`}>
        <div className="h-8/12 overflow-hidden relative">
          <img
            src={
              data?.pictures?.length > 0
                ? API.BASE_URL + data?.pictures[0]?.image_path
                : // eslint-disable-next-line prefer-template
                  API.BASE_URL + `images/icons/default-property.png`
            }
            className={`w-full h-50 ${
              data?.pictures?.length > 0 ? 'object-cover' : 'object-cover'
            }`}
            alt=""
          />
        </div>
      </Link>
      <div className="flex flex-col justify-between p-3 min-h-[125px]">
        <div className="flex justify-between items-start ">
          <Link to={`/property/${id}`}>
            <div className="text-primary text-lg">
              {data?.street && data?.nb && data?.zip_code && data?.city
                ? truncate(fullAdress)
                : '#street #nb #zip code #city'}
            </div>
          </Link>

          {userProfile?.role === 'provider' &&
          (userProfile?.organisation_role === 'admin' ||
            userProfile?.organisation_role === 'publisher' ||
            userProfile?.organisation_role === undefined) ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {data?.request_status !== 'pending' && (
                <div className="my-auto">
                  <PropertyMenu
                    callReactiveProperty={ReactiveProperty}
                    callparentfunction={propertyDelete}
                    callPropertyEdit={propertyEdit}
                    callerPropertArchive={propertArchive}
                    callerPropertDuplicate={propertDuplicate}
                    data={data}
                    archiveProperty={archiveProperty}
                    userProfile={userProfile}
                  />
                </div>
              )}
            </>
          ) : (
            ''
          )}
        </div>

        <div className="flex ">
          <div className="flex w-100">
            <div
              className={`my-auto uppercase flex items-center justify-center h-7 w-auto px-2 text-white text-sm md:text-md text-center font-bold rounded-md ${
                data?.request_status === 'pending' && 'bg-[#6F6F6F]'
              }
              ${data?.request_status === 'active' && 'bg-[#73C768]'} 
               ${data?.request_status === 'draft' && 'bg-[#E6BC2E]'} 
               ${data?.request_status === 'rejected' && 'bg-[#D13838]'}
               ${data?.request_status === 'expired' && 'bg-[#C9C9C9]'}
               ${data?.request_status === 'archived' && 'bg-[#C9C9C9]'}
               ${data?.request_status === 'hold' && 'bg-[#6F6F6F]'}
              `}
            >
              {data?.request_status === 'pending'
                ? t(`under review`)
                : t(`${data?.request_status}`)}
            </div>
            {/* {propertyStatus?.[data?.status]?.value === 'pending' && ( */}
            {data?.request_status === 'pending' ||
            data?.request_status === 'hold' ||
            data?.request_status === 'draft' ||
            data?.request_status === 'rejected' ||
            data?.request_status === 'expired' ||
            data?.request_status === 'archived' ? (
              ''
            ) : (
              <div className="my-auto text-sm ml-1">
                {t('Until')} {moment(data.active_until).format('DD.MM.YYYY')}
              </div>
            )}
            {/* )} */}
          </div>
        </div>
        {data?.request_status === 'pending' ||
        data?.request_status === 'draft' ||
        data?.request_status === 'expired' ? (
          ''
        ) : (
          <div className="text-sm  flex text-[#A6A6A6] ">{`${data?.views_count} ${t(
            'views'
          )}`}</div>
        )}
      </div>
    </div>
  );
}

export default PropertyCard;
