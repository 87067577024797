/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaPlus } from 'react-icons/fa';
// import { currencyOptions } from '../../constants';
import DraftButton from '../common/DraftButton';
import ErrorContainer from '../ErrorContainer';

function RentFormCard({
  expandAll,
  collapseAll,
  errors,
  scrollTo,
  noScroll,
  scrollState,
  handelDraftSubmit,
  methods,
  Data,
  language_selected,
  chargesOption,
  setChargesOption,
  chargesOptionErr,
  rentChargesErr,
  SetRentChargesErr,
}) {
  const { t } = useTranslation();
  const [expended, setExpended] = useState(false);
  // const [currency, setCurrency] = useState(currencyOptions[0]);
  const [disable, setDisable] = useState(false);
  const { control } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const { net_rent, rent_charges } = methods.watch();

  useEffect(() => {
    setChargesOption(Data?.monthly_charge_option);
  }, [Data?.monthly_charge_option]);

  useEffect(() => {
    if (expandAll) {
      setExpended(true);
    }
  }, [expandAll]);
  useEffect(() => {
    if (collapseAll) {
      setExpended(false);
    }
  }, [collapseAll]);

  useEffect(() => {
    if (!net_rent || !chargesOption) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [net_rent, chargesOption]);

  useEffect(() => {
    if (chargesOption === 'plus_charges' && !rent_charges) {
      SetRentChargesErr({
        err: true,
        message:
          language_selected === 'en'
            ? 'Rent charges is required'
            : 'Des charges locatives sont demandées',
      });
    } else {
      SetRentChargesErr({ err: false });
    }
  }, [rent_charges, chargesOption]);

  return (
    <div className="border-2 rounded-lg overflow-hidden">
      <Accordion
        className="border-themeGray"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: '0',
          border: 'solid 2px #A6A6A6',
          borderRadius: '10px !important',
        }}
        expanded={expended}
        onChange={() => {
          setExpended(!expended);
        }}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          {expended ? (
            <FaMinus className="text-primary text-2xl my-auto" />
          ) : (
            <FaPlus className="text-primary text-2xl my-auto" />
          )}
          <div className="text-primary text-2xl font-extrabold px-3">{t('Monthly rent')}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col md:px-5 pb-4">
            <div className="grid md:grid-cols-3 gap-4 md:gap-12">
              <div ref={scrollState === 'net_rent' ? scrollTo : noScroll} className=" w-full">
                <div className="flex flex-col flex-1 h-24  relative sm_ss:pr-0 sm_s:pr-0">
                  <span className="text-sectext mb-2 h-8 sm_ss:text-xs sm_s:text-xs">
                    {t('Net rent (excl. charges)')}
                  </span>
                  <FormControl variant="outlined">
                    <OutlinedInput
                      size="small"
                      type="number"
                      className="bg-white"
                      id="outlined-adornment-weight"
                      {...control.register('net_rent')}
                      endAdornment={<InputAdornment position="end">CHF</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        min: '1',
                      }}
                    />
                    {errors.net_rent ? (
                      <ErrorContainer errMsg={t(`${errors.net_rent.message}`)} />
                    ) : (
                      <div id="outlined-weight-helper-text" className="text-textGray text-sm mt-2">
                        {t('Formate:1000.00')}
                      </div>
                    )}
                  </FormControl>
                </div>
              </div>
              <div ref={scrollState === 'rent_charges' ? scrollTo : noScroll} className=" w-full">
                {chargesOption === 'plus_charges' && (
                  <div className="flex flex-col flex-1 h-24 relative sm_ss:pr-0 sm_ss:">
                    <span className="text-sectext mb-2 h-8"> {t('Charges')}</span>
                    <FormControl variant="outlined">
                      <OutlinedInput
                        size="small"
                        type="number"
                        className="bg-white"
                        id="outlined-adornment-weight"
                        {...control.register('rent_charges')}
                        endAdornment={<InputAdornment position="end">CHF</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'weight',
                          min: '1',
                        }}
                      />
                      {rentChargesErr?.err ? (
                        <ErrorContainer errMsg={rentChargesErr?.message} />
                      ) : (
                        <div
                          id="outlined-weight-helper-text"
                          className="text-textGray text-sm mt-2"
                        >
                          {t('Formate:1000.00')}
                        </div>
                      )}
                    </FormControl>
                  </div>
                )}
                <div
                  className="flex flex-col pt-5 sm_ss:text-xs sm_s:text-xs"
                  name="monthly_charge_option"
                  onChange={(e) => {
                    methods.setValue('monthly_charge_option', e.target.value, {
                      shouldValidate: true,
                    });
                    setChargesOption(e.target.value);
                  }}
                >
                  <div className="flex items-center">
                    <input
                      type="radio"
                      className="h-5 w-5 mr-2"
                      id="plus_charges"
                      name="monthly_charge_option"
                      value="plus_charges"
                      checked={chargesOption === 'plus_charges'}
                      defaultChecked={Data?.monthly_charge_option?.includes('plus_charges')}
                    />
                    <label htmlFor="plus_charges">{t('Plus Charges')}</label>
                  </div>
                  <div className="flex items-center py-2">
                    <input
                      type="radio"
                      className="h-5 w-5 mr-2"
                      id="included"
                      name="monthly_charge_option"
                      value="included"
                      checked={chargesOption === 'included'}
                      defaultChecked={Data?.monthly_charge_option?.includes('included')}
                    />
                    <label htmlFor="included">{t('Included')}</label>
                  </div>
                  <div className="flex items-center py-2">
                    <input
                      type="radio"
                      className="h-5 w-5 mr-2"
                      id="individual"
                      name="monthly_charge_option"
                      value="individual"
                      checked={chargesOption === 'individual'}
                      defaultChecked={Data?.monthly_charge_option?.includes('individual')}
                    />
                    <label htmlFor="individual">{t('Individual')}</label>
                  </div>
                  <ErrorContainer errMsg={chargesOptionErr?.message} />
                </div>
                {errors.monthly_charge_option && (
                  <ErrorContainer errMsg={t(`${errors.monthly_charge_option.message}`)} />
                )}
              </div>
            </div>
            <DraftButton
              handelDraftSubmit={() => {
                handelDraftSubmit('draft', setIsLoading);
              }}
              disable={disable}
              language_selected={language_selected}
              draftLoading={isLoading}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default RentFormCard;
