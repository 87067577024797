import React, { useMemo, useState } from 'react';

export default function OtpInput({ value = '', valueLength, onChange, handlePaste }) {
  const [inputValue, setInputValue] = useState('');
  const regex = /^[0-9]+$/;

  const valueItems = useMemo(() => {
    let valueArray = '';
    if (value) {
      if (typeof value !== 'object') {
        valueArray = value.split('');
      } else {
        valueArray = value;
      }
    } else if (inputValue) {
      valueArray = inputValue;
    } else {
      valueArray = [];
    }
    // const valueArray = value.split('') || inputValue?.split('');
    const items = [];
    const new2 = valueArray.filter((it) => it);
    for (let i = 0; i < valueLength; i++) {
      if (valueArray[i]) {
        items.push(valueArray[i].trim());
      } else {
        items.push('');
      }

      // const char = parseInt(valueArray[i], 10);
      // if (char) {
      //   items.push(char);
      // } else {
      //   items.push('');
      // }
    }

    return items;
  }, [value, valueLength, inputValue]);

  const focusToNextInput = (target) => {
    const { nextElementSibling } = target;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInput = (target) => {
    const { previousElementSibling } = target;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnChange = (e, idx) => {
    const { target } = e;
    let targetValue = target.value.trim();
    const isTargetValueDigit = regex.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    const nextInputEl = target.nextElementSibling;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      let newValue;
      if (typeof value === 'object') {
        newValue =
          value.join('').substring(0, idx) + targetValue + value.join('').substring(idx + 1);
      } else {
        newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);
      }
      console.log('newValue', newValue);
      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);

      target.blur();
    }
  };

  // eslint-disable-next-line consistent-return
  const inputOnKeyDown = (e) => {
    const { key } = e;
    const { target } = e;

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || targetValue !== '') {
      // eslint-disable-next-line consistent-return
      return;
    }

    focusToPrevInput(target);
  };

  // eslint-disable-next-line consistent-return
  const inputOnFocus = (e) => {
    const { target } = e;

    // keep focusing back until previous input
    // element has value
    const prevInputEl = target.previousElementSibling;

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <div className="otpGroup flex ">
      {valueItems?.map((digit, idx) => (
        <input
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={1}
          className="otp-input OtpInputStyle xs:w-4 sm:w-10 xl:w-16 xl:h-16 w-10"
          placeholder="-"
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
          onPaste={(e) => handlePaste(e)}
        />
      ))}
    </div>
  );
}
