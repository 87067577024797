/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-curly-brace-presence */
// eslint-disable-next-line react/jsx-curly-brace-presence
// eslint-disable-next-line react/jsx-filename-extension

import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'js-cookie';
import { CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import TextInput from '../components/TextInput';
import { appRoutes } from '../constants/routeConstants/Routes';
// import { login } from '../apiCalls';
import { resetlogin, userLogIn } from '../redux/slices/Login';
import AxiosInstance from '../Axios';
import API from '../constants/API';
import ErrorContainer from '../components/ErrorContainer';
import { appUserRoles } from '../constants';

function EmployerFinalLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem('user'));
  const authState = userData?.data?.isAuthenticated;
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const pending = useSelector((state) => state.login.pending);
  const success = useSelector((state) => state.login.success);
  const serverError = useSelector((state) => state.login.error);
  const userInfo = useSelector((state) => state.fetchUser.user);
  const [passwordShown, setPasswordShown] = useState(false);
  const [type, setType] = useState();
  const { email } = userInfo;
  const language_selected = Cookies.get('i18next');

  let token;
  if (!userData) {
    token = null;
  } else {
    token = userData?.data?.result?.access_token;
  }
  const userRole = userData?.data?.result?.role;
  useEffect(() => {
    if (token) {
      if (userRole === appUserRoles.provider) {
        navigate(appRoutes.DASHBOARD_PROVIDER);
      } else if (userRole === appUserRoles.seeker) {
        navigate(appRoutes.DASHBOARD_SEEKER);
      }
    } else {
      navigate(appRoutes.LOGIN_STEP_2);
    }
  }, [userRole, token]);

  useEffect(() => {
    if (!localStorage.getItem('user_type')) {
      MySwal.fire({
        title: (
          <span style={{ fontSize: '16px', lineHeight: 0 }}>
            {t('Do you connect as a housing seeker of a housing offeror?')}
            <br />
            <span style={{ fontSize: '12px', color: 'gray', opacity: 0.7 }}>
              ({t('Refresh the page to change user type')})
            </span>
          </span>
        ),
        allowOutsideClick: false,
        icon: 'info',
        confirmButtonText: t('housing seeker'),
        showDenyButton: true,
        denyButtonText: t('housing offeror'),
        denyButtonColor: '#004993',
      }).then((res) => {
        if (res.isConfirmed) {
          setType('seeker');
          localStorage.setItem('user_type', 'seeker');
        }
        if (res.isDenied) {
          setType('provider');
          localStorage.setItem('user_type', 'provider');
        }
      });
    } else {
      setType(localStorage.getItem('user_type'));
    }
  }, [localStorage.getItem('user_type'), language_selected]);

  const schema = yup.object().shape({
    email: yup.string().email(t('Please enter a valid email')).required(t('Email is required')),
    password: yup
      .string()
      .required(t('Password is required'))
      .min(8, t('Password must be 8 characters long')),
  });

  const methods = useForm({ defaultValues: { email }, resolver: yupResolver(schema) });

  const {
    formState: { errors },
  } = methods;

  const { email: Email } = methods.getValues();

  useEffect(() => {
    if (!!serverError) {
      MySwal.fire({
        title: serverError?.email?.[0] ? serverError?.email?.[0] : serverError,
        icon: 'error',
      }).then((res) => {
        if (res.isConfirmed) {
          dispatch(resetlogin());
        }
      });
    }
  }, [serverError, MySwal, dispatch]);

  const role = JSON.parse(localStorage.getItem('user'))?.data?.result?.role;
  const status = JSON.parse(localStorage.getItem('user'))?.data?.result?.latest_request
    ? JSON.parse(localStorage.getItem('user'))?.data?.result?.latest_request?.status
    : JSON.parse(localStorage.getItem('user'))?.data?.result?.status;
  const is_organisation_member = JSON.parse(localStorage.getItem('user'))?.data?.result
    ?.is_organisation_member;
  const is_email_verified = JSON.parse(localStorage.getItem('user'))?.data?.result
    ?.is_email_verified;

  useEffect(() => {
    if (success === true) {
      if (!is_email_verified) {
        MySwal.fire({
          title: t('Please verify your email first'),
          icon: 'info',
          html: t(`link not received? click below button`),
          confirmButtonText: t('Verify email'),
        }).then(async (res) => {
          if (res.isConfirmed) {
            MySwal.fire({
              title: t('Link has been sent to your email'),
              icon: 'success',
            });
            await AxiosInstance.post(API.RESEND_VERIFY_EMAIL, {
              email: Email,
              language_selected,
              user_type: type,
            });
            dispatch(resetlogin());
          }
        });
        dispatch(resetlogin());
      } else if (
        success &&
        role === 'provider' &&
        (status === 'active' || status === 'suspended')
      ) {
        navigate(appRoutes.DASHBOARD_PROVIDER);
      } else if (
        success &&
        role === 'seeker' &&
        (status === 'active' ||
          status === 'suspended' ||
          status === 'rejected' ||
          status === 'archived' ||
          status === 'hold' ||
          status === 'expired')
      ) {
        navigate(appRoutes.DASHBOARD_SEEKER);
      } else if (role === 'superadmin' || role === 'admin') {
        window.open(userData.data.result.temporary_login_link, '_blank');
      } else if (is_organisation_member || status === 'active' || status === 'archived') {
        navigate(appRoutes.DASHBOARD_PROVIDER);
      } else if (status === 'inactive' || status === 'pending') {
        navigate(appRoutes.pendingActivation);
      }
    }
  }, [success, role, status]);

  const onLogin = (Data) => {
    const user_type = type;
    const data = { ...Data, language_selected, user_type };
    dispatch(userLogIn(data));
  };

  return (
    <div
      className={`w-full bg-[url("assets/images/background-A.jpg")] relative bg-cover bg-no-repeat flex justify-center min-h-[600px] items-center h-full`}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onLogin)}
          className="w-4xl h-full flex justify-center items-center"
        >
          <div className="flex w-full 2xl:w-3xl md:w-3xl overflow-hidden flex-col justify-center items-center bg-white rounded-2xl smx:w-7/12 mt-5 sm:w-1/3  sm_ss:w-full sm_s:w-full sm_ss:rounded-none sm_s:rounded-none sm_s:p-10 sm_ss:p-10">
            {(type || localStorage.getItem('user_type')) && (
              <div className="w-full md:px-13 pb-7 pt-4">
                <div className="flex w-full flex-col justify-center items-center h-full smx:w-full">
                  <div className="w-full flex flex-col relative py-3">
                    <TextInput
                      text={t('Email')}
                      textType={'email'}
                      placeholder={t('PlaceholderEmail')}
                      id="email"
                      name="email"
                    />
                    <ErrorContainer errMsg={t(errors?.email?.message)} />
                  </div>
                  <div className="w-full flex flex-col relative py-3">
                    <TextInput
                      text={t('Password')}
                      // textType={'password'}
                      textType={!passwordShown ? 'password' : 'text'}
                      placeholder={'********'}
                      id="password"
                      name="password"
                      showEyeButton
                      passwordShown={passwordShown}
                      setPasswordShown={setPasswordShown}
                      // togglePassword={togglePassword}
                    />
                    <ErrorContainer errMsg={errors?.password?.message} />
                  </div>
                </div>
                <div className="flex w-full pt-5 flex-col items-center ">
                  <div className="flex w-full flex-row items-center justify-between">
                    {pending === true ? (
                      <Button text={<CircularProgress size="20px" color="inherit" />} />
                    ) : (
                      <Button text={t('Login')} />
                    )}

                    <Link to={appRoutes.ResetPassword}>
                      <span className="text-blue underline cursor-pointer sm_ss:text-sm sm_s:text-sm">
                        {t('ForgotPWD')}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

function Button({ text }) {
  return (
    <button
      type="submit"
      className="h-10 bg-blue text-white uppercase flex justify-center items-center w-32 py-2 rounded-md cursor-pointer"
    >
      {text}
    </button>
  );
}

export default EmployerFinalLogin;
