/* eslint-disable prettier/prettier */
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AxiosInstance from '../Axios';
import API from '../constants/API';
import { appRoutes } from '../constants/routeConstants/Routes';

function PendingActivation() {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState();

  useEffect(() => {
    const userProfileData = async () => {
      const res = await AxiosInstance.post(API.GETPROFILE);
      setUserProfile(res.data.result);
    };
    userProfileData();
  }, []);

  useEffect(() => {
    if (userProfile?.latest_request?.status === 'active' && userProfile?.role === 'provider') {
      navigate(appRoutes.DASHBOARD_PROVIDER);
    } else if (
      (userProfile?.latest_request?.status === 'active' ||
        userProfile?.latest_request?.status === 'rejected' ||
        userProfile?.latest_request?.status === 'hold') &&
      userProfile?.role === 'seeker'
    ) {
      navigate(appRoutes.DASHBOARD_SEEKER);
    }
  }, [userProfile?.latest_request?.status]);

  return (
    <div className="flex h-fullX flex-col items-center justify-center">
      <div className="w-full flex flex-col items-center justify-center">
        <p className="text-sectext text-center text-lg">{t('ApplicationProceedingText')}</p>
        <p className="text-sectext text-center text-lg">{t('InformedText')}</p>
      </div>
    </div>
  );
}

export default PendingActivation;
