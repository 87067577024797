/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useLocation, useSearchParams } from 'react-router-dom';
import AxiosInstance from '../../Axios';
import API from '../../constants/API';

// import DatePickerComp from '../common/DatePicker';

export default function Extend({ data, setpublished_until, published_until }) {
  const MySwal = withReactContent(Swal);
  const location = useLocation();
  const [value, setValue] = useState();
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');

  const extentDate = () => {
    AxiosInstance.post(API.EXTEND, {
      property_id: id,
      published_until,
    }).then((res) => {
      if (res) {
        MySwal.fire({
          title: 'Date Extended',
          icon: 'success',
        });
      }
    });
  };

  const someDate = new Date();
  const numberOfDaysToAdd = 56;
  const result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
  const expectedDate = new Date(result);
  const finalDate = moment(expectedDate, 'DD/MM/YYYY');

  const selectedDate = new Date();
  const numberofDatestoAdd = 30;
  const res = selectedDate.setDate(selectedDate.getDate() + numberofDatestoAdd);
  const ExpectedSelectedDate = new Date(res);
  const ShownSelectedDate = moment(ExpectedSelectedDate, 'DD/MM/YYYY');

  useEffect(() => {
    if (ShownSelectedDate) {
      setValue(ShownSelectedDate);
      setpublished_until(moment(ShownSelectedDate).format('DD/MM/YYYY'));
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line prettier/prettier
    const defaultValue = data?.active_until;
    if (defaultValue) {
      setValue(moment(defaultValue, 'DD/MM/YYYY'));
    }
  }, [data?.active_until]);

  useEffect(() => {
    if (location.pathname.includes('duplicate-property')) {
      setValue(moment(ShownSelectedDate, 'DD/MM/YYYY'));
      setpublished_until(moment(ShownSelectedDate).format('DD/MM/YYYY'));
    }
  }, [data?.active_until]);

  return (
    <div className="mb-5">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="min-w-40">
          <div className="mb-2">{t('Publish until')}</div>
          <DatePicker
            value={value}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            inputFormat="DD/MM/yyyy"
            maxDate={finalDate}
            disablePast
            // {...methods?.register('published_until')}
            onChange={(newValue) => {
              setValue(newValue);
              setpublished_until(moment(newValue).format('DD/MM/YYYY'));
              // methods.setValue('published_until', moment(newValue).format('DD/MM/YYYY'));
            }}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                size="small"
                className="bg-white rounded-lg"
                {...params}
                onClick={() => setOpen(true)}
              />
            )}
          />
        </div>
      </LocalizationProvider>
      <p className="py-1 text-[#A6A6A6]">{t('Max 2 months')}</p>
      {location.pathname?.includes('add-property') ||
      (location.pathname?.includes('edit-property') && data?.request_status === 'draft') ? (
        ''
      ) : (
        <button
          onClick={extentDate}
          type="button"
          className="disable:bg-[#6F6F6F] py-2 px-4 text-white rounded-md mb-3 bg-[#004993]"
        >
          Extend
        </button>
      )}
    </div>
  );
}
