/* eslint-disable prettier/prettier */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../Axios';
import API from '../../constants/API';

export const addOrganization = createAsyncThunk('AddOrganization', async ({ data }) => {
  const response = await AxiosInstance.post(API.PROFILE_UPDATE, { ...data });
  return response.data;
});

const AddOrganization = createSlice({
  name: 'AddOrganization',
  initialState: {
    pending: false,
    success: false,
    error: false,
  },
  reducers: {
    AddOrganizationStart: (state) => {
      state.pending = true;
      state.error = false;
    },
    AddOrganizationSuccess: (state) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    AddOrganizationError: (state, action) => {
      state.success = false;
      state.pending = false;
      state.error = action.payload;
    },
    resetAddOrganization: (state) => {
      state.success = false;
      state.error = false;
      state.pending = false;
    },
  },
  extraReducers: {
    [addOrganization.fulfilled]: (state) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    [addOrganization.rejected]: (state) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
  },
});
export const {
  AddOrganizationError,
  AddOrganizationStart,
  AddOrganizationSuccess,
  resetAddOrganization,
} = AddOrganization.actions;
const AddOrganizationReducer = AddOrganization.reducer;
export default AddOrganizationReducer;
