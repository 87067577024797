import { createSlice } from '@reduxjs/toolkit';

const seekerSignup = createSlice({
  name: 'seekerSignup',
  initialState: {
    pending: false,
    success: false,
    error: false,
  },
  reducers: {
    seekerSignupStart: (state) => {
      state.pending = true;
      state.error = false;
    },
    seekerSignupSuccess: (state) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    seekerSignupError: (state, action) => {
      state.success = false;
      state.pending = false;
      state.error = action.payload;
    },
    resetseekerSignup: (state) => {
      state.success = false;
    },
  },
});
export const { seekerSignupError, seekerSignupStart, seekerSignupSuccess, resetseekerSignup } =
  seekerSignup.actions;
const seekerSignupReducer = seekerSignup.reducer;
export default seekerSignupReducer;
