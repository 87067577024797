/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { AppRoutes } from './routes';

import AuthMiddleware from './middleware/authmiddleware';
import PageNotFound from '../Pages/PageNotFound';

function Routers({ setStateUpdate }) {
  return (
    <Routes>
      {AppRoutes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            element={<AuthMiddleware route={route} setStateUpdate={setStateUpdate} />}
          />
        );
      })}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Routers;
