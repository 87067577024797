/* eslint-disable import/no-named-as-default */
/* eslint-disable object-shorthand */
import { configureStore } from '@reduxjs/toolkit';
import providerSignUpReducer from './slices/ProviderSignup';
import seekerSignupReducer from './slices/SeekerSignup';
import seekerSatisfactionSurveyReducer from './slices/SeekerSatisfactionSurvey';
import loginReducer from './slices/Login';
import fetchUserReducer from './slices/FetchUser';
import profileUpdateReducer from './slices/ProviderProfileUpdate';
import seekerProfileUpdateReducer from './slices/SeekerProfileUpdate';
import suspendRequestReducer from './slices/SuspendRequest';
import seekerNewReqReducer from './slices/SeekerNewReq';
import addAndEditPropertyReducer from './slices/AddAndEditProperty';
import AddOrganizationReducer from './slices/AddOrganization';
import addNewMemberReducer from './slices/AddNewMember';
import draftPropertyReducer from './slices/DraftSlice';
import resetPassword from './slices/ResetPassword';
import updateUserInfoReducer from './slices/UpdateUserInfo';
import userReducer from './slices/user';

const store = configureStore({
  reducer: {
    providerSignup: providerSignUpReducer,
    SeekerSignup: seekerSignupReducer,
    seekerSatisfactionSurvey: seekerSatisfactionSurveyReducer,
    login: loginReducer,
    fetchUser: fetchUserReducer,
    profileUpdate: profileUpdateReducer,
    seekerProfileUpdateReducer: seekerProfileUpdateReducer,
    suspendRequestReducer: suspendRequestReducer,
    seekerNewReqReducer: seekerNewReqReducer,
    addAndEditPropertyReducer: addAndEditPropertyReducer,
    AddOrganizationReducer: AddOrganizationReducer,
    addNewMemberReducer: addNewMemberReducer,
    draftPropertyReducer: draftPropertyReducer,
    resetPassword: resetPassword,
    updateUserInfoReducer: updateUserInfoReducer,
    userReducer: userReducer,
  },
});
export default store;
