/* eslint-disable prettier/prettier */
import { createSlice } from '@reduxjs/toolkit';

const addNewMember = createSlice({
  name: 'addNewMember',
  initialState: {
    pending: false,
    success: false,
    error: false,
  },
  reducers: {
    addNewMemberStart: (state) => {
      state.pending = true;
      state.error = false;
    },
    addNewMemberSuccess: (state) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    addNewMemberError: (state, action) => {
      state.success = false;
      state.pending = false;
      state.error = action.payload;
    },
    resetaddNewMember: (state) => {
      state.success = false;
    },
  },
});
export const { addNewMemberError, addNewMemberStart, addNewMemberSuccess, resetaddNewMember } =
  addNewMember.actions;
const addNewMemberReducer = addNewMember.reducer;
export default addNewMemberReducer;
