/* eslint-disable prettier/prettier */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../Axios';
import API from '../../constants/API';

export const DraftProperty = createAsyncThunk(
  'DraftProperty',
  async ({ data, setCurrentFormID, setIsLoading }, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.post(API.ADD_AND_EDIT_PROPERTY, { ...data });
      if (response?.data?.statusCode === 201) {
        setCurrentFormID(response?.data?.result?.id);
        if (setIsLoading) {
          setIsLoading(false);
        }
      }
    
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const draftProperty = createSlice({
  name: 'draftProperty',
  initialState: {
    loading: false,
    success: false,
    error: false,
    draft: false,
  },
  reducers: {
    resetdraftPropertyUpdate: (state) => {
      state.success = false;
      state.error = false;
      state.loading = false;
    },
  },
  extraReducers: {
    [DraftProperty.pending]: (state) => {
      state.success = false;
      state.loading = true;
      state.error = false;
    },
    [DraftProperty.fulfilled]: (state) => {
      state.success = true;
      state.loading = false;
      state.error = false;
    },
    [DraftProperty.rejected]: (state) => {
      state.success = false;
      state.loading = false;
      state.error = true;
    },
  },
});
export const { resetdraftPropertyUpdate } = draftProperty.actions;
const draftPropertyReducer = draftProperty.reducer;
export default draftPropertyReducer;
