/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { appUserRoles } from '../constants';
import { appRoutes } from '../constants/routeConstants/Routes';
import { resetProviderSignup } from '../redux/slices/ProviderSignup';
import { resetseekerSignup } from '../redux/slices/SeekerSignup';
import LandingPageVideo from '../assets/videos/video.mp4';
import videoThumbnail from '../assets/images/thumbnail.png';
import { resetlogin } from '../redux/slices/Login';

function Home() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('user'));
  const authState = userData?.data?.isAuthenticated;
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  localStorage.clear();

  useEffect(() => {
    MySwal.close();
  }, []);

  let token;
  if (!userData) {
    token = null;
  } else {
    token = userData?.data?.result?.access_token;
  }

  const userRole = userData?.data?.result?.role;
  useEffect(() => {
    if (token && authState) {
      if (userRole === appUserRoles.provider) {
        navigate(appRoutes.DASHBOARD_PROVIDER);
      } else if (userRole === appUserRoles.seeker) {
        navigate(appRoutes.DASHBOARD_SEEKER);
      }
    }
  }, [userRole, authState, token]);

  useEffect(() => {
    dispatch(resetProviderSignup());
    dispatch(resetseekerSignup());
    dispatch(resetlogin());
  }, [dispatch]);
  return (
    <div className="bg-primary-image md:h-full flex flex-col justify-center items-center overflow-x-hidden">
      <div className="max-w-4xl h-full">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mt-20 sm_ss:px-3 sm_s:px-5 md:px-6 sm:px-10">
          <div className="bg-white overflow-hidden py-8 px-5 rounded-2xl flex flex-col justify-start">
            <h3 className="text-2xl font-bold text-[#6F6F6F] pb-8 h-16">{t('HomeSeekerTitle')}</h3>
            <p>{t('HomeSeekerDesc')} </p>
            <ul className="list-disc ml-8">
              <li>{t('HomeSeekerDescP1')} </li>
              <li>{t('HomeSeekerDescP2')} </li>
              <li>{t('HomeSeekerDescP3')} </li>
              <li>{t('HomeSeekerDescP4')}</li>
              <li>{t('HomeSeekerDescP5')}</li>
              <li>{t('HomeSeekerDescP6')}</li>
            </ul>

            <div className="mt-8 flex justify-start items-end">
              <Link
                onClick={() => localStorage.setItem('user_type', 'seeker')}
                to={appRoutes.SEEKER_PROFILECONFORMATION}
                className="bg-primary text-sm lg:text-md text-white py-2 px-4 rounded-lg font-bold"
              >
                {t('HomeSeekerBtnTxt')}
              </Link>
            </div>
          </div>
          <div className="bg-white p-8 rounded-2xl flex flex-col justify-between">
            <div className="flex flex-col">
              <h3 className="text-2xl font-bold text-[#6F6F6F] pb-8 h-16">
                {t('HomeProviderTitle')}
              </h3>
              <p>{t('HomeProviderDesc')}</p>
              <ul className="list-disc ml-8">
                <li>{t('HomeProviderDescP1')}</li>
                <li>{t('HomeProviderDescP2')} </li>
                <li>{t('HomeProviderDescP3')} </li>
              </ul>
            </div>
            <div className="mt-8 flex justify-start items-end">
              <Link
                onClick={() => localStorage.setItem('user_type', 'provider')}
                to={appRoutes.PROVIDER_PROFILECONFORMATION}
                className="bg-primary text-sm lg:text-md text-white py-2 px-4 rounded-lg font-bold"
              >
                {t('HomeProviderBtnTxt')}
              </Link>
            </div>
          </div>
        </div>
        <div>
          <video controls poster={videoThumbnail} className="w-full py-10">
            <source src={LandingPageVideo} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
}

export default Home;
