/* eslint-disable no-nested-ternary */

import { Menu, MenuItem } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import { IoIosCopy } from 'react-icons/io';
import { MdArchive, MdDelete, MdModeEdit } from 'react-icons/md';
import { AiFillCheckCircle } from 'react-icons/ai';
import { Encrypt } from '../../util';

function PropertyMenu({
  callReactiveProperty,
  callparentfunction,
  callPropertyEdit,
  callerPropertArchive,
  callerPropertDuplicate,
  data,
  archiveProperty,
  userProfile,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // const MySwal = withReactContent(Swal);
  // const methods = useForm();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = Encrypt(data?.id);
  return (
    <div className={`${data?.request_status === 'pending' && 'hidden'}`}>
      <FaEllipsisV
        className="my-auto ml-2 cursor-pointer text-gray-500"
        size="20px"
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            padding: '10px 0px',
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {data?.request_status === 'expired' ? (
          ''
        ) : data?.request_status === 'rejected' ? (
          ''
        ) : (
          <MenuItem className="w-36 px-8" onClick={() => callPropertyEdit()}>
            <MdModeEdit className="text-[20px] mr-2  text-[#A6A6A6]" /> {t('Edit')}
          </MenuItem>
        )}
        {data?.request_status === 'draft' || data?.request_status === 'active' ? (
          ''
        ) : data?.request_status === 'expired' ? (
          ''
        ) : data?.request_status === 'rejected' ? (
          ''
        ) : data?.request_status === 'pending' ? (
          ''
        ) : data?.request_status === 'archived' ? (
          ''
        ) : data?.request_status === 'hold' ? (
          ''
        ) : (
          <MenuItem className="w-36 px-8" onClick={() => callerPropertArchive()}>
            <MdArchive className="text-[20px] mr-2 text-[#55A2F0]" />
            {t('Draft')}
          </MenuItem>
        )}

        {data?.request_status === 'expired' ? (
          ''
        ) : data?.request_status === 'archived' ? (
          ''
        ) : data?.request_status === 'hold' ? (
          ''
        ) : (
          <MenuItem className="w-36 px-8" onClick={() => callerPropertDuplicate()}>
            <IoIosCopy className="text-[20px] mr-2 text-[#006BD8]" />
            {t('Duplicate')}
          </MenuItem>
        )}
        {data?.request_status === 'active' && userProfile?.organisation_role !== 'publisher' ? (
          <MenuItem className="w-36 px-8" onClick={() => archiveProperty(id)}>
            <MdArchive className="text-[20px] mr-2 text-[#D13838]" /> {t('Archiver')}
          </MenuItem>
        ) : (
          ''
        )}
        {data?.request_status === 'expired' ? (
          <MenuItem className="w-36 px-8" onClick={() => callReactiveProperty()}>
            <AiFillCheckCircle className="text-[20px] mr-2 text-[#73C768]" /> {t('Reactivate')}
          </MenuItem>
        ) : (
          ''
        )}
        {userProfile?.organisation_role !== 'publisher' && (
          <MenuItem className="w-36 px-8" onClick={() => callparentfunction()}>
            <MdDelete className="text-[20px] mr-2 text-[#D13838]" /> {t('Delete')}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export default PropertyMenu;
