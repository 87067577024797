/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaPlus } from 'react-icons/fa';
import DraftButton from '../common/DraftButton';
import ErrorContainer from '../ErrorContainer';

function DescriptionForm({
  expandAll,
  collapseAll,
  errors,
  handelDraftSubmit,
  scrollState,
  scrollTo,
  noScroll,
  methods,
  language_selected,
  draftLoading,
}) {
  const { t } = useTranslation();
  const [expended, setExpended] = useState(false);
  const { control } = useFormContext();
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { english_description, french_description } = methods.watch();

  useEffect(() => {
    if (expandAll) {
      setExpended(true);
    }
  }, [expandAll]);
  useEffect(() => {
    if (collapseAll) {
      setExpended(false);
    }
  }, [collapseAll]);

  useEffect(() => {
    if (!english_description && !french_description) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [english_description, french_description]);

  return (
    <div className="border-2 rounded-lg  overflow-hidden">
      <Accordion
        className="border-themeGray"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: '0',
          border: 'solid 2px #A6A6A6',
          borderRadius: '10px !important',
        }}
        expanded={expended}
        onChange={() => {
          setExpended(!expended);
        }}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          {expended ? (
            <FaMinus className="text-primary text-2xl my-auto" />
          ) : (
            <FaPlus className="text-primary text-2xl my-auto" />
          )}
          <div className="text-primary text-2xl font-extrabold px-3">{t('Description')}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col px-2 md:px-5 pb-4">
            <div className="grid gap-y-6">
              <div>
                <div
                  ref={scrollState === 'english_description' ? scrollTo : noScroll}
                  className="text-sectext mb-1 md:mb-2 sm_ss:text-xs sm_s:text-xs"
                >
                  {t('English')}
                </div>
                <TextField
                  {...control.register('english_description')}
                  className="bg-white w-full"
                  id="outlined-multiline-static"
                  multiline
                  rows={8}
                  inputProps={{
                    maxLength: 300,
                  }}
                />
                <div className="text-[#A6A6A6] sm_ss:text-xs sm_s:text-xs">{`${
                  english_description?.length === undefined ? 0 : english_description?.length
                }/300 ${t('characters')}`}</div>
                {errors?.english_description && (
                  <ErrorContainer errMsg={t(`${errors.english_description.message}`)} />
                )}
              </div>
              <div>
                <div
                  ref={scrollState === 'french_description' ? scrollTo : noScroll}
                  className="text-sectext mb-1 md:mb-2 sm_ss:text-xs sm_s:text-xs"
                >
                  {t('French')}
                </div>
                <TextField
                  {...control.register('french_description')}
                  className="bg-white w-full"
                  id="outlined-multiline-static"
                  multiline
                  rows={8}
                  inputProps={{
                    maxLength: 300,
                  }}
                />
                <div className="text-[#A6A6A6] sm_ss:text-xs sm_s:text-xs">{`${
                  french_description?.length === undefined ? 0 : french_description?.length
                }/300 ${t('characters')}`}</div>
                {errors?.french_description && (
                  <ErrorContainer errMsg={t(`${errors.french_description.message}`)} />
                )}
              </div>
            </div>
            {/* <button
              type="button"
              disabled={disable}
              onClick={() => handelDraftSubmit('draft')}
              className="bg-primary disabled:bg-themeSecgray disabled:cursor-not-allowed px-4 w-20 py-2 mt-8 text-base rounded-md text-white font-bold my-1"
            >
              {t('SAVE')}
            </button> */}
            <DraftButton
              handelDraftSubmit={() => {
                handelDraftSubmit('draft', setIsLoading);
              }}
              disable={disable}
              language_selected={language_selected}
              draftLoading={isLoading}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default DescriptionForm;
