/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useForm, FormProvider } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import Container from '../Container';
import FieldsContainer from '../FieldsContainer';
import ItemContainer from '../ItemContainer';
import SelectField from '../SelectField';
import Separater from '../Separater';
import SubWrapper from '../SubWrapper';

import {
  FurnishedBoolean,
  language,
  EmployedBy,
  LegitimationCard,
  titleOptions,
  seekerProfileUpdateFormSchema,
  titleOptionsfr,
  FurnishedBooleanfr,
  languagefr,
  EmployedByfr,
  LegitimationCardFR,
  ChildrenYesArray,
} from '../../constants';
import TextInput from '../TextInput';
// import CheckBox from '../CheckBox';
import {
  resetseekerProfileUpdate,
  SeekerProfileupdate,
} from '../../redux/slices/SeekerProfileUpdate';
import { appRoutes } from '../../constants/routeConstants/Routes';
import AxiosInstance from '../../Axios';
import API from '../../constants/API';
import { updateUserInfoStart, updateUserInfoSuccess } from '../../redux/slices/UpdateUserInfo';
import ErrorContainer from '../ErrorContainer';
import CountryCodeInput from '../CountryCodeInput';

// import validations from '../util';

function SeekerProfileUpdate() {
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const success = useSelector((state) => state.seekerProfileUpdateReducer.success);
  const pending = useSelector((state) => state.seekerProfileUpdateReducer.pending);
  const error = useSelector((state) => state.seekerProfileUpdateReducer.error);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [scrollState, setScrollState] = useState();
  const [deleteProfile, setDeleteProfile] = useState(false);
  const scrollTo = useRef();
  const noScroll = useRef();
  const language_selected = Cookies.get('i18next');
  const methods = useForm({
    resolver: yupResolver(seekerProfileUpdateFormSchema),
  });
  const { children } = methods.watch();
  const { errors } = methods.formState;

  useLayoutEffect(() => {
    const userProfileData = async () => {
      const res = await AxiosInstance.post(API.GETPROFILE);
      methods.reset(res.data.result);

      const number = `+${res.data.result?.country_code}${res.data.result?.mobile_number}`;
      methods.setValue('mobile_number', number);
      setUserProfile(res.data.result);
    };

    userProfileData();
  }, []);

  useEffect(() => {
    setScrollState(Object.keys(errors)[0]);
  }, [errors]);

  useEffect(() => {
    if (scrollState && scrollTo?.current?.scrollIntoView) {
      scrollTo.current.scrollIntoView({
        behavior: 'smooth',
      });
      setScrollState('');
    }
  }, [scrollState, scrollTo?.current?.scrollIntoView]);

  const user_type = 'seeker';

  const onSubmit = (data) => {
    dispatch(updateUserInfoStart());
    const seekerData = {
      ...data,
      user_type,
      // country_code: `+${data?.countryCode?.dialCode}`,
      // mobile_number: data?.phoneNumber ? data?.phoneNumber : '',
      country_code: data?.countryCode?.dialCode
        ? `+${data?.countryCode?.dialCode}`
        : userProfile?.country_code,
      mobile_number: data?.phoneNumber ? data?.phoneNumber : userProfile?.mobile_number,
    };

    dispatch(
      SeekerProfileupdate({
        data: seekerData,
        dispatch,
        updateUserInfoSuccess,
        fun: () => {
          navigate(appRoutes.DASHBOARD_SEEKER);
        },
      })
    );
  };

  const deleteAccount = async () => {
    await AxiosInstance.post(API.DELETE_ACCOUNT).then((res) => {
      if (res) {
        localStorage.clear();
        navigate('/');
      }
    });
  };
  useEffect(() => {
    if (deleteProfile) {
      MySwal.fire({
        title: (
          <span className="flex flex-col text-base">
            <p>{t('Are you sure to delete your profile ?')}</p>
            {/* <p style={{ fontSize: '12px', fontWeight: 'bolder', color: 'red' }}>
              {t("Note: the changes can't be undone")}
            </p> */}
          </span>
        ),
        showCancelButton: true,
        confirmButtonText: t('Yes'),
        icon: 'warning',
        cancelButtonText: t('CANCEL'),
      }).then((res) => {
        if (res.isConfirmed) {
          deleteAccount();
        }
      });
      setDeleteProfile(false);
    }
  }, [MySwal, dispatch]);

  useEffect(() => {
    if (success === true && error === false) {
      MySwal.fire({
        title: (
          <span className="flex flex-col text-base">
            <p>{t('Profile successfully updated')}</p>
          </span>
        ),
        icon: 'success',
      }).then((res) => {
        if (res.isConfirmed) {
          dispatch(resetseekerProfileUpdate());
          navigate(appRoutes.DASHBOARD_SEEKER);
        }
      });
    }
  }, [success, error]);

  useEffect(() => {
    if (error?.payload?.data?.message) {
      const errorMessage = error?.payload?.data?.message;

      MySwal.fire({
        title: errorMessage?.email
          ? t(`${errorMessage?.email[0]}`)
          : t(`${errorMessage?.mobile_number ? errorMessage?.mobile_number[0] : errorMessage}`),
        icon: 'error',
      }).then((res) => {
        if (res.isConfirmed) {
          dispatch(resetseekerProfileUpdate());
        } else {
          dispatch(resetseekerProfileUpdate());
        }
      });
    }
  }, [error?.payload?.data?.message]);

  return (
    <Container className="pb-20">
      <FormProvider {...methods}>
        <form
          ref={scrollState === 'top' ? scrollTo : noScroll}
          className="w-full flex flex-col"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <span className="font-extrabold text-4xl text-themeSecgray sm_ss:text-lg sm_s:text-lg sm_ss:text-center sm_s:text-center">
            {t('MyProfile')}
          </span>

          <ItemContainer className="flex flex-col">
            <FieldsContainer
              refer={scrollState === 'title' ? scrollTo : noScroll}
              className="flex items-center"
            >
              <SubWrapper className="h-28 md:w-1/4 items-center">
                <SelectField
                  title={t('Title')}
                  id="title"
                  options={language_selected === 'en' ? titleOptions : titleOptionsfr}
                  defaultValue={userProfile?.title}
                />
                <ErrorContainer errMsg={errors.title?.message && t(`${errors.title?.message}`)} />
              </SubWrapper>
            </FieldsContainer>
            <FieldsContainer
              refer={
                scrollState === 'first_name' || scrollState === 'last_name' ? scrollTo : noScroll
              }
              className="flex items-center"
            >
              <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('Firstname')}
                  id="first_name"
                  name="first_name"
                  textType="text"
                  placeholder={t('placeholderFname')}
                />
                <ErrorContainer
                  errMsg={errors.first_name?.message && t(`${errors.first_name?.message}`)}
                />
              </SubWrapper>
              <Separater className="w-10" />
              <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('Lastname')}
                  id="last_name"
                  name="last_name"
                  textType="text"
                  placeholder={t('placeholderLname')}
                />
                <ErrorContainer
                  errMsg={errors.last_name?.message && t(`${errors.last_name?.message}`)}
                />
              </SubWrapper>
            </FieldsContainer>
            <FieldsContainer
              refer={
                scrollState === 'email' || scrollState === 'alternative_email' ? scrollTo : noScroll
              }
              className="flex items-center"
            >
              <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('EmailSeeker')}
                  textType="email"
                  id="email"
                  name="email"
                  placeholder={t('PlaceholderEmail')}
                />
                <ErrorContainer errMsg={errors.email?.message && t(`${errors.email?.message}`)} />
              </SubWrapper>
              <Separater className="w-10" />
              <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('ALternativeEmail')}
                  textType="email"
                  id="alternate_email"
                  name="alternate_email"
                  placeholder={t('Enter your alternative email')}
                />
                {/* <ErrorContainer errMsg={errors.alternate_email?.message} /> */}
              </SubWrapper>
            </FieldsContainer>
            <FieldsContainer
              refer={
                scrollState === 'mobile_number' || scrollState === 'professional_phone'
                  ? scrollTo
                  : noScroll
              }
              className="flex items-center"
            >
              <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                <span
                  className={`text-sectext mb-1 md:mb-2 sm_ss:text-xs sm_s:text-xs sm_ss:mt-3 sm_s:mt-3 `}
                >
                  {t('MobilePHN')}
                </span>
                <CountryCodeInput
                  setCountryCode={(value, countryCode) => {
                    methods.setValue('countryCode', countryCode);
                    methods.setValue('country_short_code', countryCode?.countryCode);
                    methods.setValue('phoneNumber', value.slice(countryCode?.dialCode?.length));
                  }}
                  country="ch"
                  placeholder={t('placeholderMobileNumber')}
                  name="mobile_number"
                  id="mobile_number"
                  textType="text"
                />
                <ErrorContainer
                  errMsg={errors.mobile_number?.message && t(`${errors.mobile_number?.message}`)}
                />
              </SubWrapper>
              <Separater className="w-10" />
              <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('ProfessionalPhone')}
                  textType="number"
                  id="professional_phone"
                  name="professional_phone"
                  placeholder={t('Enter your personal number')}
                />
                <ErrorContainer
                  errMsg={
                    errors.professional_phone?.message && t(`${errors.professional_phone?.message}`)
                  }
                />
              </SubWrapper>
            </FieldsContainer>
            <FieldsContainer
              refer={
                scrollState === 'spouse_partner' || scrollState === 'children' ? scrollTo : noScroll
              }
              className="flex items-center"
            >
              <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                <SelectField
                  title={t('Spousepartner')}
                  options={language_selected === 'en' ? FurnishedBoolean : FurnishedBooleanfr}
                  id="spouse_partner"
                  defaultValue={userProfile?.spouse_partner}
                />
                <ErrorContainer
                  errMsg={errors.spouse_partner?.message && t(`${errors.spouse_partner?.message}`)}
                />
              </SubWrapper>
              <Separater className="w-10" />
              <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                <SelectField
                  title={t('Children')}
                  options={language_selected === 'en' ? FurnishedBoolean : FurnishedBooleanfr}
                  id="children"
                  defaultValue={userProfile?.children}
                />
                <ErrorContainer
                  errMsg={errors.children?.message && t(`${errors.children?.message}`)}
                />
              </SubWrapper>
            </FieldsContainer>

            <FieldsContainer
              refer={scrollState === 'preferred_language' ? scrollTo : noScroll}
              className="flex items-center"
            >
              <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                <SelectField
                  title={t('Language')}
                  options={language_selected === 'en' ? language : languagefr}
                  id="preferred_language"
                  defaultValue={userProfile?.preferred_language}
                />
                <ErrorContainer
                  errMsg={
                    errors.preferred_language?.message && t(`${errors.preferred_language?.message}`)
                  }
                />
              </SubWrapper>
              <Separater className="w-10" />
              <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                {/* {children === 1  && */}
                {/* <>
                    <SelectField
                      title={t('ifChildrengetSelected')}
                      options={ChildrenYesArray}
                      id="children_count"
                      defaultValue={userProfile?.children_count}
                    />
                    {errors.children_count && (
                      <ErrorContainer errMsg={t(`${errors.children_count.message}`)} />
                    )}
                  </> */}
                {/* } */}
                {Boolean(children) === true && (
                  <>
                    <SelectField
                      title={t('ifChildrengetSelected')}
                      options={ChildrenYesArray}
                      id="children_count"
                      defaultValue={Boolean(children) === false ? '' : userProfile?.children_count}
                    />
                    {errors.children_count && (
                      <ErrorContainer errMsg={t(`${errors.children_count.message}`)} />
                    )}
                  </>
                )}
              </SubWrapper>
            </FieldsContainer>
            <Separater className="h-10" />
            <FieldsContainer
              refer={
                scrollState === 'employed_by' || scrollState === 'organisation_employer'
                  ? scrollTo
                  : noScroll
              }
              className="flex items-center"
            >
              <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                <SelectField
                  title={t('Employedby')}
                  options={language_selected === 'en' ? EmployedBy : EmployedByfr}
                  id="employed_by"
                  defaultValue={userProfile?.employed_by}
                />
                <ErrorContainer
                  errMsg={errors.employed_by?.message && t(`${errors.employed_by?.message}`)}
                />
              </SubWrapper>
              <Separater className="w-10" />
              <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('Organisation(employer)')}
                  textType="text"
                  id="organisation_employer"
                  name="organisation_employer"
                  placeholder={t('placeholderOrg')}
                />
                {errors.organisation_employer && (
                  <ErrorContainer errMsg={t(`${errors.organisation_employer.message}`)} />
                )}
              </SubWrapper>
            </FieldsContainer>
            <FieldsContainer
              refer={
                scrollState === 'position' || scrollState === 'legitimation_card_type'
                  ? scrollTo
                  : noScroll
              }
              className="flex items-center"
            >
              <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('Position')}
                  textType="text"
                  id="position"
                  name="position"
                  placeholder={t('Enter your position')}
                />
                <ErrorContainer
                  errMsg={errors.position?.message && t(`${errors.position?.message}`)}
                />
              </SubWrapper>
              <Separater className="w-10" />
              <SubWrapper className="h-28 flex-1 sm_ss:w-full sm_s:w-full">
                <SelectField
                  title={t('Legitimationcard')}
                  options={language_selected === 'en' ? LegitimationCard : LegitimationCardFR}
                  id="legitimation_card_type"
                  defaultValue={userProfile?.legitimation_card_type}
                />
                <ErrorContainer
                  errMsg={
                    errors.legitimation_card_type?.message &&
                    t(`${errors.legitimation_card_type?.message}`)
                  }
                />
              </SubWrapper>
            </FieldsContainer>
            <FieldsContainer
              refer={scrollState === 'is_intern' ? scrollTo : noScroll}
              className="flex items-center"
            >
              <SubWrapper className="h-28 flex md:w-1/4 items-center">
                <input
                  className="h-5 my-auto w-5 cursor-pointer"
                  {...methods.register('is_intern')}
                  type="checkbox"
                  name="is_intern"
                />
                {/* <CheckBox value="yes" id="is_intern" name="is_intern" /> */}
                <span className="text-sectext ml-4">{t('Intern')}</span>
              </SubWrapper>
            </FieldsContainer>
          </ItemContainer>

          <span className="font-extrabold text-4xl py-4 text-themeSecgray sm_ss:text-lg sm_s:text-lg sm_ss:text-center sm_s:text-center">
            {t('Account password')}
          </span>
          <span className="text-black pb-2 mx-4 md:mx-0">{t('ManageEmail')}</span>

          <ItemContainer className="flex flex-col">
            <FieldsContainer
              refer={
                scrollState === 'password' || scrollState === 'confirm_password'
                  ? scrollTo
                  : noScroll
              }
              className="flex flex-col items-start"
            >
              <SubWrapper className="h-28 flex-1 w-1/2 sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('OldPassword')}
                  textType={showOldPassword ? 'text' : 'password'}
                  id="oldpassword"
                  name="oldpassword"
                  placeholder="*******"
                  showEyeButton
                  passwordShown={showOldPassword}
                  setPasswordShown={setShowOldPassword}
                  // togglePassword={() => {
                  //   setShowOldPassword(!showOldPassword);
                  // }}
                />
                <ErrorContainer errMsg={t(errors?.oldpassword?.message)} />
              </SubWrapper>
              <SubWrapper className="h-28 flex-1 w-1/2 sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('PasswordP')}
                  textType={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  placeholder="*******"
                  showEyeButton
                  passwordShown={showPassword}
                  setPasswordShown={setShowPassword}
                  // togglePassword={() => {
                  //   setShowPassword(!showPassword);
                  // }}
                />
                <ErrorContainer errMsg={t(errors?.password?.message)} />
              </SubWrapper>
              <SubWrapper className="h-28 flex-1 w-1/2 sm_ss:w-full sm_s:w-full">
                <TextInput
                  text={t('repeatPassword')}
                  textType={showConfirmPassword ? 'text' : 'password'}
                  id="confirm_password"
                  name="confirm_password"
                  placeholder="*******"
                  showEyeButton
                  passwordShown={showConfirmPassword}
                  setPasswordShown={setShowConfirmPassword}
                  // togglePassword={() => {
                  //   setShowConfirmPassword(!showConfirmPassword);
                  // }}
                />
                <ErrorContainer errMsg={t(errors?.confirm_password?.message)} />
              </SubWrapper>
            </FieldsContainer>
          </ItemContainer>
          <div className="w-full flex justify-between items-center sm_ss:px-4 sm_s:px-4">
            <div className="flex flex-col">
              <button
                className="h-10 w-28 cursor-pointer my-3 rounded-lg bg-blue text-white"
                type="submit"
              >
                {pending ? <CircularProgress className="p-2" color="inherit" /> : t('SAVE')}
              </button>
              {/* {Error && <span className="text-red-600">Something went wrong</span>} */}
              {/* <ErrorContainer errMsg="Something went wrong" /> */}
            </div>
            <div className="text-red-600 cursor-pointer" onClick={() => setDeleteProfile(true)}>
              {t('X Delete my profile')}{' '}
            </div>
          </div>
        </form>
      </FormProvider>
    </Container>
  );
}

export default SeekerProfileUpdate;
