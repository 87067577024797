/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import { RiLayoutGridFill } from 'react-icons/ri';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaThList } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@mui/material';
import PageHeading from '../components/common/PageHeading';
import PropertyCard from '../components/Dashboard/PropertyCard';
import PropertyBar from '../components/Dashboard/Propertybar';
import AxiosInstance from '../Axios';
import API from '../constants/API';
import Pagination from '../components/Dashboard/Pagination';

function Dashboard() {
  const { t } = useTranslation();
  const [userProfile, setUserProfile] = useState();
  const [view, setView] = useState('grid');
  const [reFetchProperties, setRefetcgProperties] = useState(false);
  const [paginationLength, setPaginationLength] = useState(0);
  const [sellpaginationLength, setSellPaginationLength] = useState(0);
  const [page, setPage] = useState(1);
  const [sellPageNumber, setSellPageNumber] = useState(1);
  const [reFetchPropertiesOnArchice, setReFetchPropertiesOnArchice] = useState(false);
  const [viewPropertyStatus, setViewPropertyStatus] = useState('All');
  const methods = useForm();
  const [propertyRentData, setRentPropertyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sellLoading, setSellIsLoading] = useState(false);
  const [sellPropertyData, setSellPropertyData] = useState([]);
  const postPerPage = 8;

  const paginate = (pageNumber) => setPage(pageNumber);
  const SellPaginate = (pageNumber) => setSellPageNumber(pageNumber);
  const onSubmit = (propertyType) => {
    localStorage.setItem('property_type', propertyType);
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const token = user?.data?.result?.access_token;
  useEffect(() => {
    if (token) {
      const userProfileData = async () => {
        const res = await AxiosInstance.post(API.GETPROFILE);
        setUserProfile(res.data.result);
      };
      userProfileData();
    }
  }, [token]);

  const FetcPropertAccToStatus = async (e) => {
    setRentPropertyData([]);
    setSellPropertyData([]);
    const status = e;
    const types = ['sell', 'rent'];
    types.forEach(async (el) => {
      if (e === 'All') {
        setIsLoading(true);
        setSellIsLoading(true);
        const res = await AxiosInstance.post(API.PROPERTY_LIST, { page, property_type: el });
        methods.reset(res?.data?.result);
        setViewPropertyStatus(status);
        setIsLoading(false);
        setSellIsLoading(false);
        if (el === 'rent') {
          setRentPropertyData(res.data?.result?.properties);
          if (
            res?.data?.result?.properties?.data?.filter((p) => p.property_type === 'rent')?.length >
            0
          ) {
            const length = res.data?.result?.total > 8 ? res.data?.result?.total : 0;
            setPaginationLength(length);
          } else {
            setPaginationLength(0);
          }
        }
        if (el === 'sell') {
          setSellPropertyData(res.data?.result?.properties);
          if (
            res?.data?.result?.properties?.data?.filter((p) => p.property_type === 'sell')?.length >
            0
          ) {
            const length = res.data?.result?.total > 8 ? res.data?.result?.total : 0;
            setSellPaginationLength(length);
          } else {
            setSellPaginationLength(0);
          }
        }
      } else {
        setIsLoading(true);
        setSellIsLoading(true);
        const res = await AxiosInstance.post(API.PROPERTY_LIST, {
          status,
          page,
          property_type: el,
        });
        methods.reset(res?.data?.result);
        setViewPropertyStatus(status);
        setIsLoading(false);
        setSellIsLoading(false);
        if (el === 'rent') {
          if (
            res?.data?.result?.properties?.data?.filter((p) => p.property_type === 'rent')?.length >
            0
          ) {
            setRentPropertyData(res.data?.result?.properties);
            const length = res.data?.result?.total > 8 ? res.data?.result?.total : 0;
            setPaginationLength(length);
          } else {
            setPaginationLength(0);
          }
        }
        if (el === 'sell') {
          if (
            res?.data?.result?.properties?.data?.filter((p) => p.property_type === 'sell')?.length >
            0
          ) {
            setSellPropertyData(res.data?.result?.properties);
            const length = res.data?.result?.total > 8 ? res.data?.result?.total : 0;
            setSellPaginationLength(length);
          } else {
            setSellPaginationLength(0);
          }
        }
      }
    });
  };

  useEffect(() => {
    AOS.init({
      duration: 4000,
    });
  }, []);

  useEffect(() => {
    setViewPropertyStatus('All');
    const propertiesData = async () => {
      setRefetcgProperties(false);
      setIsLoading(true);
      const res = await AxiosInstance.post(API.PROPERTY_LIST, { page, property_type: 'rent' });
      methods.reset(res.data?.result);
      const length = res.data?.result?.total > 8 ? res.data?.result?.total : 0;
      setPaginationLength(length);
      setRentPropertyData(res?.data?.result?.properties || []);
      setIsLoading(false);
    };
    propertiesData();
  }, [reFetchProperties, page, reFetchPropertiesOnArchice]);

  useEffect(() => {
    setViewPropertyStatus('All');
    const propertiesData = async () => {
      setRefetcgProperties(false);
      setSellIsLoading(true);
      const res = await AxiosInstance.post(API.PROPERTY_LIST, {
        page: sellPageNumber,
        property_type: 'sell',
      });
      methods.reset(res?.data?.result);
      const length = res.data?.result?.total > 8 ? res.data?.result?.total : 0;
      setSellPaginationLength(length);
      setSellPropertyData(res?.data?.result?.properties);

      setSellIsLoading(false);
    };
    if (reFetchProperties || propertyRentData) {
      propertiesData();
    }
  }, [reFetchProperties, sellPageNumber, reFetchPropertiesOnArchice]);

  const MenuOptions = [
    { lable: t('All'), value: 'All' },
    { lable: t('active'), value: 'active' },
    { lable: t('Draft'), value: 'draft' },
    { lable: t('Expired'), value: 'expired' },
    { lable: t('Archived'), value: 'archived' },
    { lable: t('rejected'), value: 'rejected' },
  ];

  return (
    <div data-aos="fade" className="w-4/5 max-w-[1500px] mx-auto my-12">
      <div className="flex sm_ss:flex-col sm_s:flex-col w-full justify-between pb-8">
        <PageHeading className=" sm_ss:pb-5 sm_s:pb-5">{t('My properties')}</PageHeading>
        <div className="flex justify-between w-2/5 sm_ss:w-full sm_s:w-full gap-3 ">
          {MenuOptions.map((item, index) => (
            <div
              key={`${index + 1}`}
              tabIndex={`${index + 1}`}
              onClick={() => FetcPropertAccToStatus(item.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setViewPropertyStatus(item.value);
                }
              }}
              className={`my-auto cursor-pointer sm_ss:hidden md:hidden lg:inline sm_s:hidden smx:hidden ${
                viewPropertyStatus === item.value ? 'text-primary' : 'text-themeGray'
              }`}
            >
              {item.lable}
            </div>
          ))}
          <div className="w-full flex justify-between md:inline lg:hidden mt-3">
            <select 
              className="w-8/12  bg-transparent outline-none border-b-[2px] p-1 border-blue"
              onChange={(e) => {setViewPropertyStatus(e.target.value)
                FetcPropertAccToStatus(e.target.value)}}
            >
              {MenuOptions.map((item, kindex) => {
                return (
                  <option
                    key={`${kindex + 1}`}
                    tabIndex={`${kindex + 1}`}
                    className="p-6"
                    value={item.value}
                   
                  >
                    {item.lable}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="flex flex-row-reverse sm_ss:hidden sm_s:hidden smx:hidden">
            <FaThList
              onClick={() => setView('list')}
              tabIndex="0"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setView('list');
                }
              }}
              className={`text-[20px] my-auto mx-2 cursor-pointer ${
                view === 'list' ? 'text-primary' : 'text-themeGray'
              }`}
            />
            <RiLayoutGridFill
              onClick={() => setView('grid')}
              tabIndex="0"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setView('grid');
                }
              }}
              className={`text-[24px] my-auto cursor-pointer ${
                view === 'grid' ? 'text-primary' : 'text-themeGray'
              }`}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mb-8">
        <div className="flex sm_ss:justify-between sm_s:justify-between  sm_ss:mb-1 sm_s:mb-1 items-center">
          <PageHeading className="sm_ss:text-base sm_s:text-sm">{t('To rent')}</PageHeading>
          <Link to="/add-property">
            <button
              onClick={() => onSubmit('rent')}
              type="button"
              className="bg-primary px-4 py-2 text-base rounded-md text-white font-bold my-1 mx-4 sm_ss:py-1 sm_s:py-1 sm_s:my-0 sm_s:text-xs"
            >
              {t('New')}
            </button>
          </Link>
        </div>
      </div>
      <div>
        {view === 'grid' ? (
          <div className="grid overflow-hidden md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 py-4 px-2">
            {isLoading ? (
              <div className="lg:w-[64rem] xl:w-[64rem] 2xl:w-[92rem]  min-h-[325px] flex justify-center items-center">
                <CircularProgress size="40px" color="primary" />
              </div>
            ) : (
              propertyRentData?.data?.map((item, index) => {
                return (
                  <PropertyCard
                    userProfile={userProfile}
                    setRefetcgProperties={setRefetcgProperties}
                    setReFetchPropertiesOnArchice={setReFetchPropertiesOnArchice}
                    key={`${index + index}`}
                    data={item}
                    length={propertyRentData?.data?.length || 0}
                    setPage={setPage}
                    page={page}
                  />
                );
              })
            )}
          </div>
        ) : (
          <div>
            {propertyRentData?.data?.map((item, index) => {
              return (
                <div className="">
                  <PropertyBar
                    userProfile={userProfile}
                    setRefetcgProperties={setRefetcgProperties}
                    setReFetchPropertiesOnArchice={setReFetchPropertiesOnArchice}
                    key={`${index + index}`}
                    data={item}
                  />
                </div>
              );
            })}
          </div>
        )}
        <Pagination
          postPerPage={postPerPage}
          totalPost={paginationLength}
          paginate={paginate}
          page={page}
        />
      </div>
      <div className="flex justify-between items-center my-8">
        <div className="flex sm_ss:justify-between sm_s:justify-between  sm_ss:mb-1 sm_s:mb-1 items-center">
          <PageHeading className="sm_ss:text-base sm_s:text-sm">{t('To sell')}</PageHeading>
          <Link to="/add-property">
            {/* <div className="bg-primary px-4 py-2 text-base rounded-md text-white font-bold my-1 mx-4">
              NEW
            </div> */}
            <button
              onClick={() => onSubmit('sell')}
              type="button"
              className="bg-primary px-4 py-2 text-base rounded-md text-white font-bold my-1 mx-4 sm_ss:py-1 sm_s:py-1 sm_s:my-0 sm_s:text-xs"
            >
              {t('New')}
            </button>
          </Link>
        </div>
      </div>
      {view === 'grid' ? (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 py-4">
          {sellLoading ? (
            <div className="lg:w-[64rem] xl:w-[64rem] 2xl:w-[92rem]  min-h-[325px] flex justify-center items-center">
              <CircularProgress size="40px" color="primary" />
            </div>
          ) : (
            sellPropertyData?.data?.map((item, index) => {
              return (
                <PropertyCard
                  userProfile={userProfile}
                  setRefetcgProperties={setRefetcgProperties}
                  setReFetchPropertiesOnArchice={setReFetchPropertiesOnArchice}
                  key={`${index + index}`}
                  data={item}
                  length={sellPropertyData?.data?.length || 0}
                  setPage={setSellPageNumber}
                  page={sellPageNumber}
                />
              );
            })
          )}
        </div>
      ) : (
        <div>
          {sellPropertyData?.data?.map((item, index) => {
            return (
              <PropertyBar
                userProfile={userProfile}
                setRefetcgProperties={setRefetcgProperties}
                setReFetchPropertiesOnArchice={setReFetchPropertiesOnArchice}
                key={`${index + index}`}
                data={item}
              />
            );
          })}
        </div>
      )}
      <Pagination
        postPerPage={postPerPage}
        totalPost={sellpaginationLength}
        paginate={SellPaginate}
        page={sellPageNumber}
      />
    </div>
  );
}

export default Dashboard;
