import { useTranslation } from 'react-i18next';
import React from 'react';
import './terms.css';

export default function Terms() {
  const { t } = useTranslation();
  return (
    <div
      className="term-condition-main-container"
      // style={
      //   {
      // display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'center',
      // width: '100vw',
      // overflowX: 'hidden',
      // padding: '10px',
      //   }
      // }
      // style={{ color: '#6f6f6f', fontSize: '28px' }}
      // style={{ color: '#004993' }}
      // style={{ color: '#004993', textDecoration: 'underline' }}
    >
      <div style={{ textAlign: 'center' }}>
        <h1 className="term-conditon-heading">{t('TermHead')}</h1>
        <h2 className="term-conditon-sub-heading">{t('TermsConditionHead')}</h2>
        <h2 className="term-conditon-txt">{t('GCondition')}</h2>
        <span className="term-conditon-txt-version">{t('VerionShow')}</span>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          marginTop: '50px',
          overflowX: 'hidden',
        }}
      >
        <div
          style={{
            width: '50%',
            overflowX: 'hidden',
          }}
        >
          <ul>
            <li className="terms-heading-list">{t(`Preamble`)}</li>
            <li>{t(`InitialHead`)}</li>
            <li>
              <ul>
                <li className="terms-heading-list">{t(`ClauseOne`)}</li>
                <li>{t(`ClauseOneOne`)}</li>
                <li>{t(`ClauseOneTwo`)}</li>
              </ul>
            </li>
            <li>
              <ul>
                <li className="terms-heading-list">{t(`ClauseTwo`)}</li>
                <li className="list-clause-alpha">{t(`ClauseA`)}</li>
                <li>{t(`ClauseTwoOne`)}</li>
                <li className="list-clause-alpha">{t(`ClauseB`)}</li>
                <li>{t(`ClauseTwoTwo`)}</li>
                <li>{t(`ClauseTwoThree`)}</li>
                <li>{t(`ClauseTwoFour`)}</li>
                <li className="list-clause-alpha">{t(`ClauseTwoC`)}</li>
                <li>
                  <li>{t(`ClauseTwoFive`)}</li>
                  <ul>
                    <li>{t(`ClauseSubTwoone`)}</li>
                    <li>{t(`ClauseSubTwoTwo`)}</li>
                    <li>{t(`ClauseSubTwoThree`)}</li>
                    <li>{t(`ClauseSubTwoFour`)}</li>
                    <li>{t(`ClauseSubTwoFive`)}</li>
                  </ul>
                </li>
                <li>{t('ClauseTwoSix')}</li>
                <li className="terms-heading-list">{t('ClauseThree')}</li>
                <li>
                  <li>{t('ClauseThreeOne')}</li>
                  <ul>
                    <li>{t('ClauseThreeOneOne')}</li>
                    <li>{t('ClauseThreeOneTwo')}</li>
                    <li>{t('ClauseThreeOneThree')}</li>
                    <li>{t('ClauseThreeOneFour')}</li>
                    <li>{t('ClauseThreeOneFive')}</li>
                    <li>{t('ClauseThreeOneSix')}</li>
                    <li>{t('ClauseThreeOneSeven')}</li>
                    <li>{t('ClauseThreeOneEight')}</li>
                    <li>{t('ClauseThreeOneNine')}</li>
                    <li>{t('ClauseThreeOneTen')}</li>
                    <li>{t('ClauseThreeOneEle')}</li>
                  </ul>
                </li>
                <li>
                  <li className="terms-heading-list">{t('ClauseFour')}</li>
                  <ul>
                    <li>{t('ClauseFourOne')}</li>
                    <li>{t('ClauseFourTwo')}</li>
                    <li>{t('ClauseFourThree')}</li>
                    <li>{t('ClauseFourFour')}</li>
                  </ul>
                </li>
                <li className="terms-heading-list">{t('ClauseFive')}</li>
                <li>{t('ClauseFiveOne')}</li>
                <li className="terms-heading-list">{t('ClauseSix')}</li>
                <li>{t('ClauseSixOne')}</li>
                <li>{t('ClauseSixTwo')}</li>
                <li>{t('ClauseSixThree')}</li>
                <li>
                  <li className="terms-heading-list">{t('ClauseSeven')}</li>
                  <ul>
                    <li>{t('ClauseSevenOne')}</li>
                    <li>{t('ClauseSevenOneOne')}</li>
                    <li>{t('ClauseSevenOneTwo')}</li>
                    <li>{t('ClauseSevenOneThree')}</li>
                  </ul>
                  <li>{t('ClauseSevenTwo')}</li>
                  <li>{t('ClauseSevenThree')}</li>
                  <li>{t('ClauseSevenFour')}</li>
                  <li>{t('ClauseSevenFive')}</li>
                </li>
                <li className="terms-heading-list">{t('ClauseEight')}</li>
                <li>{t('ClauseEightOne')}</li>
                <li>{t('ClauseEightTwo')}</li>
                <li>{t('ClauseEightThree')}</li>
                <li className="terms-heading-list">{t('ClauseNine')}</li>
                <li>{t('ClauseNineOne')}</li>
                <li>{t('ClauseNineTwo')}</li>

                <li className="terms-heading-list">{t('ClauseTen')}</li>
                <li>{t('ClauseTenOne')}</li>
                <li>{t('ClauseTenTwo')}</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
