/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaEye } from 'react-icons/fa';
import Cookies from 'js-cookie';

import { CircularProgress, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AxiosInstance from '../Axios';
import PageHeading from '../components/common/PageHeading';
import { addPropertyFormSchema, sellPropertyFormSchema, userRoles } from '../constants';
// eslint-disable-next-line import/no-named-as-default-member

import PropertyFormCard from '../components/AddAndEditProperty/PropertyFormCard';
import RentFormCard from '../components/AddAndEditProperty/RentFormCard';
import AddressFormCard from '../components/AddAndEditProperty/AddressFormCard';
import DetailsFormCard from '../components/AddAndEditProperty/DetailsFormCard';
import DescriptionForm from '../components/AddAndEditProperty/DescriptionForm';
import ParkingAndGarageForm from '../components/AddAndEditProperty/ParkingAndGarageForm';
import PicturesForm from '../components/AddAndEditProperty/PicturesForm';
import VisitsForm from '../components/AddAndEditProperty/VisitsForm';
import { appRoutes } from '../constants/routeConstants/Routes';
import API from '../constants/API';
import {
  AddAndEditProperty as AddAndEditPropertyAction,
  resetAddAndEditPropertyUpdate,
} from '../redux/slices/AddAndEditProperty';
import SellingForm from '../components/AddAndEditProperty/SellingPriceForm';
import Extend from '../components/AddAndEditProperty/Extend';
import { DraftProperty, resetdraftPropertyUpdate } from '../redux/slices/DraftSlice';
import { Decrypt, Encrypt } from '../util';

function AddAndEditProperty() {
  const MySwal = withReactContent(Swal);
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const pending = useSelector((state) => state.addAndEditPropertyReducer.pending);
  const draftPropertyLoading = useSelector((state) => state.draftPropertyReducer.loading);
  const Error = useSelector((state) => state.addAndEditPropertyReducer.error);
  const [expandAll, setExpandAll] = useState(false);
  const [formData, setFormData] = useState({});
  const [scrollState, setScrollState] = useState();
  const [property_locationsOne, setproperty_locationsOne] = useState();
  const [property_locationsOneErr, setproperty_locationsOneErr] = useState([]);
  const [cityLocationErr, setCityLocationErr] = useState([]);
  const [propertyImages, setPropertyImages] = useState([]);
  const [collapseAll, setCollapseAll] = useState(false);
  const [currentFormID, setCurrentFormID] = useState('');
  const [PropertyDetailData, setPropertyData] = useState([]);
  const [error, setError] = useState(false);
  const [role, setRole] = useState();
  const [searchParams] = useSearchParams();
  const [fixed_renew, setfixed_renew] = useState();
  const [fixed_renewErr, setfixed_renewErr] = useState();
  const [submit, setSubmit] = useState(false);
  const [serverImages, setServerImages] = useState([]);
  const [removed, setRemoved] = useState([]);
  const [reference_codeErr, setreference_codeErr] = useState();
  const [duplicate_images, setDuplicateImages] = useState([]);
  const [underground_spots_mandatory, setunderground_spots_mandatory] = useState();
  const [outdoor_spots_mandatory, setoutdoor_spots_mandatory] = useState('optional');
  const [box_parking_mandatory, setbox_parking_mandatory] = useState('optional');
  const [statusDraft, setStatusDraft] = useState();
  const [published_until, setpublished_until] = useState();
  const [chargesOption, setChargesOption] = useState('');
  const [userProfile, setUserProfile] = useState();
  const [chargesOptionErr, setChargesOptionErr] = useState(false);
  const [renewableOptionErr, setRenewableOptionErr] = useState(false);
  const [rentChargesErr, SetRentChargesErr] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const scrollTo = useRef();
  const noScroll = useRef();
  const language_selected = Cookies.get('i18next');
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user?.data?.result?.access_token;
  const [refresh, setRefresh] = useState(1);
  useLayoutEffect(() => {
    if (token) {
      const userProfileData = async () => {
        const res = await AxiosInstance.post(API.GETPROFILE);
        setUserProfile(res.data.result);
      };
      userProfileData();
    }
  }, [token]);

  const propertyLocation = [
    { value: 'geneva_city_left', label: t('CityBtn1'), id: 1 },
    { value: 'geneva_city_right', label: t('CityBtn2'), id: 2 },
    { value: 'geneva_left_country_side', label: t('CityBtn3'), id: 3 },
    { value: 'geneva_right_country_side', label: t('CityBtn4'), id: 4 },
    { value: 'nyon_district', label: t('CityBtn5'), id: 5 },
    { value: 'lausanne_region', label: t('CityBtn6'), id: 6 },
    { value: 'france', label: t('CityBtn7'), id: 7 },
  ];

  const property_type = PropertyDetailData?.property_type
    ? PropertyDetailData?.property_type
    : localStorage.getItem('property_type');

  useEffect(() => {
    if (scrollState && scrollTo?.current?.scrollIntoView) {
      scrollTo.current.scrollIntoView({
        behavior: 'smooth',
      });
      setScrollState('');
    }
  }, [scrollState, scrollTo?.current?.scrollIntoView]);

  const schema = property_type === 'rent' ? addPropertyFormSchema : sellPropertyFormSchema;

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const draft = location.pathname.includes('draft');

  const { errors } = methods.formState;

  const onError = (err) => {
    setSubmit(true);
    setExpandAll(true);

    setScrollState(Object.keys(err)[0]);
    if (error?.err) {
      setScrollState(...scrollState, 'images');
    }
  };

  const dispatch = useDispatch();
  const [id, setId] = useState(searchParams.get('id'));
  const decryptId = Decrypt(id);
  useEffect(() => {
    if (id) {
      const DeCryptId = Decrypt(id);
      const propertiesData = async () => {
        try {
          // prettier-ignore
          const res = await AxiosInstance.get(`${API.PROPERTY}/${DeCryptId}`);
          setPropertyData(Object.assign(PropertyDetailData, res.data.result));
          setExpandAll(true);
          methods.reset({ ...res.data.result });
          setFormData(Object.assign(formData, res.data.result));
          if (res) {
            setServerImages(res.data.result.pictures);
            setfixed_renew(res.data.result.fixed_renew);
            setDuplicateImages(res.data.result.pictures?.map((picture) => picture?.id));
            setunderground_spots_mandatory(res.data.result.parking[0].underground_spots_mandatory);
            setoutdoor_spots_mandatory(res.data.result.parking[0].outdoor_spots_mandatory);
            const data = res.data.result.amenties?.map((amentie) => amentie?.name);
            methods.setValue('amenities', data);
            methods.setValue('propertyLocationsOne', t('CityBtn7'));
            setbox_parking_mandatory(res.data.result.parking[0].box_parking_mandatory);
            setunderground_spots_mandatory(res.data.result.parking[0].underground_spots_mandatory);
            if (res.data.result.country === 'france') {
              methods.setValue('city', res.data.result.city);
            }
            if (res.data.result.rent_currency) {
              methods.setValue('currency', res.data.result.rent_currency);
            } else {
              methods.setValue('currency', res.data.result.selling_price_currency);
            }
          }
        } catch (err) {
          console.log(err);
        }
      };
      propertiesData();
    }
  }, [id, refresh]);

  useEffect(() => {
    if (submit === true) {
      // if (propertyImages?.length === 0) {
      //   if (serverImages?.length) {
      //     setError({ err: false });
      //   } else {
      //     setError({
      //       err: true,
      //       message:
      //         language_selected === 'en' ? 'Images are required' : 'Les images sont obligatoires',
      //     });
      //   }
      // }
      if (!fixed_renew) {
        if (property_type === 'rent') {
          setfixed_renewErr({
            err: true,
            message:
              language_selected === 'en'
                ? 'Please select one option'
                : 'Veuillez sélectionner une option',
          });
        }
      }
      if (!property_locationsOne) {
        setproperty_locationsOneErr({
          err: true,
          message:
            language_selected === 'en'
              ? 'Property locations are required'
              : 'Les emplacements des propriétés sont requis',
        });
      }
      if (!property_locationsOne) {
        setCityLocationErr({
          err: true,
          message: language_selected === 'en' ? 'City is required' : 'La ville est obligatoire',
        });
      }
      if (chargesOption === '') {
        setChargesOptionErr({
          err: true,
          message:
            language_selected === 'en'
              ? 'Please select one option'
              : 'Veuillez sélectionner une option',
        });
      } else {
        setChargesOptionErr(false);
      }
    }
  }, [
    propertyImages,
    fixed_renew,
    submit,
    property_locationsOne,
    submit,

    language_selected,
    chargesOption,
    serverImages,
  ]);

  useEffect(() => {
    if (propertyImages?.length > 0) {
      setError({ err: false });
    }
    if (fixed_renew) {
      setfixed_renewErr({ err: false });
    }
    if (property_locationsOne) {
      setproperty_locationsOneErr({ err: false });
    }
    if (property_locationsOne) {
      setCityLocationErr({ err: false });
    }
  }, [propertyImages?.length, fixed_renew, property_locationsOne]);

  const onSubmit = (data) => {
    methods.clearErrors();
    setSubmit(true);
    setFormData(data);
    const property_locations = [property_locationsOne];
    const parking = {
      box_parking_number: data.box_parking_number,
      box_parking_included_in_price: data.box_parking_included_in_price ? 'on' : 'off',
      box_parking_price_per_parking: data.box_parking_price_per_parking,
      box_parking_mandatory: box_parking_mandatory,
      outdoor_spots_number: data.outdoor_spots_number,
      outdoor_spots_included_in_price: data.outdoor_spots_included_in_price ? 'on' : 'off',
      outdoor_spots_price_per_parking: data.outdoor_spots_price_per_parking,
      outdoor_spots_mandatory: outdoor_spots_mandatory,
      underground_spots_number: data.underground_spots_number,
      underground_spots_included_in_price: data.underground_spots_included_in_price ? 'on' : 'off',
      underground_spots_price_per_parking: data.underground_spots_price_per_parking,
      underground_spots_mandatory: underground_spots_mandatory,
    };
    if (parking?.box_parking_included_in_price === 'on') {
      delete parking?.box_parking_mandatory;
      delete parking?.box_parking_price_per_parking;
    } else {
      delete parking?.box_parking_included_in_price;
    }
    if (parking?.outdoor_spots_included_in_price === 'on') {
      delete parking?.outdoor_spots_price_per_parking;
      delete parking?.outdoor_spots_mandatory;
    } else {
      delete parking?.outdoor_spots_included_in_price;
    }
    if (parking?.underground_spots_included_in_price === 'on') {
      delete parking?.underground_spots_mandatory;
      delete parking?.underground_spots_price_per_parking;
    } else {
      delete parking?.underground_spots_included_in_price;
    }
    if (!data?.reference_code) {
      delete data.reference_code;
    }
    const Data = {
      ...data,
      id: currentFormID ? currentFormID : decryptId,
      property_type: property_type,
      pictures: propertyImages,
      status: 'submit',
      property_locations: property_locations,
      parking: parking,
      fixed_renew: fixed_renew,
      removed_images: removed,
      published_until: published_until,
      monthly_charge_option: chargesOption,
      currency: 'CHF',
      // housing_type,
      selling_price_currency: property_type === 'sell' ? 'CHF' : null,
    };
    // if (!Data?.id) {
    //   delete Data.id;
    // }
    if (
      Data.id &&
      PropertyDetailData?.request_status === 'pending' &&
      location.pathname?.includes('duplicate-property')
    ) {
      delete Data.id;
    } else if (
      Data.id &&
      PropertyDetailData?.request_status === 'draft' &&
      location.pathname?.includes('duplicate-property')
    ) {
      delete Data.id;
    } else if (
      Data.id &&
      PropertyDetailData?.request_status === 'active' &&
      location.pathname?.includes('duplicate-property')
    ) {
      delete Data.id;
    } else if (
      Data.id &&
      PropertyDetailData?.request_status === 'rejected' &&
      location.pathname?.includes('duplicate-property')
    ) {
      delete Data.id;
    }
    if (!fixed_renew && property_type === 'rent') {
      setfixed_renewErr({ err: true, message: t('This field is required') });
      setScrollState('fixed_renew');
    } else if (rentChargesErr.err === true) {
      setScrollState('rent_charges');
    } else if (renewableOptionErr.err === true) {
      setScrollState('fixed_renew');
    } else {
      setError();
      setfixed_renewErr();
      setScrollState();
      const duplicate = location.pathname?.includes('duplicate-property');
      if (duplicate) {
        const duplicateFormData = {
          ...Data,
          request_type: 'duplicate',
          duplicate_images,
          published_until: published_until,
        };
        dispatch(
          AddAndEditPropertyAction({
            data: duplicateFormData,
            fun: () => {
              navigate(appRoutes.DASHBOARD_PROVIDER);
            },
          })
        );
      } else {
        // eslint-disable-next-line no-lonely-if
        if (location.pathname.includes('add-property')) {
          if (error?.err === false || error?.err === undefined) {
            dispatch(
              AddAndEditPropertyAction({
                data: Data,
                fun: () => {
                  navigate(appRoutes.DASHBOARD_PROVIDER);
                },
              })
            );
          } else {
            console.log(error);
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (formData?.request_status === 'active' && userProfile?.is_whitelist_provider === 0) {
            Data.request_update = true;
            Swal.fire({
              icon: 'info',
              title: t(
                'Property status will change from Active to Under Review by clicking on Yes button.'
              ),
              showCancelButton: true,
              cancelButtonText: t('Cancel'),
              confirmButtonText: t('Yes'),
            }).then((response) => {
              if (response.isConfirmed) {
                Data.request_update = true;
                dispatch(
                  AddAndEditPropertyAction({
                    data: Data,
                    fun: () => {
                      navigate(appRoutes.DASHBOARD_PROVIDER);
                    },
                  })
                );
              } else {
                navigate(appRoutes.DASHBOARD_PROVIDER);
              }
            });
          } else {
            Data.request_update = true;
            dispatch(
              AddAndEditPropertyAction({
                data: Data,
                fun: () => {
                  navigate(appRoutes.DASHBOARD_PROVIDER);
                },
              })
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    if (Error) {
      if (Error === 'Reference code already exists') {
        setScrollState('reference_code');
        setreference_codeErr({ err: true, message: Error });
      } else if (Error !== 'Reference code already exists') {
        MySwal.fire({
          title: 'Something went wrong. Please try again later.',
          icon: 'error',
        }).then((res) => {
          if (res.isConfirmed) {
            dispatch(resetAddAndEditPropertyUpdate());
          }
        });
      }
    }
  }, [Error]);

  useEffect(() => {
    if (formData?.property_locations) {
      propertyLocation.forEach((element) => {
        formData?.property_locations?.map((loc) => {
          if (element.value === loc?.name) {
            setproperty_locationsOne(element);
          }
          return '';
        });
      });
    }
  }, [formData?.property_locations]);

  useEffect(() => {
    if (PropertyDetailData) {
      propertyLocation.forEach((element) => {
        const selectedValue = PropertyDetailData?.property_locations?.map((Location) => {
          return Location?.name;
        });

        if (element.value === selectedValue) {
          setproperty_locationsOne(element);
        }
      });
    }
  }, [PropertyDetailData]);

  const handelDraftSubmit = (status, nav) => {
    setStatusDraft('draft');
    const postData = methods.getValues();
    const propertyType = localStorage.getItem('property_type');
    const pictures = propertyImages;
    const property_locations = property_locationsOne ? [property_locationsOne] : '';
    const parking = {
      box_parking_number: postData.box_parking_number,
      box_parking_included_in_price: postData.box_parking_included_in_price,
      box_parking_price_per_parking: postData.box_parking_price_per_parking,
      box_parking_mandatory: box_parking_mandatory,
      outdoor_spots_number: postData.outdoor_spots_number,
      outdoor_spots_included_in_price: postData.outdoor_spots_included_in_price,
      outdoor_spots_price_per_parking: postData.outdoor_spots_price_per_parking,
      outdoor_spots_mandatory: outdoor_spots_mandatory,
      underground_spots_number: postData.underground_spots_number,
      underground_spots_included_in_price: postData.underground_spots_included_in_price,
      underground_spots_price_per_parking: postData.underground_spots_price_per_parking,
      underground_spots_mandatory: underground_spots_mandatory,
    };
    if (
      postData?.id &&
      PropertyDetailData?.request_status === 'pending' &&
      location.pathname?.includes('duplicate-property')
    ) {
      delete postData?.id;
    } else if (
      postData?.id &&
      PropertyDetailData?.request_status === 'draft' &&
      location.pathname?.includes('duplicate-property')
    ) {
      delete postData?.id;
    } else if (
      postData?.id &&
      PropertyDetailData?.request_status === 'active' &&
      location.pathname?.includes('duplicate-property')
    ) {
      delete postData?.id;
    } else if (
      postData?.id &&
      PropertyDetailData?.request_status === 'rejected' &&
      location.pathname?.includes('duplicate-property')
    ) {
      delete postData?.id;
    }
    if (location.pathname?.includes('duplicate-property')) {
      const data = {
        ...postData,
        // availability,
        // until_end,
        pictures,
        // active_until,
        status,
        property_type: PropertyDetailData?.property_type
          ? PropertyDetailData?.property_type
          : propertyType,
        removed_images: removed,
        duplicate_images,
        parking: parking,
        fixed_renew: fixed_renew,
        property_locations,
        request_type: 'duplicate',
        monthly_charge_option: chargesOption,
        currency: 'CHF',
        id: currentFormID ? currentFormID : '',
      };

      dispatch(
        DraftProperty({
          data: data,
          setCurrentFormID,
          fun: () => {
            // navigate(appRoutes.DASHBOARD_PROVIDER);
          },
        })
      ).then((resp) => {
        setPropertyData(Object.assign(PropertyDetailData, resp));
        MySwal.fire({
          title: t('Propert save as draft'),
          icon: 'success',
        }).then((res) => {
          if (res.isConfirmed) {
            dispatch(resetdraftPropertyUpdate());
            MySwal.close();
            // navigate(appRoutes.DASHBOARD_PROVIDER);
          }
        });
      });
    } else {
      dispatch(
        DraftProperty({
          data: {
            ...postData,
            // availability,
            // until_end,
            pictures,
            // active_until,
            status,
            property_type: propertyType ? propertyType : PropertyDetailData?.property_type,
            removed_images: removed,
            duplicate_images,
            parking: parking,
            fixed_renew: fixed_renew,
            property_locations,
            published_until: published_until,
            monthly_charge_option: chargesOption,
            currency: 'CHF',
            id: currentFormID ? currentFormID : decryptId,
          },
          setCurrentFormID,

          fun: () => {
            navigate(appRoutes.DASHBOARD_PROVIDER);
          },
        })
      ).then(() => {
        setRefresh((pr) => pr + 1);
        MySwal.fire({
          title: t('Propert save as draft'),
          icon: 'success',
        }).then((res) => {
          if (res.isConfirmed) {
            dispatch(resetdraftPropertyUpdate());
            MySwal.close();
            navigate(appRoutes.DASHBOARD_PROVIDER);
          }
        });
      });
    }
  };

  const handelSingleDraftSubmit = (status, setIsLoading) => {
    setIsLoading(true);
    const postData = methods.getValues();

    const propertyType = localStorage.getItem('property_type');
    const pictures = propertyImages;
    const property_locations = property_locationsOne ? [property_locationsOne] : '';
    const parking = {
      box_parking_number: postData.box_parking_number,
      box_parking_included_in_price: postData.box_parking_included_in_price,
      box_parking_price_per_parking: postData.box_parking_price_per_parking,
      box_parking_mandatory: box_parking_mandatory,
      outdoor_spots_number: postData.outdoor_spots_number,
      outdoor_spots_included_in_price: postData.outdoor_spots_included_in_price,
      outdoor_spots_price_per_parking: postData.outdoor_spots_price_per_parking,
      outdoor_spots_mandatory: outdoor_spots_mandatory,
      underground_spots_number: postData.underground_spots_number,
      underground_spots_included_in_price: postData.underground_spots_included_in_price,
      underground_spots_price_per_parking: postData.underground_spots_price_per_parking,
      underground_spots_mandatory: underground_spots_mandatory,
    };
    if (location.pathname?.includes('duplicate-property')) {
      delete postData?.id;
      dispatch(
        DraftProperty({
          data: {
            ...postData,
            // availability,
            // until_end,
            pictures,
            // active_until,
            status,
            property_type: propertyType ? propertyType : PropertyDetailData?.property_type,
            removed_images: removed,
            duplicate_images,
            parking: parking,
            fixed_renew: fixed_renew,
            property_locations,
            request_type: 'duplicate',
            monthly_charge_option: chargesOption,
            currency: 'CHF',
            // id: currentFormID ? currentFormID : searchParams.get('id'),
          },
          setCurrentFormID,
          setDraftLoading,
          fun: () => {},
        })
      ).then(() => {
        setIsLoading(false);
        setRefresh((pr) => pr + 1);
        MySwal.fire({
          title: t('Propert save as draft'),
          icon: 'success',
        }).then((res) => {
          if (res.isConfirmed) {
            dispatch(resetdraftPropertyUpdate());
            MySwal.close();
          }
        });
      });
    } else {
      dispatch(
        DraftProperty({
          data: {
            ...postData,
            pictures,
            status,
            property_type: propertyType ? propertyType : PropertyDetailData?.property_type,
            removed_images: removed,
            duplicate_images,
            parking: parking,
            fixed_renew: fixed_renew,
            property_locations,
            published_until: published_until,
            monthly_charge_option: chargesOption,
            currency: 'CHF',
            id: currentFormID ? currentFormID : decryptId,
          },
          setCurrentFormID,
          setIsLoading,
        })
      ).then((resp) => {
        setRefresh((pr) => pr + 1);
        setIsLoading(false);
        MySwal.fire({
          title: t('Propert save as draft'),
          icon: 'success',
        }).then((res) => {
          if (res.isConfirmed) {
            dispatch(resetdraftPropertyUpdate());
            MySwal.close();
            if (pictures.length > 0) {
              setPropertyImages([]);
            }
          }
        });
      });
    }
  };
  useEffect(() => {
    if (!id && currentFormID) {
      setId(Encrypt(currentFormID));
    }
  }, [currentFormID]);

  useEffect(() => {
    if (role) {
      userRoles.forEach((element) => {
        if (element.value === role.value) {
          setRole(element.value);
        }
      });
    }
  }, [role]);

  const type = property_type === 'rent' ? 'rent' : 'sell';

  return (
    <div className="relative lg:max-w-[1024px] md:max-w-[900px] md:mx-auto md:my-20 px-7">
      <div className="flex items-center h-20 pb-12 sm_ss:hidden sm_s:hidden">
        <PageHeading className="">
          {t('Property to')} {t(`${type}`)}
        </PageHeading>
        <Link to={appRoutes.DASHBOARD_PROVIDER} className="my-3 ml-4 text-primary">
          {'< '} {t('Back to My properties')}
        </Link>
      </div>
      <div className="relative flex justify-between w-full">
        <div className="flex gap-4 text-primary sm_s:ml-2 sm_ss:ml-2 sm_s:mt-5 sm_ss:mt-5">
          <div
            role="button"
            onClick={() => {
              setExpandAll(true);
              setCollapseAll(false);
            }}
            tabIndex="0"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setExpandAll(true);
                setCollapseAll(false);
              }
            }}
          >
            + {t('Expand all')}
          </div>
          <div
            role="button"
            onClick={() => {
              setCollapseAll(true);
              setExpandAll(false);
            }}
            tabIndex="0"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setCollapseAll(true);
                setExpandAll(false);
              }
            }}
          >
            - {t('Collapse all')}
          </div>
        </div>
        {location.pathname.includes('add-property') ? null : (
          <Link
            to={`/property/${searchParams.get('id')}`}
            className="text-primary flex my-2 mr-8 cursor-pointer"
          >
            <FaEye className="text-[20px] mr-1" /> <span>{t('Preview')}</span>
          </Link>
        )}
      </div>
      <div className="flex flex-col  relative ">
        <div className="absolute top-0 min-w-[250px] left-full pl-6 sm_xxx:hidden hidden xl:block">
          {location.pathname.includes('add-property') ? (
            ''
          ) : (
            <div className="flex mb-5">
              <div
                className={`my-auto uppercase flex items-center justify-center h-7 ${
                  formData?.request_status === 'pending' ? 'w-32' : 'w-22'
                } px-2 text-white text-sm md:text-md text-center font-bold rounded-md ${
                  formData?.request_status === 'pending' && 'bg-[#6F6F6F]'
                }
              ${formData?.request_status === 'active' && 'bg-[#73C768]'} 
               ${formData?.request_status === 'draft' && 'bg-[#E6BC2E]'} 
               ${formData?.request_status === 'rejected' && 'bg-[#D13838]'}
               ${formData?.request_status === 'expired' && 'bg-[#C9C9C9]'}
               ${formData?.request_status === 'archived' && 'bg-[#C9C9C9]'}
                ${formData?.request_status === 'hold' && 'bg-[#6F6F6F]'}
              `}
              >
                {formData?.request_status === 'pending'
                  ? t('under review')
                  : t(`${formData?.request_status}`)}
              </div>
            </div>
          )}
          <Extend
            methods={methods}
            data={PropertyDetailData}
            setpublished_until={setpublished_until}
            published_until={published_until}
          />
          <p className="pb-2">
            {formData?.request_status ? t('This listing is published as') : t('I rent this as')}
          </p>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="renewable"
            className=""
            name="role"
            value={role}
            onChange={(event, data) => {
              userRoles.forEach((element) => {
                if (element.value === data) {
                  setRole(data);
                  methods.setValue('role', data);
                }
              });
            }}
          >
            <FormControlLabel
              checked={role === 'landlord'}
              value="landlord"
              control={<Radio />}
              label={t('Landlord')}
            />
            <FormControlLabel
              checked={role === 'tenant'}
              value="tenant"
              control={<Radio />}
              label={t('Tenant')}
            />
            <FormControlLabel
              checked={role === 'agent'}
              value="agent"
              control={<Radio />}
              label={t('Agent')}
            />
            <FormControlLabel
              value="real_estate_agency"
              control={<Radio />}
              label={t('Real Estate Agency')}
              checked={role === 'real_estate_agency'}
            />
            <FormControlLabel
              checked={role === 'caretaker'}
              value="caretaker"
              control={<Radio />}
              label={t('Caretaker')}
            />
          </RadioGroup>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
            <div className="w-full grid gap-8 px-2">
              <PropertyFormCard
                errors={errors}
                scrollState={scrollState}
                setScrollState={setScrollState}
                expandAll={expandAll}
                collapseAll={collapseAll}
                methods={methods}
                data={PropertyDetailData}
                handelDraftSubmit={handelSingleDraftSubmit}
                scrollTo={scrollTo}
                noScroll={noScroll}
                setfixed_renew={setfixed_renew}
                fixed_renew={fixed_renew}
                error={fixed_renewErr}
                referanceErr={reference_codeErr}
                property_type={property_type}
                setRenewableOptionErr={setRenewableOptionErr}
                renewableOptionErr={renewableOptionErr}
                draftLoading={draftLoading}
              />
              {property_type === 'rent' ? (
                <RentFormCard
                  errors={errors}
                  scrollTo={scrollTo}
                  noScroll={noScroll}
                  expandAll={expandAll}
                  collapseAll={collapseAll}
                  scrollState={scrollState}
                  handelDraftSubmit={handelSingleDraftSubmit}
                  methods={methods}
                  Data={PropertyDetailData}
                  language_selected={language_selected}
                  setChargesOption={setChargesOption}
                  chargesOption={chargesOption}
                  chargesOptionErr={chargesOptionErr}
                  rentChargesErr={rentChargesErr}
                  SetRentChargesErr={SetRentChargesErr}
                  draftLoading={draftLoading}
                />
              ) : (
                <SellingForm
                  errors={errors}
                  scrollTo={scrollTo}
                  noScroll={noScroll}
                  expandAll={expandAll}
                  collapseAll={collapseAll}
                  scrollState={scrollState}
                  handelDraftSubmit={handelSingleDraftSubmit}
                  methods={methods}
                  Data={PropertyDetailData}
                  language_selected={language_selected}
                  draftLoading={draftLoading}
                />
              )}

              <AddressFormCard
                errors={errors}
                scrollTo={scrollTo}
                noScroll={noScroll}
                expandAll={expandAll}
                collapseAll={collapseAll}
                scrollState={scrollState}
                property_locationsOne={property_locationsOne}
                setproperty_locationsOne={setproperty_locationsOne}
                control={methods.control}
                handelDraftSubmit={handelSingleDraftSubmit}
                Data={PropertyDetailData}
                methods={methods}
                property_locationsOneErr={property_locationsOneErr}
                cityLocationErr={cityLocationErr}
                language_selected={language_selected}
                draftLoading={draftLoading}
              />
              <DetailsFormCard
                errors={errors}
                scrollTo={scrollTo}
                noScroll={noScroll}
                expandAll={expandAll}
                collapseAll={collapseAll}
                scrollState={scrollState}
                handelDraftSubmit={handelSingleDraftSubmit}
                data={PropertyDetailData}
                methods={methods}
                language_selected={language_selected}
                draftLoading={draftLoading}
              />
              <DescriptionForm
                errors={errors}
                scrollTo={scrollTo}
                noScroll={noScroll}
                expandAll={expandAll}
                collapseAll={collapseAll}
                scrollState={scrollState}
                handelDraftSubmit={handelSingleDraftSubmit}
                methods={methods}
                language_selected={language_selected}
                draftLoading={draftLoading}
              />
              {/* {methods.getValues()} */}
              <ParkingAndGarageForm
                errors={errors}
                scrollTo={scrollTo}
                noScroll={noScroll}
                expandAll={expandAll}
                collapseAll={collapseAll}
                scrollState={scrollState}
                methods={methods}
                handelDraftSubmit={handelSingleDraftSubmit}
                data={PropertyDetailData}
                property_type={property_type}
                setunderground_spots_mandatory={setunderground_spots_mandatory}
                underground_spots_mandatory={underground_spots_mandatory}
                outdoor_spots_mandatory={outdoor_spots_mandatory}
                setoutdoor_spots_mandatory={setoutdoor_spots_mandatory}
                setbox_parking_mandatory={(value) => {
                  setbox_parking_mandatory(value);
                }}
                setPropertyData={setPropertyData}
                box_parking_mandatory={box_parking_mandatory}
                language_selected={language_selected}
                draftLoading={draftLoading}
              />
              <PicturesForm
                setPropertyImages={setPropertyImages}
                expandAll={expandAll}
                collapseAll={collapseAll}
                handelDraftSubmit={handelSingleDraftSubmit}
                Data={PropertyDetailData}
                error={error}
                scrollTo={scrollTo}
                noScroll={noScroll}
                scrollState={scrollState}
                methods={methods}
                serverImages={serverImages}
                setServerImages={setServerImages}
                removed={removed}
                setRemoved={setRemoved}
                setDuplicateImages={setDuplicateImages}
                duplicate_images={duplicate_images}
                language_selected={language_selected}
                draftLoading={draftLoading}
              />
              <VisitsForm
                errors={errors}
                scrollTo={scrollTo}
                noScroll={noScroll}
                expandAll={expandAll}
                collapseAll={collapseAll}
                scrollState={scrollState}
                methods={methods}
                handelDraftSubmit={handelSingleDraftSubmit}
                Data={PropertyDetailData}
                role={role}
                setRole={setRole}
                language_selected={language_selected}
                draftLoading={draftLoading}
              />
              <div className=" sm_xxx:block  xl:hidden px-2">
                <div>
                  {location.pathname.includes('add-property') ? (
                    ''
                  ) : (
                    <div className="flex mb-5">
                      <div
                        className={`my-auto uppercase flex items-center justify-center h-7 ${
                          formData?.request_status === 'pending' ? 'w-32' : 'w-22'
                        } px-2 text-white text-sm md:text-md text-center font-bold rounded-md ${
                          formData?.request_status === 'pending' && 'bg-[#6F6F6F]'
                        }
              ${formData?.request_status === 'active' && 'bg-[#73C768]'} 
               ${formData?.request_status === 'draft' && 'bg-[#E6BC2E]'} 
               ${formData?.request_status === 'rejected' && 'bg-[#D13838]'}
               ${formData?.request_status === 'expired' && 'bg-[#C9C9C9]'}
               ${formData?.request_status === 'archived' && 'bg-[#C9C9C9]'}
                ${formData?.request_status === 'hold' && 'bg-[#6F6F6F]'}
              `}
                      >
                        {formData?.request_status === 'pending'
                          ? t('under review')
                          : t(`${formData?.request_status}`)}
                      </div>
                    </div>
                  )}
                </div>
                <Extend
                  methods={methods}
                  data={PropertyDetailData}
                  setpublished_until={setpublished_until}
                  published_until={published_until}
                />
                <p className="pb-2">
                  {formData?.request_status
                    ? t('This listing is published as')
                    : t('I rent this as')}
                </p>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="renewable"
                  className=""
                  name="role"
                  value={role}
                  onChange={(event, data) => {
                    userRoles.forEach((element) => {
                      if (element.value === data) {
                        setRole(data);
                        methods.setValue('role', data);
                      }
                    });
                  }}
                >
                  <FormControlLabel
                    checked={role === 'landlord'}
                    value="landlord"
                    control={<Radio />}
                    label={t('Landlord')}
                  />
                  <FormControlLabel
                    checked={role === 'tenant'}
                    value="tenant"
                    control={<Radio />}
                    label={t('Tenant')}
                  />
                  <FormControlLabel
                    checked={role === 'agent'}
                    value="agent"
                    control={<Radio />}
                    label={t('Agent')}
                  />
                  <FormControlLabel
                    value="real_estate_agency"
                    control={<Radio />}
                    label={t('Real Estate Agency')}
                    checked={role === 'real_estate_agency'}
                  />
                  <FormControlLabel
                    checked={role === 'caretaker'}
                    value="caretaker"
                    control={<Radio />}
                    label={t('Caretaker')}
                  />
                </RadioGroup>
              </div>
              <div className="flex w-full sm_ss:flex-col py-3 sm_s:flex-col md:p-0 px-3">
                {draft ? null : (
                  <button
                    type="submit"
                    disabled={pending ? true : false}
                    style={{ cursor: pending ? 'not-allowed' : 'pointer' }} // Change cursor style based on loading state
                    className="bg-primary px-4 h-10 w-64 sm_ss:w-full sm_s:w-full flex items-center justify-center py-2 text-base rounded-md text-white font-bold my-1"
                  >
                    {pending && statusDraft !== 'draft' ? (
                      <CircularProgress className="p-2" color="inherit" />
                    ) : (
                      t('SAVE AND ACTIVATE')
                    )}
                  </button>
                )}
                {(formData?.request_status === 'active' &&
                  location.pathname?.includes('edit-property')) ||
                (formData?.request_status === 'pending' &&
                  location.pathname?.includes('edit-property')) ||
                (formData?.request_status === 'hold' &&
                  location.pathname?.includes('edit-property')) ? (
                  ''
                ) : (
                  <button
                    type="button"
                    onClick={() => handelDraftSubmit('draft', true)}
                    className={`bg-[#E6BC2E] md:ml-4 ${
                      language_selected === 'en' ? 'w-64' : 'w-80'
                    } px-4 h-10 py-2 text-base sm_ss:w-full sm_s:w-full rounded-md text-white font-bold my-1`}
                  >
                    {draftPropertyLoading ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : (
                      t('SAVE AS DRAFT')
                    )}
                  </button>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default AddAndEditProperty;
