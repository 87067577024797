/* eslint-disable import/prefer-default-export */
import nyonDistrict from './Border side/nyonDistrict.png';
import LausanneRegion from './Border side/map-lake-lausanne.png';
import franceBorder from './Border side/map-lake-geneva@2x.png';
import genevaCountryLeft from '../map-geneva/map-geneva-countryside-left@2x.png';
import genevaright from '../map-geneva/map-geneva-right@2x.png';
import genevaLeft from '../map-geneva/map-geneva-left@2x.png';
import genevaCountryRight from '../map-geneva/map-geneva-countryside-right@2x.png';

export const borderSide = [nyonDistrict, LausanneRegion, franceBorder];
export const genevaSide = [genevaright, genevaLeft, genevaCountryLeft, genevaCountryRight];
