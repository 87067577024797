import { useTranslation } from 'react-i18next';
import React from 'react';
import './privacypolicy.css';

export default function TermCondition() {
  const { t } = useTranslation();
  return (
    <div className="main-conatiner-priv-policy">
      <div className="sub-conatiner-priv-policy">
        <h1 className="priv-policy-main-head">{t('TermHead')}</h1>
        <h2 className="priv-policy-sub-head">{t('Subhead')}</h2>
        <h2 className="priv-policy-pcondition">{t('PCondition')}</h2>
        <span className="priv-policy-version">{t('VerionShow')}</span>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          marginTop: '50px',
          overflowX: 'hidden',
        }}
      >
        <div
          style={{
            width: '50%',
            overflowX: 'hidden',
          }}
        >
          <ul style={{ marginBottom: '60px' }}>
            <li style={{ paddingTop: '0px' }}>
              <li style={{ color: '#004993', paddingBottom: '15px' }}>
                <b>{t('Preamble')}</b>
              </li>
              <span style={{ wordBreak: 'break-all' }}>{t('Maincontentone')}</span>
            </li>
            <li style={{ paddingTop: '15px' }}>
              <li style={{ color: '#004993', paddingBottom: '0px' }}>
                <b>{t('Subheadtwo')}</b>
              </li>
              <span style={{ wordBreak: 'break-all' }}>
                {t('Maincontent2')}
                {t('emailLink')}
              </span>
            </li>
            <li style={{ paddingTop: '15px' }}>
              <li style={{ color: '#004993', paddingBottom: '0px' }}>
                <b>{t('Subheadthree')}</b>
              </li>
              <ul>
                {t('Maincontentthree')}
                <li style={{ wordBreak: 'break-all' }}>{t('MaincontentSubOne')}</li>
                <li style={{ wordBreak: 'break-all' }}>{t('MaincontentSubTwo')}</li>
                <li style={{ wordBreak: 'break-all' }}>{t('MaincontentSubThree')}</li>
                {t('Subclausethree')}
              </ul>
            </li>
            <li style={{ paddingTop: '15px' }}>
              <li style={{ color: '#004993', paddingBottom: '0px' }}>
                <b>{t('Clausefour')}</b>
              </li>
              <li style={{ color: '#004993', paddingBottom: '0px' }}>
                <b>{t('SubclauseA')}</b>
              </li>
              <ul>
                <li>{t('Subclausefour')}</li>
                <li style={{ wordBreak: 'break-all' }}>{t('SubclassFourOne')}</li>
                <li style={{ wordBreak: 'break-all' }}>{t('SubclassFourTwo')}</li>
                <li style={{ wordBreak: 'break-all' }} className="headings">
                  <b>{t('SubclauseB')}</b>
                </li>
                <li>{t('SubclauseBOne')}</li>
              </ul>
            </li>
            <li>{t('SubclauseFourthContent')}</li>
            <li>{t('SubclauseFifthContent')}</li>
            <li>
              <li> {t('SubclauseSixthContent')}</li>
              <ul>
                <li>{t('SubclauseSixthOne')}</li>
                <li>{t('SubclauseSixthtwo')}</li>
                <li>{t('SubclauseSixththree')}</li>
                <li>{t('SubclauseSixthfour')}</li>
                <li>{t('SubclauseSixthfifth')}</li>
                <li>{t('SubclauseSixsixth')}</li>
                <li>{t('SubclauseSixSeven')}</li>
              </ul>
            </li>
            <li>
              <li>{t('SubclauseSeven')}</li>
              <ul>
                <li>{t('SubclauseSevenOne')}</li>
                <li>{t('SubclauseSevenTwo')}</li>
              </ul>
            </li>

            <li>{t('SubclauseEnd')}</li>
            <li>{t('tableHeading')}</li>
          </ul>
          <table className="mb-14">
            <thead>
              <tr>
                <th>{t('thcOne')}</th>
                <th>{t('thtwo')}</th>
                <th>{t('ththree')}</th>
                <th>{t('thfour')}</th>
                <th>{t('thfive')}</th>
                <th>{t('thsix')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('trDecsOne')}</td>
                <td>{t('trDecstwo')}</td>
                <td>{t('trDecsthree')}</td>
                <td>{t('trDecsfour')}</td>
                <td>{t('trDecsfive')}</td>
                <td>{t('trDecssix')}</td>
              </tr>
              <tr>
                <td>{t('trDecssev')}</td>
                <td>{t('trDecstwo')}</td>
                <td>{t('trDecsnine')}</td>
                <td>{t('trDecsfour')}</td>
                <td>{t('trDecsfive')}</td>
                <td>{t('trDecssix')}</td>
              </tr>
              <tr>
                <td>{t('trDecsele')}</td>
                <td>{t('trDecstwo')}</td>
                <td>{t('trDecstwe')}</td>
                <td>{t('trDecsfour')}</td>
                <td>{t('trDecsfive')}</td>
                <td>{t('trDecssix')}</td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li>
              <b>{t('Aftertable')}</b>
            </li>
            <li>
              <li> {t('SubClauseFour')}</li>
              <ul>
                <li>{t('SubClauseFourOne')}</li>
                <li>{t('SubClauseFourtwo')}</li>
                <li>{t('SubClauseFourthree')}</li>
              </ul>
              <li>
                <b>{t('AfterSubclauseFour')}</b>
              </li>
              <li>{t('AfterSubclausecontent')}</li>
              <li>
                <b>{t('ClauseC')}</b>
                <ul>
                  <li>{t('ClauseCContent')}</li>
                </ul>
                <li>
                  <li>{t('ClauseContentone')}</li>
                  <ul>
                    <li>{t('ClausePoint')}</li>
                    <li>{t('ClausePointTwo')}</li>
                    <li>{t('ClausePointthree')}</li>
                  </ul>
                </li>
                <li>{t('ClausePointfour')}</li>
                <li>{t('NewClause')}</li>
                <li>
                  <li>{t('NewClausetwo')}</li>
                  <ul>
                    <li>{t('NewClauseHead')}</li>
                  </ul>
                </li>
                <li>
                  <li>{t('NewClausethree')}</li>
                  <ul>
                    <li>{t('NewClausePointOne')}</li>
                    <li>{t('NewClausePointTwo')}</li>
                    <li>{t('NewClausePointThree')}</li>
                    <li>{t('NewClausePointFour')}</li>
                    <li>{t('NewClausePointFive')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('NewPointFive')}</li>
                  <ul>
                    <li>{t('NewPointSubOne')}</li>
                    <li>{t('NewPointSubtwo')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('NewPointSix')}</li>
                  <ul>
                    <li>
                      {t('NewPointSixSubone')}
                      <ul>
                        <li>{t('NewPointSixSubtwo')}</li>
                        <li>{t('NewPointSixSubthree')}</li>
                      </ul>
                    </li>
                    <li>{t('NewPointSubtwo')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('NewPointSeven')}</li>
                  <ul>
                    <li> {t('NewPointSevenOne')}</li>
                    <li> {t('NewPointSevenTwo')}</li>
                    <li> {t('NewPointSevenThree')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('NewPointEight')}</li>
                  <ul>
                    <li>{t('NewPointEightOne')}</li>

                    <li>
                      <li>{t('NewPointEightTwo')}</li>
                      <ul>
                        <li>{t('NewPointEightSubOne')}</li>
                        <li>{t('NewPointEightSubTwo')}</li>
                        <li>{t('NewPointEightSubThree')}</li>
                        <li>{t('NewPointEightSubFour')}</li>
                        <li>{t('NewPointEightSubFive')}</li>
                      </ul>
                    </li>
                    <li>{t('NewPointEightThree')}</li>
                    <li>{t('NewPointEightFour')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('NewPointNine')}</li>
                  <ul>
                    <li>{t('NewPointNineOne')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('NewPointTen')}</li>
                  <ul>
                    <li>{t('NewPointTenOne')}</li>
                    <li>{t('NewPointTenTwo')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('NewPointEleven')}</li>
                  <ul>
                    <li>{t('NewPointElevenOne')}</li>
                    <li>{t('NewPointElevenTwo')}</li>
                    <li>{t('NewPointElevenThree')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('NewPointTweleve')}</li>
                  <ul>
                    <li>{t('NewPointTweleveOne')}</li>
                    <li>{t('NewPointTweleveTwo')}</li>
                    <li>{t('NewPointTweleveThree')}</li>
                    <li>{t('NewPointTweleveFour')}</li>
                    <li>{t('NewPointTweleveFive')}</li>
                    <li>{t('NewPointTweleveSix')}</li>
                    <li>{t('NewPointTweleveSeven')}</li>
                    <li>{t('NewPointTweleveEight')}</li>
                    <li>{t('NewPointTweleveNine')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('PointThirteen')}</li>
                  <ul>
                    <li>{t('PointThirteenOne')}</li>
                    <li>{t('PointThirteenTwo')}</li>
                    <li>{t('PointThirteenThree')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('PointFourteen')}</li>
                  <ul>
                    <li>{t('PointFourteenOne')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading"> {t('PointFiteen')}</li>
                  <ul>
                    <li>{t('PointFiteenOne')}</li>
                    <li>{t('PointFiteenTwo')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('PointSixteen')}</li>
                  <ul>
                    <li>{t('PointSixteenOne')}</li>
                    <li>{t('PointSixteenTwo')}</li>
                  </ul>
                </li>
              </li>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
