/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Paper,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import withReactContent from 'sweetalert2-react-content';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AxiosInstance from '../../Axios';
import { OrganizationSchema } from '../../constants';
import API from '../../constants/API';
import { appRoutes } from '../../constants/routeConstants/Routes';
import ErrorContainer from '../ErrorContainer';
import FieldsContainer from '../FieldsContainer';
import SelectField from '../SelectField';
import Separater from '../Separater';
import SubWrapper from '../SubWrapper';
import TextInput from '../TextInput';
import PageHeading from '../common/PageHeading';
import { addOrganization, resetAddOrganization } from '../../redux/slices/AddOrganization';
import Members from './Members';
import { updateUserInfoStart, updateUserInfoSuccess } from '../../redux/slices/UpdateUserInfo';
import { Canton } from '../../apiCalls';
import { useForceUpdate } from '../../util';

function Organization({ expandAll, collapseAll, scrollTo, noScroll, scrollState }) {
  const MySwal = withReactContent(Swal);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [expended, setExpended] = useState(true);
  const [userProfile, setUserProfile] = useState();
  const [deleteProfile, setDeleteProfile] = useState(false);
  const [members, setMembers] = useState([]);
  const [canton, setCanton] = useState([]);
  const dispatch = useDispatch();
  const success = useSelector((state) => state.AddOrganizationReducer.success);
  const pending = useSelector((state) => state.AddOrganizationReducer.pending);
  const Error = useSelector((state) => state.AddOrganizationReducer.error);
  const language_selected = Cookies.get('i18next');

  useEffect(() => {
    Canton(212, setCanton, language_selected);
  }, [language_selected]);

  const methods = useForm({
    resolver: yupResolver(OrganizationSchema),
  });
  const { errors } = methods.formState;
  console.log('errors', errors);
  useEffect(() => {
    if (expandAll) {
      setExpended(true);
    }
  }, [expandAll]);
  useEffect(() => {
    if (collapseAll) {
      setExpended(false);
    }
  }, [collapseAll]);
  const onSubmit = (data) => {
    dispatch(updateUserInfoStart());
    dispatch(
      addOrganization({
        data,
        fun: () => {
          navigate(appRoutes.DASHBOARD_PROVIDER);
        },
      })
    );
    dispatch(updateUserInfoSuccess());
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const token = user?.data?.result?.access_token;
  useEffect(() => {
    if (token) {
      const userProfileData = async () => {
        const res = await AxiosInstance.post(API.GETPROFILE);
        setUserProfile(res.data.result);
        methods.reset(res.data.result);
        methods.setValue('nb', res.data.result.street_number);
        if (language_selected === 'en') {
          methods.setValue('chosen_role', 'Real estate agency');
        } else {
          methods.setValue('chosen_role', 'Agence immobilière');
        }
        setMembers(res.data.result.organisation_members);
      };
      userProfileData();
    }
  }, [token, language_selected]);

  useEffect(() => {
    if (success === true) {
      MySwal.fire({
        title: (
          <span className="flex flex-col text-base">
            <p>{t('Organization added successfully')}</p>
          </span>
        ),
        icon: 'success',
      }).then((res) => {
        if (res.isConfirmed) {
          dispatch(resetAddOrganization());
          navigate(appRoutes.DASHBOARD_PROVIDER);
        }
      });
    }
  }, [success]);

  const deleteORG = async () => {
    dispatch(updateUserInfoStart());
    const res = await AxiosInstance.post(API.DELETE_ORG);
    if (res) {
      navigate(appRoutes.DASHBOARD_PROVIDER);
      dispatch(updateUserInfoSuccess());
    }
  };
  useEffect(() => {
    if (deleteProfile) {
      MySwal.fire({
        title: (
          <span className="flex flex-col text-base">
            <p>{t('Are you sure to delete your organisation')}</p>
          </span>
        ),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('Yes'),
        cancelButtonText: t('Cancel'),
      }).then((res) => {
        if (res.isConfirmed) {
          deleteORG();
        }
      });
      setDeleteProfile(false);
    }
  }, [MySwal, dispatch]);

  const handleRemoveMember = async (data) => {
    MySwal.fire({
      title: (
        <span className="flex flex-col text-base">
          <p>{t('Delete_Member')}</p>
        </span>
      ),
      icon: 'info',
      confirmButtonText: t('Yes'),
      showCancelButton: true,
      cancelButtonText: t('Cancel'),
    }).then(async (res) => {
      if (res.isConfirmed) {
        const response = members.filter((member) => member?.id !== data?.id);
        await AxiosInstance.post(API.DELETE_MEMBER, {
          member_id: data?.member_id,
        });
        setMembers(response);
      }
    });
  };

  return (
    <div className="w-full h-full mt-10 flex justify-center">
      <div className="border-2 rounded-lg w-[868px] md:mx-7 lg:mx-0 xl:mx-0">
        <form onSubmit={methods.handleSubmit(onSubmit)} className="w-full p-2">
          <div className="flex items-center">
            <span className="text-themeSecgray text-4xl font-extrabold mr-5">
              {t('Organisations')}
            </span>
            <Link to={appRoutes.DASHBOARD_PROVIDER}>
              <p className="text-blue">
                {'<'} {t('Back to My properties')}
              </p>
            </Link>
          </div>

          <Accordion
            className="border-themeGray"
            sx={{
              backgroundColor: 'transparent',
              boxShadow: '0',
              border: 'solid 2px #A6A6A6',
              borderRadius: '10px !important',
            }}
            expanded={expended}
            onChange={() => {
              setExpended(!expended);
            }}
          >
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
              {expended ? (
                <FaMinus className="text-primary text-2xl my-auto" />
              ) : (
                <FaPlus className="text-primary text-2xl my-auto" />
              )}
              <div className="text-primary text-2xl font-bold px-3">{t('Organisations')}</div>
            </AccordionSummary>
            <AccordionDetails>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="md:p-8">
                    <FieldsContainer className="flex items-center">
                      <SubWrapper className="h-28 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                        <div className="w-1/3 sm_ss:w-full sm_s:w-full">
                          <TextInput
                            text={t('Role')}
                            texttype="text"
                            placeholder="Enter your name"
                            name="chosen_role"
                            id="chosen_role"
                            disable
                          />
                        </div>
                        {errors.chosen_role && (
                          <ErrorContainer errMsg={t(`${errors?.chosen_role?.message}`)} />
                        )}
                      </SubWrapper>
                      <Separater className="w-12" />
                    </FieldsContainer>
                    <FieldsContainer className="flex items-center ">
                      <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                        <TextInput
                          text={t('Organisation e-mail')}
                          texttype="email"
                          placeholder="Enter your email"
                          name="organization_email"
                          id="organization_email"
                          // defaultValue={userProfile?.email}
                        />
                        {errors.organization_email && (
                          <ErrorContainer errMsg={t(`${errors.organization_email.message}`)} />
                        )}
                      </SubWrapper>
                    </FieldsContainer>
                    <FieldsContainer className="flex items-center ">
                      <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                        <TextInput
                          text={t(' Organisation_name')}
                          texttype="text"
                          placeholder="Enter your name"
                          name="organization_name"
                          id="organization_name"
                          // defaultValue={userProfile?.email}
                        />
                        {errors.organization_name && (
                          <ErrorContainer errMsg={t(`${errors.organization_name.message}`)} />
                        )}
                      </SubWrapper>
                      <Separater className="w-12" />
                      <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                        <TextInput
                          text={t('OrganisationPhone')}
                          texttype="number"
                          placeholder="Enter your phone number"
                          name="organization_phone"
                          id="organization_phone"
                          // defaultValue={userProfile?.mobile_number}
                        />
                        {errors.organization_phone && (
                          <ErrorContainer errMsg={t(`${errors.organization_phone.message}`)} />
                        )}
                      </SubWrapper>
                    </FieldsContainer>
                  </div>
                </form>
              </FormProvider>
            </AccordionDetails>
          </Accordion>
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col">
              <button
                className="h-10 w-28 cursor-pointer my-3 rounded-lg bg-blue text-white"
                type="submit"
              >
                {pending ? <CircularProgress className="p-2" color="inherit" /> : t('SAVE')}
              </button>
              {Error && <span className="text-red-600">{t('Something went wrong')}</span>}
            </div>
            <div className="text-red-600 cursor-pointer" onClick={() => setDeleteProfile(true)}>
              {t('X Delete my organisation')}
            </div>
          </div>
        </form>
        <div className="h-96">
          <Paper elevation={0} className="p-4 paper mt-8">
            <div className="flex mb-8 mx-3 mt-2">
              <PageHeading>{t('Members')}</PageHeading>
              <Link to={appRoutes.ADDNEWMEMBER}>
                <div className="bg-primary px-4 py-2 text-base rounded-md text-white font-bold my-1 mx-4">
                  {t('New')}
                </div>
              </Link>
            </div>
            {members?.length > 0 &&
              members?.map((member, index) => {
                return (
                  <Members index={index} member={member} handleRemoveMember={handleRemoveMember} />
                );
              })}
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default Organization;
