/* eslint-disable prettier/prettier */

const API = {
  BASE_URL: 'https://housing-dev.cagi.ch/admin/public/',
  // BASE_URL:'http://192.168.1.144:8001/',
  PROVIDER_SIGNUP: 'api/register/provider',
  SEEKER_SIGNUP: 'api/register/seeker',
  FETCH_USER: 'api/fetch-user',
  LOGIN: 'api/login',
  PROFILE_UPDATE: 'api/profile/provider/update',
  GETPROFILE: 'api/profile/getProfile',
  SEEKER_PROFILE_UPDATE: 'api/profile/seeker/update',
  DELETE_ACCOUNT: 'api/removeAccount',
  PROPERTY_LIST: 'api/properties',
  PROPERTY: 'api/property',
  SEEN_PROPERTY: 'api/seeker/property/seen',
  PROPERTY_DELETE: 'api/property/delete',
  ADD_AND_EDIT_PROPERTY: 'api/property/save/record',
  SEEKER_LISTING: 'api/seeker/matched/properties',
  SUSPEND: 'api/seeker/request/suspend',
  SEEKERNEWREQUEST: 'api/seeker/request/create',
  AddNEWMEMBER: 'api/organisation/member/add',
  EXTEND: 'api/property/extend',
  UPDATE_MEMBER: 'api/organisation/member/update',
  DELETE_MEMBER: 'api/organisation/member/delete',
  DELETE_ORG: 'api/organisation/delete',
  FORGETPASSWORD: 'api/forgot-password',
  RESETPASSWORD: 'api/reset-password',
  ADD_VIEW: 'api/property/view/add',
  ADD_AND_EDIT_PROPERTY_DRAFT: 'api/property/save/record',
  ARCHIVE_PROPERTY: 'api/property/archive',
  RESEND_VERIFY_EMAIL: 'api/resend/verify/email',
  CITIES_AND_LOCATIONS: 'api/switzerland/cities',
  SATISFACTION_SURVEY: 'api/survey/add',
  REACTIVE_PROPERTY: 'api/property/reactivate',
  GET_OTP: 'api/send-otp',
  VERIFY_OTP: 'api/verify-otp',
};

export default API;
