/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { t } from 'i18next';
import { FaUser } from 'react-icons/fa';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ImCancelCircle } from 'react-icons/im';
import AxiosInstance from '../../Axios';
import API from '../../constants/API';

function Members({ index, member, handleRemoveMember }) {
  const MySwal = withReactContent(Swal);
  const [role, setRole] = useState();
  const UpdateMember = async (data) => {
    console.log('role', role);
    // console.log('member', { member_id: member.id, role: member.role });
    await AxiosInstance.post(API.UPDATE_MEMBER, {
      members: [{ member_id: data.member_id, role }],
    }).then(() => {
      MySwal.fire({
        title: (
          <span className="flex flex-col text-base">
            <p>{t('Member role update')}</p>
          </span>
        ),
        icon: 'success',
        confirmButtonText: 'Ok',
      });
    });
  };
  return (
    <div key={index} className="flex flex-col w-full p-4">
      <div className="flex items-center">
        <div className="flex-1 flex items-center">
          <FaUser color="#A6A6A6" width="21px" height="24px" />
          <span className="ml-2">{member?.member_details?.name}</span>
        </div>
        <div className="flex-1 flex items-center">
          <input
            type="radio"
            className="h-4 w-4 mr-2"
            value="admin"
            id={`admin${member?.id}`}
            name={`member${member?.id}`}
            onChange={(e) => setRole(e.target.value)}
            defaultChecked={member?.role?.includes('admin')}
          />
          <label htmlFor={`admin${member?.id}`}>{t('Admin')}</label>
        </div>
        <div className="flex-1 flex items-center">
          <input
            type="radio"
            className="h-4 w-4 mr-2"
            value="publisher"
            id={`publisher${member?.id}`}
            name={`member${member?.id}`}
            onChange={(e) => setRole(e.target.value)}
            defaultChecked={member?.role?.includes('publisher')}
          />
          <label htmlFor={`publisher${member?.id}`}>{t('Publisher')}</label>
        </div>
        <div className="cursor-pointer flex items-center">
          <button
            type="button"
            className="text-white bg-blue p-1 mr-2 rounded-md cu-pointer"
            onClick={() => UpdateMember(member)}
          >
            {t('Update')}
          </button>
          <ImCancelCircle color="red" onClick={() => handleRemoveMember(member)} />
        </div>
      </div>
    </div>
  );
}

export default Members;
