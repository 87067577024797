/* eslint-disable radix */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { noOfbathrooms } from '../../constants';
import ErrorContainer from '../ErrorContainer';
import CheckBox from '../CheckBox';
import SelectField from '../SelectField';
import DraftButton from '../common/DraftButton';

function ParkingAndGarageForm({
  expandAll,
  collapseAll,
  methods,
  errors,
  handelDraftSubmit,
  data,
  property_type,
  setunderground_spots_mandatory,
  setoutdoor_spots_mandatory,
  setbox_parking_mandatory,
  language_selected,
  scrollState,
  scrollTo,
  noScroll,
}) {
  const { t } = useTranslation();
  const [expended, setExpended] = useState(false);
  const { control } = useFormContext();
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    box_parking_number,
    box_parking_included_in_price,
    outdoor_spots_included_in_price,
    underground_spots_included_in_price,
    box_parking_price_per_parking,
    outdoor_spots_number,
    outdoor_spots_price_per_parking,
    underground_spots_number,
    underground_spots_price_per_parking,
  } = methods.watch();

  useEffect(() => {
    if (data?.parking) {
      methods?.setValue('box_parking_mandatory', data?.parking?.[0]?.box_parking_mandatory);
      methods?.setValue('outdoor_spots_mandatory', data?.parking?.[0]?.outdoor_spots_mandatory);
      methods?.setValue(
        'underground_spots_mandatory',
        data?.parking?.[0]?.underground_spots_mandatory
      );
      methods.setValue(
        'underground_spots_price_per_parking',
        data?.parking?.[0]?.underground_spots_price_per_parking || ''
      );
      methods.setValue(
        'outdoor_spots_price_per_parking',
        data?.parking?.[0]?.outdoor_spots_price_per_parking || ''
      );
      methods.setValue(
        'box_parking_included_in_price',
        data?.parking?.[0]?.box_parking_included_in_price === 'true'
          ? data?.parking?.[0]?.box_parking_included_in_price
          : parseInt(data?.parking?.[0]?.box_parking_included_in_price)
      );
      methods.setValue(
        'outdoor_spots_included_in_price',
        data?.parking?.[0]?.outdoor_spots_included_in_price === 'true'
          ? data?.parking?.[0]?.outdoor_spots_included_in_price
          : parseInt(data?.parking?.[0]?.outdoor_spots_included_in_price)
      );
      methods.setValue(
        'underground_spots_included_in_price',
        data?.parking?.[0]?.underground_spots_included_in_price === 'true'
          ? data?.parking?.[0]?.underground_spots_included_in_price
          : parseInt(data?.parking?.[0]?.underground_spots_included_in_price)
      );
      methods.setValue(
        'box_parking_price_per_parking',
        data?.parking?.[0]?.box_parking_price_per_parking || ''
      );
      methods.setValue('box_parking_number', data?.parking?.[0]?.box_parking_number);
      methods.setValue('outdoor_spots_number', data?.parking?.[0]?.outdoor_spots_number);
      methods.setValue('underground_spots_number', data?.parking?.[0]?.underground_spots_number);
    }
  }, [data?.parking]);

  useEffect(() => {
    if (expandAll) {
      setExpended(true);
    }
  }, [expandAll]);
  useEffect(() => {
    if (collapseAll) {
      setExpended(false);
    }
  }, [collapseAll]);

  useEffect(() => {
    if (
      !box_parking_number &&
      !box_parking_price_per_parking &&
      !outdoor_spots_number &&
      !outdoor_spots_price_per_parking &&
      !underground_spots_number &&
      !underground_spots_price_per_parking
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [
    box_parking_number,
    box_parking_price_per_parking,
    outdoor_spots_number,
    outdoor_spots_price_per_parking,
    underground_spots_number,
    underground_spots_price_per_parking,
  ]);

  return (
    <div className="border-2  rounded-lg   overflow-hidden">
      <Accordion
        className="border-themeGray"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: '0',
          border: 'solid 2px #A6A6A6',
          borderRadius: '10px !important',
        }}
        expanded={expended}
        onChange={() => {
          setExpended(!expended);
        }}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          {expended ? (
            <FaMinus className="text-primary text-2xl my-auto" />
          ) : (
            <FaPlus className="text-primary text-2xl my-auto" />
          )}
          <div className="text-primary text-2xl font-extrabold px-3">{t('Parking/Garage')}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col px-2 md:px-10 pb-4">
            <div className="grid md:grid-cols-3 gap-y-6 gap-x-4 md:gap-x-12">
              <div className=" w-full">
                <SelectField
                  title={t('Box parking (Number)')}
                  options={noOfbathrooms}
                  id="box_parking_number"
                  methods={methods}
                  setbox_parking_mandatory={setbox_parking_mandatory}
                  box_parking_number={box_parking_number}
                  defaultValue={data?.parking?.[0]?.box_parking_number?.toLocaleString('en-IN')}
                />
                <ErrorContainer errMsg={errors?.box_parking_number?.message} />
                <div className="mt-2 sm_ss:text-xs sm_s:text-xs">
                  <CheckBox
                    value={Boolean(true)}
                    id="box_parking_included_in_price"
                    name="box_parking_included_in_price"
                    label={t('Included in price')}
                    // checked={
                    //   data?.parking?.[0]?.box_parking_included_in_price === '0' ? false : true
                    // }
                  />
                </div>
                {box_parking_included_in_price !== 'true' && (
                  <div className="sm_ss:w-full sm_s:w-full mt-6">
                    <div className="sm_ss:w-full sm_s:w-full">
                      <div
                        ref={scrollState === 'box_parking_price_per_parking' ? scrollTo : noScroll}
                        className="flex flex-col flex-1 relative sm_ss:pr-0 sm_ss:"
                      >
                        <span className="text-sectext mb-2 ">
                          {property_type === 'sell' ? t('Price / per parking') : t('Monthly rent')}
                        </span>
                        <FormControl variant="outlined">
                          <OutlinedInput
                            type="number"
                            size="small"
                            className="bg-white sm_ss:text-xs sm_s:text-xs"
                            id="box_parking_price_per_parking"
                            {...control.register('box_parking_price_per_parking')}
                            endAdornment={<InputAdornment position="end">CHF</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                              min: 1,
                            }}
                            defaultValue={
                              data ? data?.parking?.[0]?.box_parking_price_per_parking : ''
                            }
                          />
                        </FormControl>
                        <ErrorContainer
                          errMsg={t(`${errors?.box_parking_price_per_parking?.message || ''}`)}
                        />
                      </div>
                    </div>
                    <div
                      className="flex flex-col pt-5 sm_ss:text-xs sm_s:text-xs"
                      name="box_parking_mandatory"
                    >
                      <div className="flex items-center py-2">
                        <input
                          type="radio"
                          className="h-5 w-5 mr-2"
                          id="box_parking_mandatory"
                          name="box_parking_mandatory"
                          value="mandatory"
                          onChange={(e) => {
                            setbox_parking_mandatory(e.target.value);
                            methods?.setValue('box_parking_mandatory', e.target.value);
                          }}
                          checked={methods.getValues('box_parking_mandatory') === 'mandatory'}
                          defaultChecked={data?.parking?.[0]?.box_parking_mandatory?.includes(
                            'mandatory'
                          )}
                        />
                        <label htmlFor="box-mandatory">{t('Mandatory')}</label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          className="h-5 w-5 mr-2"
                          id="box_parking_mandatory"
                          name="box_parking_mandatory"
                          value="optional"
                          onChange={(e) => {
                            setbox_parking_mandatory(e.target.value);
                            methods?.setValue('box_parking_mandatory', e.target.value);
                          }}
                          checked={methods.getValues('box_parking_mandatory') === 'optional'}
                          defaultChecked={data?.parking?.[0]?.box_parking_mandatory?.includes(
                            'optional'
                          )}
                        />
                        <label htmlFor="box-optional">{t('Optional')}</label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full sm_ss:text-xs sm_s:text-xs">
                <SelectField
                  title={t('Outdoor spots (Number)')}
                  options={noOfbathrooms}
                  id="outdoor_spots_number"
                  methods={methods}
                  outdoor_spots_number={outdoor_spots_number}
                  setoutdoor_spots_mandatory={setoutdoor_spots_mandatory}
                  defaultValue={data?.parking?.[0]?.outdoor_spots_number?.toLocaleString('en-IN')}
                />
                <ErrorContainer errMsg={errors?.outdoor_spots_number?.message} />
                <div className="mt-2 sm_ss:text-xs sm_s:text-xs">
                  <CheckBox
                    value={Boolean(true)}
                    id="outdoor_spots_included_in_price"
                    name="outdoor_spots_included_in_price"
                    label={t('Included in price')}
                  />
                </div>
                {outdoor_spots_included_in_price !== 'true' && (
                  <div className="sm_ss:w-full sm_s:w-full mt-6">
                    <div className="sm_ss:w-full sm_s:w-full">
                      <div
                        className="flex flex-col flex-1 relative sm_ss:pr-0 sm_ss:"
                        ref={
                          scrollState === 'outdoor_spots_price_per_parking' ? scrollTo : noScroll
                        }
                      >
                        <span className="text-sectext mb-2 sm_ss:text-xs sm_s:text-xs">
                          {property_type === 'sell' ? t('Price / per parking') : t('Monthly rent')}
                        </span>
                        <FormControl variant="outlined">
                          <OutlinedInput
                            type="number"
                            size="small"
                            className="bg-white sm_ss:text-xs sm_s:text-xs"
                            id="outdoor_spots_price_per_parking"
                            // value={data ? data?.parking?.[0]?.outdoor_spots_price_per_parking : ''}
                            {...control.register('outdoor_spots_price_per_parking')}
                            endAdornment={<InputAdornment position="end">CHF</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                              min: 1,
                            }}
                            defaultValue={data?.parking?.[0]?.outdoor_spots_price_per_parking}
                          />
                        </FormControl>

                        <ErrorContainer
                          errMsg={t(`${errors?.outdoor_spots_price_per_parking?.message || ''}`)}
                        />
                      </div>
                    </div>
                    <div
                      className="flex flex-col pt-5 sm_ss:text-xs sm_s:text-xs"
                      name="outdoor_spots_mandatory"
                    >
                      <div className="flex items-center py-2">
                        <input
                          type="radio"
                          className="h-5 w-5 mr-2"
                          id="outdoor_spots_mandatory"
                          name="outdoor_spots_mandatory"
                          value="mandatory"
                          onChange={(e) => {
                            setoutdoor_spots_mandatory(e.target.value);
                            methods?.setValue('outdoor_spots_mandatory', e.target.value);
                          }}
                          checked={methods.getValues('outdoor_spots_mandatory') === 'mandatory'}
                          defaultChecked={data?.parking?.[0]?.outdoor_spots_mandatory?.includes(
                            'mandatory'
                          )}
                        />
                        <label htmlFor="mandatory">{t('Mandatory')}</label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          className="h-5 w-5 mr-2"
                          id="outdoor_spots_mandatory"
                          name="outdoor_spots_mandatory"
                          value="optional"
                          onChange={(e) => {
                            setoutdoor_spots_mandatory(e.target.value);
                            methods?.setValue('outdoor_spots_mandatory', e.target.value);
                          }}
                          checked={methods.getValues('outdoor_spots_mandatory') === 'optional'}
                          defaultChecked={data?.parking?.[0]?.outdoor_spots_mandatory?.includes(
                            'optional'
                          )}
                        />
                        <label htmlFor="optional">{t('Optional')}</label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="sm_ss:w-full sm_s:w-full sm_ss:text-xs sm_s:text-xs">
                <SelectField
                  title={t('Underground spot (Number)')}
                  options={noOfbathrooms}
                  id="underground_spots_number"
                  methods={methods}
                  underground_spots_number={underground_spots_number}
                  setunderground_spots_mandatory={setunderground_spots_mandatory}
                  defaultValue={data?.parking?.[0]?.underground_spots_number?.toLocaleString('en')}
                />
                {errors.underground_spots_no && (
                  <ErrorContainer errMsg={errors?.underground_spots_no.message} />
                )}
                <ErrorContainer errMsg={errors?.underground_spots_number?.message} />
                <div className="mt-2 sm_ss:text-xs sm_s:text-xs">
                  <CheckBox
                    value={Boolean(true)}
                    id="underground_spots_included_in_price"
                    name="underground_spots_included_in_price"
                    label={t('Included in price')}
                  />
                </div>
                {underground_spots_included_in_price !== 'true' && (
                  <div className="sm_ss:w-full sm_s:w-full mt-6">
                    <div className="sm_ss:w-full sm_s:w-full">
                      <div
                        className="flex flex-col flex-1 relative sm_ss:pr-0 sm_ss:"
                        ref={
                          scrollState === 'underground_spots_price_per_parking'
                            ? scrollTo
                            : noScroll
                        }
                      >
                        <span className="text-sectext mb-2 sm_ss:text-xs sm_s:text-xs">
                          {property_type === 'sell' ? t('Price / per parking') : t('Monthly rent')}
                        </span>
                        <FormControl variant="outlined">
                          <OutlinedInput
                            type="number"
                            size="small"
                            className="bg-white sm_ss:text-xs sm_s:text-xs"
                            id="underground_spots_price_per_parking"
                            // value={
                            //   data ? data?.parking?.[0]?.underground_spots_price_per_parking : ''
                            // }
                            {...control.register('underground_spots_price_per_parking')}
                            endAdornment={<InputAdornment position="end">CHF</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                              min: 1,
                            }}
                            defaultValue={data?.parking?.[0]?.underground_spots_price_per_parking}
                          />
                        </FormControl>
                        <ErrorContainer
                          errMsg={t(
                            `${errors?.underground_spots_price_per_parking?.message || ' '}`
                          )}
                        />
                      </div>
                    </div>
                    <div
                      className="flex flex-col pt-5 sm_ss:text-xs sm_s:text-xs"
                      name="fixed_renew"
                    >
                      <div className="flex items-center py-2">
                        <input
                          type="radio"
                          className="h-5 w-5 mr-2"
                          id="out_mandatory"
                          name="underground_spots_mandatory"
                          value="mandatory"
                          onChange={(e) => {
                            setunderground_spots_mandatory(e.target.value);
                            methods?.setValue('underground_spots_mandatory', e.target.value);
                          }}
                          defaultChecked={data?.parking?.[0]?.underground_spots_mandatory?.includes(
                            'mandatory'
                          )}
                          checked={methods.getValues('underground_spots_mandatory') === 'mandatory'}
                        />
                        <label htmlFor="out_mandatory">{t('Mandatory')}</label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          className="h-5 w-5 mr-2"
                          id="out_optional"
                          name="underground_spots_mandatory"
                          value="optional"
                          onChange={(e) => {
                            setunderground_spots_mandatory(e.target.value);
                            methods?.setValue('underground_spots_mandatory', e.target.value);
                          }}
                          defaultChecked={data?.parking?.[0]?.underground_spots_mandatory?.includes(
                            'optional'
                          )}
                          checked={methods.getValues('underground_spots_mandatory') === 'optional'}
                        />

                        <label htmlFor="out_optional">{t('Optional')}</label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div />
            </div>
            <DraftButton
              handelDraftSubmit={() => {
                handelDraftSubmit('draft', setIsLoading);
              }}
              disable={disable}
              language_selected={language_selected}
              draftLoading={isLoading}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ParkingAndGarageForm;
