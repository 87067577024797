/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdModeEditOutline, MdOutlineErrorOutline } from 'react-icons/md';
import { CircularProgress } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import Cookies from 'js-cookie';
import 'swiper/css';
import { RxCross2 } from 'react-icons/rx';
import AxiosInstance from '../Axios';
import PageHeading from '../components/common/PageHeading';
import image from '../assets/images/No_image_available.svg.png';
import Card from '../components/common/Card';
import API from '../constants/API';
import { appUserRoles, numbers, renewablePeriod, rolesConstant } from '../constants';
import { appRoutes } from '../constants/routeConstants/Routes';

import './terms.css';

// Import Swiper styles
import 'swiper/css/navigation';
import Capitalize, { Decrypt, numberWithCommas } from '../util';

function PropertyDetails() {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  const { id } = useParams();
  const cookie = Cookies.get('i18next');
  const [propertyData, setPropertyData] = useState([]);
  const [userProfile, setUserProfile] = useState();
  const [galleryView, setGalleryView] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [floor, setFloor] = useState();
  const [Period, setPeriod] = useState();
  const [role, setRole] = useState('');
  const [showNotFoundText, setShowNotFoundText] = useState(false);
  const decryptId = Decrypt(id);
  const propertySeenMethod = async (userId) => {
    if (userId !== undefined) {
      await AxiosInstance.post(API.SEEN_PROPERTY, {
        property_id: decryptId,
        request_id: `${userId}`,
      }).then((response) => {
        if (response) {
          // console.log('seen response', response);
        }
      });
    }
  };
  console.log('propertyData?.kitchen', propertyData?.parking);
  const getUserDetails = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.data?.result?.access_token;
    if (token) {
      const userProfileData = async () => {
        const res = await AxiosInstance.post(API.GETPROFILE);
        const userId = res.data.result?.latest_request?.id;
        propertySeenMethod(userId);
      };
      userProfileData();
    }
  };

  const DeCryptId = Decrypt(id);

  useEffect(() => {
    const propertiesData = async () => {
      try {
        // prettier-ignore
        const res = await AxiosInstance.get(`${API.PROPERTY}/${DeCryptId}`);
        setPropertyData(res.data.result);
      } catch (err) {
        console.log(err.response);
      }
    };
    propertiesData();
    getUserDetails();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (Object.keys(propertyData).length > 0) {
        setShowNotFoundText(false);
      } else {
        setShowNotFoundText(true);
      }
    }, 60000);
  }, [propertyData]);

  useLayoutEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.data?.result?.access_token;
    if (token) {
      const userProfileData = async () => {
        const res = await AxiosInstance.post(API.GETPROFILE);
        setUserProfile(res.data.result);
      };
      userProfileData();
    }
  }, []);

  useEffect(() => {
    const container = document.getElementById('mapcontainer');
    if (container) {
      const address = propertyData?.zip_code
        ? `${propertyData?.street},${propertyData?.nb},${propertyData?.zip_code},${propertyData?.city}`
        : 'Zurich';
      // eslint-disable-next-line no-undef, no-unused-vars
      const Map = new SearchChMap({ center: `${address}` });
    }
  }, [propertyData]);

  useEffect(() => {
    if (propertyData?.floor) {
      numbers.forEach((el) => {
        if (el.value === propertyData?.floor) {
          setFloor(el.label);
        }
      });
    }
    if (propertyData?.renewable_period) {
      renewablePeriod.forEach((el) => {
        if (el.value === propertyData?.renewable_period) {
          setPeriod(t(`${el.label}`));
        }
      });
    }
    rolesConstant.forEach((element) => {
      if (element.value === propertyData?.role) {
        if (cookie === 'en') {
          setRole(element.label);
        } else {
          setRole(element.frValue);
        }
      }
    });
  }, [propertyData?.floor, propertyData?.renewable_period, propertyData?.role, cookie]);

  const openGallery = (picture) => {
    if (picture?.id) {
      const data = [picture, ...propertyData?.pictures];
      const filterData = data?.filter((img, index) => data?.indexOf(img) === index);
      setGalleryImages(filterData);
    } else {
      setGalleryImages([...propertyData?.pictures]);
    }

    if (propertyData?.pictures?.length > 1) {
      setGalleryView(!galleryView);
    }
  };

  const organisation_role = JSON.parse(localStorage.getItem('user'))?.data?.result
    ?.organisation_role;

  const propertyType = propertyData?.property_type === 'rent' ? 'rent' : 'sell';
  const housing_type = Capitalize(propertyData?.housing_type);

  return (
    <div className="w-full  smx:p-2  my-20 sm_s:w-full ">
      {Object.keys(propertyData).length > 0 ? (
        <div className="w-full flex flex-col justify-center items-center">
          <div className="flex flex-col lg:w-[900px] smx:w-full md:w-full sm_s:w-full md:px-7 sm_ss:w-full sm_ss:px-2 justify-center">
            <div className="flex items-center sm_s:flex-col sm_ss:flex-col sm_s:px-3 sm_ss:px-3">
              {userProfile?.role === 'provider' && (
                <PageHeading className="sm_s:pb-5 sm_ss:pb-5 sm_ss:text-lg sm_s:text-lg sm_s:text-center sm_ss:text-center">
                  {t('Property to')} {t(`${propertyType}`)} {t('preview')}
                </PageHeading>
              )}

              <Link
                to={
                  userProfile?.role === appUserRoles.provider
                    ? appRoutes.DASHBOARD_PROVIDER
                    : appRoutes.DASHBOARD_SEEKER
                }
                className="my-3 ml-4 text-primary sm_ss:hidden sm_s:hidden"
              >
                {'< '}
                {t('Back')}
              </Link>
            </div>

            <div className="flex flex-col ">
              <div className="flex justify-between items-center">
                <div>
                  {userProfile?.role === appUserRoles.provider &&
                    (organisation_role === 'admin' || organisation_role === undefined) && (
                      //         <div className="flex lg:hidden    top-0 -right-52 ">
                      //           <div
                      //             className={`my-auto uppercase flex items-center justify-center h-7 w-auto px-2 text-white text-sm md:text-md text-center font-bold rounded-md ${
                      //               propertyData?.request_status === 'pending' && 'bg-[#6F6F6F]'
                      //             }
                      // ${propertyData?.request_status === 'active' && 'bg-[#73C768]'}
                      //  ${propertyData?.request_status === 'draft' && 'bg-[#E6BC2E]'}
                      //  ${propertyData?.request_status === 'rejected' && 'bg-[#D13838]'}
                      //  ${propertyData?.request_status === 'expired' && 'bg-[#C9C9C9]'}
                      //  ${propertyData?.request_status === 'archived' && 'bg-[#C9C9C9]'}
                      //   ${propertyData?.request_status === 'hold' && 'bg-[#6F6F6F]'}
                      // `}
                      //           >
                      //             {propertyData?.request_status === 'pending'
                      //               ? t('under review')
                      //               : t(`${propertyData?.request_status}`)}
                      //           </div>
                      //         </div>
                      <div className="flex lg:hidden sm_xxx:block top-0 -right-52">
                        <div
                          className={`my-auto uppercase flex items-center justify-center h-7 w-auto px-2 text-white text-sm md:text-md text-center font-bold rounded-md ${
                            propertyData?.request_status === 'pending' && 'bg-[#6F6F6F]'
                          }
    ${propertyData?.request_status === 'active' && 'bg-[#73C768]'}
    ${propertyData?.request_status === 'draft' && 'bg-[#E6BC2E]'}
    ${propertyData?.request_status === 'rejected' && 'bg-[#D13838]'}
    ${propertyData?.request_status === 'expired' && 'bg-[#C9C9C9]'}
    ${propertyData?.request_status === 'archived' && 'bg-[#C9C9C9]'}
    ${propertyData?.request_status === 'hold' && 'bg-[#6F6F6F]'}
    `}
                        >
                          {propertyData?.request_status === 'pending'
                            ? t('under review')
                            : t(`${propertyData?.request_status}`)}
                        </div>
                      </div>
                    )}
                </div>
                <div>
                  {userProfile?.role === appUserRoles.provider &&
                    (organisation_role === 'admin' || organisation_role === undefined) && (
                      <Link
                        to={`/edit-property?id=${id}`}
                        className="flex justify-end sm_s:justify-between sm_ss:justify-between pr-5"
                      >
                        <Link
                          to={
                            userProfile?.role === appUserRoles.provider
                              ? appRoutes.DASHBOARD_PROVIDER
                              : appRoutes.DASHBOARD_SEEKER
                          }
                          className="my-3 ml-4 text-primary md:hidden lg:hidden"
                        >
                          {'< '}
                          {t('Back')}
                        </Link>
                        <div className="text-primary flex my-2 cursor-pointer">
                          <span
                            className={`flex items-center ${
                              (propertyData?.request_status === 'pending' ||
                                propertyData?.request_status === 'expired' ||
                                propertyData?.request_status === 'rejected') &&
                              'hidden'
                            }`}
                          >
                            <MdModeEditOutline className="mr-1" size="25px" /> {t('Edit')}
                          </span>
                        </div>
                      </Link>
                    )}
                </div>
              </div>
              {/* <MdModeEdit className="text-[20px]" /> */}
              <Card className="w-full relative">
                <Swiper
                  slidesPerView={1}
                  navigation
                  modules={[Navigation, Autoplay]}
                  pagination={{ clickable: true }}
                  autoplay={{
                    delay: 2500,
                  }}
                  loop={propertyData?.pictures?.length > 1}
                  className="cursor-pointer"
                >
                  {propertyData?.pictures?.length ? (
                    propertyData?.pictures?.map((picture, index) => {
                      return (
                        <div
                          className="rounded-t-2xl overflow-hidden  w-full object-contain h-[500px] sm_s:h-[300px]"
                          key={index}
                        >
                          <SwiperSlide className="w-full h-full overflow-hidden" key={index}>
                            <img
                              onClick={() => openGallery(picture)}
                              className="rounded-t-2xl w-full h-[400px] object-cover"
                              src={`${API.BASE_URL + picture?.image_path}`}
                              alt="green iguana"
                            />
                          </SwiperSlide>
                        </div>
                      );
                    })
                  ) : (
                    <SwiperSlide>
                      <img
                        className="rounded-t-2xl w-full h-[400px] object-cover"
                        src={`${API.BASE_URL}/images/icons/default-property.png`}
                        alt="green iguana"
                      />
                    </SwiperSlide>
                  )}
                </Swiper>
                <div className="px-7 sm_s:px-5 pb-16">
                  <div className="mb-8 mt-2 flex items-center justify-between">
                    {propertyData?.pictures?.length > 1 ? (
                      <p className="cursor-pointer text-[#004993]" onClick={openGallery}>
                        {t('Open gallery')} {'>'}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>

                  <div>
                    {t('Reference')} {propertyData?.reference_code}
                  </div>
                  <div className="flex justify-between w-full text-[#343437] py-4">
                    <div className="text-lg font-bold">
                      {t(`${housing_type}`)} {t('to')} {t(`${propertyData?.property_type}`)},
                      {propertyData?.surface ? ` ${propertyData?.surface} m2` : ''}
                      <br />
                      {propertyData?.number_of_rooms}{' '}
                      {propertyData?.number_of_rooms !== 'Studio' && t('room(s)')}
                      {propertyData?.number_of_rooms !== 'Studio' && propertyData?.kitchen
                        ? `(${t('Kitchen included')})`
                        : ''}
                    </div>
                    <div>
                      <div className="text-lg font-bold">
                        {propertyData?.overall_rent
                          ? `CHF ${
                              propertyData?.overall_rent === null
                                ? ''
                                : propertyData?.overall_rent.toLocaleString('en')
                            }.00`
                          : `CHF ${
                              propertyData?.selling_price === null
                                ? ' '
                                : propertyData?.selling_price.toLocaleString('en')
                            }.00`}
                      </div>
                      <div className="text-sm">{t(propertyData?.monthly_charge_option)}</div>
                    </div>
                  </div>
                  <div>
                    {propertyData?.street && `${propertyData?.street} ${propertyData?.nb}`} <br />
                    {propertyData?.street && `${propertyData?.zip_code} ${propertyData?.city}`}
                    <br />
                    {t(`${propertyData?.country || ''}`)}
                  </div>
                  <div className="w-[150px] mb-8">{propertyData?.[id - 1]?.tittle}</div>
                  <div className="grid grid-cols-2 w-auto md:w-[400px] mb-2">
                    <div className="w-[200px]">
                      {/* {propertyData?.property_type === 'rent'
                        ? propertyData?.renewable_period
                          ? t('Renewable period')
                          : t('Until')
                        : ''} */}
                      {t('Start')}
                    </div>
                    <div>{propertyData?.availability}</div>
                    {propertyData?.renewable_period ? (
                      <>
                        <div className="w-[200px]">{t('Minimal Rent')}</div>
                        <div>{t(`${propertyData?.renewable_period}`)}</div>
                      </>
                    ) : (
                      <>
                        <div className="w-[200px]">{t('Until (end)')}</div>
                        <div>{propertyData?.until_end}</div>
                      </>
                    )}
                    {/*                     
                    <div>
                      {propertyData?.renewable_period
                        ? propertyData?.renewable_period
                        : propertyData?.until_end}
                    </div> */}
                  </div>

                  {propertyData?.zip_code && propertyData?.street ? (
                    <div
                      id="mapcontainer"
                      style={{ width: '100%', height: '300px', borderRadius: '7px', zIndex: '1' }}
                    />
                  ) : null}
                  <div className="w-full h-full flex mt-2 sm_s:items-start sm_ss:items-start py-3 sm_s:flex-col sm_ss:flex-col">
                    <div className="flex flex-col flex-1 ">
                      <span className="font-[900] my-4">{t('Details')}</span>
                      {propertyData?.surface && (
                        <Details
                          className="md:h-10 h-[44px]"
                          title={t('surface')}
                          value={`${propertyData?.surface} m2`}
                        />
                      )}
                      <Details
                        className="md:h-10 h-[44px]"
                        title={t('furnituree')}
                        value={t(`${propertyData?.furniture || ''}`)}
                      />
                      {propertyData?.housing_type !== 'room' && (
                        <>
                          <Details
                            className="md:h-10 h-[44px]"
                            title={t('nb of rooms')}
                            value={propertyData?.number_of_rooms}
                          />
                          <Details
                            className="md:h-10 h-[44px]"
                            title={t('nb of bedrooms')}
                            value={propertyData?.number_of_bedrooms}
                          />
                          <Details
                            className="md:h-10 h-[44px]"
                            title={t('nb of bathrooms')}
                            value={propertyData?.number_of_bathrooms}
                          />
                          {propertyType === 'sell' ? (
                            <Details
                              className="md:h-10 h-[44px]"
                              title={t('kitchenNon')}
                              value={t(`${propertyData?.kitchen || ''} m2 `)}
                            />
                          ) : (
                            <Details
                              className="md:h-10 h-[44px]"
                              title={t('kitchenNon')}
                              value={t(`${propertyData?.kitchen || ''}`)}
                            />
                          )}

                          <Details
                            className="md:h-10 h-[44px]"
                            title={t('kitchen_style')}
                            value={
                              propertyData?.kitchen_style === 'open'
                                ? t('American/open')
                                : t(propertyData?.kitchen_style)
                            }
                          />
                        </>
                      )}
                      {propertyData?.amenties?.length > 0 && (
                        <Details
                          className="md:h-10 h-[44px] flex items-start justify-start"
                          title={t('amenties')}
                          value={propertyData?.amenties?.map((val, index) => {
                            return (
                              <div className="h-full" key={index}>
                                <p className="mr-2 capitalize">{t(`${Capitalize(val.name)}`)}</p>
                              </div>
                            );
                          })}
                        />
                      )}
                      {floor && (
                        <Details
                          className="md:h-10 h-[44px]"
                          title={t('floor')}
                          value={t(`${floor}`)}
                        />
                      )}
                    </div>
                    <div className="flex flex-col flex-1 md:flex-auto md:w-[20.5rem]">
                      {(propertyData?.parking?.[0]?.box_parking_number !== 0 ||
                        propertyData?.parking?.[0]?.outdoor_spots_number !== 0 ||
                        propertyData?.parking?.[0]?.underground_spots_number !== 0) && (
                        <span className="font-[900] my-3">{t('Parking')}</span>
                      )}

                      {propertyData?.parking?.[0]?.box_parking_number !== 0 && (
                        <Details
                          className="md:h-10 h-[44px]"
                          title={t('Box parking (Number)')}
                          value={propertyData?.parking?.map((item, index) => {
                            return (
                              <div key={index}>
                                {item.box_parking_number &&
                                  `${item.box_parking_number}
                              ${
                                item?.box_parking_included_in_price === 'true'
                                  ? `(${t('includedd')})`
                                  : ``
                              }
                            ${
                              item?.box_parking_mandatory !== null
                                ? `(${t(item?.box_parking_mandatory)})`
                                : ''
                            }
                              
                              ${item?.box_parking_price_per_parking ? 'CHF' : ''}
                              ${
                                item?.box_parking_price_per_parking
                                  ? `${parseInt(
                                      item?.box_parking_price_per_parking,
                                      10
                                    ).toLocaleString('en')}.00`
                                  : ''
                              }
                              ${item?.box_parking_price_per_parking ? `/${t('place')}` : ''} `}
                              </div>
                            );
                          })}
                        />
                      )}
                      {propertyData?.parking?.[0]?.outdoor_spots_number !== 0 && (
                        <Details
                          className="md:h-10 h-[44px]"
                          title={t('Outdoor spots (Number)')}
                          value={propertyData?.parking?.map((item) => {
                            return (
                              <div>
                                {item.outdoor_spots_number &&
                                  `${item?.outdoor_spots_number} ${
                                    item?.outdoor_spots_included_in_price === 'true'
                                      ? `(${t('includedd')})`
                                      : ``
                                  }
                               ${
                                 item?.outdoor_spots_mandatory !== null
                                   ? `(${t(item?.outdoor_spots_mandatory)})`
                                   : ''
                               }
                               ${`${item?.outdoor_spots_price_per_parking ? 'CHF' : ''} ${
                                 item?.outdoor_spots_price_per_parking
                                   ? `${parseInt(
                                       item?.outdoor_spots_price_per_parking,
                                       10
                                     ).toLocaleString('en')}.00`
                                   : ''
                               }`}  ${
                                    item?.outdoor_spots_price_per_parking ? `/${t('place')}` : ''
                                  }`}
                              </div>
                            );
                          })}
                        />
                      )}
                      {propertyData?.parking?.[0]?.underground_spots_number !== 0 && (
                        <Details
                          className="md:h-10 h-[44px]"
                          title={t('Underground spot (Number)')}
                          value={propertyData?.parking?.map((item) => {
                            return (
                              <div>
                                {item.underground_spots_number &&
                                  `${item?.underground_spots_number}
                               ${
                                 item?.underground_spots_included_in_price === 'true'
                                   ? `(${t('includedd')})`
                                   : ``
                               } ${`${
                                    item?.underground_spots_mandatory
                                      ? `(${t(item?.underground_spots_mandatory)})`
                                      : ''
                                  }`} ${`${
                                    item?.underground_spots_price_per_parking ? 'CHF' : ''
                                  } ${
                                    item?.underground_spots_price_per_parking
                                      ? `${parseInt(
                                          item?.underground_spots_price_per_parking,
                                          10
                                        ).toLocaleString('en')}.00`
                                      : ''
                                  }`}
                               ${
                                 item?.underground_spots_price_per_parking ? `/${t('place')}` : ''
                               }`}
                              </div>
                            );
                          })}
                        />
                      )}
                      {/* {(propertyData?.parking?.[0]?.box_parking_number ||
                        propertyData?.parking?.[0]?.outdoor_spots_number ||
                        propertyData?.parking?.[0]?.underground_spots_number) && (
                        <div className="h-11" />
                      )} */}
                      {(propertyData?.parking?.[0]?.box_parking_number !== 0 ||
                        propertyData?.parking?.[0]?.outdoor_spots_number !== 0 ||
                        propertyData?.parking?.[0]?.underground_spots_number !== 0) && (
                        <div className="h-11" />
                      )}
                      <span className="font-[900] my-3">{t('Price')}</span>
                      {propertyData?.selling_price && propertyData?.property_type === 'sell' ? (
                        <Details
                          className="md:h-10 h-[44px]"
                          title={t('Selling price')}
                          value={
                            propertyData?.selling_price &&
                            `CHF ${numberWithCommas(
                              propertyData?.selling_price.toLocaleString('en')
                            )}.00`
                          }
                        />
                      ) : (
                        <>
                          <Details
                            className="md:h-10 h-[44px]"
                            title={t('Gross rent / month')}
                            value={
                              propertyData?.overall_rent
                                ? `${numberWithCommas(
                                    propertyData?.overall_rent.toLocaleString('en')
                                  )}.00`
                                : ''
                            }
                          />
                          <Details
                            className="md:h-10 h-[44px]"
                            title={t('Charges / month')}
                            value={
                              propertyData?.rent_charges
                                ? `${numberWithCommas(
                                    propertyData?.rent_charges.toLocaleString('en')
                                  )}.00`
                                : ''
                            }
                          />
                          <Details
                            className="md:h-10 h-[44px]"
                            title={t('Net rent /month')}
                            value={
                              propertyData?.net_rent
                                ? `${numberWithCommas(
                                    propertyData?.net_rent.toLocaleString('en')
                                  )}.00`
                                : '0.00'
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {cookie === 'en' ? (
                    <p className="text-black text-base">{propertyData?.english_description}</p>
                  ) : (
                    <p className="text-black text-base">{propertyData?.french_description}</p>
                  )}
                </div>
                {userProfile?.role === appUserRoles.provider &&
                  (organisation_role === 'admin' || organisation_role === undefined) && (
                    <div className="flex smx:hidden mb-5 sm_xx:hidden sm_xxx:hidden absolute top-0 -right-52 sm_s:hidden sm_ss:hidden">
                      <div
                        className={`my-auto uppercase flex items-center justify-center h-7 w-auto px-2 text-white text-sm md:text-md text-center font-bold rounded-md ${
                          propertyData?.request_status === 'pending' && 'bg-[#6F6F6F]'
                        }
              ${propertyData?.request_status === 'active' && 'bg-[#73C768]'} 
               ${propertyData?.request_status === 'draft' && 'bg-[#E6BC2E]'} 
               ${propertyData?.request_status === 'rejected' && 'bg-[#D13838]'}
               ${propertyData?.request_status === 'expired' && 'bg-[#C9C9C9]'}
               ${propertyData?.request_status === 'archived' && 'bg-[#C9C9C9]'}
                ${propertyData?.request_status === 'hold' && 'bg-[#6F6F6F]'}
              `}
                      >
                        {propertyData?.request_status === 'pending'
                          ? t('under review')
                          : t(`${propertyData?.request_status}`)}
                      </div>
                    </div>
                  )}
              </Card>
            </div>
            <Card className="w-full my-4 p-6 flex flex-col">
              <span className="font-[900]">{t('To know more and to organise a visit')}</span>
              <div className="flex sm_ss:flex-col sm_s:flex-col mt-2">
                <div className="flex flex-col">
                  <div className="w-[300px]  flex flex-col">
                    <Text
                      className="capitalize"
                      text={
                        propertyData?.title &&
                        `${propertyData?.title} ${propertyData?.first_name} ${propertyData?.last_name}`
                      }
                    />
                    <Text
                      className="text-black font-bold capitalize"
                      text={propertyData?.company && propertyData?.company}
                    />
                    <Text className="capitalize" text={t(`${role}`)} />
                    <Text
                      className="text-blue capitalize"
                      text={propertyData?.email && propertyData?.email}
                    />
                    <Text className="text-blue" text={propertyData?.phone && propertyData?.phone} />
                  </div>
                </div>
                <div className="pl-2 sm_s:pl-0 overflow-hidden">
                  <Text className="text-black" text={propertyData?.english_comment} />
                </div>
              </div>
            </Card>
          </div>
        </div>
      ) : (
        <div>
          {showNotFoundText ? (
            <div className="h-[600px] w-full flex items-center justify-center text-lg">
              <MdOutlineErrorOutline size="30px" /> No Property found
            </div>
          ) : (
            <div className="h-[600px] w-full flex items-center justify-center text-lg">
              <CircularProgress />
            </div>
          )}
        </div>
      )}
      {galleryView === true && (
        <div className="absolute w-full flex justify-center items-center overflow-hidden h-screen md:px-7 lg:px-28 top-0 left-0 bg-[#3C3C3C] bg-opacity-80 z-[1302]">
          {/* <img
            src={cross}
            alt="cancel"
            onClick={() => setGalleryView(!galleryView)}
            className="absolute right-10 top-9 h-8 cursor-pointer text-white"
            style={{ fill: 'white' }}
          /> */}
          <div
            onClick={() => setGalleryView(!galleryView)}
            className="absolute right-10 top-9 h-8 cursor-pointer text-white"
          >
            <RxCross2 size={40} />
          </div>
          <Swiper
            slidesPerView={1}
            navigation
            modules={[Navigation, Autoplay]}
            pagination={{ clickable: true }}
            className="mt-8"
            loop={!!propertyData?.pictures?.length}
            autoplay={{
              delay: 2500,
            }}
            allowSlideNext={true}
          >
            {propertyData?.pictures ? (
              galleryImages?.map((picture, index) => {
                return (
                  <div key={index} onClick={(e) => e.stopPropagation()}>
                    <SwiperSlide key={index}>
                      <img
                        className="w-full  h-48 md:h-[600px] lg:h-[800px] object-contain rounded-2xl"
                        src={`${API.BASE_URL + picture?.image_path}`}
                        alt="green iguana"
                      />
                    </SwiperSlide>
                  </div>
                );
              })
            ) : (
              <SwiperSlide>
                <img className="w-full h-full" src={image} alt="green iguana" />
              </SwiperSlide>
            )}
          </Swiper>
        </div>
      )}
    </div>
  );
}

function Details({ title, value, className }) {
  return (
    <div className="flex items-center gap-3 md:my-1 w-full ">
      <span className={`md:w-[125px] w-[155px] lg:w-[170px]  text-black text-[16px]  ${className}`}>
        {title}
      </span>
      <div className="w-1" />
      <span className={`w-[155px] lg:w-[180px] text-black text-[16px]  ${className} `}>
        {value}
      </span>
    </div>
  );
}

function Text({ text, className }) {
  return <span className={` py-1 text-base text-black ${className}`}>{text}</span>;
}

export default PropertyDetails;
