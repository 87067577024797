/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Autocomplete,
  // CircularProgress,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { titleOptions, titleOptionsfr, userRoles, userRolesfr } from '../../constants';
import DraftButton from '../common/DraftButton';
import ErrorContainer from '../ErrorContainer';
import SelectField from '../SelectField';
import TextInput from '../TextInput';

function VisitsForm({
  expandAll,
  collapseAll,
  errors,
  scrollState,
  handelDraftSubmit,
  Data,
  setRole,
  role,
  methods,
  language_selected,
  scrollTo,
  noScroll,
}) {
  const { t } = useTranslation();
  const [expended, setExpended] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    title,
    first_name,
    last_name,
    role: Role,
    email,
    phone,
    english_comment,
  } = methods.watch();

  useEffect(() => {
    if (!title || !first_name || !last_name || !Role || !email || !phone || !english_comment) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [title, first_name, last_name, Role, email, phone, english_comment]);

  const { control } = useFormContext();

  useEffect(() => {
    if (expandAll) {
      setExpended(true);
    }
  }, [expandAll]);
  useEffect(() => {
    if (collapseAll) {
      setExpended(false);
    }
  }, [collapseAll]);

  useEffect(() => {
    if (Data?.role) {
      userRoles.forEach((element) => {
        // const selectedValue = Data?.role === 1 || Data?.role === 0 ? !!Data?.role : Data?.role;
        if (element.value === Data?.role) {
          // console.log('element', element);
          setRole(element);
        }
      });
    }
  }, [Data?.role]);

  useEffect(() => {
    if (role) {
      userRoles.forEach((element) => {
        // const selectedValue = Data?.role === 1 || Data?.role === 0 ? !!Data?.role : Data?.role;
        if (element.value === role.value) {
          setRole(element);
        }
      });
    }
  }, [role]);

  return (
    <div className="border-2  rounded-lg  overflow-hidden">
      <Accordion
        className="border-themeGray"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: '0',
          border: 'solid 2px #A6A6A6',
          borderRadius: '10px !important',
        }}
        expanded={expended}
        onChange={() => {
          setExpended(!expended);
        }}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          {expended ? (
            <FaMinus className="text-primary text-2xl my-auto" />
          ) : (
            <FaPlus className="text-primary text-2xl my-auto" />
          )}
          <div className="text-primary text-2xl font-extrabold px-3">{t('Visits')}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div
            ref={scrollState === 'title' ? scrollTo : noScroll}
            className="w-full flex flex-col px-2 md:px-5 pb-4"
          >
            <div className="md:grid lg:grid md:grid-cols-2 gap-y-6 gap-x-4 md:gap-x-12">
              <div>
                <div className=" w-full">
                  <div className="w-8/12 sm_ss:w-full sm_s:w-full">
                    <SelectField
                      title={t('Title')}
                      options={language_selected === 'en' ? titleOptions : titleOptionsfr}
                      id="title"
                      defaultValue={Data?.title}
                    />
                  </div>
                  {errors.title && <ErrorContainer errMsg={t(`${errors.title.message}`)} />}
                </div>
              </div>
              <div />
              <div ref={scrollState === 'first_name' ? scrollTo : noScroll} className=" w-full">
                <TextInput
                  text={t('RFirstname')}
                  textType="text"
                  placeholder={t('placeholderFname')}
                  name="first_name"
                  id="first_name"
                />
                {errors.first_name && <ErrorContainer errMsg={t(`${errors.first_name.message}`)} />}
              </div>
              <div ref={scrollState === 'last_name' ? scrollTo : noScroll} className=" w-full">
                <TextInput
                  text={t('RLastname')}
                  textType="text"
                  placeholder={t('placeholderLname')}
                  name="last_name"
                  id="last_name"
                />
                {errors.last_name && <ErrorContainer errMsg={t(`${errors.last_name.message}`)} />}
              </div>
              <div ref={scrollState === 'email' ? scrollTo : noScroll} className=" w-full">
                <TextInput
                  text={t('Company')}
                  textType="text"
                  placeholder={t('PlaceholderCompany')}
                  name="company"
                  id="company"
                />
                {errors.company && <ErrorContainer errMsg={t(`${errors.company.message}`)} />}
              </div>
              <div ref={scrollState === 'role' ? scrollTo : noScroll} className=" w-full">
                <div className=" sm_ss:w-full sm_s:w-full">
                  <SelectField
                    id="role"
                    options={language_selected === 'en' ? userRoles : userRolesfr}
                    title={t('Role')}
                    setRole={setRole}
                    defaultValue={Data?.role ? Data?.role : role}
                    methods={methods}
                  />
                </div>

                {errors.role && <ErrorContainer errMsg={t(`${errors.role.message}`)} />}
              </div>

              <div ref={scrollState === 'email' ? scrollTo : noScroll} className=" w-full">
                <TextInput
                  text={t('VisitEmail')}
                  textType="email"
                  placeholder={t('placeholderEmail')}
                  name="email"
                  id="email"
                />
                {errors.email && <ErrorContainer errMsg={t(`${errors.email.message}`)} />}
              </div>
              <div ref={scrollState === 'phone' ? scrollTo : noScroll} className=" w-full">
                <TextInput
                  text={t('RMobilePHN')}
                  textType="text"
                  placeholder={t('placeholderMobileNumber')}
                  name="phone"
                  id="phone"
                />
                <div className="text-textGray mt-2 sm_ss:text-xs sm_s:text-xs">
                  {t('Format: +41 22 222 22 22')}
                </div>
                {errors.phone && <ErrorContainer errMsg={t(`${errors.phone.message}`)} />}
              </div>
              <div className="col-span-2 sm_ss:text-xs sm_s:text-xs">
                {t('Visiting hours, comments')}
              </div>
              <div
                ref={scrollState === 'english_comment' ? scrollTo : noScroll}
                className="col-span-2 sm_ss:text-xs sm_s:text-xs"
              >
                <TextInput
                  // {...control.register('english_comment')}
                  className="bg-white w-full"
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  max={120}
                  name="english_comment"
                />
                <div className="text-[#A6A6A6]">{`${english_comment?.length || 0}/120 ${t(
                  'characters'
                )}`}</div>
                {errors.english_comment && (
                  <ErrorContainer errMsg={t(`${errors.english_comment.message}`)} />
                )}
              </div>
            </div>
            <div className="flex items-center">
              <DraftButton
                handelDraftSubmit={() => {
                  handelDraftSubmit('draft', setIsLoading);
                }}
                disable={disable}
                language_selected={language_selected}
                draftLoading={isLoading}
              />
              <div className="w-1" />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default VisitsForm;
