/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FaMinus, FaPlus, FaBuilding } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import AxiosInstance from '../../Axios';
import {
  language,
  languagefr,
  providerProfileSchema,
  titleOptions,
  titleOptionsfr,
} from '../../constants';
import API from '../../constants/API';
import { appRoutes } from '../../constants/routeConstants/Routes';
import { resetprofileUpdate } from '../../redux/slices/ProviderProfileUpdate';
import ErrorContainer from '../ErrorContainer';
import FieldsContainer from '../FieldsContainer';
import SelectField from '../SelectField';
import Separater from '../Separater';
import SubWrapper from '../SubWrapper';
import TextInput from '../TextInput';
import PageHeading from '../common/PageHeading';
import { updateUserInfoStart, updateUserInfoSuccess } from '../../redux/slices/UpdateUserInfo';
import { ProfileUpdate } from '../../apiCalls';
import ItemContainer from '../ItemContainer';
import CountryCodeInput from '../CountryCodeInput';

function ProviderProfileUpdate({ expandAll, collapseAll }) {
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [expended, setExpended] = useState(true);
  const [userProfile, setUserProfile] = useState();
  const [deleteProfile, setDeleteProfile] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const success = useSelector((state) => state.profileUpdate.success);
  const pending = useSelector((state) => state.profileUpdate.pending);
  const Error = useSelector((state) => state.profileUpdate.error);

  const language_selected = Cookies.get('i18next');
  const [getProviderData, setGetProviderData] = useState([]);

  const methods = useForm({
    resolver: yupResolver(providerProfileSchema),
  });
  const { errors } = methods.formState;

  useLayoutEffect(() => {
    const userProfileData = async () => {
      const res = await AxiosInstance.post(API.GETPROFILE);
      methods.reset(res.data.result);
      setGetProviderData(res.data.result);
      const number = `${res.data.result?.country_code}${res.data.result?.mobile_number}`;

      methods.setValue('mobile_number', number);
      setUserProfile(res.data.result);
    };
    userProfileData();
  }, []);

  useEffect(() => {
    if (expandAll) {
      setExpended(true);
    }
  }, [expandAll]);
  useEffect(() => {
    if (collapseAll) {
      setExpended(false);
    }
  }, [collapseAll]);
  const user_type = 'provider';

  const updateProfile = (data) => {
    dispatch(updateUserInfoStart());
    const ProviderData = {
      ...data,
      user_type,
      country_code: data?.countryCode?.dialCode
        ? `+${data?.countryCode?.dialCode}`
        : userProfile?.country_code,
      mobile_number: data?.phoneNumber ? data?.phoneNumber : userProfile?.mobile_number,
    };

    ProfileUpdate(ProviderData, updateUserInfoSuccess, dispatch, updateUserInfoSuccess, () => {
      navigate(appRoutes.DASHBOARD_PROVIDER);
    });
  };

  const deleteAccount = async () => {
    const res = await AxiosInstance.post(API.DELETE_ACCOUNT);
    localStorage.clear();
    navigate('/');
  };
  useEffect(() => {
    if (deleteProfile) {
      MySwal.fire({
        title: (
          <span className="flex flex-col text-base">
            <p style={{ fontFamily: 'museo-sans, sans-serif', fontSize: '18px' }}>
              {t('Are you sure to delete your profile ?')}
            </p>
            <p style={{ fontSize: '12px', fontWeight: 'bolder' }}>
              {t("Note: the changes can't be undone")}
            </p>
          </span>
        ),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: t('CANCEL'),
        confirmButtonText: t('Yes'),
      }).then((res) => {
        if (res.isConfirmed) {
          deleteAccount();
        }
      });
      setDeleteProfile(false);
    }
  }, [MySwal, dispatch]);

  useEffect(() => {
    if (success === true) {
      MySwal.fire({
        title: (
          <span className="flex flex-col text-base">
            <p>{t('Profile successfully updated')}</p>
          </span>
        ),
        icon: 'success',
      }).then((res) => {
        if (res.isConfirmed) {
          dispatch(resetprofileUpdate());
          navigate(appRoutes.DASHBOARD_PROVIDER);
        }
      });
    }
  }, [success]);

  useEffect(() => {
    if (Error) {
      MySwal.fire({
        title: Error?.email
          ? t(`${Error?.email[0]}`)
          : t(`${Error?.mobile_number ? Error?.mobile_number[0] : Error}`),
        icon: 'error',
      }).then((res) => {
        if (res.isConfirmed) {
          dispatch(resetprofileUpdate());
        } else {
          dispatch(resetprofileUpdate());
        }
      });
    }
  }, [Error]);

  return (
    <div className="w-full h-full mt-10 flex justify-center">
      <div className="border-2 rounded-lg w-[868px]">
        <form onSubmit={methods.handleSubmit(updateProfile)} className="w-full p-2">
          <div className="flex items-center">
            <span className="text-themeSecgray text-4xl font-extrabold mr-5">{t('Profile')}</span>
            <Link to={appRoutes.DASHBOARD_PROVIDER}>
              <p className="text-blue">
                {'<'} {t('Back to My properties')}
              </p>
            </Link>
          </div>

          <Accordion
            className="border-themeGray"
            sx={{
              backgroundColor: 'transparent',
              boxShadow: '0',
              border: 'solid 2px #A6A6A6',
              borderRadius: '10px !important',
            }}
            expanded={expended}
            onChange={() => {
              setExpended(!expended);
            }}
          >
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
              {expended ? (
                <FaMinus className="text-primary text-2xl my-auto" />
              ) : (
                <FaPlus className="text-primary text-2xl my-auto" />
              )}
              <div className="text-primary text-2xl font-bold px-3">{t('Profile')}</div>
            </AccordionSummary>
            <AccordionDetails>
              <FormProvider {...methods}>
                <div className="md:px-8">
                  <FieldsContainer className="flex items-center">
                    <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                      {/* <div className="w-8/12 sm_ss:w-full sm_s:w-full"> */}
                      <SelectField
                        title={t('Title')}
                        options={language_selected === 'en' ? titleOptions : titleOptionsfr}
                        id="title"
                        defaultValue={userProfile?.title}
                      />
                      {/* </div> */}
                      {errors.title && <ErrorContainer errMsg={t(`${errors.title.message}`)} />}
                    </SubWrapper>
                    <Separater className="w-12" />
                    <SubWrapper className="h-24 mb-6 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                      {/* <TextInput
                        text={t('PositionProvider')}
                        textType="text"
                        placeholder={t('Enter your position')}
                        name="position"
                        id="position"
                      /> */}
                    </SubWrapper>
                  </FieldsContainer>
                  <FieldsContainer className="flex items-center ">
                    <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('Firstname')}
                        textType="text"
                        placeholder={t('placeholderFname')}
                        name="first_name"
                        id="first_name"
                        // defaultValue={userProfile?.name}
                      />
                      {errors.first_name && (
                        <ErrorContainer errMsg={t(`${errors.first_name.message}`)} />
                      )}
                    </SubWrapper>
                    <Separater className="w-12" />
                    <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('Lastname')}
                        textType="text"
                        placeholder={t('placeholderLname')}
                        name="last_name"
                        id="last_name"
                        // defaultValue={userProfile?.last_name}
                      />
                      {errors.last_name && (
                        <ErrorContainer errMsg={t(`${errors.last_name.message}`)} />
                      )}
                    </SubWrapper>
                  </FieldsContainer>
                  <FieldsContainer className="flex items-center ">
                    <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('ProviderEmail')}
                        textType="email"
                        placeholder={t('placeholderEmail')}
                        name="email"
                        id="email"
                        // defaultValue={userProfile?.email}
                      />
                      {errors.email && <ErrorContainer errMsg={t(`${errors.email.message}`)} />}
                    </SubWrapper>
                    <Separater className="w-12" />
                    <SubWrapper className="h-24 mb-4 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                      {/* <TextInput
                        text={t('MobilePHN')}
                        textType="text"
                        placeholder={t('placeholderMobileNumber')}
                        name="mobile_number"
                        id="mobile_number"
                        // defaultValue={userProfile?.mobile_number}
                      /> */}
                      <span
                        className={`text-sectext mb-1 md:mb-2 sm_ss:text-xs sm_s:text-xs sm_ss:mt-3 sm_s:mt-3 `}
                      >
                        {t('MobilePHN')}
                      </span>
                      <CountryCodeInput
                        setCountryCode={(value, countryCode) => {
                          methods.setValue('countryCode', countryCode);
                          methods.setValue(
                            'phoneNumber',
                            value.slice(countryCode?.dialCode?.length || 0)
                          );
                        }}
                        country="ch"
                        placeholder={t('placeholderMobileNumber')}
                        name="mobile_number"
                        id="mobile_number"
                        textType="text"
                        defaultValue={userProfile?.phoneNumber}
                      />
                      {errors.mobile_number && (
                        <ErrorContainer errMsg={t(`${errors.mobile_number.message}`)} />
                      )}
                    </SubWrapper>
                  </FieldsContainer>
                  <FieldsContainer className="flex items-center ">
                    <SubWrapper className="h-28 flex-1 flex flex-col sm_ss:w-full sm_s:w-full">
                      <div className="w-1/3 sm_ss:w-full sm_s:w-full">
                        <SelectField
                          title={t('Language')}
                          options={language_selected === 'en' ? language : languagefr}
                          id="preferred_language"
                          defaultValue={userProfile?.preferred_language}
                        />
                      </div>
                      {errors.preferred_language && (
                        <ErrorContainer errMsg={t(`${errors.preferred_language.message}`)} />
                      )}
                    </SubWrapper>
                    <Separater className="w-12" />
                  </FieldsContainer>
                  <FieldsContainer className="flex flex-col items-start mb-3">
                    <SubWrapper className="h-28 flex-1 w-1/2 sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('OldPassword')}
                        textType={showOldPassword ? 'text' : 'password'}
                        id="oldpassword"
                        name="oldpassword"
                        placeholder="*******"
                        showEyeButton
                        passwordShown={showOldPassword}
                        setPasswordShown={setShowOldPassword}
                        togglePassword={() => {
                          setShowOldPassword(!showOldPassword);
                        }}
                      />
                      {errors?.oldpassword ? (
                        <ErrorContainer errMsg={t(`${errors?.oldpassword?.message}`)} />
                      ) : (
                        ''
                      )}
                    </SubWrapper>
                    <SubWrapper className="h-28 flex-1 w-1/2 sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('PasswordP')}
                        textType={showPassword ? 'text' : 'password'}
                        id="password"
                        name="password"
                        placeholder="*******"
                        showEyeButton
                        passwordShown={showPassword}
                        setPasswordShown={setShowPassword}
                        togglePassword={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                      {errors?.password ? (
                        <ErrorContainer errMsg={t(`${errors?.password?.message}`)} />
                      ) : (
                        ''
                      )}
                    </SubWrapper>
                    <SubWrapper className="h-28 flex-1 w-1/2 sm_ss:w-full sm_s:w-full">
                      <TextInput
                        text={t('repeatPassword')}
                        textType={showConfirmPassword ? 'text' : 'password'}
                        id="confirm_password"
                        name="confirm_password"
                        placeholder="*******"
                        showEyeButton
                        passwordShown={showConfirmPassword}
                        setPasswordShown={setShowConfirmPassword}
                        togglePassword={() => {
                          setShowConfirmPassword(!showConfirmPassword);
                        }}
                      />
                      {errors?.confirm_password ? (
                        <ErrorContainer errMsg={t(`${errors?.confirm_password?.message}`)} />
                      ) : (
                        ''
                      )}
                    </SubWrapper>
                  </FieldsContainer>
                </div>
              </FormProvider>
            </AccordionDetails>
          </Accordion>
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col">
              <button
                className="h-10 w-28 cursor-pointer my-3 rounded-lg bg-blue text-white"
                type="submit"
              >
                {pending ? <CircularProgress className="p-2" color="inherit" /> : t('SAVE')}
              </button>
              {/* {Error?.email?.[0] && <span className="text-red-600">{Error?.email?.[0]}</span>}
              {Error?.mobile_number?.[0] && (
                <span className="text-red-600">{Error?.mobile_number?.[0]}</span>
              )} */}
            </div>
            <div className="text-red-600 cursor-pointer" onClick={() => setDeleteProfile(true)}>
              {t('X Delete my profile')}
            </div>
          </div>
        </form>
        {userProfile?.organization_name && (
          <div className="h-64">
            <Paper elevation={0} className="p-4 paper mt-8">
              <div className="flex mb-8 mx-3 mt-2">
                <PageHeading>{t('Organisations')}</PageHeading>
                {/* <Link to="/organisations">
                  <div className="bg-primary px-4 py-2 text-base rounded-md text-white font-bold my-1 mx-4">
                    {t('New')}
                  </div>
                </Link> */}
              </div>
              <div className="flex mb-8 mx-5">
                <FaBuilding className="mr-2 text-slate-500" />
                <div className="text-sm">{userProfile?.organization_name}</div>
              </div>
            </Paper>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProviderProfileUpdate;
