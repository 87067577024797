import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

function RadioButton({ value, id, name, selected, methods, setRequestType }) {
  const { register, control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: props }) => (
        <input
          {...props}
          className="h-5 w-5 cursor-pointer "
          type="radio"
          {...register(name)}
          id={id}
          value={value}
          selected={selected}
          onChange={() => {
            methods.setValue(name, value);
            setRequestType(value);
          }}
          // checked={checked}
        />
      )}
    />
  );
}

export default RadioButton;
