/* eslint-disable no-unneeded-ternary */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FaMinus, FaPlus } from 'react-icons/fa';
import CheckBox from '../CheckBox';
import MultipleCheck from '../common/MultipleCheck';
import SelectField from '../SelectField';
import ErrorContainer from '../ErrorContainer';
import {
  amenitiesTypeOption,
  amenitiesTypeOptionfr,
  noOfbathrooms,
  Numbers,
} from '../../constants';
import DraftButton from '../common/DraftButton';

function DetailsFormCard({
  expandAll,
  collapseAll,
  errors,
  handelDraftSubmit,
  data,
  scrollState,
  noScroll,
  scrollTo,
  methods,
  language_selected,
}) {
  const { t } = useTranslation();
  const [expended, setExpended] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    surface,
    furniture,
    number_of_rooms,
    number_of_bedrooms,
    number_of_bathrooms,
    kitchen,
    kitchen_style,
    housing_type,
    amenties,
    amenities,
  } = methods.watch();
  const property_type = localStorage.getItem('property_type');

  useEffect(() => {
    if (data) {
      methods.setValue('dining_space', data?.dining_space);
      methods.setValue('guest_wc', data?.guest_wc);
    }
  }, [data?.dining_space, data?.guest_wc]);

  const KitchenStyleOption = [
    { value: 'closed', label: t('Closed'), id: 1 },
    { value: 'open', label: t('Open'), id: 2 },
  ];

  const kitchenOpt = [
    { value: 'furnished', label: t('furnishedd') },
    { value: 'unfurnished', label: t('unfurnishedd') },
  ];

  const { control } = useFormContext();

  const furnitureOptions = [
    {
      value: 'furnished',
      label: t('furnished'),
      id: 1,
    },
    {
      value: 'unfurnished',
      label: t('Unfurnished'),
      id: 2,
    },
    { label: t('Indifferent'), value: 'indifferent', id: 3 },
  ];
  useEffect(() => {
    if (expandAll) {
      setExpended(true);
    }
  }, [expandAll]);

  useEffect(() => {
    if (collapseAll) {
      setExpended(false);
    }
  }, [collapseAll]);

  useEffect(() => {
    if (
      housing_type !== 'room' &&
      (!number_of_rooms ||
        !furniture ||
        !number_of_bedrooms ||
        !number_of_bathrooms ||
        !kitchen ||
        !kitchen_style)
    ) {
      setDisable(true);
    } else if (housing_type === 'room' && (!furniture || !surface)) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [
    surface,
    number_of_rooms,
    furniture,
    number_of_bedrooms,
    number_of_bathrooms,
    kitchen,
    kitchen_style,
    amenties,
    housing_type,
  ]);

  // const am = data?.amenties?.map((el) => el?.name);

  return (
    <div className="border-2 rounded-lg overflow-hidden">
      <Accordion
        className="border-themeGray"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: '0',
          border: 'solid 2px #A6A6A6',
          borderRadius: '10px !important',
        }}
        expanded={expended}
        onChange={() => {
          setExpended(!expended);
        }}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          {expended ? (
            <FaMinus className="text-primary text-2xl my-auto" />
          ) : (
            <FaPlus className="text-primary text-2xl my-auto" />
          )}
          <div className="text-primary text-2xl font-extrabold px-3">{t('Details')}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col px-2 md:px-5 pb-4">
            <div className="md:grid md:grid-cols-3  md:gap-y-6 md:gap-x-12">
              <div ref={scrollState === 'surface' ? scrollTo : noScroll} className="w-full">
                <div className="flex flex-col flex-1 relative">
                  <span className="text-sectext mb-2 sm_ss:text-xs sm_s:text-xs">
                    {t('Surface')}
                  </span>
                  <FormControl variant="outlined">
                    <OutlinedInput
                      size="small"
                      type="number"
                      className="bg-white"
                      placeholder={t('PlaceholderSurfaceArea')}
                      id="surface"
                      name="surface"
                      {...control.register('surface')}
                      endAdornment={<InputAdornment position="end">m2</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                    />
                    {errors?.surface && <ErrorContainer errMsg={t(`${errors.surface.message}`)} />}
                  </FormControl>
                </div>
              </div>

              <div
                ref={scrollState === 'furniture' ? scrollTo : noScroll}
                className=" flex-1 sm_ss:w-full sm_s:w-full"
              >
                <SelectField
                  title={t('Furniture')}
                  options={furnitureOptions}
                  id="furniture"
                  defaultValue={data?.furniture}
                />
                {errors?.furniture && <ErrorContainer errMsg={t(`${errors.furniture.message}`)} />}
              </div>
              {methods.getValues().housing_type !== 'room' && (
                <div
                  ref={scrollState === 'number_of_rooms' ? scrollTo : noScroll}
                  className=" flex-1 sm_ss:w-full sm_s:w-full"
                >
                  <SelectField
                    title={t('Nb of rooms')}
                    options={Numbers}
                    id="number_of_rooms"
                    defaultValue={data?.number_of_rooms}
                  />
                  <p className="opacity-30">{t('Geneva: kitchen is a room')}</p>
                  {errors?.number_of_rooms && (
                    <ErrorContainer errMsg={t(`${errors.number_of_rooms.message}`)} />
                  )}
                </div>
              )}

              {methods.getValues().housing_type !== 'room' && (
                <div
                  ref={scrollState === 'number_of_bedrooms' ? scrollTo : noScroll}
                  className=" flex-1 sm_ss:w-full sm_s:w-full"
                >
                  <SelectField
                    title={t('Nb of bedrooms')}
                    options={Numbers}
                    id="number_of_bedrooms"
                    defaultValue={data?.number_of_bedrooms}
                  />
                  {errors?.number_of_bedrooms && (
                    <ErrorContainer errMsg={t(`${errors.number_of_bedrooms.message}`)} />
                  )}
                </div>
              )}
              {methods.getValues().housing_type !== 'room' && (
                <div
                  ref={scrollState === 'number_of_bathrooms' ? scrollTo : noScroll}
                  className=" flex-1 sm_ss:w-full sm_s:w-full"
                >
                  <SelectField
                    title={t('Nb of bathrooms')}
                    options={noOfbathrooms}
                    id="number_of_bathrooms"
                    defaultValue={data?.number_of_bathrooms}
                  />
                  {errors?.number_of_bathrooms && (
                    <ErrorContainer errMsg={t(`${errors.number_of_bathrooms.message}`)} />
                  )}
                  <div className="mt-2 sm_ss:text-xs sm_s:text-xs">
                    <CheckBox
                      value
                      id="guest_wc"
                      name="guest_wc"
                      label={t('Guest WC')}
                      // checked={guest}
                    />
                    {errors?.guest_wc && (
                      <ErrorContainer errMsg={t(`${errors.guest_wc.message}`)} />
                    )}
                  </div>
                </div>
              )}
              {methods.getValues().housing_type !== 'room' && (
                <div ref={scrollState === 'kitchen' ? scrollTo : noScroll} className="sw-full">
                  <div className="flex flex-col flex-1 relative ">
                    {property_type === 'sell' ? (
                      <FormControl variant="outlined">
                        <div className="h-8 pb-1 md:pb-2 ">
                          <div className="text-sectext absolute sm_ss:text-xs sm_s:text-xs sm_ss:mt-3 sm_s:mt-3">
                            {t('Kitchen')}
                          </div>
                        </div>
                        <OutlinedInput
                          size="small"
                          type="number"
                          className="bg-white"
                          placeholder={t('PlaceholderSurfaceArea')}
                          id="kitchen"
                          {...control.register('kitchen')}
                          endAdornment={<InputAdornment position="end">m2</InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                        />
                        {errors?.kitchen && <ErrorContainer errMsg={t('errorsSurface')} />}
                      </FormControl>
                    ) : (
                      <FormControl variant="outlined">
                        <SelectField
                          title={t('Kitchen')}
                          options={kitchenOpt}
                          id="kitchen"
                          defaultValue={data?.kitchen}
                        />
                        {errors?.kitchen && (
                          <ErrorContainer errMsg={t(`${errors.kitchen.message}`)} />
                        )}
                      </FormControl>
                    )}
                  </div>
                </div>
              )}
              {methods.getValues().housing_type !== 'room' && (
                <div className="w-full" ref={scrollState === 'kitchen_style' ? scrollTo : noScroll}>
                  <div className=" flex-1 sm_ss:w-full sm_s:w-full">
                    <SelectField
                      title={t('Kitchen style')}
                      options={KitchenStyleOption}
                      id="kitchen_style"
                      defaultValue={data?.kitchen_style}
                    />
                    {errors?.kitchen_style && (
                      <ErrorContainer errMsg={t(`${errors.kitchen_style.message}`)} />
                    )}
                    <div className="mt-2 sm_ss:text-xs sm_s:text-xs">
                      <CheckBox
                        value
                        id="dining_space"
                        name="dining_space"
                        label={t('Dining Space')}
                        // checked={dining}
                      />
                      {errors?.dining_space && (
                        <ErrorContainer errMsg={t(`${errors.dining_space.message}`)} />
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="w-full col-span-3 mt-4 sm_ss:text-xs sm_s:text-xs">
                <MultipleCheck
                  mainClassName="md:grid md:grid-cols-3 gap-y-4 md:gap-x-12 sm_ss:text-xs sm_s:text-xs"
                  name="amenities"
                  label={t('Amenities')}
                  Options={language_selected === 'en' ? amenitiesTypeOption : amenitiesTypeOptionfr}
                  defaultValue={
                    amenities?.length > 0
                      ? amenities.map((el) => el?.name || el)
                      : data?.amenties?.map((el) => el?.name)
                  }
                />
              </div>
            </div>
            <DraftButton
              handelDraftSubmit={() => {
                handelDraftSubmit('draft', setIsLoading);
              }}
              disable={disable}
              language_selected={language_selected}
              draftLoading={isLoading}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default DetailsFormCard;
