/* eslint-disable react/jsx-boolean-value */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-debugger */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // FormControlLabel,
  // Radio,
  // RadioGroup,
} from '@mui/material';
import { AiFillWarning } from 'react-icons/ai';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useFormContext } from 'react-hook-form';
import { FaMinus, FaPlus } from 'react-icons/fa';
import moment from 'moment';
import {
  apartmentTypeOptions,
  apartmentTypeOptionsfr,
  houseTypeSubOptions,
  houseTypeSubOptionsfr,
  housingTypeOptions,
  housingTypeOptionsfr,
  numbers,
  numbersfr,
  renewablePeriod,
  renewablePeriodfr,
  SellhousingTypeOptions,
  SellhousingTypeOptionsfr,
} from '../../constants';
import DatePickerComp from '../common/DatePicker';
import DraftButton from '../common/DraftButton';
import ErrorContainer from '../ErrorContainer';
import SelectField from '../SelectField';
import TextInput from '../TextInput';
import Separater from '../Separater';

// import WarningIcon from '@mui/icons-material/Warning';

function PropertyFormCard({
  expandAll,
  collapseAll,
  errors,
  scrollState,
  setScrollState,
  methods,
  handelDraftSubmit,
  data,
  setfixed_renew,
  error,
  property_type,
  referanceErr,
  fixed_renew,
  setRenewableOptionErr,
  renewableOptionErr,
}) {
  const { t } = useTranslation();
  const [expended, setExpended] = useState(false);
  const [disable, setDisable] = useState(false);
  const language_selected = Cookies.get('i18next');
  const [isLoading, setIsLoading] = useState(false);

  const scrollTo = useRef();
  const noScroll = useRef();
  const { housing_type, floor, renewable_period, availability, sub_type, until_end, role } =
    methods.watch();

  useEffect(() => {
    if (scrollState && scrollTo?.current?.scrollIntoView) {
      scrollTo.current.scrollIntoView({
        behavior: 'smooth',
      });
      setScrollState('');
    }
  }, [scrollState, scrollTo?.current?.scrollIntoView]);

  useEffect(() => {
    if (data?.sublet) {
      methods.setValue('sublet', data?.sublet === 'sublet' ? data?.sublet : parseInt(data?.sublet));
    }
  }, [data?.sublet]);

  useEffect(() => {
    if (expandAll) {
      setExpended(true);
    }
  }, [expandAll]);

  useEffect(() => {
    if (collapseAll) {
      setExpended(false);
    }
  }, [collapseAll]);

  useEffect(() => {
    if (property_type === 'rent') {
      if (
        housing_type === 'apartment' &&
        floor &&
        sub_type &&
        availability &&
        fixed_renew === 'fixed' &&
        until_end
      ) {
        setDisable(false);
      } else if (
        housing_type === 'apartment' &&
        floor &&
        sub_type &&
        availability &&
        fixed_renew === 'renewable' &&
        renewable_period
      ) {
        setDisable(false);
      } else if (
        housing_type === 'room' &&
        floor &&
        availability &&
        fixed_renew === 'fixed' &&
        until_end
      ) {
        setDisable(false);
      } else if (
        housing_type === 'room' &&
        floor &&
        availability &&
        fixed_renew === 'renewable' &&
        renewable_period
      ) {
        setDisable(false);
      } else if (
        housing_type === 'house' &&
        sub_type &&
        availability &&
        fixed_renew === 'fixed' &&
        until_end
      ) {
        setDisable(false);
      } else if (
        housing_type === 'house' &&
        sub_type &&
        availability &&
        fixed_renew === 'renewable' &&
        renewable_period
      ) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else if (property_type === 'sell') {
      if (housing_type === 'apartment' && floor && sub_type && availability) {
        setDisable(false);
      } else if (housing_type === 'house' && sub_type && availability) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }
  }, [housing_type, sub_type, floor, fixed_renew, availability, until_end, renewable_period]);

  useEffect(() => {
    if (
      fixed_renew === 'renewable' &&
      (renewable_period === undefined || renewable_period === null)
    ) {
      setRenewableOptionErr({
        err: true,
        type: 'rp',
        message: 'Please select renewable period',
      });
    } else {
      setRenewableOptionErr(false);
    }
  }, [fixed_renew, renewable_period]);

  const someDate = new Date();
  const numberOfDaysToAdd = 30;
  const result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
  const expectedDate = new Date(result);
  const finalDate = moment(expectedDate, 'DD/MM/YYYY');

  return (
    <div className="border-2 rounded-lg ">
      <Accordion
        className="border-themeGray"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: '0',
          border: 'solid 2px #A6A6A6',
          borderRadius: '10px !important',
        }}
        expanded={expended}
        onChange={() => {
          setExpended(!expended);
        }}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          {expended ? (
            <FaMinus className="text-primary text-2xl my-auto" />
          ) : (
            <FaPlus className="text-primary text-2xl my-auto" />
          )}
          <div className="text-primary text-2xl font-extrabold px-3">{t('Property')}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col px-2 md:px-5 pb-4">
            <div className="grid md:grid-cols-3 gap-4 md:gap-12">
              {role === 'real_estate_agency' && (
                <div
                  ref={scrollState === 'reference_code' ? scrollTo : noScroll}
                  className=" w-full"
                >
                  <TextInput
                    text={t('Reference code')}
                    textType="text"
                    placeholder={t('PlaceholderRefCode')}
                    name="reference_code"
                    id="reference_code"
                  />
                  {referanceErr?.err && <ErrorContainer errMsg={referanceErr?.message} />}
                </div>
              )}
              <div
                ref={scrollState === 'housing_type' ? scrollTo : noScroll}
                className="md:col-span-2"
              >
                {property_type === 'rent' ? (
                  <div className="sm_ss:w-full sm_s:w-full">
                    <SelectField
                      title={t('Housing type')}
                      options={
                        language_selected === 'en' ? housingTypeOptions : housingTypeOptionsfr
                      }
                      id="housing_type"
                      defaultValue={data?.housing_type}
                    />
                  </div>
                ) : (
                  <div className="sm_ss:w-full sm_s:w-full">
                    <SelectField
                      title={t('Housing type')}
                      options={
                        language_selected === 'en'
                          ? SellhousingTypeOptions
                          : SellhousingTypeOptionsfr
                      }
                      id="housing_type"
                      defaultValue={data?.housing_type}
                    />
                  </div>
                )}
                {errors.housing_type && (
                  <ErrorContainer errMsg={t(`${errors.housing_type.message}`)} />
                )}

                {property_type === 'rent' && (
                  <div className="mt-3" style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                      <AiFillWarning size="20" color="#E6BC2E" />
                    </div>

                    <p className="mt-0 ml-2 sm_ss:text-xs sm_s:text-xs">{t('SubletText')}</p>
                  </div>
                )}
                {/* {property_type === 'rent' && (
                  <CheckBox
                    className="mt-4"
                    value="sublet"
                    id="sublet"
                    name="sublet"
                    label={t('Sublet (regin approved)')}
                    checked={!!sublet}
                  />
                )} */}
                <Separater className="h-5" />
                {housing_type === 'apartment' && (
                  <div ref={scrollState === 'sub_type' ? scrollTo : noScroll}>
                    <SelectField
                      title={t('Sub_Type')}
                      options={
                        language_selected === 'en' ? apartmentTypeOptions : apartmentTypeOptionsfr
                      }
                      id="sub_type"
                      defaultValue={data?.sub_type}
                    />
                  </div>
                )}
                {housing_type === 'apartment' && (
                  <ErrorContainer
                    errMsg={errors?.sub_type?.message && t(`${errors?.sub_type?.message}`)}
                  />
                )}
                {housing_type === 'house' && (
                  <div ref={scrollState === 'sub_type' ? scrollTo : noScroll}>
                    <SelectField
                      title={t('Sub_Type_house')}
                      options={
                        language_selected === 'en' ? houseTypeSubOptions : houseTypeSubOptionsfr
                      }
                      id="sub_type"
                      defaultValue={data?.sub_type}
                    />
                  </div>
                )}
                {housing_type === 'house' && (
                  <ErrorContainer
                    errMsg={errors?.sub_type?.message && t(`${errors?.sub_type?.message}`)}
                  />
                )}
              </div>
              {(housing_type === 'apartment' || housing_type === 'room') && (
                <div
                  ref={scrollState === 'floor' ? scrollTo : noScroll}
                  className="sm_ss:w-full sm_s:w-full"
                >
                  <SelectField
                    title={t('Floor')}
                    options={language_selected === 'en' ? numbers : numbersfr}
                    id="floor"
                    defaultValue={data?.floor?.toString()}
                  />
                  {errors.floor && <ErrorContainer errMsg={t(`${errors.floor.message}`)} />}
                </div>
              )}
              <div
                ref={scrollState === 'availability' ? scrollTo : noScroll}
                className="sm_ss:w-full sm_s:w-full"
              >
                <DatePickerComp
                  name="availability"
                  label={t('availability')}
                  defaultValue={data?.availability || ''}
                  methods={methods}
                  disablePast={true}
                  afterOneMonthDate={finalDate}
                />
                {errors.availability && (
                  <ErrorContainer errMsg={t(`${errors.availability.message}`)} />
                )}
              </div>
              <div className="sm_ss:w-full sm_s:w-full flex flex-col">
                {property_type === 'rent' && (
                  <div
                    ref={scrollState === 'fixed_renew' ? scrollTo : noScroll}
                    className="flex flex-col pt-5 sm_ss:text-xs sm_s:text-xs"
                    name="fixed_renew"
                    onChange={(e) => setfixed_renew(e.target.value)}
                  >
                    <div className="flex items-center py-2">
                      <input
                        type="radio"
                        className="h-5 w-5 mr-2"
                        id="renewable"
                        name="fixed_renew"
                        value="renewable"
                        defaultChecked={data?.fixed_renew?.includes('renewable')}
                      />
                      <label htmlFor="renewable">{t('Renewable')}</label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        className="h-5 w-5 mr-2"
                        id="fixed"
                        name="fixed_renew"
                        value="fixed"
                        defaultChecked={data?.fixed_renew?.includes('fixed')}
                      />
                      <label htmlFor="fixed">{t('Fixed Duration')}</label>
                    </div>
                  </div>
                )}
                {error?.err && <ErrorContainer errMsg={error?.message} />}
              </div>
              {property_type === 'rent' && fixed_renew === 'fixed' ? (
                <div className="sm_ss:w-full sm_s:w-full">
                  <DatePickerComp
                    name="until_end"
                    label={t('Until (end)')}
                    defaultValue={data?.until_end}
                    methods={methods}
                    disablePast={true}
                    afterOneMonthDate={finalDate}
                    minDate={finalDate}
                  />
                  {renewableOptionErr.type === 'fd' && (
                    <ErrorContainer
                      errMsg={renewableOptionErr.type === 'fd' && renewableOptionErr.message}
                    />
                  )}
                </div>
              ) : (
                fixed_renew === 'renewable' && (
                  <div>
                    <SelectField
                      title={t('Renewable period')}
                      options={language_selected === 'en' ? renewablePeriod : renewablePeriodfr}
                      id="renewable_period"
                      defaultValue={data?.renewable_period || renewable_period}
                    />
                    {renewableOptionErr.type === 'rp' && (
                      <ErrorContainer
                        errMsg={renewableOptionErr.type === 'rp' && t(renewableOptionErr.message)}
                      />
                    )}
                  </div>
                )
              )}
            </div>
            <DraftButton
              handelDraftSubmit={async () => {
                handelDraftSubmit('draft', setIsLoading);
              }}
              disable={disable}
              language_selected={language_selected}
              draftLoading={isLoading}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default PropertyFormCard;
