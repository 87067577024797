import React from 'react';

function FieldsContainer({ children, className, refer }) {
  return (
    <div
      ref={refer}
      className={`${className} sm_ss:flex-col sm_s:flex-col sm_ss:justify-start sm_s:items-start`}
    >
      {children}
    </div>
  );
}

export default FieldsContainer;
