import React from 'react';
import { useTranslation } from 'react-i18next';

function FooterTextContainer({ link, text, icon, flex1 = true }) {
  const { t } = useTranslation();
  return (
    <div
      className={`sm_ss:mr-0 sm_ss:py-2 sm_s:py-1 ${
        flex1 ? 'flex-1' : ''
      }  flex items-center sm_ss:w-[300px] sm_s:w-[300px]`}
    >
      <div>{icon}</div>
      <a href={link} target="__blank">
        <span className="text-sm uppercase smx:text-xs w-56 text-white cursor-pointer font-semibold">
          {t(`${text}`)}
        </span>
      </a>
    </div>
  );
}

export default FooterTextContainer;
