import React from 'react';
import { useTranslation } from 'react-i18next';

function SuccessFullSignup() {
  const { t } = useTranslation();
  return (
    <div className="w-full bg-signUpBG">
      <div className="max-w-4xl w-4xl flex flex-col sm_ss:w-full sm_s:w-full">
        <span className="text-themeSecgray font-extrabold text-3xl mb-5 sm_ss:text-lg sm_s:text-lg sm_ss:text-center sm_s:text-center">
          {t('Thanks')}
        </span>
        <div className="bg-white p-10 flex flex-col rounded-lg sm_ss:rounded-none sm_s:rounded-none sm_ss:bg-transparent sm_s:bg-transparent sm_ss:text-center sm_s:text-center">
          <span className="text-black font-bold mb-5">{t('Note')}</span>
          <span className="text-black mb-5">{t('Des1')}</span>
          <span className="text-black">{t('Des2')}</span>
        </div>
      </div>
    </div>
  );
}

export default SuccessFullSignup;
