/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';

function ErrorContainer({ errMsg, className }) {
  return (
    <span
      className={`text-red-600 text-sm bottom-3 left-1 sm_ss:bottom-0 sm_s:bottom-0 sm_ss:left-5 sm_s:left-5 ${className}`}
    >
      {errMsg}
    </span>
  );
}

export default ErrorContainer;
