/* eslint-disable no-unneeded-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

function DatePickerComp({
  name,
  label,
  defaultValue,
  methods,
  disablePast,
  afterOneMonthDate,
  // maxDate,
  minDate,
}) {
  const [value, setValue] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (afterOneMonthDate) {
      setValue(moment(afterOneMonthDate, 'DD/MM/YYYY'));
    }
    if (defaultValue) {
      // eslint-disable-next-line prettier/prettier
      setValue(moment(defaultValue, 'DD/MM/YYYY'));
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value) {
      methods.setValue(name, moment(value).format('DD/MM/YYYY'), { shouldValidate: true });
    } else {
      methods.setValue(name, null, { shouldValidate: true });
    }
  }, [value, defaultValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="min-w-40 w-full">
        <div className="mb-2 sm_ss:text-xs sm_s:text-xs">{label}</div>
        <DatePicker
          className="w-full"
          value={value}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          inputFormat="DD/MM/yyyy"
          // maxDate={maxDate}
          minDate={minDate}
          disablePast={disablePast}
          {...methods?.register(name)}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              size="small"
              sx={{
                background: 'white !important',
              }}
              className="rounded-lg"
              {...params}
              onClick={() => setOpen(true)}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  );
}

export default DatePickerComp;
