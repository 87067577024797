/* eslint-disable no-else-return */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Navigate } from 'react-router-dom';

function AuthMiddleware(props) {
  const userData = JSON.parse(localStorage.getItem('user'));
  const authState = userData?.data?.isAuthenticated;
  let data;
  if (userData?.length > 0) {
    data = userData;
  }

  let token;
  if (!data) {
    token = null;
  } else {
    token = userData?.data?.result?.access_token;
  }

  const userRole = userData?.data?.result?.role;
  const { route } = props;
  const authRoute = route?.meta?.authRoute;

  // ** Assign vars based on route meta

  if (!authRoute && !authState && !token) {
    /**
     ** If user is not Logged in & route meta is undefined
     ** OR
     ** If user is not Logged in & route.meta.authRoute, !route.meta.publicRoute are undefined
     ** Then redirect user to login
     */
    return <Navigate to="/login" />;
  } else if (!authRoute && authState && !route.authorizedRoles?.includes(userRole)) {
    // ** If route has meta and authRole and user is Logged in then redirect user to home page (DefaultRoute)
    // return <Redirect to="/" />;
    return <Navigate to="/login" />;
  } else {
    // ** If none of the above render component
    return <route.component {...props} />;
  }
}

export default AuthMiddleware;
