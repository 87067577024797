import React from 'react';

function ItemContainer({ children, bg, className }) {
  return (
    <div
      className={`md:py-10 md:px-12 sm_ss:px-0 sm_s:px-0 border ${
        bg && 'sm_ss:bg-white' && 'sm_s:bg-white'
      } bg-transparent sm_ss:border-0 sm_s:border-0 sm_ss:w-full sm_s:w-full border-textGray flex justify-start flex-col md:my-4 rounded-lg ${className}`}
    >
      {children}
    </div>
  );
}

export default ItemContainer;
