/* eslint-disable prettier/prettier */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../Axios';
import API from '../../constants/API';

export const AddAndEditProperty = createAsyncThunk(
  'AddAndEditProperty',
  async ({ data, setCurrentFormID, fun }, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.post(API.ADD_AND_EDIT_PROPERTY, { ...data });
      if (response?.data?.statusCode === 201) {
        fun();
      }
      setCurrentFormID(response?.data?.result?.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const addAndEditProperty = createSlice({
  name: 'addAndEditProperty',
  initialState: {
    pending: false,
    success: false,
    error: false,
    draft: false,
  },
  reducers: {
    addAndEditPropertyStart: (state) => {
      state.pending = true;
      state.error = false;
    },
    addAndEditPropertySuccess: (state) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    addAndEditPropertyError: (state, action) => {
      state.success = false;
      state.pending = false;
      state.error = action.payload;
    },
    resetAddAndEditPropertyUpdate: (state) => {
      state.success = false;
      state.error = false;
      state.pending = false;
    },
  },
  extraReducers: {
    [AddAndEditProperty.pending]: (state) => {
      state.success = false;
      state.pending = true;
      state.error = false;
    },
    [AddAndEditProperty.fulfilled]: (state) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    [AddAndEditProperty.rejected]: (state, action) => {
      state.success = true;
      state.pending = false;
      state.error = action.payload;
    },
  },
});
export const { resetAddAndEditPropertyUpdate } = addAndEditProperty.actions;
const addAndEditPropertyReducer = addAndEditProperty.reducer;
export default addAndEditPropertyReducer;
