/* eslint-disable prettier/prettier */
// eslint-disable-next-line no-undef
// eslint-disable-next-line import/prefer-default-export
export const appRoutes = {
  HOME: '/',
  PROVIDER_PROFILECONFORMATION: '/provider/profile-confirmation',
  SEEKER_PROFILECONFORMATION: '/seeker/profile-confirmation',
  LOGIN_STEP_2: '/login',
  PROVIDER_REGISTRATION: '/provider-register',
  SEEKER_REGISTRATION: '/seeker-register',
  DASHBOARD_PROVIDER: '/dashboard-provider',
  DASHBOARD_SEEKER: '/dashboard-seeker',
  PROPERTY_DETAIL_VIEW: '/property/:id',
  ADD_PROPERTY: '/add-property',
  EDIT_PROPERTY: '/edit-property',
  DRAFT_PROPERTY: '/draft-property',
  DUPLICATE_PROPERTY: '/duplicate-property',
  PROVIDER_PROFILE_UPDATE: '/provider-update',
  ORGANISATIONS: '/organisations',
  SEEKER_PROFILE_UPDATE: '/seeker-update',
  pendingActivation: '/pending-activation',
  EDITSEEKERALERTS: '/edit-housing-alerts',
  SEEKERNEWREQUEST: '/new-request-seeker',
  SATISFACTIONSURVEY: '/satisfaction-survey',
  ADDNEWMEMBER: '/new-member',
  ResetPassword: '/reset-password',
  SetPassword: '/reset-password/:id',
  SetPasswordSuccess: '/reset-password-success',
  TermsCondition: '/privacy-policy',
  Terms: '/terms-condition',
  AfterLoginMsg: '/email_verify/',
};
