import React from 'react';
import ReactPaginate from 'react-paginate';
import styles from './PaginationStyles.module.css';

function Pagination({ postPerPage, totalPost, paginate, page }) {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPost / postPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex pt-3">
      <ReactPaginate
        previousLabel=""
        nextLabel=""
        pageCount={pageNumbers.length}
        renderOnZeroPageCount={null}
        selectedPageRel={page}
        onPageChange={(number) => paginate(number.selected + 1)}
        containerClassName={styles.pagination}
        previousLinkClassName={styles.paginationLink}
        nextLinkClassName={styles.paginationLink}
        disabledClassName={styles.paginationDisabled}
        activeClassName={styles.paginationActive}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
      />
    </div>
  );
}

export default Pagination;
