/* eslint-disable camelcase */
import { CircularProgress } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

export default function DraftButton({
  disable,
  handelDraftSubmit,
  language_selected,
  draftLoading,
}) {
  return (
    <button
      type="button"
      disabled={disable}
      onClick={() => handelDraftSubmit('draft')}
      className="bg-primary disabled:bg-themeSecgray disabled:cursor-not-allowed mt-8 px-4 max-w-max py-2 text-base rounded-md text-white font-bold my-1"
    >
      {draftLoading ? <CircularProgress size="20px" color="inherit" /> : t('SAVE')}
    </button>
  );
}
