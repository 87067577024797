import React from 'react';

function ShowImageDetails({ index, val, handleDeleteImage }) {
  return (
    <div
      key={index}
      className="border-2 flex flex-row justify-between border-textGray rounded-sm mt-6"
    >
      <div className="flex w-9/12 item-center p-2 justify-start h-28">
        <img
          src={URL.createObjectURL(val)}
          alt="card"
          width="176px"
          height="96px"
          className="rounded-md object-cover"
        />
        <div className="h-full overflow-hidden px-1 flex justify-start items-center w-80 sm_ss:hidden sm_s:hidden pl-2">
          <span className="text-blue">{val.name}</span>
        </div>
      </div>
      <div className="flex items-center justify-end p-3 h-28">
        {/* <i className="fa-solid fa-bars text-textGray pr-3 cursor-pointer"></i> */}
        <span
          onKeyDown={() => {}}
          onClick={() => handleDeleteImage(val.name)}
          className="bg-red-600 p-1 h-6 flex items-center justify-center cursor-pointer"
        >
          <i id={val.name} className="fa-solid fa-trash text-white" />
        </span>
      </div>
    </div>
  );
}

export default ShowImageDetails;
