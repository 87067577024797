/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable object-shorthand */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-props-no-spreading */

import { Autocomplete, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';

function SelectField({
  title,
  options,
  id,
  defaultValue,
  setRole,
  property_locationsOne,
  setproperty_locationsOne,
  disabled,
  methods,
  setbox_parking_mandatory,
  outdoor_spots_number,
  setoutdoor_spots_mandatory,
  zip,
  zipOptions,
  underground_spots_number,
  setunderground_spots_mandatory,
  setBoxUpdate,
  box_parking_number,
}) {
  const { control } = useFormContext();
  const [value, setValue] = useState(defaultValue ? options[0] : null);

  useEffect(() => {
    if (zip) {
      zipOptions.forEach((element) => {
        const selectedValue = zip;
        if (element.value === selectedValue) {
          setValue(element);
        }
      });
    }
    if (property_locationsOne) {
      setValue(property_locationsOne);
    }
    if (defaultValue === 0) {
      options.forEach((element) => {
        const selectedValue =
          defaultValue === 1 || defaultValue === 0 ? !!defaultValue : defaultValue;
        if (element.value === selectedValue) {
          setValue(element);
        }
      });
    }
    if (defaultValue) {
      options.forEach((element) => {
        const selectedValue =
          defaultValue === 1 || defaultValue === 0 ? !!defaultValue : defaultValue;
        if (element.value === selectedValue) {
          setValue(element);
        }
      });
    }
  }, [defaultValue, zip]);

  return (
    <div className="flex flex-col w-full">
      <div className="h-8 pb-1 md:pb-2 ">
        <div className="text-sectext absolute sm_ss:text-xs sm_s:text-xs sm_ss:mt-3 sm_s:mt-3">
          {title}
        </div>
      </div>
      <Controller
        name={id}
        control={control}
        render={({ field: props }) => (
          <Autocomplete
            {...props}
            fullWidth
            className="bg-white rounded-md"
            size="small"
            options={zipOptions ? zipOptions : options}
            value={value}
            isOptionEqualToValue={(option, selectValue) => option.label === selectValue.label}
            getOptionLabel={(option) => option.label}
            disabled={disabled}
            renderInput={(params) => (
              <TextField variant="outlined" placeholder={t('PlaceholderSelect')} {...params} />
            )}
            onChange={(event, data) => {
              if (box_parking_number) {
                setbox_parking_mandatory('optional');
                methods?.setValue('box_parking_mandatory', 'optional');
                methods?.setValue('box_parking_price_per_parking', '');
              } else if (outdoor_spots_number) {
                setoutdoor_spots_mandatory('optional');
                methods?.setValue('outdoor_spots_mandatory', 'optional');
                methods?.setValue('outdoor_spots_price_per_parking', '');
              } else if (underground_spots_number) {
                setunderground_spots_mandatory('optional');
                methods?.setValue('underground_spots_mandatory', 'optional');
                methods?.setValue('underground_spots_price_per_parking', '');
              }
              setValue(data);
              if (setRole) {
                setRole(data);
              }

              if (setproperty_locationsOne) {
                setproperty_locationsOne(data);
              }
              props.onChange(data?.value);
            }}
          />
        )}
      />
    </div>
  );
}

export default SelectField;
