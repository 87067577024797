import React from 'react';
import { useFormContext } from 'react-hook-form';

function CheckBox({ name, id, value, label, className, checked }) {
  const { register } = useFormContext();

  return (
    <div className={`flex ${className}`}>
      <input
        className="h-5 my-auto w-5 cursor-pointer"
        {...register(name)}
        type="checkbox"
        name={name}
        id={id}
        value={value}
        checked={checked}
      />
      <div className="my-auto ml-2">{label}</div>
    </div>
  );
}

export default CheckBox;
