import React from 'react';
import { useTranslation } from 'react-i18next';

function ResetPassword() {
  const { t } = useTranslation();
  return (
    <div className="w-full flex justify-center items-center h-full bg-signUpBG">
      <div className="flex items-center justify-center w-1/3 sm_ss:w-full sm_s:w-full sm_s:text-center sm_s:px-6 sm:w-full md:w-1/3 ">
        <p className="text-sectext text-center tracking-wider">{t('ResetPWDThanks')}</p>
      </div>
    </div>
  );
}

export default ResetPassword;
