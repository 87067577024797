import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { MdPermDeviceInformation } from 'react-icons/md';

export default function EmailVerified() {
  const { t } = useTranslation();
  const role = window.location.search?.split('role=')[1];

  const [text, setText] = useState();
  const languageSelected = Cookies.get('text');

  useEffect(() => {
    if (role === 'seeker') {
      setText('AfterLoginMsgConfirm');
    } else {
      setText('AfterLoginMsgConfirmProvider');
    }
  }, [role, languageSelected]);

  return (
    <div className="h-full w-full flex items-center justify-center">
      <div className="w-1/3 h-auto overflow-hidden sm_ss:w-full sm_s:w-full flex items-center bg-white rounded-xl p-5 shadow-lg text-[#6f6f6f]">
        <div className="flex items-start justify-start h-auto">
          <MdPermDeviceInformation size="100px" className="mr-1 " />
        </div>
        <span className="sub-div-text-msg h-auto">{t(text)}</span>
      </div>
    </div>
  );
}
