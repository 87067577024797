/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable arrow-body-style */
// eslint-disable-next-line no-unused-vars
/* eslint-disable camelcase */
import { FormControl, FormControlLabel, FormGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

function MultipleCheck({ name, label, Options, mainClassName, disabled, defaultValue, alerts }) {
  const { control } = useFormContext();
  const [stateValue, setStateValue] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    const housing_type = alerts?.map((item) => {
      return item?.housing_type;
    });
    if (defaultValue?.length >= 0) {
      const Data = defaultValue?.map((item) => {
        return item;
      });

      setStateValue(Data);
    }
    if (alerts) {
      setStateValue(housing_type);
    }
  }, [defaultValue, alerts]);

  const handleChange = (event, onChange) => {
    const selectValue = event.target.value;

    let newSelectedValues;

    if (selectedValues.includes(selectValue)) {
      // Deselect the value
      newSelectedValues = selectedValues.filter((v) => v !== selectValue);
    } else {
      // Select the value
      newSelectedValues = [selectValue];
    }
    setSelectedValues(newSelectedValues);
    if (event.target.checked) {
      if (selectValue === 'room') {
        onChange(['room']);
        setStateValue(['room']);
      } else {
        onChange([...stateValue, event.target.value]);
        setStateValue([...stateValue, event.target.value]);
      }
    } else {
      const array = [...stateValue];
      array.splice(array.indexOf(event.target.value), 1);
      onChange([...array]);
      setStateValue([...array]);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={stateValue}
      render={({ field: props }) => (
        <FormControl className="w-full" required component="fieldset" variant="standard">
          <span className="text-sectext pb-2 sm_ss:text-xs sm_s:text-xs">{label}</span>
          <FormGroup className="w-full">
            <div className={`flex flex-col ${mainClassName}`}>
              {Options?.map((item, key) => {
                return (
                  <FormControlLabel
                    key={`${key + 1}`}
                    control={
                      <input
                        className="h-5 my-auto w-5 md:ml-7 sm_s:ml-3 sm_ss:ml-3 mr-2 cursor-pointer"
                        onChange={(e) => handleChange(e, props.onChange)}
                        value={item.value}
                        type="checkbox"
                        checked={stateValue?.includes(item.value)}
                        disabled={disabled && stateValue[key] !== item.value}
                      />
                    }
                    label={<span className="sm_ss:text-xs sm_s:text-xs">{item.label}</span>}
                  />
                );
              })}
            </div>
          </FormGroup>
        </FormControl>
      )}
    />
  );
}

export default MultipleCheck;
