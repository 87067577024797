/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaPlus } from 'react-icons/fa';
import DraftButton from '../common/DraftButton';
import ErrorContainer from '../ErrorContainer';

function SellingForm({
  expandAll,
  collapseAll,
  errors,
  scrollTo,
  noScroll,
  scrollState,
  handelDraftSubmit,
  methods,
  language_selected,
  Data,
  draftLoading,
}) {
  const { t } = useTranslation();
  const [expended, setExpended] = useState(false);
  const [disable, setDisable] = useState(false);
  const { control } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const { selling_price } = methods.watch();

  useEffect(() => {
    if (expandAll) {
      setExpended(true);
    }
  }, [expandAll]);
  useEffect(() => {
    if (collapseAll) {
      setExpended(false);
    }
  }, [collapseAll]);

  useEffect(() => {
    if (!selling_price) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [selling_price]);

  return (
    <div className="border-2 rounded-lg overflow-hidden">
      <Accordion
        className="border-themeGray"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: '0',
          border: 'solid 2px #A6A6A6',
          borderRadius: '10px !important',
        }}
        expanded={expended}
        onChange={() => {
          setExpended(!expended);
        }}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          {expended ? (
            <FaMinus className="text-primary text-2xl my-auto" />
          ) : (
            <FaPlus className="text-primary text-2xl my-auto" />
          )}
          <div className="text-primary text-2xl font-extrabold px-3">{t('Selling price')}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col md:px-5 pb-4">
            <div className="grid md:grid-cols-3 gap-4 md:gap-12">
              <div ref={scrollState === 'selling_price' ? scrollTo : noScroll} className=" w-full">
                <div className="flex flex-col flex-1 h-24  relative sm_ss:pr-0 sm_s:pr-0">
                  <span className="text-sectext mb-2 h-8 sm_ss:text-xs sm_s:text-xs">
                    {t('Price')}
                  </span>
                  <FormControl variant="outlined">
                    <OutlinedInput
                      size="small"
                      type="number"
                      className="bg-white"
                      id="outlined-adornment-weight"
                      {...control.register('selling_price')}
                      endAdornment={<InputAdornment position="end">CHF</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        min: '0',
                      }}
                    />
                    {errors.selling_price ? (
                      <ErrorContainer errMsg={t(`${errors.selling_price.message}`)} />
                    ) : (
                      <div id="outlined-weight-helper-text" className="text-textGray text-sm mt-2">
                        {t('Formate:1000.00')}
                      </div>
                    )}
                  </FormControl>
                </div>
              </div>
            </div>
            <DraftButton
              handelDraftSubmit={() => {
                handelDraftSubmit('draft', setIsLoading);
              }}
              disable={disable}
              language_selected={language_selected}
              draftLoading={isLoading}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default SellingForm;
