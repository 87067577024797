/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable func-names */
/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */

import * as yup from 'yup';

export const headerText = {
  heading: 'CAGI HOUSING PLATFORM',
};
export const appUserRoles = {
  provider: 'provider',
  seeker: 'seeker',
};
export const ProviderForm = {
  HeaderTitle: 'private household employees platform',
  HeaderConnexion: 'connexion',
  TypeSelectorTitle: 'cagi private household employees platform',
  TypeSelectorDesc:
    'This platform is for authorised private household employees and employers in Switzerland in conformity with the Private Household Employees Ordinance of 6 June 2011 and who have a legitimation card from the Federal Department of Home Affairs (FFA).',
  ProfileConformation: 'Profile confirmation',
  TypeSelectorButtonEmployee: 'HOUSEHOLD EMPLOYEE',
  TypeSelectorButtonEmployer: 'EMPLOYER',
  ProfileConformationDesc:
    'This email address exists in our database. Please confirm your identification.',
  EMPLOYER_DETAILS: 'EMPLOYER DETAILS',
  EMPLOYER_AGREETERMS:
    'I hereby authorise the CAGI to give my contact details to any person looking for a private household employee in conformity with the Private Household Employees Ordinance of 6 June 2011 and to forward for approval my employment application to the Federal Department of Foreign Affairs (FFA)',
  EMPLOYER_RESPOSIBILITYTEXT:
    'The CAGI and the FFA decline all responsibility with regard to this employment application. Responsibility remains entirely with the person who has completed and send this employment application.',
  EMPLOYER_AGREETERMS2:
    'I also undertake to inform the CAGI without delay if I find a new employer.',
  PRIVATE_ADDRESS: 'Private Address',
  Employee_Agreement:
    'I hereby authorise the CAGI to give my contact details to any person looking for a private household employee in conformity with the Private Household Employees Ordinance of 6 June 2011 and to forward for approval my employment application to the Federal Department of Foreign Affairs (FFA).',
  Employee_Agreement2:
    'I also undertake to inform the CAGI without delay if I find a new employer.',
  ProviderHeading: 'Housing provider',
  Desiredhousing: 'Desired housing',
  MyProfile: 'My profile',
  RegistringAsA: 'I am registering as a (role) *',
  Accountpassword: 'Account password',
  AccountpasswordSub: 'Manage your housing preferences and email alerts.',
  SeekerTerms:
    'Please note that the housing service is only available to employees working for International Organizations, Permanent Missions, General Consulates, NGOs and International sports federations located in the canton of Geneva.',
  SeekerTermsAccept:
    'I accept to receive email alerts from CAGI and the the platform immobilier.ch in accordance with the terms of use of the housing program',
};

export const thankYouPage = {
  Thanks: 'Thank you for registering',
  Note: 'Please click the activation link we sent to your email ',
  Des1: 'To confirm your email address, an email has been sent to your email address containing an activation link. Please click on the link to activate your account. If you do not click the link your account will remain inactive',
  Des2: 'If you do not receive the email within a few minutes, please check your spam folder for a message from immo.cagi@etat.ge.ch',
};

export const HeaderTitle = {
  HeaderTitle: 'CAGI HOUSING PLATFORM',
};

export const offerOfEmployement = {
  HEading: ' Offer of employement',
  Des: ' This request has to be completed in person by the individual wishing to hire a private household employee in conformity with the',
  Des2: 'Private Household Employees Ordinance',
  btnText: 'continue',
};

export const LoginText = {
  btnText: 'Login',
  forgetPWD: 'Forgot your password?',
};

export const profileConformation = {
  ProfileConformation: 'Profile confirmation',
  ProfileConformationDesc:
    'This email address exists in our database. Please confirm your identification.',
};

export const propertyStatus = {
  ACTIVE: {
    value: 'active',
    label: 'ACTIVE',
    color: 'bg-[#73C768]',
  },
  UNDER_REVIEW: {
    value: 'under-review',
    label: 'UNDER REVIEW',
    color: 'bg-[#6F6F6F]',
  },
  DRAFT: {
    value: 'draft',
    label: 'DRAFT',
    color: 'bg-[#C9C9C9]',
  },
  EXPIRED: {
    value: 'expired',
    label: 'EXPIRED',
    color: 'bg-[#C9C9C9]',
  },
  REJECTED: {
    value: 'rejected',
    label: 'REJECTED',
    color: 'bg-[#D13838]',
  },
};

export const RightButtonData = [
  {
    id: 1,
    name: 'Geneva city right',
    value: 'geneva_city_right',
  },
  {
    id: 2,
    name: 'Geneva city left',
    value: 'geneva_city_left',
  },
  {
    id: 3,
    name: 'Geneva left country-side',
    value: 'geneva_left_country_side',
  },
  { id: 4, name: 'Geneva right country-side', value: 'geneva_right_country_side' },
];

export const LeftButtonData = [
  { id: 1, name: 'Nyon district', value: 'nyon_district' },
  {
    id: 2,
    name: 'Lausanne region',
    value: 'lausanne_region',
  },
  {
    id: 3,
    name: 'France (border region)',
    value: 'france',
  },
];

export const userRoles = [
  { label: 'Real Estate Agency', value: 'real_estate_agency' },
  { label: 'Caretaker', value: 'caretaker' },
  { label: 'Landlord', value: 'landlord' },
  { label: 'Tenant', value: 'tenant' },
  { label: 'Agent', value: 'agent' },
];

export const userRolesfr = [
  { label: 'Agence immobilière', value: 'real_estate_agency' },
  { label: 'Concierge', value: 'caretaker' },
  { label: 'Propriétaire', value: 'landlord' },
  { label: 'Locataire', value: 'tenant' },
  { label: 'Agent', value: 'agent' },
];

export const rolesConstant = [
  { label: 'Real Estate Agency', value: 'real_estate_agency', frValue: 'Agence immobilière' },
  { label: 'Caretaker', value: 'caretaker', frValue: 'Concierge' },
  { label: 'Landlord', value: 'landlord', frValue: 'Propriétaire' },
  { label: 'Tenant', value: 'tenant', frValue: 'Locataire' },
  { label: 'Agent', value: 'agent', frValue: 'Agent' },
];

export const MemberRoles = [
  { label: 'Admin', value: 'admin' },
  { label: 'Publisher', value: 'publisher' },
];
export const MemberRolesFr = [
  { label: 'Administrateur', value: 'admin' },
  { label: 'Éditeur', value: 'publisher' },
];
export const titleOptions = [
  {
    value: 'mr',
    label: 'Mr',
    id: 1,
  },
  {
    value: 'mrs',
    label: 'Mrs',
    id: 2,
  },
];

export const titleOptionsfr = [
  {
    value: 'mr',
    label: 'M',
    id: 1,
  },
  {
    value: 'mrs',
    label: 'Mme',
    id: 2,
  },
];

export const language = [
  { label: 'English', value: 'english', id: 1 },
  { label: 'French', value: 'french', id: 2 },
];

export const languagefr = [
  { label: 'Anglais', value: 'english', id: 1 },
  { label: 'Français', value: 'french', id: 2 },
];

export const accomodationOptions = [
  {
    value: 'cagi',
    label: 'CAGI',
  },
  {
    value: 'individual_searches',
    label: 'Individual searches',
  },
  {
    value: 'relocation_agency',
    label: 'Relocation agency',
  },
  {
    value: 'friends_acquaintances',
    label: 'Friends/acquaintances',
  },
  {
    value: 'at_work',
    label: 'At work',
  },
  {
    value: 'other_website',
    label: 'Other website (glocals, comparis...)',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const accomodationOptionsFr = [
  {
    value: 'cagi',
    label: 'CAGI',
  },
  {
    value: 'individual_searches',
    label: 'Recherches individuelles',
  },
  {
    value: 'relocation_agency',
    label: 'Agence de relocation',
  },
  {
    value: 'friends_acquaintances',
    label: 'Amis/connaissances',
  },
  {
    value: 'at_work',
    label: 'Au travail',
  },
  {
    value: 'other_website',
    label: 'Autre site internet (glocals, comparis...)',
  },
  {
    value: 'other',
    label: 'Autres',
  },
];

export const premise = [
  { label: 'The Landlord', value: 'the_landlord' },
  { label: 'A tenant (sublet)', value: 'a_tenant' },
  { label: 'A relocation agent or the regie', value: 'a_relocation_agent_or_the_regie' },
];

export const premisefr = [
  { label: 'Le propriétaire', value: 'the_landlord' },
  { label: 'Un locataire (sous-location)', value: 'a_tenant' },
  { label: 'Un agent de relocalisation ou la régie', value: 'a_relocation_agent_or_the_regie' },
];

export const totalRental = [
  {
    value: '1_to_5',
    label: '1 to 5',
  },
  {
    value: '6_to_10',
    label: '6 to 10',
  },
  {
    value: '11_to_20',
    label: '11 to 20',
  },
  {
    value: 'more_than_21',
    label: '+ More than 21',
  },
];

export const totalRentalFr = [
  {
    value: '1_to_5',
    label: '1 à 5',
  },
  {
    value: '6_to_10',
    label: '6 à 10',
  },
  {
    value: '11_to_20',
    label: '11 à 20',
  },
  {
    value: 'more_than_21',
    label: '+ de 21',
  },
];
export const landlordAccomodation = [
  {
    value: 'a_landlord',
    label: 'A landlord',
  },
  {
    value: 'a_tenant',
    label: 'A tenant (subletting)',
  },
  {
    value: 'a_real_estate_agency',
    label: 'A Real Estate Agency',
  },
];

export const landlordAccomodationFr = [
  {
    value: 'a_landlord',
    label: 'Un propriétaire',
  },
  {
    value: 'a_tenant',
    label: 'Un locataire (sous-location)',
  },
  {
    value: 'a_real_estate_agency',
    label: 'Une régie',
  },
];

export const agencyType = [
  {
    value: '1',
    label: 'Value 1',
  },
  {
    value: '2',
    label: 'Value 2',
  },
  {
    value: '3',
    label: 'Value 3',
  },
];

export const ourServices = [
  {
    value: 'very_useful',
    label: 'Very useful',
  },
  {
    value: 'useful',
    label: 'Useful',
  },
  {
    value: 'moderate_useful',
    label: 'Moderately useful',
  },
  {
    value: 'not_very_useful',
    label: 'Not very  useful',
  },
];

export const ourServicesFr = [
  {
    value: 'very_useful',
    label: 'Très utiles',
  },
  {
    value: 'useful',
    label: 'Utiles',
  },
  {
    value: 'moderate_useful',
    label: 'Moyennement utiles',
  },
  {
    value: 'not_very_useful',
    label: 'Peu utiles',
  },
];

export const providerFormSchema = yup.object().shape({
  title: yup.string().required('Title is required').trim().nullable(),
  first_name: yup
    .string()
    .trim()
    .nullable()
    .required('First name is required')
    .max(100, 'Maximum 100 characters allowed'),
  last_name: yup
    .string()
    .trim()
    .nullable()
    .required('Last name is required')
    .max(100, 'Maximum 100 characters allowed'),
  email: yup.string().email('Please enter a valid email').required('Email is required').trim(),
  mobile_number: yup
    .string()
    .trim()
    .required('Mobile number is required')
    .min(6, 'min. 6 and max 13 digits are allowed')
    .max(13, 'min. 6 and max 13 digits are allowed'),
  preferred_language: yup.string().required('Language is required').trim(),
  chosen_role: yup.string().required('Role is required').trim(),
  // organisation_name: yup.string().required('Organization name is required').trim().nullable(),
  // organisation_email: yup
  //   .string()
  //   .email()
  //   .required('Organization email is required')
  //   .trim()
  //   .nullable(),
  // organisation_phone: yup.string().required('Organization number is required').trim().nullable(),
  // street: yup.string().required('Street address is required').trim(),
  // street_number: yup.string().required('NB is required').trim(),
  password: yup.string().min(8).required('Password is required').trim(),
  confirm_password: yup
    .string()
    .trim()
    .nullable()
    .required('Repeat password is required')
    .oneOf([yup.ref('password')], 'Repeat password and password must match'),
});

export const providerFormOrganizationSchema = yup.object().shape({
  title: yup.string().required('Title is required').trim().nullable(),
  first_name: yup
    .string()
    .required('First name is required')
    .max(100, 'Maximum 100 characters allowed')
    .trim()
    .nullable(),
  last_name: yup
    .string()
    .required('Last name is required')
    .max(100, 'Maximum 100 characters allowed')
    .trim()
    .nullable(),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required')
    .trim()
    .nullable(),
  mobile_number: yup
    .string()
    .required('Mobile number is required')
    .min(6, 'min. 6 and max 13 digits are allowed')
    .max(13, 'min. 6 and max 13 digits are allowed')
    .trim()
    .nullable(),
  preferred_language: yup.string().required('Language is required').trim().nullable(),
  chosen_role: yup.string().required('Role is required').trim().nullable(),
  organisation_name: yup.string().required('Organization name is required').trim().nullable(),
  organisation_email: yup
    .string()
    .email()
    .required('Organization email is required')
    .trim()
    .nullable(),
  organisation_phone: yup.string().required('Organization number is required').trim().nullable(),

  // street: yup.string().required('Street address is required').trim().nullable(),
  // street_number: yup.string().required('NB is required').trim().nullable(),
  // zip_code: yup.string().required(t('ZipCodeErr')),
  // city: yup.string().required(t('CityErr')),
  password: yup.string().min(8).required('Password is required').trim().nullable(),
  confirm_password: yup
    .string()
    .trim()
    .nullable()
    .required('Repeat password is required')
    .oneOf([yup.ref('password')], 'Repeat password and password must match'),
});

export const suspendFormSchema = yup.object().shape({
  reason: yup.string().required('Please select your reason').trim().nullable(),
  satisfaction: yup.string().required('Please select your satisfaction reason').trim().nullable(),
});

export const AddNewMemberSchema = yup.object().shape({
  title: yup.string().required('Title is required').trim().nullable(),
  // country: yup.string().required('Country is required').trim().nullable(),
  first_name: yup
    .string()
    .required('First name is required')
    .max(100, 'Maximum 100 characters allowed')
    .trim()
    .nullable(),
  last_name: yup
    .string()
    .required('Last name is required')
    .max(100, 'Maximum 100 characters allowed')
    .trim()
    .nullable(),
  email: yup.string().email('Please enter a valid email').required('Email is required').trim(),
  mobile_number: yup
    .string()
    .required('Mobile number is required')
    .min(6, 'min. 6 and max 13 digits are allowed')
    .max(13, 'min. 6 and max 13 digits are allowed')
    .trim()
    .nullable(),
  preferred_language: yup.string().required('Language is required').trim().nullable(),
  // street: yup.string().required('Street address is required').trim().nullable(),
  // street_number: yup.string().required('NB is required').trim().nullable(),
  // zip_code: yup.string().required('Zip code is required').trim().nullable(),
  // city: yup.string().required('City is required').trim().nullable(),
  password: yup.string().min(8).required('Password is required').trim().nullable(),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Repeat password and password must match')
    .required('Repeat password is required')
    .trim()
    .nullable(),
  member_role: yup.string().required('Role is required').trim().nullable(),
});

export const seekerFormSchema = yup.object().shape({
  title: yup.string().required('Title is required').trim().nullable(),
  first_name: yup.string().required('First name is required').trim().nullable(),
  last_name: yup.string().required('Last name is required').trim().nullable(),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required')
    .trim()
    .nullable(),
  // mobile_number: yup
  //   .string()
  //   .required('Mobile number is required')
  //   .min(6, 'min. 6 and max 13 digits are allowed')
  //   .max(13, 'min. 6 and max 13 digits are allowed')
  //   .trim()
  //   .nullable(),
  // professional_phone: yup
  //   .string()
  //   // .required('Mobile number is required')
  //   .min(6, 'min. 6 and max 13 digits are allowed')
  //   .max(13, 'min. 6 and max 13 digits are allowed')
  //   .trim()
  //   .nullable(),
  spouse_partner: yup.boolean().required('Please select spouse partner').nullable(),
  children: yup.boolean().required('Please select children').nullable(),
  children_count: yup
    .string()
    .when('children', {
      is: (children) => children === true,
      then: yup.string().required('Please select children value').trim(),
    })
    .nullable(),
  organisation_employer: yup.string().required('Please enter organisation employer'),
  preferred_language: yup.string().required('Please select prefered language').trim().nullable(),
  employed_by: yup.string().required('Please select employed by').trim().nullable(),
  position: yup.string().required('Please select position').trim().nullable(),
  legitimation_card_type: yup
    .string()
    .required('Please select legitimation card type')
    .trim()
    .nullable(),
  password: yup
    .string()
    .min(8)
    .required('password must be at least 8 characters')
    .min(8, 'password must be at least 8 characters')
    .trim()
    .nullable(),
  confirm_password: yup
    .string()
    .required('Repeat password is required')
    .oneOf([yup.ref('password')], 'Repeat password and password must match')
    .trim()
    .nullable(),
});

export const satisfactionSurveySchema = yup.object().shape({
  accommodation_through: yup
    .string()
    .required('Please select the type of accommodation')
    .trim()
    .nullable(),
  // other_accomodation_through: yup.string().trim().nullable()
  //   .max(30, 'Must be exactly 30 characters'),

  other_accomodation_through: yup
    .string()
    .when('accommodation_through', {
      is: (accommodation_through) => accommodation_through == 'other',
      then: yup
        .string()
        .required('Other field is required')
        .max(30, 'Must be exactly 30 characters')
        .trim(),
    })
    .nullable(),
  rental_applications_count: yup
    .string()
    .required('Please select the rental application')
    .trim()
    .nullable(),
  allocated_landlord: yup.string().required('Select the type of landlord').trim().nullable(),
  // agency: yup
  //   .string()
  //   .required('Please fill the agency')
  //   .max(30, 'Must be exactly 30 characters')
  //   .trim()
  //   .nullable(),
  services_use: yup.string().required('Please rate our services').trim().nullable(),
  // comment: yup.string().required('Comments is required').trim().nullable(),
});

export const seekerProfileUpdateFormSchema = yup.object().shape(
  {
    title: yup.string().required('Title is required').trim().nullable(),
    first_name: yup.string().required('First name is required').trim().nullable(),
    last_name: yup.string().required('Last name is required').trim().nullable(),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Email is required')
      .trim()
      .nullable(),
    // mobile_number: yup
    //   .string()
    //   .required('Mobile number is required')
    //   .min(6, 'min. 6 and max 13 digits are allowed')
    //   .max(13, 'min. 6 and max 13 digits are allowed')
    //   .trim()
    //   .nullable(),
    // professional_phone: yup
    //   .string()
    //   // .required('Mobile number is required')
    //   .min(6, 'min. 6 and max 13 digits are allowed')
    //   .max(13, 'min. 6 and max 13 digits are allowed')
    //   .trim()
    //   .nullable(),
    spouse_partner: yup.boolean().required('Please select spouse partner').nullable(),
    children: yup.boolean().required('Please select children').nullable(),
    children_count: yup
      .string()
      .when('children', {
        is: (children) => children === true,
        then: yup.string().required('Please select children value').trim(),
      })
      .nullable(),
    preferred_language: yup.string().required('Please select prefered language').trim().nullable(),
    employed_by: yup.string().required('Please select employed by').trim().nullable(),
    position: yup.string().required('Please select position').trim().nullable(),
    organisation_employer: yup
      .string()
      .required('Please enter organisation employer')
      .trim()
      .nullable(),
    legitimation_card_type: yup
      .string()
      .required('Please select legitimation card type')
      .trim()
      .nullable(),
    oldpassword: yup.string().when('password', {
      is: (password) => password?.length > 0,
      then: yup.string().required('Please enter your old password').min(8),
      // otherwise: yup.string().password().required(t('PasswordErr')),
    }),
    password: yup.string().when('oldpassword', {
      is: (oldpassword) => oldpassword?.length > 0,
      then: yup.string().required('Please enter your new password').min(8),
    }),
    confirm_password: yup.string().when('password', {
      is: (password) => password?.length > 0,
      then: yup
        .string()
        .required('Please enter your repeat password')
        .oneOf([yup.ref('password')], 'New password and repeat password do not match'),
    }),
  },
  [['password', 'oldpassword', 'confirm_password']]
);

export const seekerFormSchemaFirstPage = yup.object().shape({
  request_type: yup.string().required('RequestTypeErr').nullable(),
  housing_type: yup.array().min(1, 'Check at least one Housing type'),
  furnished: yup
    .string()
    .when('housing_type', {
      is: (housing_type) => {
        let validate = false;
        if (!housing_type?.includes('room')) {
          validate = true;
        }
        return validate;
      },
      then: () => yup.string().required('Furnished is required'),
    })
    .trim(),
  minimum_bedrooms: yup
    .string()
    .when('housing_type', {
      is: (housing_type) => {
        let validate = false;
        if (!housing_type?.includes('room')) {
          validate = true;
        }
        return validate;
      },
      then: () => yup.string().required('Please enter number of bedrooms'),
    })
    .trim(),
  maximum_budget_range: yup
    .string()
    .required('Please enter maximum budget')
    .min(0, 'Please enter a valid amount'),
  duration: yup
    .string()
    .when('request_type', {
      is: (request_type) => {
        let validate = false;
        if (request_type === 'rent') {
          validate = true;
        }
        return validate;
      },
      then: () => yup.string().required('Please select duration').nullable(),
    })
    .trim()
    .nullable(),
});

export const housingTypeOption = [
  { label: 'Room', value: 'room', id: 1 },
  { label: 'Apartment', value: 'apartment', id: 1 },
  { label: 'House', value: 'house', id: 1 },
];

export const housingTypeOptionfr = [
  { label: 'Chambre', value: 'room', id: 1 },
  { label: 'Appartement', value: 'apartment', id: 1 },
  { label: 'Maison', value: 'house', id: 1 },
];

export const housingTypeBuyOption = [
  { label: 'Apartment', value: 'apartment', id: 1 },
  { label: 'House', value: 'house', id: 1 },
];

export const housingTypeBuyOptionfr = [
  { label: 'Appartement', value: 'apartment', id: 1 },
  { label: 'Maison', value: 'house', id: 1 },
];

export const amenitiesTypeOption = [
  { label: 'Balcony', value: 'balcony', id: 1 },
  { label: 'Terrace', value: 'terrace', id: 1 },
  { label: 'Garden', value: 'garden', id: 1 },
];

export const amenitiesTypeOptionfr = [
  { label: 'Balcon', value: 'balcony', id: 1 },
  { label: 'Terrasse', value: 'terrace', id: 1 },
  { label: 'Jardin', value: 'garden', id: 1 },
];

export const furnished = [
  { label: 'Furnished', value: 'yes', id: 1 },
  { label: 'Unfurnished', value: 'no', id: 2 },
  { label: 'Indifferent', value: 'indifferent', id: 3 },
];

export const furnishedfr = [
  { label: 'Equipée', value: 'yes', id: 1 },
  { label: 'Agencée', value: 'no', id: 2 },
  { label: 'Indifférent', value: 'indifferent', id: 3 },
];

export const FurnishedBoolean = [
  { label: 'Yes', value: true, id: 1 },
  { label: 'No', value: false, id: 2 },
];

export const FurnishedBooleanfr = [
  { label: 'Oui', value: true, id: 1 },
  { label: 'Non', value: false, id: 2 },
];

export const ChildrenYesArray = [
  { label: '1', value: '1', id: 1 },
  { label: '2', value: '2', id: 2 },
  { label: '3', value: '3', id: 3 },
  { label: '4', value: '4', id: 4 },
  { label: '5', value: '5', id: 5 },
  { label: '6+', value: '6_above', id: 6 },
];

export const bedRooms = [
  { label: '1', value: '1', id: 1 },
  { label: '2', value: '2', id: 2 },
  { label: '3', value: '3', id: 3 },
  { label: '4', value: '4', id: 4 },
  { label: '5', value: '5', id: 5 },
  { label: '6', value: '6', id: 6 },
  { label: '7', value: '7', id: 7 },
  { label: '8', value: '8', id: 8 },
  { label: '9', value: '9', id: 9 },
  { label: '10', value: '10', id: 10 },
];

export const budgetRanges = [
  { label: `CHF 1'000 and less`, value: '0 - 1000', id: 1 },
  { label: `CHF 1'001 and 2'000`, value: '1001 - 2000', id: 2 },
  { label: `CHF 2'001 and 3'000`, value: '2001 - 3000', id: 3 },
  { label: `CHF 3'001 and 4'500`, value: '3001 - 4500', id: 4 },
  { label: `CHF 4'501 and 6'000`, value: '4501 - 6000', id: 5 },
  { label: `CHF 6'001 and 500'000`, value: '6001 - 500000', id: 6 },
  { label: `CHF 500'001 and 1'000'000`, value: '500001 - 1000000', id: 7 },
  { label: `CHF 1'000'001 and 2'000'000`, value: '1000001 - 2000000', id: 8 },
  { label: `CHF 2'000'001 and 3'000'000`, value: '2000001 - 3000000', id: 9 },
  { label: `More than CHF 3'000'000`, value: 'More than CHF 3000000', id: 10 },
];

export const budgetRangesfr = [
  { label: `CHF 1'000 et moins`, value: '0 - 1000', id: 1 },
  { label: `CHF 1'001 et 2'000`, value: '1001 - 2000', id: 2 },
  { label: `CHF 2'001 et 3'000`, value: '2001 - 3000', id: 3 },
  { label: `CHF 3'001 et 4'500`, value: '3001 - 4500', id: 4 },
  { label: `CHF 4'501 et 6'000`, value: '4501 - 6000', id: 5 },
  { label: `CHF 6'001 et 500'000`, value: '6001 - 500000', id: 6 },
  { label: `CHF 500'001 et 1'000'000`, value: '500001 - 1000000', id: 7 },
  { label: `CHF 1'000'001 et 2'000'000`, value: '1000001 - 2000000', id: 8 },
  { label: `CHF 2'000'001 et 3'000'000`, value: '2000001 - 3000000', id: 9 },
  { label: `Plus que CHF 3'000'000`, value: 'More than CHF 3000000', id: 10 },
];

export const EmployedBy = [
  { label: 'UN Geneva', value: 'un_geneva', id: 1 },
  { label: 'CERN', value: 'cern', id: 2 },
  { label: 'Other international organization', value: 'other_international_orgnaization', id: 3 },
  { label: 'Permanent mission', value: 'permanent_mission', id: 4 },
  { label: 'Consulate', value: 'consulate', id: 5 },
  { label: 'UNIGE/HUG', value: 'unige_hug', id: 6 },
  { label: 'NGO', value: 'ngo', id: 7 },
  // { label: 'Multinational company', value: 'mulinational_company', id: 8 },
];

export const EmployedByfr = [
  { label: 'ONU Genève', value: 'un_geneva', id: 1 },
  { label: 'CERN', value: 'cern', id: 2 },
  { label: 'Autre organisation internationale', value: 'other_international_orgnaization', id: 3 },
  { label: 'Mission permanente', value: 'permanent_mission', id: 4 },
  { label: 'Consulat', value: 'consulate', id: 5 },
  { label: 'UNIGE/HUG', value: 'unige_hug', id: 6 },
  { label: 'ONG', value: 'ngo', id: 7 },
  // { label: 'Entreprise multinationale', value: 'mulinational_company', id: 8 },
];

export const LegitimationCard = [
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' },
  { label: 'E', value: 'E' },
  { label: 'F', value: 'F' },
  { label: 'G', value: 'G' },
  { label: 'H', value: 'H' },
  { label: 'I', value: 'I' },
  { label: 'K', value: 'K' },
  { label: 'L', value: 'L' },
  { label: 'P', value: 'P' },
  { label: 'R', value: 'R' },
  { label: 'S', value: 'S' },
  { label: 'Ci', value: 'Ci' },
  { label: 'KB', value: 'KB' },
  { label: 'KC', value: 'KC' },
  { label: 'KD', value: 'KD' },
  { label: 'KE', value: 'KE' },
  { label: 'KH', value: 'KH' },
  { label: 'NGO ', value: 'ngo' },
  { label: 'HUG/UNIGE ', value: 'hug_unige' },
  { label: 'To receive', value: 'to_receive' },
];

export const LegitimationCardFR = [
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' },
  { label: 'E', value: 'E' },
  { label: 'F', value: 'F' },
  { label: 'G', value: 'G' },
  { label: 'H', value: 'H' },
  { label: 'I', value: 'I' },
  { label: 'K', value: 'K' },
  { label: 'L', value: 'L' },
  { label: 'P', value: 'P' },
  { label: 'R', value: 'R' },
  { label: 'S', value: 'S' },
  { label: 'Ci', value: 'Ci' },
  { label: 'KB', value: 'KB' },
  { label: 'KC', value: 'KC' },
  { label: 'KD', value: 'KD' },
  { label: 'KE', value: 'KE' },
  { label: 'KH', value: 'KH' },
  { label: 'ONG ', value: 'ngo' },
  { label: 'À recevoir', value: 'to_receive' },
  { label: 'HUG/UNIGE ', value: 'hug_unige' },
];

export const defaultOption = {
  first_name: 'Enter your first name',
  position: 'Enter your position',
  last_name: 'Enter your last name',
  email: 'Enter your email',
  mobile_number: 'Enter your mobile number',
  street: 'Enter your street',
  nb: 'Enter your NB',
  zip_code: 'Enter your zip code',
  city: 'Enter your city',
};

export const currencyOptions = [
  {
    value: 'USD',
    label: 'USD',
    id: 1,
  },
  {
    value: 'CHF',
    label: 'CHF',
    id: 2,
  },
];
export const numbers = [
  { label: 'Lower ground floor', value: 'lower_ground_floor' },
  { label: 'Ground floor', value: 'ground_floor' },
  { label: 'Upper ground floor', value: 'upper_ground_floor' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20_above', label: '20+' },
];

export const numbersfr = [
  { label: 'Rez inférieur', value: 'lower_ground_floor' },
  { label: 'Rez', value: 'ground_floor' },
  { label: 'Rez supérieur', value: 'upper_ground_floor' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20_above', label: '20+' },
];

export const Numbers = [
  { value: 'Studio', label: 'Studio' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
];
export const noOfbathrooms = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
];

export const propertyLocation = [
  { value: 'geneva_city_left', label: 'Geneva city left', id: 1 },
  { value: 'geneva_city_right', label: 'Geneva city right', id: 2 },
  { value: 'geneva_left_country_side', label: 'Geneva left country-side', id: 3 },
  { value: 'geneva_right_country_side', label: 'Geneva right country-side', id: 4 },
];

export const propertyLoc = [
  { value: 'nyon_district', label: 'Nyon district', id: 1 },
  { value: 'lausanne_region', label: 'Lausanne region', id: 2 },
  { value: 'france', label: 'France (border region)', id: 3 },
];

export const housingTypeOptions = [
  {
    value: 'apartment',
    label: 'Apartment',
    id: 1,
  },
  {
    value: 'room',
    label: 'Room',
    id: 2,
  },
  {
    value: 'house',
    label: 'House',
    id: 3,
  },
];
export const housingTypeOptionsfr = [
  {
    value: 'apartment',
    label: 'Appartement',
    id: 1,
  },
  {
    value: 'room',
    label: 'Chambre',
    id: 2,
  },
  {
    value: 'house',
    label: 'Maison',
    id: 3,
  },
];

export const SellhousingTypeOptions = [
  {
    value: 'apartment',
    label: 'Apartment',
    id: 1,
  },
  {
    value: 'house',
    label: 'House',
    id: 3,
  },
];
export const SellhousingTypeOptionsfr = [
  {
    value: 'apartment',
    label: 'Appartement',
    id: 1,
  },
  {
    value: 'house',
    label: 'Maison',
    id: 3,
  },
];

export const apartmentTypeOptions = [
  {
    value: 'standard',
    label: 'Standard',
    id: 1,
  },
  {
    value: 'penthouse',
    label: 'Penthouse',
    id: 2,
  },
  {
    value: 'duplex',
    label: 'Duplex',
    id: 3,
  },
  {
    value: 'with_garden',
    label: 'With garden',
    id: 4,
  },
];

export const apartmentTypeOptionsfr = [
  {
    value: 'standard',
    label: 'Standard',
    id: 1,
  },
  {
    value: 'penthouse',
    label: 'Attique',
    id: 2,
  },
  {
    value: 'duplex',
    label: 'Duplex',
    id: 3,
  },
  {
    value: 'with_garden',
    label: 'Avec jardin',
    id: 4,
  },
];

export const houseTypeSubOptions = [
  {
    value: 'individual',
    label: 'Individual',
    id: 1,
  },
  {
    value: 'twin',
    label: 'Twin',
    id: 2,
  },
  {
    value: 'semi_detached',
    label: 'Semi-detached',
    id: 3,
  },
  {
    value: 'adjoining',
    label: 'Adjoining',
    id: 3,
  },
  {
    value: 'village_house',
    label: 'Town house',
    id: 4,
  },
];

export const houseTypeSubOptionsfr = [
  {
    value: 'individual',
    label: 'Individuelle',
    id: 1,
  },
  {
    value: 'twin',
    label: 'Jumelle',
    id: 2,
  },
  {
    value: 'semi_detached',
    label: 'Mitoyenne',
    id: 3,
  },
  {
    value: 'adjoining',
    label: 'Contiguë',
    id: 3,
  },
  {
    value: 'village_house',
    label: 'Maison de village',
    id: 4,
  },
];

export const addAndEditPropertyFormsText = {
  property: {
    heading: 'Property',
  },
  MonthlyRent: {
    heading: 'Monthly rent',
  },
  address: {
    heading: 'Address',
  },
  details: {
    heading: 'Details',
  },
  description: {
    heading: 'Description',
  },
  parkingAndGarage: {
    heading: 'Parking/Garage',
  },
  pictures: {
    heading: 'Pictures',
  },
  visits: {
    heading: 'Visits',
  },
};

export const renewablePeriod = [
  {
    value: '1_to_3_months',
    label: '1 to 3 months',
    id: 1,
  },
  {
    value: '4_to_6_months',
    label: '4 to 6 months',
    id: 2,
  },
  {
    value: '1_year_or_plus',
    label: '1 year or +',
    id: 3,
  },
];

export const renewablePeriodfr = [
  {
    value: '1_to_3_months',
    label: '1 à 3 mois',
    id: 1,
  },
  {
    value: '4_to_6_months',
    label: '4 à 6 mois',
    id: 2,
  },
  {
    value: '1_year_or_plus',
    label: '1 année ou +',
    id: 3,
  },
];
/* eslint-disable camelcase */
export const addPropertyFormSchema = yup.object().shape({
  housing_type: yup.string().required('Housing type is required').trim().nullable(),
  sub_type: yup
    .string()
    .when('housing_type', {
      is: (housing_type) => {
        let validate = false;
        if (housing_type !== 'room') {
          validate = true;
        }
        return validate;
      },
      then: () => yup.string().required('Please select sub type').nullable(),
    })
    .trim()
    .nullable(),
  floor: yup
    .string()
    .when('housing_type', {
      is: (housing_type) => housing_type === 'apartment' || housing_type === 'room',
      then: () =>
        yup
          .string()
          .required('Number of Floor is required')
          .trim()
          .nullable('Number of Floor is required'),
    })
    .nullable(),
  availability: yup.string().required('Availability is required').trim().nullable(),
  // until_end: yup.string().required(Date is required).trim(),
  net_rent: yup.string().required('Net rent is required').trim().nullable(),
  monthly_charge_option: yup.string().required('Please Select one option').trim().nullable(),
  country: yup.string().required('Country is required').trim().nullable(),
  street: yup.string().required('Street address is required').trim().nullable(),
  nb: yup.string().required('NB is required').trim().nullable(),
  zip_code: yup.string().required('Zip code is required').trim().nullable(),
  box_parking_price_per_parking: yup.string().when('box_parking_mandatory', {
    is: (box_parking_mandatory) => {
      let validate = false;
      if (box_parking_mandatory === 'mandatory') {
        validate = true;
        return validate;
      }
      return validate;
    },
    then: () => yup.string().required('The monthly rent is required'),
    otherwise: () => yup.string(),
  }),
  outdoor_spots_price_per_parking: yup.string().when('outdoor_spots_mandatory', {
    is: (outdoor_spots_mandatory) => {
      let validate = false;

      if (outdoor_spots_mandatory === 'mandatory') {
        validate = true;
        return validate;
      }
      return validate;
    },
    then: () => yup.string().required('The monthly rent is required'),
    otherwise: () => yup.string(),
  }),
  underground_spots_price_per_parking: yup.string().when('underground_spots_mandatory', {
    is: (underground_spots_mandatory) => {
      let validate = false;
      if (underground_spots_mandatory === 'mandatory') {
        validate = true;
        return validate;
      }
      return validate;
    },
    then: () => yup.string().required('The monthly rent is required'),
    otherwise: () => yup.string(),
  }),
  city: yup
    .string()
    .when('country', {
      is: (country) => {
        let validate = false;
        if (country === 'france' || country === undefined || country === null) {
          validate = true;
        } else {
          validate = false;
        }
        return validate;
      },
      then: () => yup.string().required('City is required').nullable(),
    })
    .trim()
    .nullable(),
  furniture: yup.string().required('Furniture status is required').trim().nullable(),
  number_of_rooms: yup
    .string()
    .when('housing_type', {
      is: 'room',
      then: () => {},
      otherwise: () => yup.string().required('Number of rooms is required').nullable(),
    })
    .trim()
    .nullable(),
  number_of_bedrooms: yup
    .string()
    .when('housing_type', {
      is: 'room',
      then: () => {},
      otherwise: () => yup.string().required('Number of bedrooms is required').nullable(),
    })
    .trim()
    .nullable(),
  number_of_bathrooms: yup
    .string()
    .when('housing_type', {
      is: 'room',
      then: () => {},
      otherwise: () => yup.string().required('Number of bathrooms is required').nullable(),
    })
    .trim()
    .nullable(),
  kitchen: yup
    .string()
    .when('housing_type', {
      is: 'room',
      then: () => {},
      otherwise: () => yup.string().required('Kitchen area is required').nullable(),
    })
    .trim()
    .nullable(),
  kitchen_style: yup
    .string()
    .when('housing_type', {
      is: 'room',
      then: () => {},
      otherwise: () => yup.string().required('Kitchen style is required').nullable(),
    })
    .trim()
    .nullable(),
  box_parking_number: yup
    .string()
    .when('box_parking_included_in_price', {
      is: 'true',
      then: yup.string().required('Please select box number').nullable(),
    })
    .nullable(),
  outdoor_spots_number: yup
    .string()
    .when('outdoor_spots_included_in_price', {
      is: 'true',
      then: yup.string().required('Please select box number'),
    })
    .nullable(),
  underground_spots_number: yup
    .string()
    .when('underground_spots_included_in_price', {
      is: 'true',
      then: yup.string().required('Please select box number').nullable(),
    })
    .nullable(),
  english_description: yup
    .string()
    // .required('English description is required')
    .trim()
    .nullable(),
  french_description: yup
    .string()
    // .required('french description is required')
    .trim()
    .nullable(),
  title: yup.string().required('Title is required').trim().nullable(),
  first_name: yup
    .string()
    .required('First name is required')
    .max(100, 'Maximum 100 characters allowed')
    .trim()
    .nullable(),
  last_name: yup
    .string()
    .required('Last name is required')
    .max(100, 'Maximum 100 characters allowed')
    .trim()
    .nullable(),
  role: yup.string().required('Role is required').trim().nullable(),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required')
    .trim()
    .nullable(),
  phone: yup
    .string()
    .required('Mobile number is required')
    .min(6, 'min. 6 and max 13 digits are allowed')
    .max(13, 'min. 6 and max 13 digits are allowed')
    .trim()
    .nullable(),
  english_comment: yup.string().required('Description is required').trim().nullable(),
});

export const sellPropertyFormSchema = yup.object().shape({
  housing_type: yup.string().required('Housing type is required').trim().nullable(),
  sub_type: yup
    .string()
    .when('housing_type', {
      is: (housing_type) => {
        let validate = false;
        if (housing_type !== 'room') {
          validate = true;
        }
        return validate;
      },
      then: () => yup.string().required('Please select sub type').nullable(),
    })
    .trim()
    .nullable(),
  floor: yup
    .string()
    .when('housing_type', {
      is: (housing_type) => housing_type === 'apartment',
      then: yup
        .string()
        .required('Number of Floor is required')
        .trim()
        .nullable('Number of Floor is required'),
    })
    .nullable(),
  availability: yup.string().required('Availability is required').trim().nullable(),
  selling_price: yup.string().required('Selling price is required').trim().nullable(),
  street: yup.string().required('Street address is required').trim().nullable(),
  country: yup.string().required('Country is required').trim().nullable(),
  nb: yup.string().required('NB is required').trim().nullable(),
  zip_code: yup.string().required('Zip code is required').trim().nullable(),
  city: yup.string().required('City is required').trim().nullable(),
  surface: yup
    .string()
    // .required('Surface area is required')
    .trim()
    .nullable(),
  furniture: yup.string().required('Furniture status is required').trim().nullable(),
  number_of_rooms: yup
    .string()
    .when('housing_type', {
      is: 'room',
      then: () => {},
      otherwise: () => yup.string().required('Number of rooms is required').trim().nullable(),
    })
    .trim()
    .nullable(),
  number_of_bedrooms: yup
    .string()
    .when('housing_type', {
      is: 'room',
      then: () => {},
      otherwise: () => yup.string().required('Number of bedrooms is required').trim().nullable(),
    })
    .trim()
    .nullable(),
  number_of_bathrooms: yup
    .string()
    .when('housing_type', {
      is: 'room',
      then: () => {},
      otherwise: () => yup.string().required('Number of bathrooms is required').trim().nullable(),
    })
    .trim()
    .nullable(),

  kitchen: yup
    .string()
    .when('housing_type', {
      is: 'room',
      then: () => {},
      otherwise: () => yup.string().required('Kitchen area is required').trim().nullable(),
    })
    .trim()
    .nullable(),
  kitchen_style: yup
    .string()
    .when('housing_type', {
      is: 'room',
      then: () => {},
      otherwise: () => yup.string().required('Kitchen style is required').trim().nullable(),
    })
    .trim()
    .nullable(),
  english_description: yup
    .string()
    // required('English description is required')
    .trim()
    .nullable(),
  french_description: yup
    .string()
    // .required('french description is required')
    .trim()
    .nullable(),
  title: yup.string().required('Title is required').trim().nullable(),
  first_name: yup
    .string()
    .required('First name is required')
    .max(100, 'Maximum 100 characters allowed')
    .trim()
    .nullable(),
  last_name: yup
    .string()
    .required('Last name is required')
    .max(100, 'Maximum 100 characters allowed')
    .trim()
    .nullable(),

  role: yup.string().required('Role is required').trim().nullable(),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required')
    .trim()
    .nullable(),
  phone: yup
    .string()
    .required('Mobile number is required')
    .min(6, 'min. 6 and max 13 digits are allowed')
    .max(13, 'min. 6 and max 13 digits are allowed')
    .trim()
    .nullable(),
  english_comment: yup.string().required('Description is required').trim().nullable(),
});

export const OrganizationSchema = yup.object().shape({
  organization_name: yup.string().required('Organisation name is required').trim().nullable(),
  // chosen_role: yup.string().required(t('RoleErr')),
  // street: yup.string().required('Street address is required').trim().nullable(),
  // zip_code: yup.string().required('Zip code is required').trim().nullable(),
  // city: yup.string().required('City is required').trim().nullable(),
  organization_email: yup
    .string()
    .email()
    .required('Organisation Email is required')
    .trim()
    .nullable(),
  organization_phone: yup.string().required('Organisation phone is required').trim().nullable(),
  // nb: yup.string().required('NB is required').trim().nullable(),
});

export const MemberSchem = yup.object().shape({
  title: yup.string().required('Title is required').trim().nullable(),
  position: yup.string().required('Position is required').trim().nullable(),
  first_name: yup.string().required('FnameErr').max(100, 'FnameMaxCharErr').trim().nullable(),
  last_name: yup.string().required('LnameErr').max(100, 'LnameMaxCharErr').trim().nullable(),
  email: yup.string().email('ValidEmailErr').required('EmailErr').trim().nullable(),
  mobile_number: yup
    .string()
    .required('MobileErr')
    .min(6, 'MinlenMobile')
    .max(13, 'MaxMobileErr')
    .trim()
    .nullable(),
  preferred_language: yup.string().required('LangErr').trim().nullable(),
  street: yup.string().required('AddressErr').trim().nullable(),
  street_number: yup.string().required('NbErr').trim().nullable(),
  zip_code: yup.string().required('ZipCodeErr').trim().nullable(),
  city: yup.string().required('CityErr').trim().nullable(),
  password: yup.string().min(8).required('PasswordErr').trim().nullable(),
  member_role: yup.string().required('Role is required').trim().nullable(),
});

export const FormSubmittedText = {
  ThankYouText: 'Thank you for your offer of employment',
  ApplicationProceedingText:
    ' Your application will be processed by the CAGI and validated by the FFA',
  InformedText: 'You will be informed about the decision by email within 2 to 5 working days.',
  Info: 'The CAGI Private Household Employees Platform is a service provided by the International Geneva Welcome Center which offer practical assistance for settling in to newcomers and their family to Geneva and the Lemanic region',
  Services: 'More services by CAGI',
};

export const suspendReasonsEn = [
  {
    label: 'I’ve found accommodation via the Bourse du Logement/www.immobilier.ch',
    value: 'I’ve found accommodation via the Bourse du Logement/www.immobilier.ch',
  },
  {
    label: 'I’ve found accommodation through a colleague/friend',
    value: 'I’ve found accommodation through a colleague/friend',
  },
  {
    label: 'I’ve found accommodation through a relocation agency/house hunter',
    value: 'I’ve found accommodation through a relocation agency/house hunter',
  },
];

export const suspendReasonsFr = [
  {
    label: `J'ai trouvé un logement via la Bourse du Logement/www.immobilier.ch`,
    value: 'I’ve found accommodation via the Bourse du Logement/www.immobilier.ch',
  },
  {
    label: `J'ai trouvé un logement via un collègue/ami`,
    value: 'I’ve found accommodation through a colleague/friend',
  },
  {
    label: `J'ai trouvé un logement via une agence de relocation/un intermédiaire`,
    value: 'I’ve found accommodation through a relocation agency/house hunter',
  },
];

export const satisfactionReasonsEn = [
  {
    label: 'Very Satisfied',
    value: 'very_satisfied',
  },
  {
    label: 'Satisfied',
    value: 'satisfied',
  },
  {
    label: 'Neutral',
    value: 'neutral',
  },
  {
    label: 'Unsatisfied',
    value: 'unsatisfied',
  },
  {
    label: 'Very Unsatisfied',
    value: 'very_unsatisfied',
  },
];

export const satisfactionReasonsFr = [
  {
    label: 'Très satisfait',
    value: 'very_satisfied',
  },
  {
    label: 'satisfait',
    value: 'satisfied',
  },
  {
    label: 'neutre',
    value: 'neutral',
  },
  {
    label: 'Insatisfait',
    value: 'unsatisfied',
  },
  {
    label: 'Très insatisfait',
    value: 'very_unsatisfied',
  },
];

export const countryOptions = [
  {
    label: 'Switzerland',
    value: 'Switzerland',
  },
  {
    label: 'France',
    value: 'france',
  },
];

export const countryOptionsfr = [
  {
    label: 'Suisse',
    value: 'Switzerland',
  },
  {
    label: 'La France',
    value: 'france',
  },
];

export const providerProfileSchema = yup.object().shape(
  {
    title: yup.string().required('Title is required').trim().nullable(),
    // position: yup.string().required('Position is required'),
    first_name: yup
      .string()
      .trim()
      .nullable()
      .required('First name is required')
      .max(100, 'Maximum 100 characters allowed'),
    last_name: yup
      .string()
      .trim()
      .nullable()
      .required('Last name is required')
      .max(100, 'Maximum 100 characters allowed'),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Email is required')
      .trim()
      .nullable(),
    preferred_language: yup.string().required('Language is required').trim().nullable(),
    oldpassword: yup.string().when('password', {
      is: (password) => password?.length > 0,
      then: yup.string().required('Please enter your old password'),
      // otherwise: yup.string().password().required(t('PasswordErr')),
    }),
    password: yup.string().when('oldpassword', {
      is: (oldpassword) => oldpassword?.length > 0,
      then: yup.string().required('Please enter your new password').min(8),
    }),
    confirm_password: yup.string().when('password', {
      is: (password) => password?.length > 0,
      then: yup
        .string()
        .required('Please enter your repeat password')
        .oneOf([yup.ref('password')], 'New password and repeat password do not match'),
    }),
  },
  [['password', 'oldpassword', 'confirm_password']]
);

export const propertyHoldReasons = [
  {
    label: 'Invalid telephone number',
    value: 'invalid_phone',
    frLabel: 'Numéro de téléphone pas valable',
  },
  {
    label: 'Availability of the property too far away in time',
    value: 'availability_of_the_property_too_far_away',
    frLabel: 'Rental period too short',
  },
  {
    label: 'Invalid telephone number',
    value: 'rental_short',
    frLabel: 'Durée de location trop courte',
  },
];

export const propertyRejectedReasons = [
  {
    label: 'Property too far away geographically',
    value: 'property_too_far_away',
    frLabel: 'Bien géographiquement trop éloigné',
  },
  {
    label: 'Tenancy method not in line with the law',
    value: 'tenancy_method_not_in_line',
    frLabel: 'Mode de location non conforme',
  },
  {
    label: 'Rent too high for the housing platform',
    value: 'rent_too_high',
    frLabel: 'Loyer trop élevé pour la Bourse du logement',
  },
];
