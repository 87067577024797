import { appRoutes } from '../../constants/routeConstants/Routes';
import AddAndEditProperty from '../../Pages/AddAndEditProperty';
import Dashboard from '../../Pages/Dashboard';
import Home from '../../Pages/Home';
import PropertyDetails from '../../Pages/PropertyDetails';
import ProviderRegistration from '../../Pages/ProviderRegistration';
import SeekerRegistration from '../../Pages/SeekerRegistration';
import LoginStep1 from '../../Pages/LoginStep1';
import Login from '../../Pages/Login';
import ProviderProfileUpdate from '../../components/provider/ProviderProfileUpdate';
import SeekerProfileUpdate from '../../components/seeker/SeekerProfileUpdate';
import DashBoardSeeker from '../../Pages/DashBoardSeeker';
import Organisations from '../../components/provider/Organisations';
import PendingActivation from '../../Pages/PendingActivation';
import EditSeekerHousingAlerts from '../../components/seeker/EditSeekerHousingAlerts';
import SeekerNewRequest from '../../components/seeker/Dashboard/NewRequest';
import SatisfactionSurvey from '../../components/seeker/Dashboard/SatifactionSurvey';
import AddNewMember from '../../components/provider/AddNewMember';
import ResetPassword from '../../Pages/ResetPassword';
import ChangePAssword from '../../Pages/ChangePassword';
import SetPasswordSuccess from '../../Pages/PasswordChangeSuccess';
import PrivacyPolicy from '../../Pages/PrivacyPolicy';
import Terms from '../../Pages/Terms';
import EmailVerified from '../../components/EmailVerified';
// ** Document title

// ** Default Route
const DefaultRoute = appRoutes.Home;

// ** Merge Routes
// const  = [
//   {
//     path: appRoutes.Home,
//     component: LandingPage,
//     meta: {
//       authRoute: true,
//     },
//   },
// ];

const AppRoutes = [
  {
    path: appRoutes.HOME,
    component: Home,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.PROVIDER_PROFILECONFORMATION,
    component: LoginStep1,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.SEEKER_PROFILECONFORMATION,
    component: LoginStep1,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.LOGIN_STEP_2,
    component: Login,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.AfterLoginMsg,
    component: EmailVerified,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.PROVIDER_REGISTRATION,
    component: ProviderRegistration,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.TermsCondition,
    component: PrivacyPolicy,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.Terms,
    component: Terms,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.SEEKER_REGISTRATION,
    component: SeekerRegistration,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.DASHBOARD_PROVIDER,
    component: Dashboard,
    authorizedRoles: ['provider'],
  },
  {
    path: appRoutes.DASHBOARD_SEEKER,
    component: DashBoardSeeker,
    authorizedRoles: ['seeker'],
  },
  {
    path: appRoutes.EDITSEEKERALERTS,
    component: EditSeekerHousingAlerts,
    authorizedRoles: ['seeker'],
  },
  {
    path: appRoutes.SEEKERNEWREQUEST,
    component: SeekerNewRequest,
    authorizedRoles: ['seeker'],
  },
  {
    path: appRoutes.SATISFACTIONSURVEY,
    component: SatisfactionSurvey,
    authorizedRoles: ['seeker'],
  },
  {
    path: appRoutes.PROPERTY_DETAIL_VIEW,
    component: PropertyDetails,
    authorizedRoles: ['provider', 'seeker'],
  },
  {
    path: appRoutes.ADD_PROPERTY,
    component: AddAndEditProperty,
    authorizedRoles: ['provider'],
  },
  {
    path: appRoutes.EDIT_PROPERTY,
    component: AddAndEditProperty,
    authorizedRoles: ['provider'],
  },
  {
    path: appRoutes.DRAFT_PROPERTY,
    component: AddAndEditProperty,
    authorizedRoles: ['provider'],
  },
  {
    path: appRoutes.DUPLICATE_PROPERTY,
    component: AddAndEditProperty,
    authorizedRoles: ['provider'],
  },
  {
    path: appRoutes.ADDNEWMEMBER,
    component: AddNewMember,
    authorizedRoles: ['provider'],
  },
  {
    path: appRoutes.PROVIDER_PROFILE_UPDATE,
    component: ProviderProfileUpdate,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.ORGANISATIONS,
    component: Organisations,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.SEEKER_PROFILE_UPDATE,
    component: SeekerProfileUpdate,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.pendingActivation,
    component: PendingActivation,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.ResetPassword,
    component: ResetPassword,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.SetPassword,
    component: ChangePAssword,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.SetPasswordSuccess,
    component: SetPasswordSuccess,
    meta: {
      authRoute: true,
    },
  },
];

// return (
//   <Routes>
//     {AppRoutes.map((route, index) => (
//       <Route key={`${index + 1}`} path={route.path} element={<route.element />} />
//     ))}
//   </Routes>
// );
// }

export { DefaultRoute, AppRoutes };
