/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import Button from '../components/Button';
import { appRoutes } from '../constants/routeConstants/Routes';
import { resetlogin } from '../redux/slices/Login';

function ProfileConfirmation() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetlogin());
  }, []);
  //   const pending = useSelector((state) => state.user.pending);
  const userInfo = useSelector((state) => state.fetchUser.user);
  const { name, email, last_name } = userInfo;
  const pending = false;
  const location = useLocation();
  const link = location.pathname.includes('provider')
    ? appRoutes.PROVIDER_REGISTRATION
    : appRoutes.SEEKER_REGISTRATION;
  const initial = last_name?.split('')[0];
  return (
    <div className="w-full bg-secgray h-fullX flex justify-center items-center">
      <div className="max-w-4xl md:w-3xl flex flex-col sm_ss:w-full sm_s:w-full sm_s:px-1 smx:w-9/12 sm_ss:item-center">
        <p className="text-themeSecgray font-bold text-4xl py-8 sm_s:py-1 sm_s:hidden sm_ss:hidden lg:py-2">
          {t('ProfileConformation')}
        </p>
        <p className="text-sectext mb-1 sm_s:text-center sm_ss:text-center sm_s:font-semibold sm_s:text-base sm_s:py-8 sm_ss:py-8 sm_ss:font-semibold lg:py-1">
          {name && email && t('ProfileConformationDesc')}
        </p>
        <div className="bg-white py-6 rounded-xl sm_ss:w-full sm_s:w-full sm_ss:rounded-none sm_s:rounded-none flex flex-col justify-center items-center 2xl:py-12">
          {pending ? (
            <CircularProgress />
          ) : (
            <div className="w-1/3 flex flex-col sm_s:w-full sm_ss:w-full sm_s:justify-center sm_s:items-center md:w-full md:justify-center smx:w-full">
              {name && email ? (
                <>
                  <UserInfo title={'Name'} value={`${name} ${initial}`} t={t} />
                  <UserInfo title={t('Email')} value={email} />
                </>
              ) : (
                ''
              )}
            </div>
          )}
          <div className="mt-5" />
          {name && email ? (
            <Button title={t('ThisIsME')} route={appRoutes.LOGIN_STEP_2} />
          ) : pending ? (
            ''
          ) : (
            <span className="text-sectext">{t('NoAccount')}</span>
          )}

          {name && email ? (
            <Button title={t('CreateNewProfile')} route={link} />
          ) : pending ? null : (
            <Button title={t('NewProfile')} route={link} />
          )}
        </div>
      </div>
    </div>
  );
}

function UserInfo({ title, value }) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center sm_s:justify-evenly py-1 sm_s:w-9/12 sm_ss:w-full sm_ss:text-center sm_s:items-center  w-64  md:self-center smx:text-center smx:w-9/12 smx:self-center">
      <span className="text-sectext  w-1/2 sm_s:text-center  capitalize mr-2">
        {t(`${title}`)}:
      </span>
      <span className="text-sectext w-1/2 sm_s:text-center capitalize">{value}</span>
    </div>
  );
}
export default ProfileConfirmation;
