/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-props-no-spreading */
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaPlus } from 'react-icons/fa';
import AxiosInstance from '../../Axios';
import API from '../../constants/API';
import DraftButton from '../common/DraftButton';
import ErrorContainer from '../ErrorContainer';
import SelectField from '../SelectField';
import TextInput from '../TextInput';

export const countryOptions = [
  {
    label: 'Switzerland',
    value: 'Switzerland',
  },
  {
    label: 'France',
    value: 'france',
  },
];

export const countryOptionsfr = [
  {
    label: 'Suisse',
    value: 'Switzerland',
  },
  {
    label: 'France',
    value: 'france',
  },
];

function AddressFormCard({
  expandAll,
  collapseAll,
  errors,
  scrollTo,
  noScroll,
  scrollState,
  setproperty_locationsOne,
  handelDraftSubmit,
  methods,
  property_locationsOneErr,
  language_selected,
  Data,
  cityLocationErr,
}) {
  const { t } = useTranslation();
  const [expended, setExpended] = useState(false);
  const [disable, setDisable] = useState(false);
  const [show, setShow] = useState(false);
  const [sameZipCityOptions, setSameZipCityOptions] = useState([]);
  const [sameZipRegionOptions, setSameZipRegionOptions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [citiesAndLocations, setCitiesAndLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { street, nb, zip_code, city, propertyLocationsOne, country } = methods.watch();

  useEffect(() => {
    AxiosInstance.get(API.CITIES_AND_LOCATIONS)
      .then((res) => {
        // eslint-disable-next-line arrow-body-style
        const data = res.data.result?.map((el) => {
          return {
            zip: el.zip_code,
            city: el.city,
            region: language_selected === 'en' ? el.english_region : el.french_region,
            label: el.zip_code,
            value: el.zip_code,
            region_value: el.region_value,
          };
        });
        setCitiesAndLocations(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [language_selected]);

  useEffect(() => {
    const data = [];
    const uniqueData = {};
    for (const i in citiesAndLocations) {
      const zip = citiesAndLocations[i]?.zip;
      uniqueData[zip] = citiesAndLocations[i];
    }
    for (const i in uniqueData) {
      data.push(uniqueData[i]);
    }
    setRegions(data);
  }, [citiesAndLocations]);

  useEffect(() => {
    const uniqueState = [];
    citiesAndLocations.map((loc) => {
      if (uniqueState.length > 0) {
        let ret = 1;
        uniqueState.map((val) => {
          if (val.zip === loc.zip) {
            ret = 0;
            return false;
          }
        });
        if (ret === 1) {
          uniqueState.push(loc);
        }
      } else {
        uniqueState.push(loc);
      }
    });
    // setRegions(uniqueState);
    if (country === 'france') {
      setShow(false);
      methods.setValue('propertyLocationsOne', t('CityBtn7'));
      setproperty_locationsOne({
        value: 'france',
        label: t('CityBtn7'),
        id: 7,
        name: t('CityBtn7'),
        region_value: 'france',
      });
      // methods.setValue('city', '');
    } else if (country === 'Switzerland') {
      const zipsa = [];
      const masterArray = [];
      citiesAndLocations.forEach((el) => {
        if (zipsa?.[0]?.zip !== el.zip) {
          masterArray.push(...zipsa);
        }
        if (zip_code === el.zip) {
          zipsa.push(el);
        }
        if (zipsa?.length > 1) {
          setShow(true);
          const cityData = [];
          const regionData = [];
          for (let i = 0; i < zipsa.length; i++) {
            const cityObj = {
              label: zipsa[i].city,
              value: zipsa[i].city,
            };
            const regionObj = {
              label: zipsa[i].region,
              value: zipsa[i].region,
            };

            cityData.push(cityObj);
            regionData.push(regionObj);
          }
          setSameZipCityOptions(cityData);
          setSameZipRegionOptions([regionData[0]]);
        } else {
          setShow(false);
        }
        if (zipsa?.length === 1) {
          if (el.zip === zip_code) {
            methods.setValue('propertyLocationsOne', el.region);
            methods.setValue('city', el.city, { shouldValidate: true });
            // if (el.city) {
            //   methods.clearErrors('city');
            // }
            setproperty_locationsOne(el);
          }
        }
      });
    }
    if (country === undefined || zip_code === undefined) {
      if (country === 'france') {
        methods.setValue('propertyLocationsOne', t('CityBtn7'));
      } else {
        methods.setValue('propertyLocationsOne', '');
      }
      methods.setValue('city', '');
      setproperty_locationsOne();
    }
  }, [country, zip_code, language_selected, citiesAndLocations]);

  useEffect(() => {
    if (expandAll) {
      setExpended(true);
    }
  }, [expandAll]);

  useEffect(() => {
    if (collapseAll) {
      setExpended(false);
    }
  }, [collapseAll]);

  useEffect(() => {
    if (!street || !nb || !zip_code || !city || !propertyLocationsOne) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [street, nb, zip_code, city, propertyLocationsOne]);

  return (
    <div className="border-2 rounded-lg overflow-hidden">
      <Accordion
        className="border-themeGray"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: '0',
          border: 'solid 2px #A6A6A6',
          borderRadius: '10px !important',
        }}
        expanded={expended}
        onChange={() => {
          setExpended(!expended);
        }}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          {expended ? (
            <FaMinus className="text-primary text-2xl my-auto" />
          ) : (
            <FaPlus className="text-primary text-2xl my-auto" />
          )}
          <div className="text-primary text-2xl font-extrabold px-3">{t('Address')}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col px-2 md:px-5 pb-4">
            <div
              ref={scrollState === 'country' ? scrollTo : noScroll}
              className="sm_ss:w-full sm_s:w-full md:mb-6 md:w-1/2 lg:w-1/2"
            >
              <SelectField
                id="country"
                options={language_selected === 'en' ? countryOptions : countryOptionsfr}
                title={t('formCountry')}
                language_selected={language_selected}
                defaultValue={Data?.country}
              />
              {errors?.country && <ErrorContainer errMsg={t(`${errors.country.message}`)} />}
            </div>
            <div className="md:grid md:grid-cols-6 gap-y-6 gap-x-4 md:gap-x-12">
              <div
                ref={scrollState === 'zip_code' ? scrollTo : noScroll}
                className="sm_ss:w-full sm_s:w-full col-span-2"
              >
                {country === 'Switzerland' ? (
                  regions.length > 0 && (
                    <SelectField
                      title={t('zip_code')}
                      options={regions}
                      name="zip_code"
                      id="zip_code"
                      defaultValue={Data?.zip_code}
                    />
                  )
                ) : (
                  <TextInput
                    text={t('zip_code')}
                    texttype="text"
                    // placeholder={t('Enter city')}
                    name="zip_code"
                    id="zip_code"
                    placeholder={t('Enter zip code')}
                    max="5"
                    // eslint-disable-next-line no-unneeded-ternary
                  />
                )}

                {errors?.zip_code && <ErrorContainer errMsg={t(`${errors.zip_code.message}`)} />}
              </div>
              <div
                ref={scrollState === 'street' ? scrollTo : noScroll}
                className=" w-full col-span-4"
              >
                <TextInput
                  text={t('Street')}
                  texttype="text"
                  placeholder={t('Enter your street name')}
                  name="street"
                  id="street"
                />
                {errors?.street && <ErrorContainer errMsg={t(`${errors.street.message}`)} />}
              </div>
              <div
                ref={scrollState === 'nb' ? scrollTo : noScroll}
                className="sm_ss:w-full sm_s:w-full col-span-2"
              >
                <TextInput
                  text={t('Nb')}
                  texttype="number"
                  placeholder={t('PlaceholderNumber')}
                  name="nb"
                  id="nb"
                />
                {errors?.nb && <ErrorContainer errMsg={t(`${errors.nb.message}`)} />}
              </div>
              <div
                ref={scrollState === 'city' ? scrollTo : noScroll}
                className="sm_ss:w-full sm_s:w-full col-span-4"
              >
                {show ? (
                  <SelectField
                    title={t('City')}
                    name="city"
                    id="city"
                    options={sameZipCityOptions}
                    defaultValue={Data?.city}
                  />
                ) : (
                  <TextInput
                    text={t('City')}
                    texttype="text"
                    placeholder={t('Enter city')}
                    name="city"
                    id="city"
                    // eslint-disable-next-line no-unneeded-ternary
                    disable={country === 'Switzerland' ? true : false}
                  />
                )}

                {errors?.city ? (
                  <ErrorContainer errMsg={t(`${errors?.city?.message}`)} />
                ) : (
                  cityLocationErr?.err && (
                    <ErrorContainer errMsg={t(`${cityLocationErr?.message}`)} />
                  )
                )}
              </div>
              <div className="w-full col-span-4">
                <div
                  className="flex flex-col w-full"
                  ref={scrollState === 'propertyLocations' ? scrollTo : noScroll}
                >
                  {show ? (
                    <SelectField
                      title={t('Select property location')}
                      id="propertyLocationsOne"
                      name="propertyLocationsOne"
                      options={sameZipRegionOptions}
                      defaultValue={sameZipRegionOptions?.[0]}
                    />
                  ) : (
                    <TextInput
                      text={t('Select property location')}
                      id="propertyLocationsOne"
                      name="propertyLocationsOne"
                      disable
                    />
                  )}
                </div>
                {property_locationsOneErr?.err && (
                  <ErrorContainer errMsg={property_locationsOneErr?.message} />
                )}
              </div>
            </div>

            <DraftButton
              handelDraftSubmit={() => {
                handelDraftSubmit('draft', setIsLoading);
              }}
              disable={disable}
              language_selected={language_selected}
              draftLoading={isLoading}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AddressFormCard;
