/* eslint-disable camelcase */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Cookies from 'js-cookie';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@mui/material';
import { appRoutes } from '../../../constants/routeConstants/Routes';
import AxiosInstance from '../../../Axios';
import API from '../../../constants/API';

function PropertyStatusBar({ setIsSuspended }) {
  const success = useSelector((state) => state.suspendRequestReducer.success);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [reason, setReason] = useState();
  const languageSelected = Cookies.get('i18next');

  const reasonsOptions = [
    {
      label: 'Budget too low for the type of accommodation requested',
      value: 'budget_too_low',
      frLabel: 'Budget trop bas pour le type de logement demandé',
    },
    {
      label: 'Number of rooms too high',
      value: 'number_rooms_high',
      frLabel: 'Nombre de chambres trop élevé',
    },
  ];

  const ProfileHoldOptions = [
    {
      label: 'Employer not specified',
      value: 'employer_not_specified',
      frLabel: 'Employeur non spécifié',
    },
  ];

  const SeekerRequestRejected = [
    {
      label:
        'The Housing platform is available for employees of the International Geneva, i.e. IO, MP, consulates, NGOs and faculty of the HUG and UNIGE.',
      value: 'housing_platform_availability',
      frLabel:
        'Plateforme disponible pour les employés de la Genève internationale, soit OI, MP, Consulats, ONG et corps professoral des HUG et de l’UNIGE',
    },
    {
      label: 'Rental period too short, minimum 1 month',
      value: 'rental_period_short',
      frLabel: 'Durée de location trop courte, minimum 1 mois',
    },
  ];

  useEffect(() => {
    if (userProfile?.status === 'hold') {
      ProfileHoldOptions.forEach((element) => {
        if (element.value === userProfile?.reason) {
          if (languageSelected === 'en') {
            setReason(element.label);
          } else {
            setReason(element.frLabel);
          }
        }
      });
    }
    if (userProfile?.latest_request?.status === 'hold') {
      reasonsOptions.forEach((element) => {
        if (element.value === userProfile?.latest_request?.reason) {
          if (languageSelected === 'en') {
            setReason(element.label);
          } else {
            setReason(element.frLabel);
          }
        }
      });
    }
    if (userProfile?.latest_request?.status === 'rejected') {
      SeekerRequestRejected.forEach((element) => {
        if (element.value === userProfile?.latest_request?.reason) {
          if (languageSelected === 'en') {
            setReason(element.label);
          } else {
            setReason(element.frLabel);
          }
        }
      });
    }
  }, [userProfile?.latest_request?.reason, languageSelected, userProfile?.status]);

  useLayoutEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.data?.result?.access_token;
    if (token) {
      const userProfileData = async () => {
        const res = await AxiosInstance.post(API.GETPROFILE);
        setUserProfile(res.data.result);
      };
      userProfileData();
    }
  }, []);

  const suspendRequest = () => {
    setShowModal(false);
    setIsSuspended(true);
  };

  useEffect(() => {
    if (success === true) {
      localStorage.setItem('suspend', 'suspended');
    }
  }, [success]);

  const expireDate = moment(userProfile?.latest_request?.expiry_date).format('DD MMM YYYY');
  const month = expireDate.split(' ')[1];

  const suspendDate = moment(userProfile?.latest_request?.suspended_date).format('DD MMM YYYY');
  const susMonth = t(`${suspendDate.split(' ')[1]}`);

  return (
    <>
      <div
        className={`flex flex-col items-center justify-center my-12 md:w-full max-w-[920px] lg:w-[670px] lg:text-lg mx-auto bg-white py-6 px-4 rounded-2xl ${
          (userProfile?.latest_request?.status === 'suspended' ||
            userProfile?.latest_request?.status === 'rejected' ||
            userProfile?.latest_request?.status === 'expired') &&
          'border border-red-600 text-red-600'
        }`}
      >
        <div className="py-2">
          {userProfile?.status === 'hold' ? (
            <div className="flex items-start gap-6 sm_ss:flex-col sm_s:flex-col">
              <div>
                {t('Your profile is on hold')}
                <p className="text-sm text-gray-400">
                  {reason ? `(${reason})` : `(${userProfile?.personal_note})`}
                </p>
              </div>
              <button
                onClick={() => navigate(appRoutes.SEEKER_PROFILE_UPDATE)}
                type="submit"
                className="bg-blue text-white font-bold text-sm py-2 px-2 w-64 rounded-md uppercase"
              >
                {t('Edit_details')}
              </button>
            </div>
          ) : (
            <>
              {userProfile?.latest_request?.suspended_date !== null &&
                userProfile?.latest_request?.status === 'suspended' && (
                  <div className="flex items-center gap-4 sm_ss:flex-col sm_s:flex-col">
                    <p>{t('Alerts are suspended since')}</p>
                    {`${suspendDate?.split(' ')[0]}  ${susMonth}  ${suspendDate?.split(' ')[2]}`}
                    <button
                      onClick={() => navigate(appRoutes.SEEKERNEWREQUEST)}
                      type="submit"
                      className="bg-blue text-white font-bold text-sm py-2 px-5 w-64 rounded-md"
                    >
                      <p className="capitalize">{t('active')}</p>
                    </button>
                  </div>
                )}

              {userProfile?.latest_request?.expiry_date !== null &&
                userProfile?.latest_request?.status === 'active' && (
                  <div className="flex items-center gap-4 sm_ss:flex-col sm_s:flex-col ">
                    {t('Alerts activated until')}{' '}
                    {`${expireDate?.split(' ')[0]}  ${t(`${month}`)}  ${expireDate?.split(' ')[2]}`}
                    <button
                      onClick={() => setShowModal(true)}
                      type="submit"
                      className="bg-blue text-white font-bold text-sm py-2 px-5 w-64 rounded-md"
                    >
                      {t('SUSPEND')}
                    </button>
                  </div>
                )}

              {userProfile?.latest_request?.expiry_date !== null &&
                userProfile?.latest_request?.status === 'expired' && (
                  <div className="flex items-start gap-4 sm_ss:flex-col sm_s:flex-col">
                    <div>
                      {t('Alerts are expired since')}
                      <p>{t('ten')}</p>
                    </div>
                    <button
                      onClick={() => navigate(appRoutes.SEEKERNEWREQUEST)}
                      type="submit"
                      className="bg-blue text-white font-bold text-sm py-2 w-64 px-2 rounded-md"
                    >
                      {t('NEW SEARCH')}
                    </button>
                  </div>
                )}

              {userProfile?.latest_request?.status === 'archived' && (
                <div className="flex items-center gap-4 sm_ss:flex-col sm_s:flex-col">
                  {t('Your request has been archieved')}
                  <button
                    onClick={() => navigate(appRoutes.SEEKERNEWREQUEST)}
                    type="submit"
                    className="bg-blue text-white font-bold text-sm py-2 w-64 px-2 rounded-md"
                  >
                    {t('NEW SEARCH')}
                  </button>
                </div>
              )}

              {userProfile?.latest_request === null && (
                <div className="flex items-center gap-6 sm_ss:flex-col sm_s:flex-col">
                  {t('Your request has been deleted')}
                  <button
                    onClick={() => navigate(appRoutes.SEEKERNEWREQUEST)}
                    type="submit"
                    className="bg-blue text-white font-bold text-sm py-2 w-auto rounded-md"
                  >
                    {t('NEW SEARCH')}
                  </button>
                </div>
              )}

              {userProfile?.latest_request?.status === 'rejected' && (
                <div className="flex items-start gap-6 sm_ss:flex-col sm_s:flex-col">
                  <div>
                    {t('Your request has been rejected')}{' '}
                    <p className="text-sm text-gray-400">
                      {reason ? `(${reason})` : userProfile?.latest_request?.personal_note}
                    </p>
                  </div>
                  <button
                    onClick={() => navigate(appRoutes.SEEKERNEWREQUEST)}
                    type="submit"
                    className="bg-blue text-white font-bold text-sm py-2 px-2 w-64 rounded-md"
                  >
                    {t('NEW SEARCH')}
                  </button>
                </div>
              )}

              {userProfile?.latest_request?.status === 'hold' && (
                <div className="flex items-start gap-6 sm_ss:flex-col sm_s:flex-col">
                  <div>
                    {t('Your request is on hold')}
                    <p className="text-sm text-gray-400">
                      {reason ? `(${reason})` : userProfile?.latest_request?.personal_note}
                    </p>
                  </div>
                  <button
                    onClick={() => navigate(appRoutes.EDITSEEKERALERTS)}
                    type="submit"
                    className="bg-blue text-white font-bold text-sm py-2 px-2 w-64 rounded-md uppercase"
                  >
                    {t('Modify request detail')}
                  </button>
                </div>
              )}

              {userProfile?.latest_request?.status === 'pending' && (
                <>
                  <p className="text-sectext md:text-sm text-center w-full">
                    {t('ApplicationProceedingText')}
                  </p>
                  <p className="text-sectext md:text-sm text-center w-full">{t('InformedText')}</p>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="absolute w-full md:w-[500px] md:left-1/2 md:-translate-x-[250px] top-1/4 bg-[#E8E8E8] border-[#707070] rounded-lg p-10">
          <div className="">
            <div className="font-bold mb-2">
              {t('Please confirm the suspension of your alerts.')}
            </div>
          </div>
          <div className="flex mt-5 w-full">
            <button
              type="button"
              onClick={suspendRequest}
              className="bg-primary text-white font-bold text-sm py-2 px-5  rounded-md"
            >
              {t('SUSPEND')}
            </button>
            <button
              onClick={() => {
                setShowModal(false);
              }}
              type="submit"
              className=" text-primary font-bold text-sm py-2 px-5 rounded-md"
            >
              {t('CANCEL')}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PropertyStatusBar;
