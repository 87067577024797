// eslint-disable-next-line import/no-extraneous-dependencies
import PhoneInput from 'react-phone-input-2';
import { Controller, useForm, useFormContext } from 'react-hook-form';

export default function CountryCodeInput({ country, setCountryCode, placeholder, name, textType }) {
  const { control } = useFormContext();

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Controller
      render={({ field: { onChange, value } }) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <PhoneInput
          onChange={(e, data) => {
            setCountryCode(e, data);
            onChange(e);
          }}
          value={value}
          placeholder={placeholder}
          country={country}
          inputProps={{ style: { padding: '5px 19px 7.5px 58px', width: '332px' } }}
          specialLabel=""
          type={`${textType}`}
          // onChange={(phone) => this.setState({ phone })}
        />
      )}
      control={control}
      name={name}
    />
  );
}
