import { createSlice } from '@reduxjs/toolkit';

const suspendRequest = createSlice({
  name: 'suspendRequest',
  initialState: {
    pending: false,
    success: false,
    error: false,
  },
  reducers: {
    suspendRequestStart: (state) => {
      state.pending = true;
      state.error = false;
    },
    suspendRequestSuccess: (state) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    suspendRequestError: (state, action) => {
      state.success = false;
      state.pending = false;
      state.error = action.payload;
    },
    resetsuspendRequest: (state) => {
      state.success = false;
    },
  },
});
export const {
  suspendRequestError,
  suspendRequestStart,
  suspendRequestSuccess,
  resetsuspendRequest,
} = suspendRequest.actions;
const suspendRequestReducer = suspendRequest.reducer;
export default suspendRequestReducer;
