/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-var */
import React, { useState } from 'react';

import './App.css';

import Header from './components/layoutComponents/Header';
import Routers from './router/Router';
import Footer from './components/layoutComponents/Footer';

function App() {
  const [stateUpdate, setStateUpdate] = useState(false);
  const [toggleUserGuideURL, setToggleUserGuideURL] = useState(false);
  const [language, setLanguage] = useState(false);
  const toggleURL = (val) => {
    setToggleUserGuideURL(val);
  };

  return (
    <>
      <Header
        stateUpdate={stateUpdate}
        toggleURL={toggleURL}
        setLanguage={setLanguage}
        language={language}
      />
      <div
        style={{
          height: 'calc(100vh - 128px)',
          overflowY: 'scroll',
        }}
        className="w-full h-full flex flex-col justify-between bg-[#E8E8E8]"
      >
        <div className="flex-1 sm:ss:p-4 sm_s:p-4" onClick={() => setLanguage(false)}>
          <Routers setStateUpdate={setStateUpdate} />
        </div>
        <Footer toggleUserGuideURL={toggleUserGuideURL} />
      </div>
    </>
  );
}

export default App;
