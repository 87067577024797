import React from 'react';

function PageHeading({ className, children }) {
  return (
    <h3
      className={`sm:text-base md:text-lg lg:text-xl xl:text-4xl font-bold text-[#6F6F6F] ${className}`}
    >
      {children}
    </h3>
  );
}

export default PageHeading;
