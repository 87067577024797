/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { useLocation } from 'react-router-dom';
import { appRoutes } from '../../constants/routeConstants/Routes';
import FooterTextContainer from './FooterTextContainer';

function Footer({ toggleUserGuideURL }) {
  const location = useLocation();
  const link = toggleUserGuideURL
    ? 'https://www.cagi.ch/fr/infos-pratiques/plateforme-emploi-domestiques-prives/#guide'
    : '#';
  const path =
    location.pathname === appRoutes.HOME ||
    location.pathname === appRoutes.SEEKER_REGISTRATION ||
    location.pathname === appRoutes.PROVIDER_REGISTRATION ||
    location.pathname === appRoutes.PROVIDER_PROFILECONFORMATION ||
    location.pathname === appRoutes.SEEKER_PROFILECONFORMATION ||
    location.pathname === appRoutes.LOGIN_STEP_2;

  const footerOptions = [
    {
      id: 1,
      text: 'ABOUT CAGI',
      link: 'https://www.cagi.ch/en/about/',
      icon: (
        <i className="fa-solid smx:text-xs fa-circle-info text-white text-lg cursor-pointer flex items-center justify-center h-6 w-6 mr-1 lg:text-xl" />
      ),
    },
    {
      id: 2,
      text: 'TERMS OF USE',
      link: appRoutes.Terms,
      icon: (
        <i className="fa-solid fa-file-lines text-white text-lg cursor-pointer flex items-center justify-center h-6 w-6 mr-1 smx:text-xs lg:text-xl" />
      ),
    },
    {
      id: 3,
      text: 'PrivacyPolicy',
      link: appRoutes.TermsCondition,
      icon: (
        <i className="fa-solid smx:text-xs fa-file-lines text-white text-lg cursor-pointer flex items-center justify-center h-6 w-6 mr-1 lg:text-xl" />
      ),
    },
  ];

  const data = [
    {
      id: 4,
      text: 'USER GUIDE',
      link,
      icon: (
        <i className="fa-solid fa-circle-question text-white text-lg cursor-pointer flex items-center justify-center h-6 w-6 mr-1 lg:text-xl smx:text-sm" />
      ),
    },
  ];

  return (
    <div
      className={`${
        path && 'hidden'
      } w-full smx:flex-col md:flex md:justify-between py-4 px-3 bg-blue`}
    >
      <div className="flex gap-3 w-[75%] flex-row smx:flex-col smx:items-start items-center sm_ss:flex-col sm_ss:items-start sm_s:flex-col sm_s:items-start">
        {footerOptions.map((el) => (
          <FooterTextContainer
            key={el.id}
            icon={el.icon}
            text={el.text}
            link={el.link}
            flex1={false}
          />
        ))}
      </div>
      <div className="flex w-[25%] items-center smx:items-start smx:justify-start justify-end sm_ss:flex-col sm_ss:items-start sm_s:flex-col sm_s:items-start">
        {data.map((el) => (
          <FooterTextContainer
            key={el.id}
            icon={el.icon}
            text={el.text}
            link={el.link}
            flex1={false}
          />
        ))}
      </div>
    </div>
  );
}

export default Footer;
